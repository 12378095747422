import { Icon, Message, Popup } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { format } from 'date-fns'
import * as React from 'react'

import { Recipe, RecipeSchedule } from '../../../types'
import RemoteValue from '../../remoteValues/remoteValue'
import UserName from '../../remoteValues/userName'

interface Props {
  isFirstOne?: boolean
  schedule: RecipeSchedule
}

export default function RecipeScheduleFeedItem(props: Props) {
  const { isFirstOne, schedule } = props

  const [isExpanded, setIsExpanded] = React.useState(false)

  const hasArgs = Object.keys(schedule.args || {}).length > 0

  const duration =
    (((schedule.completedOn || 0) - (schedule.startedOn || 0)) / 1000).toFixed(
      1,
    ) + 's'

  return (
    <React.Fragment>
      <div className="rounded-md border border-dotted bg-white p-6">
        <header className="relative flex items-baseline justify-between gap-2 text-base">
          <div className="mr-2 self-start rounded-full border bg-slate-100 px-[12.07px] py-[10.032px] text-slate-600">
            <Icon size="large" name="lab" fitted />
          </div>

          <div className="grow">
            <span className="text-lg text-slate-600">
              Recipe{' '}
              <RemoteValue
                predicate={(r: Recipe) => (
                  <NavLink
                    className="font-semibold !text-slate-700"
                    to={'/recipes/' + r._id}
                  >
                    {r.name}
                  </NavLink>
                )}
                collection="recipes"
                id={schedule.recipeId}
              />{' '}
              ran on {format(schedule.completedOn || 0, 'PPpp')}{' '}
              {schedule.status === 'error' && (
                <Popup
                  position="top center"
                  content={<>Failed in {duration}</>}
                  trigger={
                    <span className="ml-1 cursor-help">
                      <span className="text-red-500">
                        <Icon name="times" size="small" />
                      </span>
                      <span className="text-base text-slate-400">
                        {duration}
                      </span>{' '}
                    </span>
                  }
                />
              )}
              {schedule.status === 'completed' && (
                <Popup
                  position="top center"
                  content={<>Completed successfully in {duration}</>}
                  trigger={
                    <span className="ml-1 cursor-help">
                      <span className="text-green-500">
                        <Icon name="check" size="small" />
                      </span>
                      <span className="text-base text-slate-400">
                        {duration}
                      </span>{' '}
                    </span>
                  }
                />
              )}
            </span>

            {schedule.runType === 'instantly' && (
              <div className="mt-1.5 flex flex-wrap gap-1 text-base text-slate-500">
                Triggered instantly by{' '}
                <span className="font-semibold text-slate-600">
                  <UserName id={schedule.createdBy} />
                </span>
              </div>
            )}

            {schedule.runType === 'delayed' && (
              <div className="mt-1.5 text-base text-slate-500">
                <span className="relative -top-px">
                  <Icon name="calendar alternate outline" fitted size="small" />
                </span>{' '}
                Scheduled by{' '}
                <span className="font-semibold text-slate-600">
                  <UserName id={schedule.createdBy} />
                </span>{' '}
                on {format(schedule.addedToTheQueueOn || 0, 'PPpp')}
              </div>
            )}
          </div>

          <Popup
            disabled={isExpanded}
            position="top center"
            content="Display arguments"
            trigger={
              <button
                type="button"
                onClick={() => setIsExpanded((v) => !v)}
                className="relative -right-2 px-2 py-0.5"
              >
                <Icon
                  name={isExpanded ? 'chevron up' : 'chevron down'}
                  fitted
                />
              </button>
            }
          />
        </header>

        {isExpanded && hasArgs && (
          <div className="-mx-6 -mb-6 mt-4 border-t bg-slate-50 px-6 pb-2">
            <div className="mb-2 mt-4 px-2 text-slate-500">
              Recipe was invoked with these arguments:
            </div>
            <table className="w-full">
              <thead>
                <tr className="border-b text-left text-sm font-semibold uppercase text-primary">
                  <th className="px-2 py-2">Argument</th>
                  <th className="px-2 py-2">Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(schedule.args || {}).map(
                  ([argName, argValue]) => {
                    return (
                      <tr
                        className="border-b align-baseline text-sm last:border-b-0"
                        key={argName}
                      >
                        <td className="px-2 py-2 font-mono font-semibold text-slate-500">
                          {argName}
                        </td>
                        <td className="px-2 py-2 text-base text-slate-700">
                          {argValue || (
                            <span className="font-mono italic text-slate-400">
                              empty
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
          </div>
        )}

        {isExpanded && !hasArgs && (
          <div className="-mx-6 -mb-6 mt-4 border-t bg-slate-50 px-6 py-4 italic text-slate-500">
            The recipe was invoked with <strong>no arguments</strong>.
          </div>
        )}

        {schedule.error && (
          <Message error size="mini" as="pre">
            {JSON.stringify(schedule.error || {}, null, 2)}
          </Message>
        )}
      </div>

      {!isFirstOne && <div className="ml-12 h-14 border-l border-dotted" />}
    </React.Fragment>
  )
}
