import { NavLink } from 'react-router-dom'
import { groupBy } from 'ramda'
import { format } from 'date-fns'
import * as React from 'react'
import { Popup } from 'semantic-ui-react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  timeOffTransactionSchema,
  candidateSchema,
  contractSchema,
  companySchema,
} from '../types'
import { classNames, currencyFormat, validateResponse } from '../utils'
import NewsSection from './NewsSection'
import { useApi } from '../store/mainContext'

interface Props {
  periodStart: number
  periodEnd: number
}

export default function TimeoffNews(props: Props) {
  const { periodStart, periodEnd } = props

  const api = useApi()

  const { data, isLoading, error } = useSWR(
    ['period-timeoff', periodStart, periodEnd],
    async () => {
      const timeoffTxs = await api
        .get('/timeoff-transactions/pro', {
          params: {
            date: { $gte: periodStart, $lte: periodEnd },
            status: 'Approved',
          },
          headers: {
            'astor-sortby': 'date',
            'astor-sortby-dir': 1,
          },
        })
        .then(validateResponse(z.array(timeOffTransactionSchema)))

      const contracts = await api
        .get('contracts/in', {
          params: { _id: timeoffTxs.map((d) => d.contractId) },
        })
        .then(validateResponse(z.array(contractSchema)))

      const [candidates, customers] = await Promise.all([
        api
          .get('candidates/in', {
            params: { _id: contracts.map((c) => c.candidateId) },
          })
          .then(validateResponse(z.array(candidateSchema))),
        api
          .get('companies/in', {
            params: { _id: contracts.map((c) => c.customerId) },
          })
          .then(validateResponse(z.array(companySchema))),
      ])

      return timeoffTxs
        .map((tx) => {
          const contract = contracts.find((c) => c._id === tx.contractId)
          return {
            ...tx,
            contract,
            candidate: candidates.find((c) => c._id === contract?.candidateId),
            customer: customers.find((c) => c._id === contract?.customerId),
          }
        })
        .filter((tx) => !!tx.contract) // filter out txs from deleted contracts
    },
  )

  const timeoffTxs = data || []

  const debits = timeoffTxs.filter((tx) => tx.type === 'debit')

  const groupedByCandidate = groupBy(
    ({ candidate }) => candidate?._id || 'none',
    debits,
  )

  return (
    <NewsSection
      title="Time-off"
      isLoading={isLoading}
      error={error?.message}
      placeholderCount={4}
    >
      {debits.length > 0 ? (
        <ul className="space-y-2">
          {Object.entries(groupedByCandidate).map(([candidateId, txs]) => {
            const candidate = (txs || [])[0]?.candidate
            const customer = (txs || [])[0]?.customer
            return (
              <li
                className="rounded-md bg-slate-50 px-4 py-3"
                key={candidateId}
              >
                <div className="m-0 text-base leading-normal">
                  {candidate?.name ? (
                    <NavLink
                      className="font-semibold text-primary hover:!text-black"
                      to={'/candidates/' + candidate?._id}
                    >
                      {candidate.name}
                    </NavLink>
                  ) : (
                    <span className="text-slate-600">Unknown candidate</span>
                  )}{' '}
                  from <span className="font-semibold">{customer?.name}</span>
                </div>

                <div className="mt-1 inline-flex flex-wrap gap-x-2 pr-6">
                  {(txs || []).map((tx, i, allTxs) => {
                    const creditTx = timeoffTxs.find(
                      (t) => t.uncoveredTransactionId === tx._id,
                    )
                    const isNotCovered = Boolean(creditTx)
                    return (
                      <span
                        className={classNames(
                          isNotCovered
                            ? 'cursor-help text-red-700'
                            : 'text-slate-600',
                          'whitespace-nowrap text-base',
                        )}
                        key={tx._id}
                      >
                        <Popup
                          size="mini"
                          content={
                            <div>
                              Not covered by PTO policy
                              {typeof creditTx?.dayValue === 'number' && (
                                <div className="text-red-700">
                                  Cost: {currencyFormat(creditTx.dayValue)}
                                </div>
                              )}
                            </div>
                          }
                          position="top center"
                          mouseEnterDelay={0}
                          mouseLeaveDelay={0}
                          disabled={!isNotCovered}
                          trigger={
                            <span
                              className={classNames(
                                isNotCovered &&
                                  'underline decoration-red-600 decoration-dotted underline-offset-4',
                              )}
                            >
                              {format(tx.date, 'MMM do')}
                            </span>
                          }
                        />

                        {i < allTxs.length - 1 && (
                          <span className="text-slate-800">,</span>
                        )}
                      </span>
                    )
                  })}
                </div>
              </li>
            )
          })}
        </ul>
      ) : (
        <div className="italic text-slate-500">
          No time-off taken this period
        </div>
      )}
    </NewsSection>
  )
}
