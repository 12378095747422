import { format, isPast, isSameDay } from 'date-fns'
import * as React from 'react'
import { Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import { z } from 'zod'

import {
  timeoffRequestSchema,
  candidateSchema,
  contractSchema,
  TimeoffRequest,
  companySchema,
  Candidate,
  Contract,
  Company,
} from '../../types'
import { classNames, currencyFormat, validateResponse } from '../../utils'
import useTimeoffCoverage from '../../hooks/useTimeoffCoverage'
import NewsSection from '../../components/NewsSection'
import { useApi } from '../../store/mainContext'

type ExtraFields = {
  candidate?: Candidate
  contract?: Contract
  customer?: Company
}

interface ItemProps {
  timeoffRequest: TimeoffRequest & ExtraFields
}

function Item(props: ItemProps) {
  const coverage = useTimeoffCoverage(props.timeoffRequest._id)

  return (
    <div className="">
      <div className="flex items-baseline justify-between gap-4">
        <span>
          <Link
            className="text-lg font-semibold !text-slate-600 hover:!text-primary"
            to={'/candidates/' + props.timeoffRequest.candidate?._id}
          >
            {props.timeoffRequest.candidate?.name}
          </Link>{' '}
          <span className="mx-1">from</span>{' '}
          <Link
            className="text-lg font-semibold !text-slate-600 hover:!text-primary"
            to={'/companies/' + props.timeoffRequest.customer?._id}
          >
            {props.timeoffRequest.customer?.name}
          </Link>
        </span>

        <div
          className={classNames(
            'inline-block rounded px-1.5 py-0.5 text-xs font-semibold uppercase',
            props.timeoffRequest.status === 'Approved' &&
              'bg-green-300 text-green-800',
            props.timeoffRequest.status === 'Rejected' &&
              'bg-red-200 text-red-800',
            props.timeoffRequest.status === 'Canceled' &&
              'bg-slate-200 text-slate-700',
            props.timeoffRequest.status === 'Waiting for approval' &&
              props.timeoffRequest.underReview
              ? 'bg-cyan-200 text-cyan-800'
              : 'bg-amber-200 text-amber-800',
          )}
        >
          {props.timeoffRequest.status === 'Waiting for approval' &&
          props.timeoffRequest.underReview
            ? 'Under review'
            : props.timeoffRequest.status}
        </div>
      </div>

      <div>
        {props.timeoffRequest.dates.map((day, i) => {
          const tx = (coverage.data || []).find(
            (item) => isSameDay(item.date, day) && !item.coveredByPtoPolicy,
          )
          const isNotCovered = Boolean(tx)
          return (
            <span
              className={classNames(
                isNotCovered
                  ? 'cursor-help text-red-700'
                  : isPast(day)
                    ? 'text-slate-400'
                    : 'text-slate-600',
                'whitespace-nowrap text-base',
              )}
              key={day.toISOString()}
            >
              <Popup
                size="mini"
                content={
                  <div>
                    Not covered by PTO policy
                    {typeof tx?.dayValue === 'number' && (
                      <div>Cost: {currencyFormat(tx.dayValue)}</div>
                    )}
                  </div>
                }
                position="top center"
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                disabled={!isNotCovered}
                trigger={
                  <span
                    className={classNames(
                      isNotCovered &&
                        'underline decoration-red-600 decoration-dotted underline-offset-4',
                    )}
                  >
                    {format(day, 'MMM do')}
                  </span>
                }
              />

              {i < props.timeoffRequest.dates.length - 1 && (
                <span className="text-slate-800">, </span>
              )}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default function TimeoffForReview() {
  const api = useApi()

  const { data, isLoading, error } = useSWR(
    'pending-timeoff-requests',
    async () => {
      const requests = await api
        .get('/timeoff-requests', {
          params: { status: 'Waiting for approval' },
          headers: {
            'astor-sortby': 'lastUpdateOn',
            'astor-sortby-dir': '-1',
          },
        })
        .then(validateResponse(z.array(timeoffRequestSchema)))

      const contracts = await api
        .get('/contracts/in', {
          params: { _id: requests.map((r) => r.contractId) },
        })
        .then(validateResponse(z.array(contractSchema)))

      const [candidates, customers] = await Promise.all([
        api
          .get('/candidates/in', {
            params: { _id: contracts.map((c) => c.candidateId) },
          })
          .then(validateResponse(z.array(candidateSchema))),
        api
          .get('/companies/in', {
            params: { _id: contracts.map((c) => c.customerId) },
          })
          .then(validateResponse(z.array(companySchema))),
      ])

      return (
        requests
          .map((request) => {
            const contract = contracts.find((c) => c._id === request.contractId)
            return {
              ...request,
              contract,
              candidate: candidates.find(
                (c) => c._id === contract?.candidateId,
              ),
              customer: customers.find((c) => c._id === contract?.customerId),
            }
          })
          // filter out requests from deleted candidates, customers or contracts
          .filter((r) => r.candidate && r.customer && r.contract)
      )
    },
  )

  const requests = data || []

  return (
    <NewsSection
      title="Time-off Requests awaiting review"
      isLoading={isLoading}
      placeholderCount={4}
      error={error?.message}
    >
      {requests.length > 0 ? (
        <ul className="space-y-2">
          {requests.map((request) => (
            <li className="rounded-md bg-slate-50 px-4 py-2" key={request._id}>
              <Item timeoffRequest={request} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="italic text-slate-500">
          No time-off requests awaiting review
        </div>
      )}
    </NewsSection>
  )
}
