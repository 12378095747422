import {
  ButtonGroup,
  ButtonOr,
  Button,
  Input,
  Popup,
  Form,
  Ref,
} from 'semantic-ui-react'
import {
  SubmitHandler,
  useFieldArray,
  Controller,
  useForm,
} from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import TextareaAutosize from 'react-textarea-autosize'
import { startOfToday } from 'date-fns'
import { AxiosError } from 'axios'
import * as React from 'react'

import { Collection, Recipe, RecipeArgs } from '../../types'
import { classNames, getTimestamp } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

type FormValues = {
  args: (RecipeArgs & { value: string | number | null })[]
  date: Date
  time: string
}

interface Props {
  relatedCollection: Collection
  onSuccess(): Promise<any>
  relatedId: string
  recipe: Recipe
  inlineDateTime?: boolean
  onCancel?(): void
}

export default function RecipeSchedulerForm(props: Props) {
  const {
    onCancel,
    inlineDateTime,
    recipe,
    relatedCollection,
    onSuccess,
    relatedId,
  } = props

  const { addToast } = useToasts()
  const api = useApi()

  const form = useForm<FormValues>({
    defaultValues: {
      args: (recipe.args || []).map((arg) => ({
        ...arg,
        value: arg.type === 'number' ? null : '',
      })),
      date: undefined,
      time: '',
    },
  })

  const argsField = useFieldArray({ control: form.control, name: 'args' })

  const [shouldSchedule, setShouldSchedule] = React.useState(!!inlineDateTime)
  const [isPending, setIsPending] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const successMsg = shouldSchedule
      ? 'Recipe successfully scheduled'
      : 'Recipe successfully ran'
    setIsPending(true)
    return api
      .post('recipe-schedules', {
        recipeId: recipe._id,
        runOnCollection: relatedCollection,
        runOnId: relatedId,
        args: data.args.reduce((acc, arg) => {
          if (arg.type === 'number' && isNaN(Number(arg.value))) return acc
          if (arg.type === 'number' && typeof arg.value !== 'number') return acc
          return {
            ...acc,
            [arg.name]: arg.type === 'number' ? arg.value : arg.value,
          }
        }, {}),
        ...(shouldSchedule
          ? { whenToRun: getTimestamp(data.date, data.time) }
          : { whenToRun: Date.now() + 10000 }),
      })
      .then(onSuccess)
      .then(() => setShouldSchedule(false))
      .then(() => addToast(successMsg, { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsPending(false))
  }

  const dateTimeFields = (
    <div className="grid grid-cols-2 items-start gap-4">
      <Controller
        name="date"
        control={form.control}
        rules={{ required: 'Required' }}
        render={({ field, fieldState }) => {
          return (
            <div className="[&_.field]:!mb-0 [&_.field]:!block">
              <label className="text-sm font-semibold leading-none">Date</label>
              <Ref innerRef={field.ref}>
                <SemanticDatepicker
                  {...field}
                  onChange={(_, { value }) => field.onChange(value)}
                  className="!mb-0"
                  clearable={false}
                  clearOnSameDateClick={false}
                  minDate={startOfToday()}
                  error={!!fieldState.error}
                  disabled={form.formState.isSubmitting}
                />
              </Ref>
              <div className="text-base leading-normal text-red-600">
                {fieldState.error?.message}
              </div>
            </div>
          )
        }}
      />
      <Controller
        name="time"
        control={form.control}
        rules={{ required: 'Required' }}
        render={({ field, fieldState }) => {
          return (
            <div>
              <label className="text-sm font-semibold leading-none">
                Time (local)
              </label>
              <Input
                {...field}
                className="[&_::-webkit-calendar-picker-indicator]:hidden [&_::-webkit-datetime-edit-hour-field:focus]:bg-slate-300 [&_::-webkit-datetime-edit-minute-field:focus]:bg-slate-300"
                icon="clock"
                type="time"
                onChange={(_, { value }) => field.onChange(value)}
                fluid
                error={!!fieldState.error}
                disabled={form.formState.isSubmitting}
              />
              <div className="text-base leading-normal text-red-600">
                {fieldState.error?.message}
              </div>
            </div>
          )
        }}
      />
    </div>
  )

  return (
    <React.Fragment>
      <Form
        className="mt-4 rounded-md bg-slate-50 px-4 py-4"
        onSubmit={form.handleSubmit(onSubmit)}
        spellCheck="false"
        id="recipe-scheduler"
      >
        <Form.Field className="border-b pb-4">
          <label>Recipe Description</label>

          <div
            className={classNames(
              'text-base text-slate-600',
              !recipe.description && 'italic',
            )}
          >
            {recipe.description || 'No description'}
          </div>
        </Form.Field>

        {argsField.fields.length > 0 ? (
          <fieldset className="p-0">
            <div className="pb-4 text-base text-slate-600">
              This recipe requires some parameters to run.{' '}
              <strong className="font-semibold">
                Please fill them carefuly
              </strong>
              .
            </div>

            {argsField.fields.map((field, index) => {
              return (
                <Form.Field key={field.id}>
                  <label className="leading-none">{field.displayName}</label>

                  {field.type === 'long-text' && (
                    <div>
                      <TextareaAutosize
                        {...form.register(`args.${index}.value`)}
                        className={classNames(
                          'field !mb-0 !resize-none',
                          form.formState.isSubmitting && 'disabled',
                          form.formState.errors.args?.[index] && 'error',
                        )}
                        disabled={form.formState.isSubmitting}
                        minRows={3}
                        rows={3}
                      />
                      <div className="text-sm text-red-600">
                        {form.formState.errors.args?.[index]?.value?.message}
                      </div>
                    </div>
                  )}

                  {field.type === 'text' && (
                    <Controller
                      name={`args.${index}.value`}
                      control={form.control}
                      render={({ field: f, fieldState }) => {
                        return (
                          <div>
                            <Input
                              {...f}
                              onChange={(e) => f.onChange(e.target.value)}
                              disabled={form.formState.isSubmitting}
                              value={f.value || ''}
                              type="text"
                              fluid
                              error={!!fieldState.error}
                            />
                            <div className="text-sm text-red-600">
                              {fieldState.error?.message}
                            </div>
                          </div>
                        )
                      }}
                    />
                  )}

                  {field.type === 'number' && (
                    <Controller
                      name={`args.${index}.value`}
                      control={form.control}
                      render={({ field: f, fieldState }) => {
                        return (
                          <div>
                            <Input
                              {...f}
                              onChange={(e) => {
                                f.onChange(
                                  isNaN(e.target.valueAsNumber)
                                    ? null
                                    : e.target.valueAsNumber,
                                )
                              }}
                              disabled={form.formState.isSubmitting}
                              value={f.value === null ? '' : f.value}
                              type="number"
                              fluid
                              input={
                                <input className="disabled:!bg-slate-100" />
                              }
                              error={!!fieldState.error}
                            />
                            <div className="text-sm text-red-600">
                              {fieldState.error?.message}
                            </div>
                          </div>
                        )
                      }}
                    />
                  )}

                  {field.description && (
                    <div className="mt-1.5 pl-px text-sm leading-none text-slate-500">
                      {field.description}
                    </div>
                  )}
                </Form.Field>
              )
            })}
          </fieldset>
        ) : (
          <div className="mt-4">
            Great! This recipe does not require any parameters.{' '}
            <b>It&apos;s ready to run!</b>
          </div>
        )}

        {inlineDateTime ? (
          <div className="mt-6 border-t pt-4">
            <div className="text-base font-semibold">
              Delayed Recipe Execution
            </div>
            <div className="pb-4 text-base text-slate-600">
              Select a date and local time at which the recipe should run.
            </div>
            {dateTimeFields}
          </div>
        ) : (
          <div className="mt-6">
            <ButtonGroup>
              <Button
                className="!px-8"
                icon="play"
                content="Run now"
                disabled={isPending || shouldSchedule}
                loading={isPending && !shouldSchedule}
                type="submit"
                primary
              />
              <ButtonOr />
              <Popup
                position="top center"
                open={shouldSchedule}
                trigger={
                  <Button
                    onClick={() => setShouldSchedule(true)}
                    content="Run later"
                    disabled={isPending || shouldSchedule}
                    type="button"
                    color="purple"
                  />
                }
              >
                <Form.Field className="min-w-[420px]">
                  <div className="text-lg font-semibold">
                    Delayed Recipe Execution
                  </div>
                  <div className="pb-4 text-base text-slate-600">
                    Select a date and local time at which the recipe should run.
                  </div>

                  {dateTimeFields}

                  {form.watch('date') && form.watch('time') && (
                    <div className="-mb-2 mt-3 rounded-md border bg-slate-100 px-2 py-1.5 text-sm text-slate-600">
                      Recipe would run on{' '}
                      <strong>
                        {new Date(
                          getTimestamp(form.watch('date'), form.watch('time')),
                        ).toString()}
                      </strong>
                    </div>
                  )}

                  <div className="mt-6 flex gap-2">
                    <Button
                      form="recipe-scheduler"
                      icon="calendar alternate outline"
                      content="Schedule Recipe"
                      disabled={isPending}
                      loading={isPending && shouldSchedule}
                      type="submit"
                      color="purple"
                    />
                    <Button
                      onClick={() => setShouldSchedule(false)}
                      content="Cancel"
                      disabled={isPending}
                      type="button"
                      basic
                    />
                  </div>
                </Form.Field>
              </Popup>
            </ButtonGroup>
          </div>
        )}
      </Form>

      {inlineDateTime && (
        <div className="mt-8 flex gap-2">
          <Button
            type="submit"
            form="recipe-scheduler"
            content="Schedule Recipe"
            disabled={isPending}
            loading={isPending}
            primary
          />{' '}
          {onCancel && (
            <Button
              onClick={onCancel}
              type="button"
              content="Cancel"
              disabled={isPending}
              basic
            />
          )}
        </div>
      )}
    </React.Fragment>
  )
}
