import { Button, Form, Input, Popup, Ref } from 'semantic-ui-react'
import { Controller, useForm } from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { startOfToday } from 'date-fns'
import * as React from 'react'

import { getTimestamp } from '../../utils'

type FormValues = {
  date: Date
  time: string
}

interface Props {
  onSubmit(sendAt: number): Promise<any>
  onCancel(): void
  isPending?: boolean
  children: React.ReactNode
  isOpen?: boolean
  title?: React.ReactNode
  description?: React.ReactNode
}

export default function InvoiceSchedulerForm(props: Props) {
  const { isPending, onCancel, children, onSubmit, isOpen } = props

  const form = useForm<FormValues>({
    defaultValues: { date: undefined, time: '' },
  })

  return (
    <Popup position="bottom center" open={isOpen} trigger={children}>
      <Form
        onSubmit={form.handleSubmit(({ date, time }) => {
          onSubmit(getTimestamp(date, time))
        })}
        spellCheck="false"
      >
        <Form.Field className="min-w-[420px]">
          <div className="text-lg font-semibold">
            {props.title || 'Schedule Invoice Delivery'}
          </div>
          <div className="pb-4 text-base text-slate-600">
            {props.description ||
              'Select a date and local time at which this invoice should be sent.'}
          </div>

          <div className="grid grid-cols-2 items-start gap-4">
            <Controller
              name="date"
              control={form.control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => {
                return (
                  <div className="[&_.field]:!mb-0 [&_.field]:!block">
                    <label className="text-sm font-semibold leading-none">
                      Date
                    </label>
                    <Ref innerRef={field.ref}>
                      <SemanticDatepicker
                        {...field}
                        onChange={(_, { value }) => field.onChange(value)}
                        className="!mb-0"
                        clearable={false}
                        clearOnSameDateClick={false}
                        minDate={startOfToday()}
                        error={!!fieldState.error}
                        disabled={isPending}
                      />
                    </Ref>
                    <div className="text-base leading-normal text-red-600">
                      {fieldState.error?.message}
                    </div>
                  </div>
                )
              }}
            />
            <Controller
              name="time"
              control={form.control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => {
                return (
                  <div>
                    <label className="text-sm font-semibold leading-none">
                      Time (local)
                    </label>
                    <Input
                      {...field}
                      className="[&_::-webkit-calendar-picker-indicator]:hidden [&_::-webkit-datetime-edit-hour-field:focus]:bg-slate-300 [&_::-webkit-datetime-edit-minute-field:focus]:bg-slate-300"
                      icon="clock"
                      type="time"
                      onChange={(_, { value }) => field.onChange(value)}
                      fluid
                      error={!!fieldState.error}
                      disabled={isPending}
                    />
                    <div className="text-base leading-normal text-red-600">
                      {fieldState.error?.message}
                    </div>
                  </div>
                )
              }}
            />
          </div>

          <div className="mt-6 flex gap-2">
            <Button
              icon="calendar alternate outline"
              content="Schedule"
              disabled={isPending}
              loading={isPending}
              type="submit"
              color="green"
            />
            <Button
              onClick={onCancel}
              content="Cancel"
              disabled={isPending}
              type="button"
              basic
            />
          </div>
        </Form.Field>
      </Form>
    </Popup>
  )
}
