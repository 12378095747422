import * as React from 'react'
import { Popup } from 'semantic-ui-react'

import { RecipeSchedule } from '../../types'

interface Props {
  schedule: RecipeSchedule
}

export default function ArgumentsPopup(props: Props) {
  const { schedule } = props

  if (Object.keys(schedule.args || {}).length === 0) {
    return (
      <span className="italic text-slate-500">
        &ndash; No arguments &ndash;{' '}
      </span>
    )
  }

  return (
    <Popup
      position="top left"
      hoverable
      wide="very"
      content={
        <div className="w-2xl">
          <table className="w-2xl">
            <thead>
              <tr className="border-b text-left text-sm font-semibold uppercase text-primary">
                <th className="px-2 pb-2">Argument</th>
                <th className="px-2 pb-2">Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(schedule.args || {}).map(
                ([argName, argValue]) => {
                  return (
                    <tr
                      className="border-b align-baseline text-base"
                      key={argName}
                    >
                      <td className="px-2 py-3 font-mono font-semibold text-slate-500">
                        {argName}
                      </td>
                      <td className="px-2 py-3 text-lg text-slate-700">
                        {argValue || (
                          <span className="font-mono italic text-slate-400">
                            empty
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                },
              )}
            </tbody>
          </table>
        </div>
      }
      trigger={
        <div className="flex cursor-help gap-5 py-2 text-sm">
          {Object.entries(schedule.args || {}).map(([argName, argValue]) => {
            return (
              <span
                className="max-w-xl shrink-0 overflow-hidden overflow-ellipsis text-nowrap rounded-md py-1"
                key={argName}
              >
                <span className="font-mono font-semibold text-slate-700">
                  {argName}
                </span>
                :{' '}
                {argValue ? (
                  <span className="text-base text-slate-600">{argValue}</span>
                ) : (
                  <span className="font-mono italic text-slate-400">empty</span>
                )}
              </span>
            )
          })}
        </div>
      }
    />
  )
}
