import { Modal, ModalProps } from 'semantic-ui-react'
import * as React from 'react'

import {
  EnhancedInvoice,
  EnhancedPayment,
} from '../../hooks/useBillingPayments'
import UpdatePaymentForm from './UpdatePaymentForm'

interface Props extends ModalProps {
  onSuccess(): Promise<any>
  onCancel(): void
  invoices: EnhancedInvoice[]
  payment?: EnhancedPayment
}

export default function UpdatePaymentDialog(props: Props) {
  const { onDeleteClick, onSuccess, onCancel, payment, invoices, ...other } =
    props

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      centered={false}
      onClose={onCancel}
      {...other}
    >
      <Modal.Header>Edit Payment</Modal.Header>
      <Modal.Content className="!pt-2">
        {payment && (
          <UpdatePaymentForm
            onSuccess={onSuccess}
            onCancel={onCancel}
            invoices={invoices}
            payment={payment}
          />
        )}
      </Modal.Content>
    </Modal>
  )
}
