import { useContractsApi, useTalentApi } from '../../store/mainContext'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import SearchCandidates from '../../components/search/searchCandidates'
import ContractsFilter from '../../components/filters/contractsFilter'
import { ENV_VARS } from '../../env'
import PageHeader from '../../components/pageHeader/pageHeader'
import PagedTable from '../../components/dataTable/pagedTable'
import Period from '../../components/period'

const PageContent = styled.div`
  margin: 0px 50px 50px 50px;
`

const Contracts = () => {
  document.title = 'Contracts'

  const history = useHistory()
  const contractsApi = useContractsApi()
  const api = useTalentApi()

  const defaultFilter = {
    active: true,
  }

  const [filter, setFilter] = useState(defaultFilter)

  const getDataPosthook = async (contracts) => {
    const [
      { data: deals },
      { data: companies },
      { data: contacts },
      { data: candidates },
    ] = await Promise.all([
      contractsApi.get(`deals/in`, {
        params: { contractId: contracts.map((x) => x._id) },
      }),

      api.get(`companies/in`, {
        params: { _id: contracts.map((x) => x.customerId) },
      }),

      api.get(`contacts/in`, {
        params: {
          _id: [
            ...contracts.map((x) => x.supervisorId),
            ...contracts.map((x) => x.hiringManagerId),
          ],
        },
      }),

      api.get(`candidates/in`, {
        params: { _id: contracts.map((x) => x.candidateId) },
      }),
    ])

    const rows = contracts.map((contract) => {
      const deal = deals.find((x) => x.contractId === contract._id)
      const customer = companies.find((x) => x._id === contract.customerId)
      const supervisor = contacts.find((x) => x._id === contract.supervisorId)
      const candidate = candidates.find((x) => x._id === contract.candidateId)
      const hiringManager = contacts.find(
        (x) => x._id === contract.hiringManagerId,
      )

      return {
        _id: contract._id,
        position: deal.position,
        salary: deal.monthlySalary,

        team: customer ? customer.name : 'No Customer',

        supervisor: supervisor && supervisor.name,

        hiringManager: hiringManager && hiringManager.name,

        candidateId: candidate && candidate._id,
        name: candidate ? candidate.name : 'No Candidate',
        location: candidate ? candidate.location : 'No Candidate',

        startingDate: contract && contract.startingDate,
      }
    })

    return rows
  }

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: 250,
    },
    {
      title: 'Position',
      field: 'position',
      width: 250,
    },
    {
      title: 'Team',
      width: 200,
      field: 'team',
    },
    {
      title: 'Hiring Manager',
      width: 200,
      field: 'hiringManager',
    },
    {
      title: 'Supervisor',
      width: 200,
      field: 'supervisor',
    },
    {
      title: 'Fee',
      align: 'center',
      width: 100,
      field: 'salary',
      render: (salary) => <React.Fragment>$ {salary}</React.Fragment>,
    },
    {
      title: 'Starting Date',
      width: 250,
      field: 'startingDate',
      render: (startingDate) =>
        startingDate ? (
          <React.Fragment>
            {new Date(startingDate).toLocaleDateString()}
            &nbsp; (<Period date={startingDate} />)
          </React.Fragment>
        ) : (
          'No Date'
        ),
    },
    {
      title: 'Location',
      field: 'location',
    },
  ]

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Contracts', link: '/contracts' },
        ]}
        title="Contracts"
        sub="One screen to find them all."
        search={<SearchCandidates />}
      ></PageHeader>

      <PageContent>
        <ContractsFilter
          onFilterChange={setFilter}
          defaultFilter={defaultFilter}
        />

        <PagedTable
          collection="contracts"
          posthook={getDataPosthook}
          filter={filter}
          onRowClick={(row) =>
            (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
              ? window.open
              : history.push)(`/candidates/${row.candidateId}`)
          }
          defaultSort="team"
          columns={columns}
        />
      </PageContent>
    </React.Fragment>
  )
}

export default Contracts
