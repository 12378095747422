import { useHistory } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import useSWR from 'swr'
import React from 'react'
import { z } from 'zod'

import { validateResponse } from '../../utils'
import { gmailLabelSchema } from '../../types'
import PageHeader from '../../components/pageHeader/pageHeader'
import { useApi } from '../../store/mainContext'

const PageContent = styled.div`
  display: flex;
  margin: 0px 50px 50px 40px;
`

const EmailsPage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const history = useHistory()
  const api = useApi()

  const { data: labels } = useSWR('email-labels', () =>
    api
      .get('v1/email/labels')
      .then(validateResponse(z.array(gmailLabelSchema))),
  )

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Emails', link: '/inbox' },
        ]}
        title="Inbox"
      />

      <PageContent>
        <div style={{ marginRight: 30 }}>
          <Menu secondary vertical>
            <Menu.Item
              icon="inbox"
              name="Inbox"
              active={document.location.pathname === '/emails/inbox'}
              onClick={() => history.push('/emails/inbox')}
            />

            <Menu.Item
              icon="paper plane outline"
              name="Sent"
              active={document.location.pathname === '/emails/sent'}
              onClick={() => history.push('/emails/sent')}
            />
          </Menu>
          <Menu secondary vertical>
            <Menu.Item header>Labels</Menu.Item>
            {(labels || [])
              .filter((l) => l.type !== 'system')
              .map((l) => (
                <Menu.Item
                  icon="bookmark"
                  key={l.id}
                  name={l.name}
                  active={document.location.pathname === `/emails/${l.name}`}
                  onClick={() => history.push(`/emails/${l.name}`)}
                />
              ))}
          </Menu>
        </div>

        <div style={{ flex: 1 }}>{children}</div>
      </PageContent>
    </React.Fragment>
  )
}

export default EmailsPage
