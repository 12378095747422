import React, { useState } from 'react'

import { useAuthApi } from '../../store/mainContext'
import { ApiError } from '../../types'
import POWERS from '../../powers'
import Login from './views/login'

type SessionContextType = {
  state: { error?: ApiError; loaded: boolean }
  actions: {
    logOut(): void
    handleOnSuccess(r: { code: string }): void
    handleOnError(errorResponse: any): void
  }
}

const SessionContext = React.createContext<SessionContextType>(
  {} as SessionContextType,
)

const SessionContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [error, setError] = useState<ApiError>()
  const [loaded, setLoaded] = useState(true)

  const api = useAuthApi()

  const token = window.localStorage.getItem('token')

  let user: any

  try {
    user = JSON.parse(window.localStorage.getItem('user') || '')
  } catch (e) {}

  if (user && Array.isArray(user.powers)) {
    user.hasPower = (power: (typeof POWERS)[keyof typeof POWERS]) => {
      return (
        (user.powers || []).indexOf('master') !== -1 ||
        (user.powers || []).indexOf(power) !== -1
      )
    }
  }

  const logOut = () => {
    window.localStorage.clear()
    // window.localStorage.removeItem("token");
    // window.localStorage.removeItem("user");
    window.location.assign('/')
  }

  const handleOnSuccess: SessionContextType['actions']['handleOnSuccess'] =
    (r: { code: string }) => {
      console.log('Handle google response.')
      console.log(r)

      setLoaded(false)

      api
        .post('code', { code: r.code, redirect_uri: document.location.origin })
        .then((res) => {
          window.localStorage.setItem('token', res.data.token)
          window.localStorage.setItem('user', JSON.stringify(res.data.user))
          setLoaded(true)
          window.location.assign('/')
        })
        .catch((e) => {
          console.log(e)

          setLoaded(true)
          setError({
            message: 'Sorry dude. Looks like you are out of this party.',
          })
        })
    }

  const handleOnError: SessionContextType['actions']['handleOnError'] = (
    errorResponse,
  ) => {
    setLoaded(true)
    setError({
      message: 'Sorry dude. Looks like you are out of this party.',
    })
  }

  const state = { user, error, loaded }
  const actions = { logOut, handleOnSuccess, handleOnError }

  return (
    <SessionContext.Provider value={{ state, actions }}>
      {token ? children : <Login />}
    </SessionContext.Provider>
  )
}

export { SessionContext as default, SessionContextProvider as Provider }
