import React from 'react'
import SettingsPage from './settingsPage'

const AccessControlSettings = () => {
  return (
    <SettingsPage>
      <h2>Access Control</h2>
    </SettingsPage>
  )
}

export default AccessControlSettings
