import * as React from 'react'

import TransactionStatusLabel from './TransactionStatusLabel'
import { PaymentOrderStatus } from '../../types'
import { currencyFormat } from '../../utils'
import { Summary } from './PaymentOrderProgressBar'

interface Props {
  paymentOrderStatus: PaymentOrderStatus
  summary: Summary
}

export default function AmountsSummaryWidget(props: Props) {
  const { summary, paymentOrderStatus } = props
  return (
    <table className="w-full">
      <tbody>
        <tr>
          <th className="pb-2 text-left text-sm font-semibold uppercase leading-6 text-slate-400">
            All
          </th>
          <td className="pb-2 text-right text-sm text-slate-500">
            {currencyFormat(summary.amounts.total)}
          </td>
        </tr>
        <tr>
          <th className="text-left">
            {paymentOrderStatus === 'Draft' ? (
              <TransactionStatusLabel status="Approved" />
            ) : (
              <TransactionStatusLabel status="Completed" />
            )}
          </th>
          <td className="text-right text-sm font-semibold text-slate-600">
            {currencyFormat(summary.amounts.Approved)}
          </td>
        </tr>
        <tr>
          <th className="relative pl-7 text-left">
            <div className="absolute bottom-3 left-2 h-4 w-3 border border-r-0 border-t-0 border-dashed border-slate-400" />
            <span className="whitespace-nowrap text-sm font-normal leading-6 text-slate-500">
              USA accounts
            </span>
          </th>
          <td className="text-right text-sm font-normal text-slate-500/90">
            {currencyFormat(summary.amounts.usa)}
          </td>
        </tr>
        <tr>
          <th className="relative pl-7 text-left">
            <div className="absolute bottom-3 left-2 h-6 w-3 border border-r-0 border-t-0 border-dashed border-slate-400" />
            <span className="whitespace-nowrap text-sm font-normal leading-6 text-slate-500">
              International accounts
            </span>
          </th>
          <td className="text-right text-sm font-normal text-slate-500/90">
            {currencyFormat(summary.amounts.international)}
          </td>
        </tr>
        <tr>
          <th className="relative pl-7 text-left">
            <div className="absolute bottom-3 left-2 h-6 w-3 border border-r-0 border-t-0 border-dashed border-slate-400" />
            <span className="whitespace-nowrap text-sm font-normal leading-6 text-slate-500">
              Payoneer
            </span>
          </th>
          <td className="text-right text-sm font-normal text-slate-500/90">
            {currencyFormat(summary.amounts.payoneer)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
