import {
  ButtonGroup,
  Dropdown,
  Confirm,
  Button,
  Popup,
  Icon,
  IconGroup,
} from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'

import { Invoice, InvoiceStatus } from '../../types'
import TransactionStatusLabel from './TransactionStatusLabel'
import InvoiceSchedulerForm from './InvoiceSchedulerForm'
import { EnhancedInvoice } from '../../hooks/useInvoice'
import InvoiceStatusLabel from './InvoiceStatusLabel'
import { classNames } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

function validateReadyForSend(
  invoice: EnhancedInvoice,
): React.ReactNode | undefined {
  if (!invoice.customerId) return 'A customer must be defined'
  if (!invoice.name) return 'An invoice number must be set'
  if (!invoice.issueDate) return 'The invoice issue date must be set'
  if (!invoice.dueDate) return 'The invoice due date must be set'
  if ((invoice.transactions || []).length === 0) {
    return 'Invoice must have at least one transaction'
  }
  if (
    !invoice.transactions?.every(
      (tx) => tx.status === 'Approved' || tx.status === 'Dismissed',
    )
  ) {
    return (
      <>
        Every transaction must be either{' '}
        <TransactionStatusLabel status="Approved" /> or{' '}
        <TransactionStatusLabel status="Dismissed" />
      </>
    )
  }
  if (!invoice.transactions?.some((tx) => tx.status === 'Approved')) {
    return (
      <>
        At least one transaction must be{' '}
        <TransactionStatusLabel status="Approved" />
      </>
    )
  }
  if ((invoice.sendToIds || []).length === 0) {
    return 'Delivery details incomplete: Add at least one recipient'
  }
  if (!invoice.sendMessage) return 'Delivery details incomplete: Add a message'
}

type Dialog =
  | 'confirm-uncollectible'
  | 'confirm-mark-as-sent'
  | 'confirm-delete'
  | 'confirm-cancel'
  | 'confirm-send'

interface Props {
  onViewFeedClick?(): void
  onUpdateSuccess(): Promise<any>
  onDeleteSuccess(): void
  showFinalActions?: boolean
  isFeedVisible?: boolean
  invoice: EnhancedInvoice
}

export default function InvoiceActions(props: Props) {
  const { invoice, onUpdateSuccess, onDeleteSuccess, showFinalActions } = props

  const { addToast } = useToasts()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isChangingStatus, setIsChangingStatus] = React.useState<
    Invoice['status'] | 'delete' | null
  >(null)

  const handleDelete = async () => {
    setIsChangingStatus('delete')
    return api
      .delete(`/billing-invoices/${invoice._id}`)
      .then(onDeleteSuccess)
      .then(() => setCurrentDialog(null))
      .then(() => addToast('Invoice deleted', { variant: 'success' }))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsChangingStatus(null))
  }

  const handleStatusChange = async (status: InvoiceStatus, sendAt?: number) => {
    setIsChangingStatus(status)
    return api
      .patch('billing-invoices/' + invoice._id, {
        status,
        ...(status === 'Scheduled'
          ? {
              sendAt: typeof sendAt === 'number' ? sendAt : Date.now() + 10000, // if not set, send in 10 seconds from now
            }
          : {}),
      })
      .then(onUpdateSuccess)
      .then(() => setCurrentDialog(null))
      .then(() => setShouldSchedule(false))
      .then(() => addToast('Invoice status updated', { variant: 'success' }))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsChangingStatus(null))
  }

  const readyForSendValidationReason = validateReadyForSend(invoice)

  const canMarkAsUncollectible = invoice.status === 'Sent' && showFinalActions
  const canSetAsReadyToBeSent = invoice.status === 'Scheduled'
  const canMarkAsCanceled = invoice.status === 'Sent' && showFinalActions
  const canSetAsDraft = invoice.status === 'Ready to be Sent'
  const canMarkAsSent =
    invoice.status === 'Ready to be Sent' || invoice.status === 'Scheduled'
  const canDownload = invoice.invoicePdf && invoice.status !== 'Draft'
  const canDelete = invoice.status === 'Draft'
  const canSend = invoice.status === 'Ready to be Sent'

  const [shouldSchedule, setShouldSchedule] = React.useState(false)

  return (
    <div className="flex h-[35px] items-baseline justify-between @container">
      <div className="leading-[34px]">
        <span className="hidden pr-2 text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px] @2xl:inline-block">
          Status:
        </span>
        <InvoiceStatusLabel status={invoice.status} dueDate={invoice.dueDate} />
        {invoice.status === 'Sent' && invoice.manual && (
          <span className="ml-2 text-sm text-slate-500">(manually)</span>
        )}
        {invoice.status === 'Sent' && (invoice.totalPaid || 0) > 0 && (
          <span className="ml-2 text-sm text-slate-500">(Partially paid)</span>
        )}
      </div>

      <div className="flex items-baseline gap-2">
        {invoice.status === 'Draft' && (
          <Popup
            content={readyForSendValidationReason}
            position="bottom left"
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            disabled={!readyForSendValidationReason}
            trigger={
              <div
                className={classNames(
                  !!readyForSendValidationReason && 'cursor-not-allowed',
                )}
              >
                <Button
                  content="Mark as Ready to be Sent"
                  className="!mr-0"
                  loading={isChangingStatus === 'Ready to be Sent'}
                  onClick={() => handleStatusChange('Ready to be Sent')}
                  disabled={
                    isChangingStatus === 'Ready to be Sent' ||
                    !!readyForSendValidationReason
                  }
                  type="button"
                  size="small"
                  primary
                />
              </div>
            }
          />
        )}

        {canSetAsDraft && (
          <Button
            content={
              <>
                <span className="!hidden @3xl:!inline">Convert to </span>
                Draft
              </>
            }
            className="!mr-0"
            onClick={() => handleStatusChange('Draft')}
            disabled={isChangingStatus !== null}
            loading={isChangingStatus === 'Draft'}
            type="button"
            size="small"
            basic
          />
        )}

        {canSetAsReadyToBeSent && (
          <Button
            icon="undo alternate"
            content="Unschedule"
            className="!mr-0"
            onClick={() => handleStatusChange('Ready to be Sent')}
            disabled={isChangingStatus !== null}
            loading={isChangingStatus === 'Ready to be Sent'}
            type="button"
            size="small"
            basic
          />
        )}

        {canSend && (
          <ButtonGroup color="green" size="small">
            <InvoiceSchedulerForm
              isPending={isChangingStatus === 'Scheduled'}
              onSubmit={(sendAt) => handleStatusChange('Scheduled', sendAt)}
              onCancel={() => setShouldSchedule(false)}
              isOpen={shouldSchedule}
            >
              <Button
                content={
                  <>
                    Schedule
                    <span className="!hidden @2xl:!inline"> Delivery</span>
                  </>
                }
                onClick={() => setShouldSchedule(true)}
                disabled={shouldSchedule}
                type="button"
              />
            </InvoiceSchedulerForm>
            <Dropdown
              className="button icon"
              disabled={shouldSchedule}
              floating
              pointing="top right"
              options={[
                {
                  icon: 'paper plane outline',
                  text: 'Send invoice now',
                  onClick: () => setCurrentDialog('confirm-send'),
                  key: 'send-now',
                },
              ]}
              trigger={<></>}
            />
          </ButtonGroup>
        )}

        {canMarkAsSent && (
          <Popup
            content="Mark as manually sent"
            position="bottom center"
            size="small"
            trigger={
              <Button
                type="button"
                size="small"
                basic
                className="!mr-0"
                onClick={() => setCurrentDialog('confirm-mark-as-sent')}
                disabled={isChangingStatus !== null}
                icon={
                  <IconGroup>
                    <Icon name="paper plane outline" color="grey" />
                    <Icon name="check" corner color="black" />
                  </IconGroup>
                }
              />
            }
          />
        )}

        {canMarkAsUncollectible && (
          <Popup
            disabled={(invoice.totalPaid || 0) === 0}
            content={
              <>
                Cannot mark as <InvoiceStatusLabel status="Uncollectible" /> a
                partially paid invoice.
              </>
            }
            trigger={
              <div
                className={classNames(
                  (invoice.totalPaid || 0) > 0 && 'cursor-not-allowed',
                )}
              >
                <Button
                  content="Mark as Uncollectible"
                  className="!mr-0"
                  onClick={() => setCurrentDialog('confirm-uncollectible')}
                  disabled={
                    isChangingStatus !== null || (invoice.totalPaid || 0) > 0
                  }
                  loading={isChangingStatus === 'Uncollectible'}
                  icon={
                    <Icon
                      name="thumbs down outline"
                      className="!hidden @2xl:!inline-block"
                    />
                  }
                  type="button"
                  size="small"
                  color="red"
                  basic
                />
              </div>
            }
          />
        )}

        {canMarkAsCanceled && (
          <Popup
            disabled={(invoice.totalPaid || 0) === 0}
            content={
              <>
                Cannot mark as <InvoiceStatusLabel status="Canceled" /> a
                partially paid invoice.
              </>
            }
            trigger={
              <div
                className={classNames(
                  (invoice.totalPaid || 0) > 0 && 'cursor-not-allowed',
                )}
              >
                <Button
                  content="Mark as Canceled"
                  className="!mr-0"
                  onClick={() => setCurrentDialog('confirm-cancel')}
                  disabled={
                    isChangingStatus !== null || (invoice.totalPaid || 0) > 0
                  }
                  loading={isChangingStatus === 'Canceled'}
                  icon={
                    <Icon name="ban" className="!hidden @2xl:!inline-block" />
                  }
                  type="button"
                  size="small"
                  color="grey"
                  basic
                />
              </div>
            }
          />
        )}

        {canDelete && (
          <Button
            className="!mr-0"
            onClick={() => setCurrentDialog('confirm-delete')}
            disabled={isChangingStatus !== null}
            type="button"
            icon="trash alternate outline"
            size="small"
            color="red"
            basic
          />
        )}

        {canDownload && (
          <Popup
            content="Download as PDF"
            position="bottom center"
            size="small"
            trigger={
              <Button
                className="!mr-0"
                href={invoice.invoicePdf}
                icon="download"
                size="small"
                as="a"
                download={(invoice.name || '').replace(/\s+/g, '_') + '.pdf'}
                basic
              />
            }
          />
        )}

        {typeof props.onViewFeedClick === 'function' && (
          <Popup
            content={props.isFeedVisible ? 'Hide Feed' : 'Show Feed'}
            position="bottom center"
            trigger={
              <Button
                active={props.isFeedVisible}
                onClick={props.onViewFeedClick}
                className="!mr-0"
                type="button"
                icon="list"
                size="small"
                basic
              />
            }
          />
        )}
      </div>

      <Confirm
        header="Hey... removing the invoice?"
        content="This action cannot be undone."
        closeOnDimmerClick={isChangingStatus !== 'delete'}
        closeOnEscape={isChangingStatus !== 'delete'}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={handleDelete}
        open={currentDialog === 'confirm-delete'}
        cancelButton={
          <Button disabled={isChangingStatus === 'delete'} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'delete'}
            loading={isChangingStatus === 'delete'}
            content="Sure, remove"
            primary={false}
            color="red"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... marking the invoice as sent?"
        content={
          <div className="px-6 text-base">
            <div>Please note that this action</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>
                will require you to{' '}
                <strong>send the invoice to the customer on your own</strong>,
              </li>
              {invoice.status === 'Scheduled' && (
                <li>will unschedule all email deliveries for this invoice,</li>
              )}
              <li>
                will change the invoice status to{' '}
                <InvoiceStatusLabel status="Sent" />,
              </li>
              <li>
                will no longer be able to be converted back to{' '}
                <InvoiceStatusLabel status="Draft" /> or deleted.
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Sent'}
        closeOnEscape={isChangingStatus !== 'Sent'}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => handleStatusChange('Sent')}
        open={currentDialog === 'confirm-mark-as-sent'}
        cancelButton={
          <Button disabled={isChangingStatus === 'Sent'} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Sent'}
            loading={isChangingStatus === 'Sent'}
            content="Sure, mark as sent"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... sending the invoice?"
        content={
          <div className="px-6 text-base">
            <div>Please note that the invoice</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>will be sent via e-mail to the customer,</li>
              <li>
                will change its status to{' '}
                <InvoiceStatusLabel status="Scheduled" />,
              </li>
              <li>
                will no longer be able to be converted back to{' '}
                <InvoiceStatusLabel status="Draft" /> or deleted.
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Scheduled'}
        closeOnEscape={isChangingStatus !== 'Scheduled'}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => handleStatusChange('Scheduled')}
        open={currentDialog === 'confirm-send'}
        cancelButton={
          <Button
            disabled={isChangingStatus === 'Scheduled'}
            content="Cancel"
          />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Scheduled'}
            loading={isChangingStatus === 'Scheduled'}
            content="Sure, send the invoice"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... marking as uncollectible?"
        content={
          <div className="px-6 text-base">
            <div>
              Use this status if you positively know that an already{' '}
              <InvoiceStatusLabel status="Sent" /> invoice will not be{' '}
              <InvoiceStatusLabel status="Paid" /> for some reason. This action:
            </div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>
                will change its status to{' '}
                <InvoiceStatusLabel status="Uncollectible" />,
              </li>
              <li>Prevent any other status change.</li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Uncollectible'}
        closeOnEscape={isChangingStatus !== 'Uncollectible'}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => handleStatusChange('Uncollectible')}
        open={currentDialog === 'confirm-uncollectible'}
        cancelButton={
          <Button
            disabled={isChangingStatus === 'Uncollectible'}
            content="Cancel"
          />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Uncollectible'}
            loading={isChangingStatus === 'Uncollectible'}
            content="Sure, mark as Uncollectible"
            primary={false}
            color="red"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... canceling the invoice?"
        content={
          <div className="px-6 text-base">
            <div>The invoice will:</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>
                change its status to <InvoiceStatusLabel status="Canceled" />,
              </li>
              <li>Prevent any other status change.</li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Canceled'}
        closeOnEscape={isChangingStatus !== 'Canceled'}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => handleStatusChange('Canceled')}
        open={currentDialog === 'confirm-cancel'}
        cancelButton={
          <Button disabled={isChangingStatus === 'Canceled'} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Canceled'}
            loading={isChangingStatus === 'Canceled'}
            content="Sure, mark as Canceled"
            primary={false}
            color="red"
          />
        }
        size="tiny"
      />
    </div>
  )
}
