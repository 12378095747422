import {
  IconGroup,
  Dropdown,
  Button,
  Label,
  Popup,
  Icon,
} from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import * as React from 'react'
import { Link } from 'react-router-dom'

import {
  PayrollTransactionStatus,
  PayrollTransaction,
  PayrollStatus,
  PaymentSetup,
  Candidate,
  Company,
  Deal,
} from '../../types'
import { classNames, currencyFormat } from '../../utils'
import TransactionStatusLabel from './TransactionStatusLabel'
import RelatedNotesPopup from '../RelatedNotesPopup'
import DealDetailsPopup from '../DealDetailsPopup'
import { useToasts } from '../toasts/ToastsProvider'
import UserName from '../remoteValues/userName'

type ExtraFields = {
  paymentSetup?: PaymentSetup
  candidate?: Candidate
  customer?: Company
  deal?: Deal
}

interface Props {
  payrollStatus?: PayrollStatus
  onTransactionUpdate(
    id: PayrollTransaction['_id'],
    status: PayrollTransactionStatus,
  ): Promise<any>
  transactions?: Array<PayrollTransaction & ExtraFields>
  isAddingCustomTx?: boolean
  isDisplayingTotal?: boolean
  onDeleteCustomTx(txId: PayrollTransaction['_id']): void
  onAddCustomTx(): void
  onMouseEnter(): void
  onMouseLeave(): void
  isHighlighted?: boolean
}

export default function PayrollRow(props: Props) {
  const {
    onTransactionUpdate,
    onDeleteCustomTx,
    isDisplayingTotal,
    isAddingCustomTx,
    isHighlighted,
    onAddCustomTx,
    transactions,
  } = props

  const discounts = (transactions || []).filter((t) => t.type === 'discount')
  const credits = (transactions || []).filter((t) => t.type === 'credit')

  const { addToast } = useToasts()

  const [currentActions, setCurrentActions] = React.useState<
    Record<string, PayrollTransactionStatus | null>
  >({})

  const handleTransactionStatusUpdate = async (
    id: PayrollTransaction['_id'],
    status: PayrollTransactionStatus,
  ) => {
    setCurrentActions((prev) => ({ ...prev, [id]: status }))
    return onTransactionUpdate(id, status)
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setCurrentActions((prev) => ({ ...prev, [id]: null })))
  }

  return (
    <React.Fragment>
      {[...credits, ...discounts].map((tx, i, allTxs) => (
        <tr
          className={classNames(
            'group border border-l-0 border-r-0 border-slate-300',
            isHighlighted && 'bg-slate-50',
          )}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          key={tx._id}
        >
          {i === 0 && (
            <td
              rowSpan={
                allTxs.length +
                (isAddingCustomTx ? 3 : 0) +
                (isDisplayingTotal ? 1 : 0)
              }
              className={classNames(
                'px-3 align-baseline',
                allTxs.every((t) => t.status === 'Dismissed') && 'line-through',
              )}
            >
              {tx.candidate ? (
                <Link
                  className="text-nowrap !text-slate-600 hover:!text-primary"
                  to={'/candidates/' + tx.candidate._id}
                >
                  {tx.candidate.name}
                </Link>
              ) : (
                <span className="text-slate-400">Candidate not found</span>
              )}
              {!tx.paymentSetup && (
                <Popup
                  content="Missing Payment Setup"
                  position="top center"
                  trigger={
                    <div className="relative -top-px left-1 inline-block cursor-help px-1 opacity-70">
                      <IconGroup size="small">
                        <Icon
                          name="exclamation triangle"
                          color="orange"
                          fitted
                        />
                      </IconGroup>
                    </div>
                  }
                />
              )}
            </td>
          )}

          {i === 0 && (
            <td
              className="pr-3 align-baseline"
              rowSpan={
                allTxs.length +
                (isAddingCustomTx ? 3 : 0) +
                (isDisplayingTotal ? 1 : 0)
              }
            >
              {props.payrollStatus !== 'Completed' && (
                <Popup
                  content="Add custom transaction"
                  disabled={isAddingCustomTx}
                  position="top center"
                  size="small"
                  trigger={
                    <Button
                      onClick={onAddCustomTx}
                      icon="add"
                      className={classNames(
                        '!mr-0 text-nowrap',
                        isHighlighted && !isAddingCustomTx
                          ? 'opacity-100'
                          : 'pointer-events-none opacity-0',
                      )}
                      size="mini"
                      compact
                      basic
                    />
                  }
                />
              )}
            </td>
          )}

          <td
            className={classNames(
              'text-nowrap px-3 py-3.5 align-baseline text-slate-500',
              tx.status === 'Dismissed' && 'line-through',
            )}
          >
            {tx.customer ? (
              tx.customer.name
            ) : tx.generatedByPayroll ? (
              <span className="text-slate-400">Customer not found</span>
            ) : (
              <Popup
                content={
                  <div className="text-base">
                    Created by <UserName id={tx.createdBy} />
                    <br />
                    on {format(tx.createdOn, 'PPP')}
                  </div>
                }
                position="top center"
                trigger={
                  <Label
                    className="cursor-help"
                    circular
                    color={
                      tx.type === 'credit'
                        ? 'green'
                        : tx.type === 'discount'
                          ? 'red'
                          : 'grey'
                    }
                    basic
                    content={`Custom ${tx.type}`}
                  />
                }
              />
            )}
          </td>

          <td className="px-3 py-3.5 text-right align-baseline">
            <span
              className={classNames(
                tx.status === 'Dismissed' && 'line-through',
              )}
            >
              <span
                className={classNames(
                  tx.type === 'credit' && 'text-green-800/80 ',
                  tx.type === 'discount' && 'text-red-600/80',
                )}
              >
                {currencyFormat(tx.amount * (tx.type === 'discount' ? -1 : 1))}
              </span>
            </span>
          </td>

          <td className="relative px-3 py-3.5 align-baseline">
            {tx.deal && tx.type === 'credit' && tx.generatedByPayroll ? (
              <DealDetailsPopup
                deal={tx.deal}
                trigger={
                  <span className="cursor-help">
                    <span
                      className={classNames(
                        tx.status === 'Dismissed' && 'line-through',
                      )}
                    >
                      <span
                        className={classNames(
                          tx.status === 'Dismissed'
                            ? 'text-slate-400'
                            : 'text-slate-500',
                        )}
                      >
                        {tx.description}
                      </span>
                    </span>
                    <Icon
                      name="help"
                      bordered
                      circular
                      size="tiny"
                      className="relative left-1.5 top-[-2.5px] text-slate-400"
                    />
                  </span>
                }
              />
            ) : (
              <>
                <span
                  className={classNames(
                    tx.status === 'Dismissed' && 'line-through',
                  )}
                >
                  <span
                    className={classNames(
                      tx.status === 'Dismissed'
                        ? 'text-slate-400'
                        : 'text-slate-500',
                    )}
                  >
                    {tx.description}
                  </span>
                </span>
                {tx.timeOffRequestId && (
                  <RelatedNotesPopup
                    relatedCollection="timeoff-requests"
                    relatedId={tx.timeOffRequestId}
                  />
                )}
              </>
            )}
            {props.payrollStatus !== 'Completed' &&
              tx.generatedByPayroll === false && (
                <Button
                  className={classNames(
                    'absolute right-0 top-3 !mr-0',
                    isHighlighted && !isAddingCustomTx
                      ? 'opacity-100'
                      : 'opacity-0',
                  )}
                  onClick={() => onDeleteCustomTx(tx._id)}
                  icon="times"
                  size="mini"
                  compact
                  basic
                />
              )}
          </td>

          <td className="px-3 text-right align-baseline">
            {props.payrollStatus === 'Completed' ||
            tx.generatedByPayroll === false ? (
              <TransactionStatusLabel status={tx.status} />
            ) : (
              <Dropdown
                className="relative -top-0.5 whitespace-nowrap"
                pointing
                item
                disabled={!!currentActions[tx._id]}
                loading={!!currentActions[tx._id]}
                selectOnBlur={false}
                options={[
                  {
                    key: 1,
                    text: 'Approved',
                    value: 'Approved',
                    content: <TransactionStatusLabel status="Approved" />,
                  },
                  {
                    key: 2,
                    text: 'Dismissed',
                    value: 'Dismissed',
                    content: <TransactionStatusLabel status="Dismissed" />,
                  },
                ]}
                value={tx.status}
                trigger={<TransactionStatusLabel status={tx.status} />}
                onChange={(_, { value }) => {
                  if (value === 'Approved' || value === 'Dismissed') {
                    handleTransactionStatusUpdate(tx._id, value)
                  }
                }}
              />
            )}
          </td>
        </tr>
      ))}
    </React.Fragment>
  )
}
