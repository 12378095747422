import React from 'react'
import { useHistory } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import featureToggles from '../../featureToggles'

const PageContent = styled.div`
  margin: 20px 20px;
  display: flex;
`

const Column1 = styled.div`
  margin-right: 20px;
  margin-top: 50px;
`

const SettingsPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const history = useHistory()

  return (
    <React.Fragment>
      {/* <PageHeader title="Settings" sub="One Screen to configure them all." /> */}

      <PageContent>
        <Column1>
          <Menu secondary vertical>
            <Menu.Item header>Global Settings</Menu.Item>
            <Menu.Item
              name="Access Control"
              active={document.location.pathname === '/settings'}
              onClick={() => history.push('/settings')}
            />
            <Menu.Item
              name="External Recruiters"
              active={
                document.location.pathname === '/settings/external-recruiters'
              }
              onClick={() => history.push('/settings/external-recruiters')}
            />
          </Menu>
          <Menu secondary vertical>
            <Menu.Item header>Email Settings</Menu.Item>

            {featureToggles.emails && (
              <Menu.Item
                name="Email Sync"
                active={document.location.pathname === '/settings/email-sync'}
                onClick={() => history.push('/settings/email-sync')}
              />
            )}

            <Menu.Item
              name="Templates"
              active={
                document.location.pathname === '/settings/email-templates'
              }
              onClick={() => history.push('/settings/email-templates')}
            />
            {/* <Menu.Item name="Email Templates" /> */}

            {/* <Menu.Item name="External Recruiters" /> */}
            {/* <Menu.Item name="Candidates Metadata" /> */}

            {/* <Menu.Item name="Webhooks" /> */}
          </Menu>
        </Column1>

        <div>{children}</div>
      </PageContent>
    </React.Fragment>
  )
}

export default SettingsPage
