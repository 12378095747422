import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Icon, Loader } from 'semantic-ui-react'
import styled from 'styled-components'

import { Collection, FunnelColumn } from '../../types'
import { useTalentApi } from '../../store/mainContext'
import StatusSelector from './statusSelector'
import useFunnels from '../../hooks/useFunnels'
import Period from '../period'

const Container = styled.div`
  background-color: beige;
  color: #585858;
  border-radius: 4px;
  margin: 0 30px 30px;
  border: dotted 1px #e5e5c3;
  color: #585858;

  &:hover {
    border: solid 1px #e5e5c3;
  }
`

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;

  text-align: center;
`

interface Props {
  onSuccess(): void
  collection: Collection
  entity: { _id: string; status?: string | null; lastStatusUpdate?: number }
}

const FunnelBar = ({ entity, collection, onSuccess }: Props) => {
  const { data: funnels, isLoading, error } = useFunnels(collection)

  const api = useTalentApi()

  const [isOpen, setIsOpen] = useState(false)

  const entityFunnelName = entity.status?.split('.')[0]
  const entityFunnel = funnels?.find((f) => f.name === entityFunnelName)
  const entityColumn = entityFunnel?.columns.find((x) => x.id === entity.status)

  const handleRemove = async () => {
    return api
      .delete(`${collection}/${entity._id}/status`)
      .then(() => setIsOpen(false))
      .then(() => onSuccess())
  }

  const handleSave = async (columnId: FunnelColumn['id']) => {
    return api
      .patch(`${collection}/${entity._id}`, { status: columnId })
      .then(() => setIsOpen(false))
      .then(() => onSuccess())
  }

  if (isLoading) {
    return (
      <div className="m-9 text-center">
        <Loader active inline size="mini" />
      </div>
    )
  }

  if (error && !funnels) {
    return (
      <div className="m-9 rounded-md bg-red-100 p-4 text-red-800">
        <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
        {error.message}
      </div>
    )
  }

  return (
    <Container>
      {isOpen && funnels ? (
        <StatusSelector
          funnels={funnels}
          value={entityColumn}
          onCancel={() => setIsOpen(false)}
          onRemove={handleRemove}
          onSave={handleSave}
        />
      ) : (
        <Button type="button" onClick={() => setIsOpen((prev) => !prev)}>
          {entityFunnel ? (
            <>
              {entityFunnel.title} <Icon name="angle right" />
              {entityColumn?.title && (
                <span className="font-semibold">{entityColumn.title}</span>
              )}
              <div className="mt-1">
                <Period date={entity.lastStatusUpdate} />
              </div>
            </>
          ) : (
            <span className="text-slate-600">
              <Icon name="arrow right" /> Send to a Stage
            </span>
          )}
        </Button>
      )}
    </Container>
  )
}

export default FunnelBar
