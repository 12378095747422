import { Button, Confirm, Form, Icon, Modal } from 'semantic-ui-react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import useSWR, { useSWRConfig } from 'swr'
import TextareaAutosize from 'react-textarea-autosize'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import * as React from 'react'
import { z } from 'zod'

import { Candidate, TimeoffRequest, timeoffRequestSchema } from '../../types'
import { classNames, validateResponse } from '../../utils'
import TimeoffReviewConfirmation from '../TimeoffReviewConfirmation'
import TimeoffRequestDetails from '../feeds/TimeoffRequestDetails'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

export type Dialog =
  | { type: 'approve-timeoff-request'; request: TimeoffRequest }
  | { type: 'reject-timeoff-request'; request: TimeoffRequest }
  | { type: 'review-timeoff-request'; request: TimeoffRequest }

interface Props {
  candidateId: Candidate['_id']
  onApprove(request: TimeoffRequest): void
  onReject(request: TimeoffRequest): void
  onClose(): void
  currentDialog: Dialog | null
}

export default function TimeoffModal(props: Props) {
  const { currentDialog, onApprove, onReject, onClose } = props

  const { mutate: globalMutate } = useSWRConfig()
  const { addToast } = useToasts()
  const api = useApi()

  const lastApprovedRequest = useSWR(
    currentDialog
      ? ['previous-timeoff-request', currentDialog.request.contractId]
      : null,
    () =>
      api
        .get('timeoff-requests', {
          params: {
            contractId: currentDialog?.request.contractId,
            status: 'Approved',
          },
          headers: {
            'astor-sortby': 'createdOn',
            'astor-sortby-dir': '-1',
            'astor-limit': 1,
          },
        })
        .then(validateResponse(z.array(timeoffRequestSchema))),
  )

  const [candidateNote, setCandidateNote] = React.useState('')
  const [isPending, setIsPending] = React.useState(false)

  const form = useForm<{ relatedNote: string }>({
    defaultValues: { relatedNote: '' },
  })

  const handleReview = async (
    status: TimeoffRequest['status'],
    underReview?: boolean,
  ) => {
    setIsPending(true)
    if (!currentDialog?.request._id) return
    const relatedNote = (form.getValues('relatedNote') || '').trim()
    return Promise.all([
      api.patch(`timeoff-requests/${currentDialog.request._id}`, {
        candidateNote,
        status,
        ...(underReview ? { underReview: true } : {}),
      }),
      relatedNote.length > 0
        ? api.post('related-notes', {
            relatedCollection: 'timeoff-requests',
            relatedId: currentDialog.request._id,
            body: relatedNote,
          })
        : Promise.resolve(),
    ])
      .then(() =>
        globalMutate(
          (key) =>
            Array.isArray(key) &&
            (key[0] === 'timeoff-for-review' ||
              key[0] === 'timeoff-requests' ||
              key[0] === 'related-notes'),
        ),
      )
      .then(onClose)
      .then(() => addToast('Timeoff request updated', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsPending(false))
  }

  const previous = (lastApprovedRequest.data || [])[0]

  return (
    <React.Fragment>
      <Modal
        closeOnDimmerClick={!isPending}
        closeOnEscape={!isPending}
        onClose={onClose}
        size="tiny"
        open={currentDialog?.type === 'review-timeoff-request'}
      >
        <Modal.Header>Review Timeoff Request</Modal.Header>

        <Modal.Content className="-mt-8">
          {currentDialog?.request ? (
            <div className="rounded-md bg-slate-100 p-4">
              <TimeoffRequestDetails requestId={currentDialog.request._id} />
            </div>
          ) : (
            <div className="py-4 text-base text-slate-600">Loading...</div>
          )}

          {typeof lastApprovedRequest.data === 'undefined' ? (
            lastApprovedRequest.error ? (
              <div className="my-6 rounded-md bg-red-100 p-4 text-red-800">
                <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
                {lastApprovedRequest.error.message}
              </div>
            ) : (
              <div className="py-4 text-base text-slate-600">
                Loading previous timeoff request info...
              </div>
            )
          ) : previous ? (
            <div className="mt-4 border-t py-4">
              <h3 className="flex justify-between gap-2 text-slate-600">
                <span>
                  <Icon name="info circle" color="grey" /> Previous timeoff
                  request
                </span>
                {previous.status && (
                  <div
                    className={classNames(
                      'inline-block rounded px-1.5 py-0.5 text-xs font-semibold uppercase',
                      previous.status === 'Approved' &&
                        'bg-green-300 text-green-800',
                      previous.status === 'Rejected' &&
                        'bg-red-200 text-red-800',
                      previous.status === 'Canceled' &&
                        'bg-slate-200 text-slate-700',
                      previous.status === 'Waiting for approval' &&
                        'bg-amber-200 text-amber-800',
                    )}
                  >
                    {previous.status}
                  </div>
                )}
              </h3>
              <TimeoffRequestDetails requestId={previous._id} />
            </div>
          ) : (
            <div className="mt-6 rounded-md bg-slate-50 p-4">
              <Icon name="info circle" color="grey" /> The candidate has no
              previous timeoff requests.
            </div>
          )}
        </Modal.Content>

        <Modal.Actions className="-mt-2 flex">
          <div className="flex grow justify-start">
            <Button
              className="!mr-0"
              compact
              content="Close"
              basic
              onClick={onClose}
            />
          </div>
          {currentDialog?.request && (
            <>
              <Button
                content={
                  currentDialog.request.underReview
                    ? 'Under review'
                    : 'Mark as Under Review'
                }
                disabled={currentDialog.request.underReview || isPending}
                loading={isPending}
                color="blue"
                className="!mr-0"
                basic
                compact
                onClick={() => handleReview('Waiting for approval', true)}
              />
              <Button
                className="!mr-0"
                content="Approve"
                color="green"
                compact
                onClick={() => onApprove(currentDialog.request)}
              />
              <Button
                className="!mr-0"
                content="Reject"
                color="red"
                compact
                onClick={() => onReject(currentDialog.request)}
              />
            </>
          )}
        </Modal.Actions>
      </Modal>

      <Confirm
        header="Are you sure?"
        content={
          <div className="px-6 pb-4">
            <TimeoffReviewConfirmation
              onCandidateNoteChange={setCandidateNote}
              candidateNote={candidateNote}
              requestId={currentDialog?.request._id}
              isPending={isPending}
              mode="approve"
            />
            <Form className="mt-4" spellCheck="false" id="related-note-form">
              <Form.Field>
                <label
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    display: 'block',
                  }}
                >
                  Internal notes{' '}
                  <span className="ml-1 font-normal text-slate-500">
                    (optional)
                  </span>
                </label>
                <TextareaAutosize
                  {...form.register('relatedNote')}
                  className={classNames(
                    'field !mb-0 !resize-none',
                    form.formState.isSubmitting && 'disabled',
                  )}
                  disabled={form.formState.isSubmitting}
                  minRows={2}
                  rows={2}
                />
              </Form.Field>
            </Form>
          </div>
        }
        closeOnDimmerClick={!isPending}
        closeOnEscape={!isPending}
        onCancel={onClose}
        onConfirm={() => handleReview('Approved')}
        size="tiny"
        open={currentDialog?.type === 'approve-timeoff-request'}
        cancelButton={<Button disabled={isPending} content="Cancel" />}
        confirmButton={
          <Button
            disabled={isPending || (candidateNote || '').length < 4}
            primary={false}
            color="green"
            loading={isPending}
          >
            Yes, approve the request
          </Button>
        }
      />

      <Confirm
        header="Are you sure?"
        content={
          <div className="px-6 pb-4">
            <TimeoffReviewConfirmation
              onCandidateNoteChange={setCandidateNote}
              candidateNote={candidateNote}
              requestId={currentDialog?.request._id}
              isPending={isPending}
              mode="reject"
            />
          </div>
        }
        closeOnDimmerClick={!isPending}
        closeOnEscape={!isPending}
        onCancel={onClose}
        onConfirm={() => handleReview('Rejected')}
        size="tiny"
        open={currentDialog?.type === 'reject-timeoff-request'}
        cancelButton={<Button disabled={isPending} content="Cancel" />}
        confirmButton={
          <Button
            disabled={isPending || (candidateNote || '').length < 4}
            primary={false}
            color="red"
            loading={isPending}
          >
            Yes, reject the request
          </Button>
        }
      />
    </React.Fragment>
  )
}
