import { Input, Button, Label } from 'semantic-ui-react'
import React, { useState } from 'react'

import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'
import { useApi } from '../../store/mainContext'

const TrInputTags = ({
  placeholder,
  onSuccess,
  readOnly,
  disabled,
  errors,
  onBlur,
  label,
  value,
  name,
  type,
  url,
}) => {
  const api = useApi()

  const [showEdit, setShowEdit] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [, setCancelOnBlur] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [val, setVal] = useState(value)

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj = {}

    obj[name] = val
      .split(',')
      .map((x) => x.trim())
      .filter((x) => x !== '')
      .join(',')

    await api.patch(url, obj)

    setIsLoading(false)
    setEditMode(false)
    setShowAdd(false)

    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setShowEdit(false)
    setEditMode(false)
    setIsLoading(false)
  }

  const handleKeyPress = (e) => {
    // const code = e.which

    if (e.which === 13) handleSave()
  }

  if (editMode) {
    return (
      <tr>
        <th>{label}</th>
        <td
          style={{
            paddingTop: 4,
            textAlign: `right`,
          }}
        >
          <Input
            name={name}
            placeholder={placeholder}
            error={
              (errors || []).indexOf(name) !== -1 && 'This field is required.'
            }
            value={val}
            onChange={(e) => setVal(e.target.value)}
            autoFocus={true}
            onBlur={onBlur}
            type={type}
            style={{ width: '100%' }}
            disabled={isLoading}
            onKeyPress={handleKeyPress}
            onFocus={(e) => e.target.select()}
          />

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (!val || val.length === 0) {
    if (readOnly || disabled) {
      return (
        <tr>
          <th>{label}</th>
          <td></td>
        </tr>
      )
    }

    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
      >
        <th>{label}</th>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <td>{showAdd && <a>+ Add value</a>}&nbsp;</td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <tr
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <th>{label}</th>
        <td style={{ position: 'relative' }}>
          {val
            .split(',')
            .map((x) => x.trim())
            .filter((x) => x !== '')
            .map((dv) => (
              <Label
                key={dv}
                // color={color}
                style={{ margin: 0, marginBottom: 7, marginRight: 5 }}
              >
                {dv}
              </Label>
            ))}

          <TrCopyButton visible={showEdit} value={val} />
          {!readOnly && !disabled && (
            <TrEditButton
              onClick={() => setEditMode(true)}
              visible={showEdit}
            />
          )}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default TrInputTags
