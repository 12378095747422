import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Dropdown, Form, Icon, Ref } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'
import {
  BtnClearFormatting,
  BtnStrikeThrough,
  BtnNumberedList,
  EditorProvider,
  BtnBulletList,
  BtnUnderline,
  HtmlButton,
  Separator,
  BtnItalic,
  Toolbar,
  BtnLink,
  BtnRedo,
  BtnBold,
  BtnUndo,
  Editor,
} from 'react-simple-wysiwyg'

import { classNames, validateResponse } from '../../utils'
import { Contact, contactSchema } from '../../types'
import { EnhancedInvoice } from '../../hooks/useInvoice'
import EmailPresetDropdown from './EmailPresetDropdown'
import AddContactModal from '../modals/addContactModal'
import { useApi } from '../../store/mainContext'
import UserName from '../remoteValues/userName'

type FormValues = {
  sendToIds: Contact['_id'][]
  sendToCopyIds: Contact['_id'][]
  sendMessage: string
}

interface DeliveryDetailsFormProps {
  isEditModeEnabled?: boolean
  onFieldUpdate(data: Partial<EnhancedInvoice>): Promise<any>
  onEditClick(): void
  onCancel(): void
  invoice: EnhancedInvoice
}

function DeliveryDetailsForm(props: DeliveryDetailsFormProps) {
  const { onFieldUpdate, invoice, onCancel, isEditModeEnabled, onEditClick } =
    props

  const api = useApi()

  const contacts = useSWR('contacts', () =>
    api.get('contacts').then(validateResponse(z.array(contactSchema))),
  )

  const [contactCreateDefaults, setContactCreateDefaults] = React.useState<
    (Partial<Contact> & { field: 'sendToIds' | 'sendToCopyIds' }) | undefined
  >(undefined)

  const form = useForm<FormValues>({
    defaultValues: {
      sendToIds: invoice.sendToIds || [],
      sendToCopyIds: invoice.sendToCopyIds || [],
      sendMessage: invoice.sendMessage || '',
    },
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    return onFieldUpdate(data).then(onCancel)
  }

  const handleContactCreated = async (newContact: Contact) => {
    const field = contactCreateDefaults?.field
    if (!field) return
    return contacts
      .mutate()
      .then(() => setContactCreateDefaults(undefined))
      .then(() => {
        form.setValue(field, [
          ...form
            .getValues(field)
            .filter((v) => contacts.data?.some((c) => c._id === v)),
          newContact._id,
        ])
      })
  }

  const sendToIdsElement = React.useRef(null)

  return (
    <>
      <Form
        className="relative z-10"
        onSubmit={form.handleSubmit(onSubmit)}
        spellCheck="false"
      >
        <div className="pb-3.5 text-sm font-semibold uppercase leading-none text-slate-500">
          Delivery details
        </div>
        <Controller
          name="sendToIds"
          control={form.control}
          rules={{
            validate(values) {
              return values.length > 0
                ? true
                : 'At least one contact is required'
            },
          }}
          render={({ field, fieldState }) => {
            const isDisabled =
              form.formState.isSubmitting ||
              contacts.isLoading ||
              !isEditModeEnabled
            return (
              <div className="relative z-20">
                <label
                  className={classNames(
                    'absolute left-[15px] top-[7px] z-20 block text-base font-normal',
                    isDisabled ? 'text-slate-500' : 'text-slate-600',
                  )}
                >
                  For
                </label>
                <Dropdown
                  {...field}
                  icon={null}
                  error={!!fieldState.error}
                  disabled={isDisabled}
                  loading={contacts.isLoading}
                  options={(contacts.data || []).map((contact) => ({
                    text: `${contact.name} (${contact.email})`,
                    value: contact._id,
                  }))}
                  onChange={(_, p) => field.onChange(p.value)}
                  allowAdditions
                  selection
                  multiple
                  search
                  fluid
                  placeholder="No contacts selected"
                  className={classNames(
                    'no-overrides !pl-14 [&>.divider.default]:!italic [&>.divider.default]:!text-slate-400 [&>.search]:!ml-0',
                    !isEditModeEnabled &&
                      '!border-transparent !bg-transparent [&>.search]:!hidden',
                    isDisabled && '!opacity-95 [&_.delete]:!opacity-0',
                  )}
                  additionLabel="Add New Contact: "
                  renderLabel={(item) => ({
                    className:
                      '!leading-tight no-overrides !text-sm !text-slate-600 !font-normal !bg-slate-100',
                    content: item.text,
                  })}
                  ref={sendToIdsElement}
                  onAddItem={(_, p) => {
                    if (typeof p.value !== 'string') return
                    setContactCreateDefaults({
                      name: p.value,
                      field: field.name,
                    })
                    return false
                  }}
                />
              </div>
            )
          }}
        />
        <Controller
          name="sendToCopyIds"
          control={form.control}
          render={({ field }) => {
            const isDisabled =
              form.formState.isSubmitting ||
              contacts.isLoading ||
              !isEditModeEnabled
            return (
              <div
                className={classNames(
                  'relative z-10 mt-2',
                  !invoice.sendToCopyIds?.length &&
                    !isEditModeEnabled &&
                    'hidden',
                )}
              >
                <label
                  className={classNames(
                    'absolute left-[15px] top-[7px] z-20 block text-base font-normal',
                    isDisabled ? 'text-slate-500' : 'text-slate-600',
                  )}
                >
                  CC
                </label>
                <Dropdown
                  {...field}
                  icon={null}
                  disabled={isDisabled}
                  loading={contacts.isLoading}
                  options={(contacts.data || []).map((contact) => ({
                    text: `${contact.name} (${contact.email})`,
                    value: contact._id,
                  }))}
                  onChange={(_, p) => field.onChange(p.value)}
                  renderLabel={(item) => ({
                    className:
                      '!leading-tight no-overrides !text-sm !text-slate-600 !font-normal !bg-slate-100',
                    content: item.text,
                  })}
                  allowAdditions
                  selection
                  multiple
                  search
                  fluid
                  className={classNames(
                    'no-overrides z-0 !pl-14 [&>.search]:!ml-0',
                    !isEditModeEnabled && '!border-transparent !bg-transparent',
                    isDisabled && '!opacity-95 [&_.delete]:!opacity-0',
                  )}
                  additionLabel="Add New Contact: "
                  ref={sendToIdsElement}
                  onAddItem={(_, p) => {
                    if (typeof p.value !== 'string') return
                    setContactCreateDefaults({
                      name: p.value,
                      field: field.name,
                    })
                  }}
                />
              </div>
            )
          }}
        />

        <div className="mt-2">
          <Controller
            name="sendMessage"
            control={form.control}
            rules={{ required: 'A message is required' }}
            render={({ field }) => {
              const isDisabled =
                form.formState.isSubmitting || !isEditModeEnabled
              const { ref, ...other } = field
              return (
                <div>
                  <label className="sr-only block text-base font-semibold text-slate-600">
                    Message
                  </label>
                  {isEditModeEnabled ? (
                    <div
                      className={classNames(
                        'relative',
                        form.formState.errors.sendMessage && 'rsw-has-error',
                        isDisabled && 'rsw-is-disabled',
                      )}
                    >
                      <EditorProvider>
                        <Ref innerRef={ref}>
                          <Editor {...other} disabled={isDisabled}>
                            <Toolbar>
                              <BtnUndo />
                              <BtnRedo />
                              <Separator />
                              <BtnBold />
                              <BtnItalic />
                              <BtnUnderline />
                              <BtnStrikeThrough />
                              <Separator />
                              <BtnNumberedList />
                              <BtnBulletList />
                              <Separator />
                              <BtnLink />
                              <BtnClearFormatting />
                              <HtmlButton />
                            </Toolbar>
                          </Editor>
                        </Ref>
                      </EditorProvider>
                      <div className="absolute right-1.5 top-[6px]">
                        <EmailPresetDropdown
                          disabled={isDisabled}
                          onChange={(v) => form.setValue('sendMessage', v)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mb-2 whitespace-break-spaces border border-transparent border-b-slate-200 border-t-slate-200 px-4 py-[11px] text-base leading-[17.9998px] text-slate-700">
                      {form.watch('sendMessage') ? (
                        <div
                          className="rsw-view-mode"
                          dangerouslySetInnerHTML={{
                            __html: form.watch('sendMessage'),
                          }}
                        />
                      ) : (
                        <button
                          onClick={onEditClick}
                          className="text-primary"
                          type="button"
                        >
                          + Add a message
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )
            }}
          />
        </div>

        {!isEditModeEnabled && invoice.sendAt && !invoice.sentOn && (
          <div className="flex items-baseline gap-1.5 pt-2 text-sm text-slate-500">
            <span className="relative -left-px text-slate-500/80">
              <Icon name="calendar alternate outline" fitted />
            </span>
            {invoice.status === 'Ready to be Sent' ? (
              <span>
                Scheduled for delivery on {format(invoice.sendAt, 'PPPp')}
              </span>
            ) : (
              <span>Delivery date: {format(invoice.sendAt, 'PPPp')}</span>
            )}
          </div>
        )}

        {isEditModeEnabled && (
          <div className="mt-1 text-base leading-snug text-red-500">
            {[
              form.formState.errors.sendMessage?.message,
              form.formState.errors.sendToIds?.message,
            ]
              .filter(Boolean)
              .join('. ')}
          </div>
        )}

        {isEditModeEnabled && (
          <div className="flex items-baseline justify-end gap-2 pt-4">
            <Button
              content="Cancel"
              onClick={onCancel}
              disabled={form.formState.isSubmitting}
              className="!mr-0"
              type="button"
              size="small"
              basic
            />
            <Button
              content="Save"
              disabled={form.formState.isSubmitting}
              loading={form.formState.isSubmitting}
              primary
              className="!mr-0"
              type="submit"
              size="small"
            />
          </div>
        )}
      </Form>

      <AddContactModal
        open={!!contactCreateDefaults}
        onCancel={() => setContactCreateDefaults(undefined)}
        defaults={contactCreateDefaults}
        onCreated={handleContactCreated}
      />
    </>
  )
}

interface Props {
  onFieldUpdate(data: Partial<EnhancedInvoice>): Promise<any>
  invoice: EnhancedInvoice
}

export default function InvoiceDeliveryDetails(props: Props) {
  const { invoice } = props

  const [isEditModeEnabled, setIsEditModeEnabled] = React.useState(false)

  return (
    <div className="@container">
      <div
        className={classNames(
          'group relative mb-4 rounded px-6 pb-5 pt-6 text-sm shadow @xl:px-12',
          invoice.status === 'Draft'
            ? isEditModeEnabled
              ? 'bg-slate-50'
              : 'bg-white hover:bg-slate-50'
            : 'bg-white',
        )}
      >
        <DeliveryDetailsForm
          isEditModeEnabled={isEditModeEnabled}
          onFieldUpdate={props.onFieldUpdate}
          onEditClick={() => setIsEditModeEnabled(true)}
          onCancel={() => setIsEditModeEnabled(false)}
          invoice={invoice}
        />

        {invoice.sentOn && (
          <div className="pt-2 text-sm text-slate-500/80">
            <span className="relative -top-px mr-0.5">
              <Icon name="paper plane" size="small" fitted />
            </span>{' '}
            Sent on {format(invoice.sentOn, 'PPPp')}{' '}
            {invoice.sentBy && (
              <>
                by <UserName id={invoice.sentBy} />
              </>
            )}
          </div>
        )}

        {invoice.status === 'Draft' && !isEditModeEnabled && (
          <div className="absolute right-3 top-3.5">
            <Button
              onClick={() => setIsEditModeEnabled(true)}
              icon="pencil"
              size="mini"
              compact
              basic
            />
          </div>
        )}
      </div>
    </div>
  )
}
