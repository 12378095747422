import { Button, ButtonGroup, Icon } from 'semantic-ui-react'
import { useState } from 'react'
import { format } from 'date-fns'
import * as React from 'react'
import styled from 'styled-components'

import { isBeforeToday, isToday } from '../../utils'
import { BillingPeriod, Task } from '../../types'
import CheckButton from '../../components/form/checkButton'
import EntityLink from '../../components/remoteValues/entityLink'
import DateFormat from '../../components/dateFormat'
import DataTable from '../../components/dataTable/dataTable'
import UserName from '../../components/remoteValues/userName'

type CellVariant = 'overdue' | 'done' | 'due-today' | 'not-done'

const Td = styled(DataTable.Td).withConfig<{
  variant: CellVariant
  noLineThrough?: boolean
}>({
  shouldForwardProp: (p) => p !== 'noLineThrough',
})`
  padding-top: 12px;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: ${(props) => props.align};
  text-decoration: ${({ variant, noLineThrough }) =>
    variant === 'done' && !noLineThrough ? 'line-through' : 'auto'};
  color: ${({ variant }) =>
    variant === 'due-today'
      ? 'var(--green)'
      : variant === 'overdue'
        ? 'var(--red) !important'
        : variant === 'done'
          ? 'var(--primary)'
          : 'unset'};
`

interface Props {
  isToggling?: boolean
  noLineThrough?: boolean
  onToggle(t: Task): void
  onRemove(t: Task): void
  onEdit(t: Task): void
  task: Task
}

const TaskRow = ({
  noLineThrough,
  isToggling,
  onToggle,
  onRemove,
  onEdit,
  task,
}: Props) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const [, setIsRemoving] = useState(false)

  const variant =
    task.isDone || isToggling
      ? 'done'
      : isToday(task.dueDate)
        ? 'due-today'
        : isBeforeToday(task.dueDate)
          ? 'overdue'
          : 'not-done'

  return (
    <tr>
      <DataTable.Td align="center" valign="baseline" paddingTop={8}>
        <CheckButton
          disabled={task.isDone}
          onChange={() => onToggle(task)}
          value={task.isDone || isToggling}
        />
      </DataTable.Td>

      <Td
        variant={variant}
        noLineThrough={noLineThrough}
        onClick={() => onEdit(task)}
      >
        <div className="line-clamp-2">{task.subject}</div>
      </Td>

      <Td
        variant={variant}
        noLineThrough={noLineThrough}
        onClick={() => onEdit(task)}
      >
        <div
          className="line-clamp-2"
          dangerouslySetInnerHTML={{ __html: task.details || '' }}
        ></div>
      </Td>

      <Td
        variant={variant}
        className="whitespace-nowrap"
        noLineThrough={noLineThrough}
      >
        {task.relatedCollection && task.relatedCollection !== 'nothing' ? (
          <EntityLink
            entityId={task.relatedId}
            collection={task.relatedCollection}
            linkTo={
              task.relatedCollection === 'billing-periods'
                ? (period: BillingPeriod) => `/billing/${period._id}`
                : undefined
            }
            render={
              task.relatedCollection === 'payrolls'
                ? (payroll) => `Payroll: ${payroll.name}`
                : task.relatedCollection === 'billing-periods'
                  ? (period: BillingPeriod) => (
                      <div>
                        Billing period:
                        <div className="-mt-0.5 text-base">
                          {format(period.periodStart, 'yyyy-MM-dd')} &ndash;{' '}
                          {format(period.periodEnd, 'yyyy-MM-dd')}
                        </div>
                      </div>
                    )
                  : undefined
            }
          />
        ) : (
          'Nothing related'
        )}
      </Td>

      <Td
        noLineThrough={noLineThrough}
        className="whitespace-nowrap"
        variant={variant}
        align="left"
        onClick={() => onEdit(task)}
      >
        {task.doneOn ? (
          <DateFormat date={task.doneOn} explain />
        ) : (
          <DateFormat date={task.dueDate} explain />
        )}
      </Td>

      <Td
        variant={variant}
        noLineThrough={noLineThrough}
        onClick={() => onEdit(task)}
      >
        <UserName id={task.createdBy} short />
      </Td>

      <Td
        noLineThrough={noLineThrough}
        className="whitespace-nowrap"
        variant={variant}
        align="right"
      >
        {showConfirmRemove ? (
          <ButtonGroup size="tiny">
            <Button
              color="red"
              icon
              onClick={() => {
                setIsRemoving(true)
                setShowConfirmRemove(false)
                onRemove(task)
              }}
            >
              Yes, Remove.
            </Button>
            <Button
              icon
              onClick={() => {
                setShowConfirmRemove(false)
              }}
            >
              No, go back.
            </Button>
          </ButtonGroup>
        ) : (
          <>
            <Button
              size="tiny"
              basic
              icon
              onClick={() => onEdit(task)}
              disabled={isToggling}
            >
              <Icon name="pencil alternate" />
            </Button>
            <Button
              size="tiny"
              color="red"
              icon
              onClick={() => setShowConfirmRemove(true)}
              disabled={isToggling}
            >
              <Icon name="trash alternate outline" />
            </Button>
          </>
        )}
      </Td>
    </tr>
  )
}

export default TaskRow
