import {
  ModalContent,
  ModalHeader,
  ModalProps,
  Button,
  Modal,
} from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'

import { PaymentOrder } from '../../types'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

interface Props extends ModalProps {
  paymentOrderId: PaymentOrder['_id']
  onSuccess(): Promise<any>
  onClose(): void
}

export default function ConfirmGenerateDebitsModal(props: Props) {
  const { paymentOrderId, onSuccess, ...other } = props

  const { addToast } = useToasts()

  const api = useApi()

  const [isCreating, setIsCreating] = React.useState(false)

  const handleGenerateDebits = async () => {
    setIsCreating(true)
    return api
      .post<PaymentOrder>(`payment-orders/${paymentOrderId}/debits`)
      .then(() => onSuccess())
      .then(() => other.onClose())
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreating(false))
  }

  return (
    <Modal
      {...other}
      closeOnDimmerClick={!isCreating}
      closeOnEscape={!isCreating}
      size="tiny"
    >
      <ModalHeader>Confirm Debits Generation</ModalHeader>

      <ModalContent className="!pt-0">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleGenerateDebits()
          }}
        >
          <p className="text-base">This action will:</p>

          <ul className="list-disc space-y-2 pl-6 text-base">
            <li>Scan all current candidate balances.</li>
            <li>
              Create{' '}
              <strong className="text-blue-700">debit transactions</strong> for
              the corresponding amounts.
            </li>
          </ul>

          <p className="max-w-prose pt-4 text-base">
            You will be able to <strong>approve/dismiss</strong> each
            transaction and also create <strong>custom transactions</strong> to
            further adjust the debit amounts.
          </p>

          <div className="mt-10 flex gap-2">
            <Button
              content="Generate"
              className="!mr-0"
              disabled={isCreating}
              loading={isCreating}
              primary
              type="submit"
            />
            <Button
              type="button"
              content="Cancel"
              className="!mr-0"
              onClick={props.onClose}
              disabled={isCreating}
              basic
            />
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}
