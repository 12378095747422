import { SubmitHandler, useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { Button, Form } from 'semantic-ui-react'
import * as React from 'react'

import { classNames } from '../../utils'

type FormValues = { note: string }

function Field(props: {
  onCancel(): void
  onSubmit: SubmitHandler<FormValues>
  value?: string
}) {
  const { onCancel, onSubmit } = props

  const form = useForm<FormValues>({
    defaultValues: { note: props.value },
  })

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)} spellCheck="false">
      <label className="block pb-1 text-sm font-semibold uppercase text-slate-500">
        Note
      </label>
      <TextareaAutosize
        {...form.register('note')}
        className={classNames(
          'field !mb-0 !resize-none placeholder:!font-semibold',
          form.formState.isSubmitting && 'disabled',
        )}
        disabled={form.formState.isSubmitting}
        minRows={3}
        rows={3}
      />
      <div className="flex justify-end gap-1 pt-1.5">
        <Button
          onClick={onCancel}
          content="Cancel"
          className="!mr-0"
          size="tiny"
          compact
          type="button"
        />
        <Button
          content="Save"
          className="!mr-0"
          primary
          size="tiny"
          compact
          type="submit"
        />
      </div>
    </Form>
  )
}

interface Props {
  value?: string
  onChange(newValue: string): Promise<any>
  onClear(): Promise<any>
  isEditable?: boolean
}

export default function NoteForm(props: Props) {
  const [isEditing, setIsEditing] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async ({ note }) => {
    setIsEditing(false)
    return note ? props.onChange(note) : props.onClear()
  }

  return (
    <div
      className={classNames(
        'group -mx-2 rounded-md px-2 py-2',
        props.isEditable && 'hover:bg-slate-100',
        isEditing && 'bg-slate-100',
      )}
    >
      {!isEditing && (
        <div className="relative">
          {props.value ? (
            <div>
              <label className="block text-sm font-semibold uppercase text-slate-500">
                Note
              </label>
              <div className="whitespace-break-spaces text-base leading-normal">
                {props.value}
              </div>
            </div>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="text-primary"
              type="button"
            >
              + Add a custom note
            </button>
          )}

          {props.isEditable && (
            <div className="absolute right-0 top-0 opacity-0 group-hover:opacity-100">
              <Button
                onClick={() => setIsEditing(true)}
                className={classNames('!mr-0', !props.value && '!hidden')}
                icon="pencil"
                size="mini"
                compact
                basic
              />
            </div>
          )}
        </div>
      )}

      {isEditing && (
        <Field
          onSubmit={onSubmit}
          onCancel={() => setIsEditing(false)}
          value={props.value || ''}
        />
      )}
    </div>
  )
}
