import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'

import { useTalentApi } from '../../store/mainContext'
import AddContactModal from '../modals/addContactModal'
import { Contact } from '../../types'

interface Props {
  value?: any
  name: string
  allowAdditions?: boolean
  placeholder?: string
  fluid?: boolean
  error?: boolean
  disabled?: boolean
  multiple?: boolean
  onChange?(e: null, args: { name: string; value?: any }): void
}

const ContactSelect = ({
  allowAdditions,
  onChange,
  name,
  fluid,
  error,
  placeholder,
  disabled,
  value,
  multiple,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState<Props['value'] | null>(
    null,
  )
  const [options, setOptions] = useState<
    Array<{ key: Contact['_id']; value: Contact['_id']; text: string }>
  >([])

  const api = useTalentApi()

  useEffect(() => {
    api
      .get<Contact[]>(`contacts`)
      .then(({ data }) => {
        setOptions(
          data.map((c) => ({
            text: `${c.name} (${c.email})`,
            value: c._id,
            key: c._id,
          })),
        )
      })
      .finally(() => setIsLoading(false))
  }, [api])

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleAdd: DropdownProps['onAddItem'] = (_: any, payload) => {
    setSelectedValue(payload.value)
    setShowAddModal(true)
  }

  const handleCancel = () => {
    setSelectedValue(null)
    setShowAddModal(false)
    onChange && onChange(null, { name, value: null })
  }

  const handleCreated = (newvalue: Contact) => {
    const selectedOption = {
      text: newvalue.name,
      value: newvalue._id,
      key: newvalue._id,
    }

    setOptions([...options, selectedOption])
    const newValue = multiple ? [selectedOption.value] : selectedOption.value
    setSelectedValue(newValue)

    setShowAddModal(false)
    console.log('selected', selectedOption)
    onChange && onChange(null, { name, value: newValue })
  }

  const handleOnChange = (e: any, t: { value?: Props['value'] }) => {
    setSelectedValue(t.value)
    onChange && onChange(null, { name, value: t.value })
  }

  return (
    <React.Fragment>
      <Dropdown
        key={selectedValue}
        name={name}
        onChange={handleOnChange}
        placeholder={placeholder}
        search
        allowAdditions={allowAdditions}
        fluid={fluid || true}
        selection
        options={options}
        loading={isLoading}
        onAddItem={handleAdd}
        value={selectedValue}
        error={error}
        clearable
        disabled={disabled}
        multiple={multiple}
      />

      <AddContactModal
        open={showAddModal}
        defaults={{ name: selectedValue }}
        onCreated={handleCreated}
        onCancel={handleCancel}
      />
    </React.Fragment>
  )
}

export default ContactSelect
