import { Label, Placeholder } from 'semantic-ui-react'
import React, { ReactNode } from 'react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import useSWR from 'swr'

import { Collection } from '../types'
import RemoteValue from './remoteValues/remoteValue'
import { useApi } from './../store/mainContext'

const skip = ['lastEvent', 'index', 'order']

const knownFields: Record<
  string,
  {
    text?: string
    collection?: Collection
    predicate?(p: { name?: string; email?: string; text?: string }): string
    render?(value: any): ReactNode
    fullRender?(value: any): ReactNode
  }
> = {
  isPaused: {
    render: (value: any) => (value ? 'was paused.' : 'was resumed.'),
  },
  sharePublicly: {
    render: (value: any) =>
      value ? (
        <React.Fragment>
          {' '}
          - Shared Publicly was <b>enabled</b>.
        </React.Fragment>
      ) : (
        <React.Fragment>
          {' '}
          - Shared Publicly was <b>disabled</b>.
        </React.Fragment>
      ),
  },
  shareInSlack: {
    render: (value: any) =>
      value ? (
        <React.Fragment>
          {' '}
          - Share in Slack was <b>enabled</b>.
        </React.Fragment>
      ) : (
        <React.Fragment>
          {' '}
          - Share In Slack was <b>disabled</b>.
        </React.Fragment>
      ),
  },
  isHighPriority: {
    render: (value: any) =>
      value ? (
        <React.Fragment>
          {' '}
          was marked as <b>High Priority</b>.
        </React.Fragment>
      ) : (
        <React.Fragment>
          {' '}
          was marked as <b>Normal Priority</b>.
        </React.Fragment>
      ),
  },
  companyId: {
    text: 'company',
    collection: 'companies',
    predicate: (p: { name: string }) => p.name,
  },
  requesterId: {
    text: 'Requested By',
    collection: 'contacts',
    predicate: (p) => `${p.name} (${p.email})`,
  },
  hiringManagerId: {
    text: 'hiring manager',
    collection: 'contacts',
    predicate: (p) => `${p.name} (${p.email})`,
  },
  secondContact: {
    text: '2nd Contact',
    collection: 'contacts',
    predicate: (p) => `${p.name} (${p.email})`,
  },
  thirdContact: {
    text: '3rd Contact',
    collection: 'contacts',
    predicate: (p) => `${p.name} (${p.email})`,
  },
  fourthContact: {
    text: '4th Contact',
    collection: 'contacts',
    predicate: (p) => `${p.name} (${p.email})`,
  },
  techsRequired: {
    text: 'Main Techs',
    collection: 'techs',
    predicate: (p) => `${p.text}`,
  },
  profilesRequired: {
    text: 'Profiles Required',
    collection: 'profiles',
    predicate: (p) => `${p.text}`,
  },
  date: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Issue Date</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">
          {value ? format(value, 'PP') : 'left empty'}
        </span>
      </>
    ),
  },
  dueDate: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Due Date</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">
          {value ? format(value, 'PP') : 'left empty'}
        </span>
      </>
    ),
  },
  periodStartDate: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Period Start Date</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">
          {value ? format(value, 'PP') : 'left empty'}
        </span>
      </>
    ),
  },
  periodEndDate: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Period End Date</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">
          {value ? format(value, 'PP') : 'left empty'}
        </span>
      </>
    ),
  },
  sentOn: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Sent on</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">
          {value ? format(value, 'PP') : 'left empty'}
        </span>
      </>
    ),
  },
  paidOn: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Paid on</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">
          {value ? format(value, 'PP') : 'left empty'}
        </span>
      </>
    ),
  },
  period: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Period</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">{value || 'left empty'}</span>
      </>
    ),
  },
  number: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Invoice Number</span> was{' '}
        {value ? 'set to ' : ''}
        <span className="font-semibold">{value || 'left empty'}</span>
      </>
    ),
  },
  customerId: {
    fullRender: (value) => (
      <>
        <span className="font-semibold">Customer</span> was set to{' '}
        <span className="font-semibold">
          <RemoteValue
            collection="companies"
            predicate={(p) => p.name}
            id={value}
          />
        </span>
      </>
    ),
  },
}

interface ChangelogItem {
  type: 'doc-creation' | 'doc-change-remove' | 'doc-change-remove'
  what: Record<keyof typeof knownFields, any>
  when: number
  who: string
  _id: string
}

interface Props {
  entityName: string
  id: string
}

const ChangeLog = ({ entityName, id }: Props) => {
  const api = useApi()

  const { data, isLoading } = useSWR<ChangelogItem[], AxiosError>(
    id ? ['changelog', entityName, id] : null,
    async () => {
      return api
        .get('_changelog', {
          params: { docId: id },
          headers: {
            'astor-sortby': 'when',
            'astor-sortby-dir': '-1',
          },
        })
        .then((res) => res.data)
    },
  )

  if (isLoading) {
    return (
      <Placeholder>
        <Placeholder.Line length="long" />
        <Placeholder.Line length="medium" />
        <Placeholder.Line length="very long" />
        <Placeholder.Line length="medium" />
      </Placeholder>
    )
  }

  return (
    <ul className="space-y-4 text-base text-slate-800">
      {(data || []).map((entry) => (
        <li key={entry._id}>
          {entry.type === 'doc-creation' ? (
            <DocCreationEvent entityName={entityName} entry={entry} />
          ) : entry.type === 'doc-change-remove' ? (
            <DocChangeRemoveEvent entityName={entityName} entry={entry} />
          ) : (
            <DocChangeEvent entityName={entityName} entry={entry} />
          )}
        </li>
      ))}
    </ul>
  )
}

interface DocCreationEventProps {
  entityName: string
  entry: ChangelogItem
}

const DocCreationEvent = ({ entityName, entry }: DocCreationEventProps) => (
  <div>
    <span className="capitalize">{entityName}</span> created
    <div className="text-sm text-slate-500">
      by <b>{entry.who}</b> on {format(entry.when, 'Ppp')}
    </div>
  </div>
)

interface DocChangeRemoveEventProps {
  entityName: string
  entry: ChangelogItem
}

const DocChangeRemoveEvent = ({ entry }: DocChangeRemoveEventProps) => (
  <div>
    <ul>
      {Object.keys(entry.what).map((key) => {
        const field = knownFields[key]
        return (
          <li key={key}>
            <span className="font-semibold capitalize">
              {field && field?.text
                ? field.text
                : key
                    .split(/(?=[A-Z])/)
                    .map((s) => s.toLowerCase())
                    .join(' ')}
            </span>{' '}
            was removed.
          </li>
        )
      })}
    </ul>
    <div className="text-sm text-slate-500">
      by <b>{entry.who}</b> on {format(entry.when, 'Ppp')}
    </div>
  </div>
)

interface DocChangeEventProps {
  entityName: string
  entry: ChangelogItem
}

const DocChangeEvent = ({ entityName, entry }: DocChangeEventProps) => {
  const what = Object.keys(entry.what).filter((key) => !skip.includes(key))

  if (what.length === 0) return null

  const result = what.map((key) => {
    const fieldInfo = knownFields[key]
    const value = entry.what[key]

    if (!fieldInfo) {
      return (
        <div key={key}>
          <span className="font-semibold capitalize">
            {key
              .split(/(?=[A-Z])/)
              .map((s) => s.toLowerCase())
              .join(' ')}
          </span>{' '}
          was set to{' '}
          <span className="font-semibold">
            {Array.isArray(value) ? value.join(' ') : value}
          </span>
        </div>
      )
    }

    if (fieldInfo.fullRender) {
      return <div key={key}>{fieldInfo.fullRender(value)}</div>
    }

    if (fieldInfo.render) {
      return (
        <div key={key}>
          <span className="capitalize">{entityName}</span>{' '}
          {fieldInfo.render(value)}
        </div>
      )
    }

    if (!Array.isArray(value)) {
      return (
        <div key={key}>
          <span className="capitalize">{fieldInfo.text}</span> was set to{' '}
          <b>
            <RemoteValue
              collection={fieldInfo.collection}
              predicate={fieldInfo.predicate}
              id={value}
            />
          </b>
        </div>
      )
    }

    return (
      <div key={key}>
        <span className="capitalize">{fieldInfo.text}</span> was set to{' '}
        {value.map((val) => (
          <i key={val}>
            <b>
              <Label style={{ marginRight: 10 }}>
                <RemoteValue
                  collection={fieldInfo.collection}
                  predicate={fieldInfo.predicate}
                  id={val}
                />
              </Label>
            </b>
          </i>
        ))}
      </div>
    )
  })

  return (
    <div>
      <div>{result.map((x) => x)}</div>
      <div className="text-sm text-slate-500">
        by <b>{entry.who}</b> on {format(entry.when, 'Ppp')}
      </div>
    </div>
  )
}

export default ChangeLog
