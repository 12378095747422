import { Button, Icon, Label, SemanticCOLORS } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import ContactSelect from '../form/contactSelect'
import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'
import { Contact } from '../../types'
import { useApi } from '../../store/mainContext'

interface Props {
  name: string
  label: string
  placeholder?: string
  value: any
  url: string
  color?: SemanticCOLORS
  divider?: boolean
  allowAdditions?: boolean
  readOnly?: boolean
  fluid?: boolean
  disabled?: boolean
  multiple?: boolean
  onSuccess?(name: string, value?: Props['value']): void
  render?(value: Props['value']): string
}

const TrContactSelect = ({
  name,
  label,
  placeholder,
  value,
  onSuccess,
  url,
  render: renderFn,
  divider,
  color,
  allowAdditions,
  fluid,
  readOnly,
  disabled,
  multiple,
}: Props) => {
  const api = useApi()

  const [showEdit, setShowEdit] = useState(false)

  const [editMode, setEditMode] = useState(false)
  const [val, setVal] = useState(value)
  const [showAdd, setShowAdd] = useState(false)

  const [displayValue, setDisplayValue] = useState<string[] | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  const [, setCancelOnBlur] = useState(false)

  const refreshDisplayValue = React.useCallback(
    async (newvalue: Props['value']) => {
      const render = renderFn || ((x) => x.text)

      if (!newvalue) {
        setDisplayValue(null)
        return
      }

      if (Array.isArray(newvalue)) {
        const { data } = await api.get<Contact[]>('contacts')

        setDisplayValue(
          (newvalue || []).map((v) =>
            render((data.filter((d) => d._id === v) || [])[0] || {}),
          ),
        )

        return
      }

      const { data } = await api.get(`contacts/${newvalue}`)
      setDisplayValue(render(data))
    },
    [api, renderFn],
  )

  useEffect(() => {
    setVal(value)
    refreshDisplayValue(value)
  }, [value, refreshDisplayValue])

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj: Record<string, any> = {}
    obj[name] = val

    if (!val || val === '' || val.length === 0) {
      await api.delete(`${url}/${name}`)
    } else {
      await api.patch(url, obj)
    }

    await refreshDisplayValue(val)

    setIsLoading(false)
    setEditMode(false)
    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setEditMode(false)
    setIsLoading(false)
  }

  if (editMode) {
    return (
      <tr>
        <th className={divider ? 'solidBorderTop' : ''}>{label}</th>
        <td
          className={divider ? 'solidBorderTop' : ''}
          style={{
            paddingTop: 4,
            textAlign: `right`,
          }}
        >
          <ContactSelect
            allowAdditions={allowAdditions}
            onChange={(e, t) => setVal(t.value)}
            name={name}
            fluid={fluid}
            placeholder={placeholder}
            value={val}
            disabled={isLoading}
            multiple={multiple}
          />

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (
    (Array.isArray(displayValue) && displayValue.length === 0) ||
    val === '' ||
    !val
  ) {
    if (readOnly || disabled) {
      return (
        <tr>
          <th>{label}</th>
          <td></td>
        </tr>
      )
    }

    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
        style={{ cursor: 'pointer' }}
      >
        <th>{label}</th>
        <td>
          {showAdd && (
            <span style={{ color: 'var(--primary)' }}>+ Add value</span>
          )}
        </td>
      </tr>
    )
  }

  if (Array.isArray(displayValue)) {
    return (
      <tr
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <th className="!align-baseline">{label}</th>
        <td className="relative align-baseline">
          {(displayValue || []).map((dv) => (
            <Label
              color={color}
              style={{ margin: 0, marginBottom: 7, marginRight: 5 }}
              key={dv}
            >
              {dv}
            </Label>
          ))}

          <TrCopyButton visible={showEdit} value={displayValue.join(', ')} />
          <TrEditButton onClick={() => setEditMode(true)} visible={showEdit} />
        </td>
      </tr>
    )
  }

  return (
    <tr
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
    >
      <th>{label}</th>
      <td style={{ position: 'relative' }}>
        <a href={`/contacts/${value}`} target="_bank">
          <Icon name="user circle outline" />
          {displayValue}
        </a>
        <TrCopyButton visible={showEdit} value={displayValue} />
        {!readOnly && !disabled && (
          <TrEditButton onClick={() => setEditMode(true)} visible={showEdit} />
        )}
      </td>
    </tr>
  )
}

export default TrContactSelect
