import { format, formatDistanceToNow } from 'date-fns'
import { Button, Popup } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import * as React from 'react'

import { RecipeSchedule, User } from '../../types'
import ArgumentsPopup from './ArgumentsPopup'
import useSortable from '../../hooks/useSortable'
import RemoteValue from '../remoteValues/remoteValue'
import ThSortable from '../tables/ThSortable'

interface Props {
  onDeleteClick(schedule: RecipeSchedule): void
  schedules: (RecipeSchedule & { createdByUser?: User })[]
}

export default function RecipeScheduledRuns(props: Props) {
  const { onDeleteClick, schedules } = props

  const schedulesSort = useSortable('recipe-schedules', {
    sortBy: 'whenToRun',
    sortByDir: 'ascending',
  })

  const sortedSchedules = schedules.sort((first, second) => {
    const { sortByDir, sortBy } = schedulesSort
    const a = sortByDir === 'ascending' ? first : second
    const b = sortByDir === 'ascending' ? second : first
    if (sortBy === 'scheduledBy') {
      return (a.createdByUser?.name || '').localeCompare(
        b.createdByUser?.name || '',
      )
    }
    if (sortBy === 'addedToTheQueueOn') {
      return (a.addedToTheQueueOn || 0) - (b.addedToTheQueueOn || 0)
    }
    if (sortBy === 'whenToRun') return (a.whenToRun || 0) - (b.whenToRun || 0)
    return 0
  })

  if (sortedSchedules.length === 0) {
    return (
      <div className="py-12 text-center text-slate-500">
        There are no runs scheduled for this recipe
      </div>
    )
  }

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="border-b text-left text-sm font-semibold uppercase text-primary">
            <th className="px-2 py-2">Arguments</th>
            <th className="px-2 py-2">Target</th>
            <ThSortable
              className="px-2 py-2 text-center"
              {...schedulesSort.register('whenToRun')}
            >
              Scheduled Time
            </ThSortable>
            <ThSortable
              className="px-2 py-2"
              {...schedulesSort.register('scheduledBy')}
            >
              Scheduled By
            </ThSortable>
            <ThSortable
              className="px-2 py-2"
              {...schedulesSort.register('addedToTheQueueOn')}
            >
              Scheduled On
            </ThSortable>
            <th className="sr-only w-[1%] px-2 py-2"></th>
          </tr>
        </thead>
        <tbody>
          {sortedSchedules.map((schedule) => {
            return (
              <tr
                className="group border-b align-baseline hover:bg-slate-50"
                key={schedule._id}
              >
                <td className="px-2 py-0">
                  <ArgumentsPopup schedule={schedule} />
                </td>
                <td className="text-nowrap px-2 py-2">
                  {schedule.runOnCollection ? (
                    <RemoteValue
                      collection={schedule.runOnCollection}
                      predicate={({ name }) => (
                        <NavLink
                          to={`/${schedule.runOnCollection}/${schedule.runOnId}`}
                        >
                          {name}
                        </NavLink>
                      )}
                      id={schedule.runOnId}
                    />
                  ) : (
                    <span className="italic text-slate-500">
                      &ndash; No target &ndash;{' '}
                    </span>
                  )}
                </td>
                <td className="text-nowrap text-center">
                  {schedule.whenToRun ? (
                    <Popup
                      position="top center"
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0}
                      content={format(schedule.whenToRun, 'Ppp')}
                      trigger={
                        <div className="cursor-help px-2 py-3 decoration-slate-400 decoration-dotted underline-offset-2 hover:underline">
                          {formatDistanceToNow(schedule.whenToRun, {
                            addSuffix: true,
                          })}
                        </div>
                      }
                    />
                  ) : (
                    <span>Unknown time</span>
                  )}
                </td>
                <td className="px-2 py-3">
                  {schedule.createdByUser && (
                    <>
                      <div className="flex items-baseline gap-1.5 text-nowrap">
                        <img
                          className="h-6 w-6 self-center rounded-full"
                          src={schedule.createdByUser.image || '/img/bot.png'}
                          alt=""
                        />
                        {schedule.createdByUser.name}
                      </div>
                    </>
                  )}
                </td>
                <td className="text-nowrap px-2 py-3">
                  {schedule.addedToTheQueueOn &&
                    format(schedule.addedToTheQueueOn, 'Ppp')}
                </td>
                <td className="px-2 py-3 text-right">
                  <div className="-my-2 flex justify-end gap-1.5 opacity-0 group-hover:opacity-100">
                    <Button
                      onClick={() => onDeleteClick(schedule)}
                      icon="trash alternate outline"
                      size="mini"
                      compact
                      className="!mr-0"
                      color="red"
                      basic
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
