import * as React from 'react'

import { BillingTransaction } from '../../types'
import { classNames } from '../../utils'

interface Props {
  type: BillingTransaction['type']
}

export default function CustomTxLabel({ type }: Props) {
  return (
    <span
      className={classNames(
        'text-nowrap rounded-full border bg-white px-1.5 py-1 text-xs leading-none',
        type === 'charge'
          ? 'border-green-500 text-green-700'
          : 'border-red-400 text-red-600',
      )}
    >
      {type === 'charge' ? 'Custom charge' : 'Custom discount'}
    </span>
  )
}
