import {
  DropdownItemProps,
  Dropdown,
  Button,
  Select,
  Popup,
  Ref,
} from 'semantic-ui-react'
import { endOfYesterday, isPast } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { companySchema, Invoice } from '../../types'
import { validateResponse } from '../../utils'
import InvoiceStatusLabel from './InvoiceStatusLabel'
import { useApi } from '../../store/mainContext'

function getStatusOptions(invoices: Invoice[]): DropdownItemProps[] {
  return [
    {
      value: 'All',
      text: 'All invoices',
      description: `${invoices.length} invoices`,
    },
    {
      value: 'Draft',
      text: 'Draft invoices',
      content: <InvoiceStatusLabel status="Draft" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Draft').length} invoices`,
    },
    {
      value: 'Scheduled',
      text: 'Scheduled invoices',
      content: <InvoiceStatusLabel status="Scheduled" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Scheduled').length} invoices`,
    },
    {
      value: 'Failed to Send',
      text: 'Failed to Send',
      content: <InvoiceStatusLabel status="Failed to Send" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Failed to Send').length} invoices`,
    },
    {
      value: 'Sent',
      text: 'Sent invoices',
      content: <InvoiceStatusLabel status="Sent" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Sent').length} invoices`,
    },
    {
      value: 'Overdue',
      text: 'Overdue invoices',
      content: (
        <InvoiceStatusLabel
          status="Sent"
          dueDate={endOfYesterday().getTime()}
        />
      ),
      description: `${invoices.filter((invoice) => invoice.status === 'Sent' && invoice.dueDate && isPast(invoice.dueDate)).length} invoices`,
    },
    {
      value: 'Paid',
      text: 'Paid invoices',
      content: <InvoiceStatusLabel status="Paid" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Paid').length} invoices`,
    },
    {
      value: 'Canceled',
      text: 'Canceled invoices',
      content: <InvoiceStatusLabel status="Canceled" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Canceled').length} invoices`,
    },
    {
      value: 'Uncollectible',
      text: 'Uncollectible',
      content: <InvoiceStatusLabel status="Uncollectible" />,
      description: `${invoices.filter((invoice) => invoice.status === 'Uncollectible').length} invoices`,
    },
  ]
}

interface Props {
  onChange(newValue: Partial<{ status: string; customerId: string[] }>): void
  customerId: string[]
  invoices: Invoice[]
  status: string
}

export default function InvoicesFilter(props: Props) {
  const { invoices, status, onChange, customerId } = props

  const api = useApi()
  const options = getStatusOptions(invoices)
  const selectedStatusOption = options.find((option) => option.value === status)

  const companies = useSWR('companies', () =>
    api.get('companies').then(validateResponse(z.array(companySchema))),
  )

  const [isCustomerFilterOpen, setIsCustomerFilterOpen] = React.useState(false)
  const form = useForm({ defaultValues: { customerId } })
  return (
    <div className="flex items-baseline gap-2">
      <div className="flex items-baseline gap-2">
        Showing{' '}
        <Dropdown
          pointing="top left"
          selectOnBlur={false}
          icon={false}
          trigger={
            <Button
              basic={status === 'All'}
              compact
              primary={status !== 'All'}
              size="tiny"
              content={
                selectedStatusOption?.text ||
                selectedStatusOption?.content ||
                'Nothing'
              }
            />
          }
          onChange={(_, p) => {
            if (typeof p.value === 'string') onChange({ status: p.value })
          }}
          value={status}
          options={options}
        />
      </div>
      <div className="flex items-baseline gap-2">
        for{' '}
        <Popup
          open={isCustomerFilterOpen}
          trigger={
            <Button
              onClick={() => setIsCustomerFilterOpen(true)}
              basic={customerId.length === 0}
              compact
              primary={customerId.length > 0}
              size="tiny"
              content={
                customerId?.length === 0
                  ? 'All customers'
                  : companies.data
                      ?.filter((c) => customerId.includes(c._id))
                      .map((c) => c.name)
                      .join(', ')
              }
            />
          }
          position="bottom left"
        >
          <form
            className="flex w-[350px] items-start gap-2"
            onSubmit={form.handleSubmit((data) => {
              onChange({ customerId: data.customerId })
              setIsCustomerFilterOpen(false)
            })}
          >
            <Controller
              name="customerId"
              control={form.control}
              render={({ field }) => {
                const { ref, ...other } = field
                return (
                  <Ref innerRef={ref}>
                    <Select
                      {...other}
                      loading={companies.isLoading}
                      clearable
                      multiple
                      search
                      fluid
                      placeholder="Select one or more customers"
                      className="grow"
                      options={(companies.data || [])?.map((company) => ({
                        value: company._id,
                        text: company.name,
                      }))}
                      onChange={(_, p) => field.onChange(p.value)}
                    />
                  </Ref>
                )
              }}
            />
            <Button
              type="button"
              icon="times"
              basic
              size="small"
              className="!mr-0"
              onClick={() => setIsCustomerFilterOpen(false)}
            />
            <Button
              type="submit"
              icon="check"
              size="small"
              primary
              className="!mr-0"
            />
          </form>
        </Popup>
      </div>
    </div>
  )
}
