import { Button, Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react'
import * as React from 'react'

import {
  BillingTransactionStatus,
  BillingTransaction,
  Invoice,
} from '../../types'
import { EnhancedBillingTransaction } from '../../hooks/useInvoice'
import { classNames, currencyFormat } from '../../utils'
import TransactionStatusLabel from './TransactionStatusLabel'
import DealDetailsPopup from '../DealDetailsPopup'
import CustomTxLabel from './CustomTxLabel'

function getStatusOptions(tx: BillingTransaction): DropdownItemProps[] {
  const option = (value: BillingTransactionStatus): DropdownItemProps => ({
    content: <TransactionStatusLabel status={value} />,
    text: value,
    value,
    key: value,
  })
  if (['Draft', 'Approved', 'Dismissed'].includes(tx.status)) {
    return [option('Approved'), option('Dismissed')]
  }
  if (['Processing', 'Completed', 'Canceled'].includes(tx.status)) {
    return [option('Completed'), option('Canceled')]
  }
  console.warn('Unknown transaction status:', tx.status, tx)
  return []
}

interface Props {
  onStatusUpdate(newStatus: BillingTransactionStatus): Promise<any>
  onDeleteClick(): void
  invoice: Invoice
  tx: EnhancedBillingTransaction
}

export default function InvoiceRow(props: Props) {
  const { tx, invoice, onDeleteClick, onStatusUpdate } = props

  const canUpdateStatus = tx.generatedByInvoice && invoice.status === 'Draft'

  return (
    <tr
      className={classNames(
        'group border-b text-left align-baseline text-sm leading-snug text-slate-600 @3xl:text-base',
        invoice.status === 'Draft' && 'hover:bg-slate-50',
      )}
    >
      <td
        className="px-2 py-2"
        colSpan={tx.generatedByInvoice && invoice.status === 'Draft' ? 2 : 1}
      >
        {tx.deal ? (
          <DealDetailsPopup
            sow={tx.sow}
            deal={tx.deal}
            trigger={
              <span className="cursor-help">
                <span
                  className={classNames(
                    tx.status === 'Dismissed' && 'line-through',
                  )}
                >
                  {tx.candidateId && (
                    <span>
                      {tx.candidate?.name || (
                        <span className="text-slate-500">
                          Unknown Candidate
                        </span>
                      )}{' '}
                      &ndash;{' '}
                    </span>
                  )}
                  <span
                    className={classNames(
                      tx.status === 'Dismissed'
                        ? 'text-slate-400'
                        : 'text-slate-600',
                    )}
                  >
                    {tx.description}
                  </span>
                </span>

                <Icon
                  name="help"
                  bordered
                  circular
                  size="tiny"
                  className="relative left-1.5 top-[-2.5px] text-slate-500"
                />
              </span>
            }
          />
        ) : (
          <span
            className={classNames(
              tx.status === 'Dismissed' &&
                'text-slate-400 line-through decoration-slate-500',
            )}
          >
            {tx.candidateId && (
              <span>
                {tx.candidate?.name || (
                  <span className="text-slate-400">Unknown Candidate</span>
                )}{' '}
                &ndash;{' '}
              </span>
            )}
            {tx.description}
          </span>
        )}
      </td>
      {tx.generatedByInvoice === false && invoice.status === 'Draft' && (
        <td className="px-2">
          <div className="flex items-baseline justify-end gap-2 @3xl:relative @3xl:-top-px">
            <Button
              onClick={onDeleteClick}
              icon="times"
              basic
              compact
              size="mini"
              className="!mr-0 opacity-0 group-hover:opacity-100"
            />
            <CustomTxLabel type={tx.type} />
          </div>
        </td>
      )}
      {invoice.status === 'Draft' && (
        <td className="px-2 py-0 text-right align-baseline leading-none">
          {canUpdateStatus ? (
            <Dropdown
              className="whitespace-nowrap rounded-md border border-transparent px-2 hover:border-slate-300 aria-expanded:border-slate-300 [&_.icon]:!ml-1.5 [&_.icon]:!text-slate-400"
              pointing
              item
              selectOnBlur={false}
              options={getStatusOptions(tx)}
              value={tx.status}
              trigger={<TransactionStatusLabel status={tx.status} />}
              onChange={(_, { value }) => {
                if (typeof value === 'string') {
                  onStatusUpdate(value as BillingTransactionStatus)
                }
              }}
            />
          ) : (
            <div className="pr-[21px]">
              <TransactionStatusLabel status={tx.status} />
            </div>
          )}
        </td>
      )}
      <td className="px-2 text-right">
        <span
          className={classNames(
            tx.status === 'Dismissed' &&
              'text-slate-400 line-through decoration-slate-500',
          )}
        >
          <span
            className={classNames(tx.type === 'discount' && 'text-red-500')}
          >
            {currencyFormat(tx.amount * (tx.type === 'discount' ? -1 : 1))}
          </span>
        </span>
      </td>
    </tr>
  )
}
