import {
  ModalContent,
  ModalHeader,
  ModalProps,
  Button,
  Select,
  Modal,
} from 'semantic-ui-react'
import { addMonths, format, startOfMonth, subMonths } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import * as React from 'react'

import { useToasts } from '../toasts/ToastsProvider'
import { Payroll } from '../../types'
import { useApi } from '../../store/mainContext'

type PeriodOption = { text: string; value: string }

function createPeriodOptions(
  currentPayrolls: Payroll[],
  amount: number,
): PeriodOption[] {
  const latestPayroll = currentPayrolls.sort(
    (a, b) => b.periodEnd - a.periodEnd,
  )?.[0]

  const firstPeriodTimestamp: number = latestPayroll
    ? addMonths(latestPayroll.periodEnd, 1).getTime()
    : subMonths(Date.now(), 1).getTime()

  const options: PeriodOption[] = []

  for (let i = 0; i < amount; i++) {
    const periodStart = startOfMonth(addMonths(firstPeriodTimestamp, i))
    options.push({
      value: format(periodStart, 'yyyy-MM-dd'),
      text: format(periodStart, 'MMMM yyyy'),
    })
  }

  return options
}

interface Props extends ModalProps {
  onClose(): void
  payrolls: Payroll[]
}

export default function CreatePayrollModal(props: Props) {
  const { payrolls, ...other } = props

  const options = createPeriodOptions(payrolls, 3)

  const api = useApi()
  const { addToast } = useToasts()
  const history = useHistory()

  const [selectedPeriod, setSelectedPeriod] = React.useState<string>(
    options[0]?.value || '',
  )
  const [isCreating, setIsCreating] = React.useState(false)

  const handleNewPayroll = async () => {
    if (!selectedPeriod) return
    setIsCreating(true)
    return api
      .post<Payroll>('/payrolls', { date: selectedPeriod })
      .then((res) => history.push(`/payrolls/${res.data._id}`))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreating(false))
  }

  return (
    <Modal
      {...other}
      closeOnDimmerClick={!isCreating}
      closeOnEscape={!isCreating}
      size="tiny"
    >
      <ModalHeader>New Payroll</ModalHeader>

      <ModalContent className="!pt-0">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleNewPayroll()
          }}
        >
          <p className="text-base">This action will:</p>

          <ul className="list-disc space-y-2 pl-6 text-base">
            <li>
              Scan all <strong>active deals</strong> for the selected period.
            </li>
            <li>
              Create{' '}
              <strong className="text-green-800/80">credit transactions</strong>{' '}
              for the corresponding days.
            </li>
            <li>
              Create{' '}
              <strong className="text-red-600/80">discount transactions</strong>{' '}
              for any time-off not covered by the PTO policy.
            </li>
          </ul>

          <p className="max-w-prose pt-4 text-base">
            You will be able to <strong>approve/dismiss</strong> each
            transaction and also create <strong>custom transactions</strong> to
            further adjust the payroll amounts.
          </p>

          <div className="mt-6">
            <label className="block pb-1 font-semibold text-slate-600">
              Period:
            </label>
            <Select
              value={selectedPeriod}
              onChange={(_, { value }) => {
                if (typeof value !== 'string') return
                setSelectedPeriod(value)
              }}
              options={options}
              disabled={isCreating}
            />
          </div>

          <div className="mt-10 flex gap-2">
            <Button
              content="Create"
              className="!mr-0"
              disabled={isCreating}
              loading={isCreating}
              primary
              type="submit"
            />
            <Button
              type="button"
              content="Cancel"
              className="!mr-0"
              onClick={props.onClose}
              disabled={isCreating}
              basic
            />
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}
