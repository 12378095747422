import {
  DropdownItemProps,
  ModalContent,
  ModalHeader,
  Button,
  Modal,
  Icon,
} from 'semantic-ui-react'
import React, { useState } from 'react'

import SidePanel, { Section } from '../sidepanel/sidePanel'
import TrContactSelect from '../sidepanel/trContactSelect'
import HolidaysDetail from '../holidays/HolidaysDetail'
import RequirePowers from '../requirePowers'
import TrUserSelect from '../sidepanel/trUserSelect'
import TrDataSelect from '../sidepanel/trDataSelect'
import { ENV_VARS } from '../../env'
import { Company } from '../../types'
import TrSelect from '../sidepanel/trSelect'
import TrInput from '../sidepanel/trInput'
import TrFile from '../sidepanel/trFile'
import POWERS from '../../powers'

const createPaymentTermOption = (days: number): DropdownItemProps => {
  return {
    key: days,
    value: days,
    text: `${days} ${days === 1 ? 'day' : 'days'}`,
  }
}

const DEFAULT_PAYMENT_TERM_OPTIONS = [15, 30, 45, 60]

interface Props {
  onChangeSuccess(property: keyof Company, value: any): void
  company: Company
}

const CompanySidePanel = ({ company, onChangeSuccess }: Props) => {
  const [isHolidaysModalVisible, setIsHolidayModalVisible] =
    React.useState(false)

  const [paymentTermsOptions, setPaymentTermsOptions] = useState<
    DropdownItemProps[]
  >(() => {
    if (typeof company.paymentTerms === 'number') {
      return [
        ...new Set([...DEFAULT_PAYMENT_TERM_OPTIONS, company.paymentTerms]),
      ]
        .sort((a, b) => a - b)
        .map(createPaymentTermOption)
    }
    return DEFAULT_PAYMENT_TERM_OPTIONS.map(createPaymentTermOption)
  })

  return (
    <React.Fragment>
      <Section icon="building outline" title="Company Information">
        <SidePanel>
          <tbody>
            <TrInput
              key="name"
              name="name"
              label="Name"
              value={company.name}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Name..."
            />

            <TrInput
              key="sector"
              name="sector"
              label="Sector"
              value={company.sector}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Sector..."
            />

            <TrInput
              key="location"
              name="location"
              label="HQ Location"
              value={company.location}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Location..."
            />

            <TrInput
              key="address"
              name="address"
              label="Address"
              value={company.address}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Address..."
            />

            <TrInput
              key="website"
              type="url"
              name="website"
              label="Website"
              value={company.website}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Website..."
              render={(v) => (
                <a
                  rel="noreferrer"
                  href={v}
                  target={
                    ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? '_blank'
                      : undefined
                  }
                >
                  {v}
                </a>
              )}
            />

            <TrInput
              key="linkedin"
              type="url"
              name="linkedin"
              label="LinkedIn"
              value={company.linkedin}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              render={(v) => (
                <a
                  rel="noreferrer"
                  href={v}
                  target={
                    ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? '_blank'
                      : undefined
                  }
                >
                  {v}
                </a>
              )}
              placeholder="LinkedIn Url..."
            />

            <TrContactSelect
              key="mainContactId"
              name="mainContactId"
              label="Main Contact"
              value={company.mainContactId}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Main contact..."
              allowAdditions
              render={(x) => x.name}
            />

            <TrInput
              key="about"
              name="about"
              label="About"
              value={company.about}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              type="textarea"
              placeholder="About..."
            />

            <TrInput
              key="whatDoTheyDoExplained"
              name="whatDoTheyDoExplained"
              label="What do they do (Explained)"
              value={company.whatDoTheyDoExplained}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              type="textarea"
              placeholder="What do they do (explained)..."
            />

            <TrDataSelect
              key="timezoneId"
              name="timezoneId"
              label="Timezone"
              value={company.timezoneId}
              url={`companies/${company._id}`}
              dataCollection="timezones"
              onSuccess={onChangeSuccess}
              placeholder="Timezone..."
              orderBy={(x) => x.value}
            />

            <tr>
              <th>Added On</th>
              <td>
                {new Date(company.createdOn).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </td>
            </tr>
          </tbody>
        </SidePanel>
      </Section>

      <RequirePowers powers={[POWERS.customerSOWs]}>
        <Section icon="handshake outline" title="Contract Information">
          <SidePanel>
            <tbody>
              <TrFile
                key="msa"
                name="msa"
                label="Master Agreement"
                value={company.msa}
                url={`companies/${company._id}`}
                fileUrl={`cdn/msa/${company._id}`}
                onSuccess={onChangeSuccess}
              />

              <TrDataSelect
                key="costCenterId"
                name="costCenterId"
                label="Cost Center"
                value={company.costCenterId}
                url={`companies/${company._id}`}
                dataCollection="cost-centers"
                onSuccess={onChangeSuccess}
                placeholder="Cost Center..."
                orderBy={(x) => x.value}
              />

              <TrInput
                key="rateCard"
                type="url"
                name="rateCard"
                label="Rate Card"
                value={company.rateCard}
                url={`companies/${company._id}`}
                onSuccess={onChangeSuccess}
                render={(v) => (
                  <a
                    rel="noreferrer"
                    href={v}
                    target={
                      ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                        ? '_blank'
                        : undefined
                    }
                  >
                    <Icon name="file excel outline" /> Rate Card
                  </a>
                )}
                placeholder="Rate Card Url..."
              />
            </tbody>
          </SidePanel>
        </Section>
      </RequirePowers>

      <Section icon="smile outline" title="Time-Off Policy">
        <div className="px-9 pb-2 text-base text-slate-500">
          By updating these values, you will only affect contracts created
          afterwards.{' '}
          <strong>Existing contracts will keep their current values</strong>.
        </div>
        <SidePanel>
          <tbody>
            <TrInput
              key="ptoAccruedPerMonthDuringFirstYear"
              name="ptoAccruedPerMonthDuringFirstYear"
              label="Accrued/month (first year)"
              value={company.ptoAccruedPerMonthDuringFirstYear}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              render={(v) => `${v} PTO`}
              number
            />
            <TrInput
              key="ptoAccruedAutomaticallyAfterFirstYear"
              name="ptoAccruedAutomaticallyAfterFirstYear"
              label="Accrued/year"
              value={company.ptoAccruedAutomaticallyAfterFirstYear}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              render={(v) => `${v} PTO`}
              number
            />
            <TrInput
              key="ptoCarryOverLimit"
              name="ptoCarryOverLimit"
              label="Max carry over"
              value={company.ptoCarryOverLimit}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              render={(v) => `${v} PTO`}
              number
            />
            <tr className="no-hover">
              <th>Holidays</th>
              <td>
                <Button
                  className="relative -top-1.5 border-none"
                  onClick={() => setIsHolidayModalVisible(true)}
                  content="View Holidays"
                  icon="file alternate outline"
                  size="small"
                  primary
                  basic
                />
              </td>
            </tr>
          </tbody>
        </SidePanel>
      </Section>

      <Section icon="wordpress forms" title="Invoicing">
        <div className="px-9 pb-2 text-base text-slate-500">
          These values will be used to define defaults when creating or sending
          invoices for this company.
        </div>
        <SidePanel>
          <tbody>
            <TrSelect
              key="paymentTerms"
              name="paymentTerms"
              label="Payment terms"
              value={company.paymentTerms}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              render={(v) => v + (v === 1 ? ' day' : ' days')}
              number
              dropdownProps={{
                search: true,
                allowAdditions: true,
                additionLabel: 'Custom Amount: ',
                onAddItem: (_, p) => {
                  const value = Number(p.value)
                  setPaymentTermsOptions((current) => {
                    if (isNaN(value)) return current
                    return [
                      {
                        key: value,
                        value,
                        text: `${value} ${value === 1 ? 'day' : 'days'}`,
                      },
                      ...current,
                    ]
                  })
                },
                options: paymentTermsOptions,
              }}
            />
            <TrContactSelect
              key="invoiceSendToIds"
              name="invoiceSendToIds"
              label="Send invoices to"
              value={company.invoiceSendToIds}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Send to..."
              allowAdditions
              multiple
              render={(x) => x.name}
            />
            <TrContactSelect
              key="invoiceSendToCopyIds"
              name="invoiceSendToCopyIds"
              label="Send to (CC)"
              value={company.invoiceSendToCopyIds}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Send CC to..."
              allowAdditions
              multiple
              render={(x) => x.name}
            />
          </tbody>
        </SidePanel>
      </Section>

      <Section icon="settings" title="Account Configuration">
        <SidePanel>
          <tbody>
            <TrUserSelect
              key="accountManagerId"
              name="accountManagerId"
              label="Account Manager"
              value={company.accountManagerId}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Account Manager..."
            />

            <TrUserSelect
              key="adminManagerId"
              name="adminManagerId"
              label="Administrative Manager"
              value={company.adminManagerId}
              url={`companies/${company._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Admin manager..."
            />

            <RequirePowers powers={[POWERS.customerSOWs]}>
              <TrInput
                key="idealDGM"
                name="idealDGM"
                label="Ideal DGM"
                value={company.idealDGM}
                url={`companies/${company._id}`}
                onSuccess={onChangeSuccess}
                placeholder="Dev Gross Margin..."
                render={(v) => `$ ${v}`}
                number
              />
            </RequirePowers>
          </tbody>
        </SidePanel>
      </Section>

      <Modal
        size="small"
        open={isHolidaysModalVisible}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => setIsHolidayModalVisible(false)}
        closeIcon={
          <Icon
            className="absolute right-8 top-8 cursor-pointer"
            color="grey"
            name="times"
          />
        }
      >
        <ModalHeader>{company.name} Holidays</ModalHeader>
        <ModalContent className="!pb-10 !pt-0">
          <p className="max-w-prose pb-2 text-lg text-slate-600">
            Setup the holidays agreed upon with the company for the selected
            year. Candidates will see these days on their time-off calendar.
          </p>
          <HolidaysDetail company={company} />
        </ModalContent>
      </Modal>
    </React.Fragment>
  )
}

export default CompanySidePanel
