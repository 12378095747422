import { Dropdown } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { classNames, validateResponse } from '../../utils'
import { emailPresetSchema } from '../../types'
import { useApi } from '../../store/mainContext'

interface Props {
  onChange(value: string): void
  disabled?: boolean
}

export default function EmailPresetDropdown(props: Props) {
  const { onChange, disabled } = props

  const api = useApi()

  const presets = useSWR(['email-presets'], () =>
    api
      .get('email-presets', { params: { status: 'Published' } })
      .then(validateResponse(z.array(emailPresetSchema))),
  )

  const noPresets = !presets.isLoading && (presets.data || []).length === 0

  return (
    <Dropdown
      text={noPresets ? 'No presets available' : 'Presets'}
      className={classNames('pr-3.5 text-sm', noPresets && 'italic')}
      disabled={presets.isLoading || disabled || noPresets}
      loading={presets.isLoading}
      selectOnBlur={false}
      pointing="top right"
    >
      <Dropdown.Menu>
        {(presets.data || []).map((preset) => (
          <Dropdown.Item
            onClick={() => onChange(preset.body)}
            value={preset.body}
            text={preset.name}
            key={preset._id}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
