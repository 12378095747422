import { useUser } from '../pages/session/hooks'
import POWERS from '../powers'

type POWER = (typeof POWERS)[keyof typeof POWERS]

type ReturnT = {
  powers: POWER[]
  isMaster: boolean
  hasAll(pws: POWER[]): boolean
  hasAny(pws: POWER[]): boolean
}

export default function usePowers(): ReturnT {
  const user = useUser()

  const userPowers = user.powers || []

  const isMaster = userPowers.includes('master')

  return {
    powers: userPowers,
    isMaster,
    hasAll: (pws) => isMaster || pws.every((p) => userPowers.includes(p)),
    hasAny: (pws) => isMaster || pws.some((p) => userPowers.includes(p)),
  }
}
