import * as React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Btn = styled(Button)`
  padding: 7px !important;
  font-size: 8px !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 100px !important;

  // Hack to override increase selector specificity. TODO: fix
  &[disabled][disabled][disabled] {
    cursor: not-allowed;
    // background-color: silver !important;
  }
`

const CheckButton = ({
  onChange,
  value = false,
  disabled = false,
  onMouseUp = undefined,
}) => {
  return (
    <Btn
      disabled={disabled}
      onMouseUp={onMouseUp}
      onClick={onChange}
      color={value ? 'black' : 'grey'}
      basic={!value}
      size="mini"
      icon
    >
      <Icon name={value && 'check'} />
    </Btn>
  )
}

export default CheckButton
