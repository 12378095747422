import React, { useState } from 'react'
import { Dropdown, DropdownItem, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import DateFormat from '../../dateFormat'
import { Candidate, Contact, EmailMessage } from '../../../types'
import UserName from '../../remoteValues/userName'
import Period from '../../period'
import { useUser } from '../../../pages/session/hooks'
import { ENV_VARS } from '../../../env'
import axios from 'axios'

const Content = styled.div`
  position: relative;
  border: dotted 1px var(--border-grey);
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  &:hover {
    background-color: #fcfcfc;
  }
`

const NoteHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  align-items: flex-start;
  cursor: pointer;
`

const NoteTitle = styled.header`
  margin: 0px;
  width: 100%;
  padding-left: 15px;
  margin-right: 48px;
  font-size: 1.4rem;
`

const NoteContent = styled.div`
  margin-top: 20px;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Date = styled.h3`
  margin-top: 0px !important;
  margin-bottom: 5px !important;
`

const NoteMenu = styled.div`
  position: absolute;
  font-size: 15px;
  top: 0px;
  right: 0px;
  z-index: 90;
`

const Circle = styled.div`
  width: 54px;
  height: 45px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 24px; */
  color: white;
  font-family: Arial, sans-serif;
`

const ContentBottom = styled.div`
  height: 50px;
  border-left: dotted 1px var(--border-grey);
  margin-left: 40px;
`

const ViewMoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
`

interface Props {
  email: EmailMessage
  disabled?: boolean
  isFirstOne?: boolean
  onRemoveSuccess?(): void
  relatedObject?: Candidate | Contact
  onUnshare?: () => void
}

const Email = ({ email, isFirstOne, relatedObject, onUnshare }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const user = useUser()

  const [unsharing, setUnsharing] = useState(false)

  // TODO: Reemplazar esto por api = useApi('/v1/email')
  const api = axios.create({
    baseURL: `${ENV_VARS.REACT_APP_API_BASE}/v1/email`,
    responseType: 'json',
    headers: {
      Authorization: `${window.localStorage.getItem('token')}`,
    },
  })

  const handleUnshare = async () => {
    setUnsharing(true)

    await api.patch(`threads/${email.threadId}`, {
      shared: false,
    })

    onUnshare && onUnshare()
  }

  if (unsharing) {
    return <React.Fragment></React.Fragment>
  }

  return (
    <React.Fragment>
      <Content>
        <NoteHeader onClick={() => setIsCollapsed(!isCollapsed)}>
          {email.sentBy ? (
            <UserName id={email.sentBy} image height={45} />
          ) : (
            <Circle>{relatedObject?.name.substring(0, 1).toUpperCase()}</Circle>
          )}

          <NoteTitle>
            <Date>
              <b>
                <Period date={email.date} />
              </b>{' '}
              - <DateFormat date={email.date} hours /> -{' '}
              <span className="whitespace-nowrap">
                From{' '}
                <b>
                  <UserName id={email.userId} short />
                  {`'s`}
                </b>{' '}
                Inbox
              </span>
            </Date>
            Subject: {email.subject}
          </NoteTitle>

          <NoteMenu>
            <ViewMoreButton>
              {isCollapsed && <Icon name="chevron down" />}
              {!isCollapsed && <Icon name="chevron up" />}
            </ViewMoreButton>

            {email.userId === user._id && (
              <Dropdown icon="ellipsis horizontal" direction="left">
                <Dropdown.Menu>
                  <DropdownItem
                    onClick={handleUnshare}
                    style={{ color: 'var(--red)' }}
                  >
                    <Icon name="hide" /> Unshare
                  </DropdownItem>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </NoteMenu>
        </NoteHeader>

        {!isCollapsed && (
          <NoteContent>
            <p>
              <b>From:</b> {email.from}
            </p>
            <p>
              <b>To:</b> {email.to}
            </p>
            {email.cc && (
              <p>
                <b>CC:</b> {email.cc}
              </p>
            )}
            {email.bcc && (
              <p>
                <b>BCC:</b> {email.bcc}
              </p>
            )}

            <hr />
            <br />
            <p dangerouslySetInnerHTML={{ __html: email.bodyHtml }}></p>
          </NoteContent>
        )}
      </Content>
      {!isFirstOne && <ContentBottom />}
    </React.Fragment>
  )
}

export default Email
