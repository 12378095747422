import { Link, LinkProps } from 'react-router-dom'
import { Placeholder } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'

import { Collection } from '../../types'
import { useApi } from '../../store/mainContext'

interface Entity {
  _id: string
  name?: string
  title?: string
  number?: string | number
  [key: string]: any
}

interface Props {
  defaultText?: string
  collection: Collection
  entityId?: string
  onClick?(): any
  entity?: Entity
  render?(data: Entity): React.ReactNode
  linkTo?(data: Entity): LinkProps['to']
}

const EntityLink = ({
  defaultText,
  collection,
  entityId,
  onClick,
  entity,
  render,
  linkTo,
}: Props) => {
  const api = useApi()

  const { data, isLoading, error } = useSWR<Entity>(
    // only fetch if we don't have an `entity` and we DO have an `entityId`
    entityId && !entity ? ['entity', collection, entityId] : null,
    () =>
      api
        .get<Entity>(`${collection}/${entityId}`)
        .then((response) => response.data),
    // if we do wave an initial `entity` use as fallback data
    { fallbackData: entity, shouldRetryOnError: false },
  )

  if (isLoading) {
    return (
      <Placeholder className="-mt-1.5">
        <Placeholder.Line length="long" className="m-0" />
      </Placeholder>
    )
  }

  if (!data) {
    return defaultText ? (
      <span>{defaultText}</span>
    ) : error ? (
      <span className="text-slate-400">&mdash;Not found&mdash;</span>
    ) : null
  }

  if (collection === 'feeds') {
    return (
      <Link to={data.url} onClick={onClick}>
        {data.name}
      </Link>
    )
  }

  const to =
    typeof linkTo === 'function' ? linkTo(data) : `/${collection}/${data._id}`

  return (
    <Link to={to} onClick={onClick}>
      {typeof render === 'function'
        ? render(data)
        : data.name || data.title || data.number || 'No name'}
    </Link>
  )
}

export default EntityLink
