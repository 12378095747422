import * as React from 'react'

import { PayrollStatus } from '../../types'
import { classNames } from '../../utils'

interface Props {
  payroll: { status: PayrollStatus; dueDate?: number }
}

export default function PayrollStatusLabel(props: Props) {
  const { status, dueDate } = props.payroll

  const isOverdue =
    Boolean(dueDate && dueDate < Date.now()) && status !== 'Completed'

  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase',
        status === 'Draft' && !isOverdue && 'text-slate-400',
        status === 'Completed' && 'text-green-600',
        isOverdue && 'text-red-500',
      )}
    >
      {isOverdue ? 'Overdue' : status}
    </span>
  )
}
