import { React, useEffect, useState } from 'react'
import { Rating } from 'semantic-ui-react'

import { useTalentApi } from '../../store/mainContext'

const RemoteStars = ({ collection, value, maxRating, ...other }) => {
  const [rating, setRating] = useState(0)

  const api = useTalentApi()

  useEffect(() => {
    if (!value) return
    api.get(`${collection}/${value}`).then(({ data }) => setRating(data.value))
  }, [value, api, collection])

  if (!value) {
    return <span className="text-sm text-slate-500">Not set yet</span>
  }

  return (
    <Rating
      maxRating={maxRating}
      rating={rating}
      icon="star"
      disabled
      {...other}
    />
  )
}

export default RemoteStars
