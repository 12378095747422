import { Checkbox, Dropdown, Rating, Input } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  englishLevelSchema,
  candidateSchema,
  senioritySchema,
  profileSchema,
  techSchema,
  Candidate,
} from '../../types'
import { classNames, validateResponse } from '../../utils'
import CandidatesSearchResult from '../../components/candidates/CandidatesSearchResultsCards'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import Feed from '../../components/feeds/feed'

export default function CandidatesSearchPage() {
  const api = useApi()

  const techs = useSWR('techs', () =>
    api
      .get('techs')
      .then(validateResponse(z.array(techSchema)))
      .then((values) => values.sort((a, b) => a.text.localeCompare(b.text))),
  )

  const seniority = useSWR('seniority', () =>
    api
      .get('seniority')
      .then(validateResponse(z.array(senioritySchema)))
      .then((values) => values.sort((a, b) => a.value - b.value)),
  )

  const profiles = useSWR('profiles', () =>
    api
      .get('profiles')
      .then(validateResponse(z.array(profileSchema)))
      .then((values) => values.sort((a, b) => a.text.localeCompare(b.text))),
  )

  const englishLevels = useSWR('englishLevels', () =>
    api
      .get('englishLevels')
      .then(validateResponse(z.array(englishLevelSchema)))
      .then((values) => values.sort((a, b) => a.value - b.value)),
  )

  const [selectedSeniority, setSelectedSeniority] = React.useState<any[]>([])

  const [selectedProfiles, setSelectedProfiles] = React.useState<any[]>([])

  const [profilesOperator, setProfilesOperator] = React.useState<
    '$in' | '$all'
  >('$in')

  const [selectedTechs, setSelectedTechs] = React.useState<any[]>([])

  const [techsOperator, setTechsOperator] = React.useState<'$in' | '$all'>(
    '$in',
  )

  const [minimumEnglishLevel, setMinimumEnglishLevel] = React.useState<
    number | undefined
  >(undefined)

  const [location, setLocation] = React.useState('')

  const [selectedYearsInTheRole, setSelectedYearsInTheRole] = React.useState(0)
  const [selectedYearsInTheIndustry, setSelectedYearsInTheIndustry] =
    React.useState(0)

  const [selectedCandidateId, setSelectedCandidateId] = React.useState<
    Candidate['_id'] | undefined
  >()

  const result = useSWR(
    [
      'candidates',
      selectedSeniority,
      profilesOperator,
      selectedProfiles,
      techsOperator,
      selectedTechs,
      minimumEnglishLevel,
      location,
      selectedYearsInTheRole,
      selectedYearsInTheIndustry,
    ],
    () =>
      api
        .get('/candidates/pro', {
          params: {
            ...(selectedSeniority.length
              ? { seniority: { $in: selectedSeniority } }
              : {}),
            ...(location.length ? { location } : {}),
            ...(selectedProfiles.length
              ? { profiles: { [profilesOperator]: selectedProfiles } }
              : {}),
            ...(selectedTechs.length
              ? { mainTechs: { [techsOperator]: selectedTechs } }
              : {}),
            ...(selectedYearsInTheRole > 0
              ? { yearsInTheRole: { $gte: selectedYearsInTheRole } }
              : {}),
            ...(selectedYearsInTheIndustry > 0
              ? { yearsInTheIndustry: { $gte: selectedYearsInTheIndustry } }
              : {}),
            ...(typeof minimumEnglishLevel === 'number'
              ? {
                  englishLevel: {
                    $in: (englishLevels.data || [])
                      .filter((l) => l.value >= minimumEnglishLevel)
                      .map((l) => l._id),
                  },
                }
              : {}),
          },
        })
        .then(validateResponse(z.array(candidateSchema))),
    { keepPreviousData: true },
  )

  if (!result.data && result.isLoading) {
    return <PageLoader />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Candidates Search</title>
      </Helmet>

      <PageHeader
        title="Candidate Search"
        sub="One screen to find them all."
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Candidates', link: '/candidates' },
          { text: 'Advanced Search' },
        ]}
      />

      <div className="mb-14 px-14">
        <div className="flex w-full items-start gap-6">
          <div className="w-[240px] shrink-0 rounded-md bg-slate-50 px-4 pb-1">
            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Profiles
                </span>
                <div
                  className={classNames(
                    '-mr-2 flex items-center text-sm font-semibold text-slate-500',
                    selectedProfiles.length ? 'opacity-100' : 'opacity-0',
                  )}
                >
                  <span
                    className="cursor-pointer px-2 py-0.5 text-primary"
                    onClick={() => setProfilesOperator('$in')}
                  >
                    OR
                  </span>{' '}
                  <Checkbox
                    className="[&>label::before]:!bg-slate-600"
                    slider
                    onChange={(_, p) =>
                      setProfilesOperator(p.checked ? '$all' : '$in')
                    }
                    checked={profilesOperator === '$all'}
                  />{' '}
                  <span
                    className="cursor-pointer px-2 py-0.5 text-teal-600"
                    onClick={() => setProfilesOperator('$all')}
                  >
                    AND
                  </span>
                </div>
              </header>
              <Dropdown
                renderLabel={(label) => ({
                  className: classNames(
                    'no-overrides !text-sm !font-normal !text-white',
                    profilesOperator === '$all'
                      ? '!bg-teal-600'
                      : '!bg-primary',
                  ),
                  content: label.text,
                })}
                onChange={(_, p) => {
                  if (
                    Array.isArray(p.value) &&
                    p.value.every((v) => typeof v === 'string')
                  ) {
                    setSelectedProfiles(p.value)
                  }
                }}
                value={selectedProfiles}
                search
                multiple
                selection
                fluid
                clearable
                className="no-overrides"
                placeholder="Select profiles..."
                options={profiles.data?.map((p) => ({
                  value: p._id,
                  text: p.text,
                }))}
              />
            </section>

            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Technologies
                </span>
                <div
                  className={classNames(
                    '-mr-2 flex items-center text-sm font-semibold text-slate-500',
                    selectedTechs.length ? 'opacity-100' : 'opacity-0',
                  )}
                >
                  <span
                    className="cursor-pointer px-2 py-0.5 text-primary"
                    onClick={() => setTechsOperator('$in')}
                  >
                    OR
                  </span>{' '}
                  <Checkbox
                    className="[&>label::before]:!bg-slate-600"
                    slider
                    onChange={(_, p) =>
                      setTechsOperator(p.checked ? '$all' : '$in')
                    }
                    checked={techsOperator === '$all'}
                  />{' '}
                  <span
                    className="cursor-pointer px-2 py-0.5 text-teal-600"
                    onClick={() => setTechsOperator('$all')}
                  >
                    AND
                  </span>
                </div>
              </header>
              <Dropdown
                renderLabel={(label) => ({
                  className: classNames(
                    'no-overrides !text-sm !font-normal !text-white',
                    techsOperator === '$all' ? '!bg-teal-600' : '!bg-primary',
                  ),
                  content: label.text,
                })}
                onChange={(_, p) => {
                  if (
                    Array.isArray(p.value) &&
                    p.value.every((v) => typeof v === 'string')
                  ) {
                    setSelectedTechs(p.value)
                  }
                }}
                value={selectedTechs}
                search
                multiple
                selection
                fluid
                clearable
                className="no-overrides"
                placeholder="Select technologies..."
                options={techs.data?.map((t) => ({
                  value: t._id,
                  text: t.text,
                }))}
              />
            </section>

            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Seniority
                </span>
                {selectedSeniority.length > 0 && (
                  <button
                    className="text-sm text-primary underline"
                    onClick={() => setSelectedSeniority([])}
                    type="button"
                  >
                    Clear
                  </button>
                )}
              </header>
              <ul className="space-y-2">
                {(seniority.data || []).map((s) => {
                  return (
                    <li key={s.value}>
                      <label className="cursor-pointer text-slate-600">
                        <Checkbox
                          value={s._id}
                          checked={selectedSeniority.includes(s._id)}
                          onChange={(_, p) =>
                            setSelectedSeniority((prev) =>
                              p.checked
                                ? [...prev, s._id]
                                : prev.filter((v) => v !== s._id),
                            )
                          }
                        />
                        <span className="ml-1 text-base">{s.text}</span>
                      </label>
                    </li>
                  )
                })}
              </ul>
            </section>

            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Years in the role
                </span>
                {selectedYearsInTheRole > 0 && (
                  <button
                    className="text-sm text-primary underline"
                    onClick={() => setSelectedYearsInTheRole(0)}
                    type="button"
                  >
                    Clear
                  </button>
                )}
              </header>
              <input
                type="range"
                className="no-overrides block w-full"
                min={0}
                max={10}
                step={1}
                value={selectedYearsInTheRole}
                onChange={(e) => {
                  if (typeof e.target.valueAsNumber === 'number') {
                    setSelectedYearsInTheRole(e.target.valueAsNumber)
                  }
                }}
              />
              <div className="mt-1 italic text-slate-500">
                {selectedYearsInTheRole > 0 ? (
                  <>
                    More than{' '}
                    <strong>
                      {selectedYearsInTheRole}{' '}
                      {selectedYearsInTheRole === 1 ? 'year' : 'years'}
                    </strong>{' '}
                    in the role
                  </>
                ) : (
                  'Any years in the role'
                )}
              </div>
            </section>

            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Years in the industry
                </span>
                {selectedYearsInTheIndustry > 0 && (
                  <button
                    className="text-sm text-primary underline"
                    onClick={() => setSelectedYearsInTheIndustry(0)}
                    type="button"
                  >
                    Clear
                  </button>
                )}
              </header>
              <input
                type="range"
                className="no-overrides block w-full"
                min={0}
                max={10}
                step={1}
                value={selectedYearsInTheIndustry}
                onChange={(e) => {
                  if (typeof e.target.valueAsNumber === 'number') {
                    setSelectedYearsInTheIndustry(e.target.valueAsNumber)
                  }
                }}
              />
              <div className="mt-1 italic text-slate-500">
                {selectedYearsInTheIndustry > 0 ? (
                  <>
                    More than{' '}
                    <strong>
                      {selectedYearsInTheIndustry}{' '}
                      {selectedYearsInTheIndustry === 1 ? 'year' : 'years'}
                    </strong>{' '}
                    in any role
                  </>
                ) : (
                  'Any years in the industry'
                )}
              </div>
            </section>

            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Minimum English Level
                </span>
              </header>
              <Rating
                clearable
                maxRating={5}
                rating={minimumEnglishLevel || 0}
                icon="star"
                onRate={(_, p) => {
                  if (typeof p.rating === 'number') {
                    setMinimumEnglishLevel(p.rating || undefined)
                  }
                }}
              />
              {typeof minimumEnglishLevel === 'number' &&
                minimumEnglishLevel > 0 && (
                  <button
                    className="relative -top-px ml-2 text-sm text-primary underline"
                    onClick={() => setMinimumEnglishLevel(undefined)}
                    type="button"
                  >
                    Clear
                  </button>
                )}
            </section>

            <section className="py-3">
              <header className="flex items-baseline justify-between gap-2 pb-3 pt-1">
                <span className="text-sm font-semibold uppercase text-slate-500">
                  Location
                </span>
              </header>
              <Input
                type="text"
                fluid
                value={location || ''}
                onChange={(_, p) => setLocation(p.value || '')}
                icon={
                  location.length > 0
                    ? {
                        name: 'times',
                        link: true,
                        onClick: () => setLocation(''),
                      }
                    : undefined
                }
              />
            </section>
          </div>

          <div className="grow">
            {(result.data || []).length > 0 ? (
              <CandidatesSearchResult
                selectedCandidateId={selectedCandidateId}
                onCandidateClick={setSelectedCandidateId}
                candidates={result.data || []}
              />
            ) : (
              <div className="text-center text-slate-500">
                No candidate matches the current query
              </div>
            )}
          </div>
          <div className="w-[500px] shrink-0 self-stretch border-l pl-6">
            {selectedCandidateId &&
              (result.data || []).some(
                (c) => c._id === selectedCandidateId,
              ) && (
                <div>
                  <header className="text-xl font-semibold text-slate-700">
                    Candidate Feed
                  </header>
                  <Feed
                    relatedId={selectedCandidateId}
                    relatedCollection="candidates"
                    showEditor={false}
                    showFilter
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
