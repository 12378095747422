import React from 'react'

import SidePanel, { Section } from '../sidepanel/sidePanel'
import TrCompanySelect from '../sidepanel/trCompanySelect'
import TrDataSelect from '../sidepanel/trDataSelect'
import { ENV_VARS } from '../../env'
import TrCheckbox from '../sidepanel/trCheckbox'
import TrInput from '../sidepanel/trInput'

const ContactSidePanel = ({ contact, onChangeSuccess }) => (
  <React.Fragment>
    <Section icon="user circle" title="Personal Information">
      <SidePanel>
        <TrInput
          key="name"
          name="name"
          label="Name"
          value={contact.name}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Name..."
        />

        <TrInput
          key="email"
          name="email"
          label="Email"
          value={contact.email}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Email..."
        />

        <TrInput
          key="phone"
          name="phone"
          label="Phone"
          value={contact.phone}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Phone..."
        />

        <TrInput
          key="linkedin"
          name="linkedin"
          label="LinkedIn"
          value={contact.linkedin}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          render={(v) => (
            <a
              rel="noreferrer"
              href={v}
              target={
                ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                  ? '_blank'
                  : undefined
              }
            >
              {v}
            </a>
          )}
          placeholder="Linkedin Url..."
        />

        <TrCompanySelect
          key="companyId"
          name="companyId"
          label="Company"
          value={contact.companyId}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          search
          placeholder="Company..."
          render={(x) => x.name}
          allowAdditions
        />

        <TrInput
          key="position"
          name="position"
          label="Position"
          value={contact.position}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Position..."
        />

        <TrInput
          key="location"
          name="location"
          label="Location"
          value={contact.location}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Location..."
        />

        <TrDataSelect
          key="timezoneId"
          name="timezoneId"
          label="Timezone"
          value={contact.timezoneId}
          url={`contacts/${contact._id}`}
          dataCollection="timezones"
          onSuccess={onChangeSuccess}
          placeholder="Timezone..."
          orderBy={(x) => x.value}
        />

        <TrInput
          key="highlights"
          name="highlights"
          label="Highlights"
          value={contact.highlights}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          type="textarea"
          placeholder="Personality Highlights..."
        />

        <TrCheckbox
          key="isCritical"
          name="isCritical"
          label="Critical Contact"
          value={contact.isCritical}
          url={`contacts/${contact._id}`}
          help="If set this person is a critical contact in the customer, no comunication should be done without previous approval of management team."
          onSuccess={onChangeSuccess}
        />

        <tr>
          <th>Added On</th>
          <td>
            {new Date(contact.createdOn).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </td>
        </tr>
      </SidePanel>
    </Section>

    <Section icon="rocket" title="Prospect Information">
      <SidePanel>
        <TrDataSelect
          key="prospectSource"
          name="prospectSource"
          label="Prospect Source"
          value={contact.prospectSource}
          url={`contacts/${contact._id}`}
          dataCollection="prospect-sources"
          onSuccess={onChangeSuccess}
          placeholder="Prospect Source..."
          orderBy={(x) => x.value}
        />

        <TrDataSelect
          key="prospectSize"
          name="prospectSize"
          label="Prospect Size"
          value={contact.prospectSize}
          url={`contacts/${contact._id}`}
          dataCollection="prospect-sizes"
          onSuccess={onChangeSuccess}
          placeholder="Prospect Size..."
          orderBy={(x) => x.value}
        />

        <TrInput
          key="prospectedBy"
          name="prospectedBy"
          label="Prospected By"
          value={contact.prospectedBy}
          url={`contacts/${contact._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Prospected By..."
        />
      </SidePanel>
    </Section>
  </React.Fragment>
)

export default ContactSidePanel
