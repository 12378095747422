import { Button, Dropdown, DropdownItemProps, Input } from 'semantic-ui-react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { BillingTransaction, candidateSchema, Invoice } from '../../types'
import { validateResponse } from '../../utils'
import CustomTxLabel from './CustomTxLabel'
import { useApi } from '../../store/mainContext'

const NO_CANDIDATE_VALUE = 'none'
const FORM_ID = 'create-custom-tx-form'

type FormValues = {
  candidateId: string
  description: string
  amount: number | null
}

interface Props {
  invoiceId: Invoice['_id']
  onCancel(): void
  onSubmit(data: Partial<BillingTransaction>): Promise<any>
}

export default function CreateTxForm(props: Props) {
  const { onCancel, invoiceId } = props

  const api = useApi()

  const candidates = useSWR('candidates', () =>
    api.get('candidates').then(validateResponse(z.array(candidateSchema))),
  )

  const { handleSubmit, control, watch, formState } = useForm<FormValues>({
    defaultValues: {
      candidateId: NO_CANDIDATE_VALUE,
      description: '',
      amount: null,
    },
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const amount = Number(data.amount || 0)
    return props
      .onSubmit({
        billingInvoiceId: invoiceId,
        description: data.description,
        amount: amount * (amount < 0 ? -1 : 1),
        type: amount < 0 ? 'discount' : 'charge',
        ...(typeof data.candidateId === 'string' &&
        data.candidateId !== NO_CANDIDATE_VALUE
          ? { candidateId: data.candidateId }
          : {}),
      })
      .then(onCancel)
  }

  const candidateOptions: DropdownItemProps[] = [
    {
      key: NO_CANDIDATE_VALUE,
      value: NO_CANDIDATE_VALUE,
      text: <span className="text-slate-500">No candidate</span>,
    },
    ...(candidates.data || []).map(({ _id, name }) => ({
      key: _id,
      value: _id,
      text: name,
    })),
  ]

  return (
    <React.Fragment>
      <tr className="bg-slate-100">
        <td className="px-2 py-2" colSpan={4}>
          <div className="flex flex-col gap-1 @2xl:grid @2xl:grid-cols-[220px_1fr_85px]">
            <Controller
              name="candidateId"
              control={control}
              disabled={candidates.isLoading || formState.isSubmitting}
              render={({ field, fieldState }) => (
                <Dropdown
                  {...field}
                  loading={candidates.isLoading}
                  error={!!fieldState.error}
                  selection
                  options={candidateOptions}
                  search
                  fluid
                  onChange={(_, { value }) => field.onChange(value)}
                  selectOnBlur={false}
                />
              )}
            />
            <div className="relative @container/field">
              <Controller
                name="description"
                control={control}
                rules={{ required: 'Required' }}
                disabled={formState.isSubmitting}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    fluid
                    error={!!fieldState.error}
                    input={
                      <input
                        autoFocus
                        placeholder="Add a description..."
                        className="!pr-2 @3xl:!pr-32"
                        style={{ fontSize: 'unset' }}
                        form={FORM_ID}
                      />
                    }
                  />
                )}
              />

              <div className="absolute right-2 top-2 hidden @sm/field:block">
                <CustomTxLabel
                  type={(watch('amount') || 0) < 0 ? 'discount' : 'charge'}
                />
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
              <Controller
                name="amount"
                control={control}
                rules={{ required: 'Required' }}
                disabled={formState.isSubmitting}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    value={field.value === null ? '' : field.value}
                    type="number"
                    fluid
                    error={!!fieldState.error}
                    input={
                      <input
                        data-1p-ignore
                        className="!text-right"
                        style={{ fontSize: 'unset' }}
                      />
                    }
                  />
                )}
              />
            </form>
          </div>
        </td>
      </tr>
      <tr>
        <td
          className="bg-slate-100 px-2 pb-2 text-right text-sm leading-none text-slate-500"
          colSpan={4}
        >
          Use a negative amount to create a discount
        </td>
      </tr>
      <tr className="border-b">
        <td
          className="bg-slate-100 px-2 pb-2 text-sm leading-none text-slate-500"
          colSpan={4}
        >
          <div className="flex justify-end gap-2">
            <Button
              disabled={formState.isSubmitting}
              content="Cancel"
              onClick={onCancel}
              className="!mr-0"
              compact
              basic
              type="button"
            />
            <Button
              disabled={formState.isSubmitting}
              loading={formState.isSubmitting}
              form={FORM_ID}
              content="Save"
              className="!mr-0"
              type="submit"
              primary
              compact
            />
          </div>
        </td>
      </tr>
    </React.Fragment>
  )
}
