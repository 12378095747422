import { groupBy } from 'ramda'
import useSWR from 'swr'
import { z } from 'zod'

import {
  recipeScheduleSchema,
  emailMessageSchema,
  RecipeSchedule,
  EmailMessage,
  eventSchema,
  Collection,
  taskSchema,
  FeedItem,
  Task,
} from '../types'
import { validateResponse } from '../utils'
import { useUser } from '../pages/session/hooks'
import { useApi } from '../store/mainContext'
import POWERS from '../powers'
import usePowers from './usePowers'
import featureToggles from '../featureToggles'

/**
 * SWR hook that returns all pending `Task`s (sorted by descending due date) and
 * all "event feed items" (currently `EmailMessage`s `Event`s and "done" `Task`s
 * sorted by creation date or completion date) related to a specific collection
 * item.
 *
 * TODO: feels a little weird to mix 2 different item types in the same list.
 * Maybe it would make more sense for Events to be immutable entities and create
 * more Event subtypes for logging Tasks lifecycles.
 */

export default function useEventFeed({
  relatedCollection,
  relatedId,
  showTasks,
  limitPerType,
}: {
  relatedCollection: Collection
  relatedId?: string
  showTasks: boolean
  limitPerType?: number
}) {
  const params = { relatedCollection, relatedId }
  const powers = usePowers()
  const user = useUser()
  const api = useApi()

  const extraHeaders =
    typeof limitPerType === 'number'
      ? { 'astor-limit': limitPerType.toString() }
      : {}

  return useSWR(
    ['events-feed', relatedCollection, relatedId],
    // we need to hit 4 different endpoints to gather all the required data
    () =>
      Promise.all([
        showTasks
          ? api
              .get('tasks', {
                headers: {
                  'astor-sortby': 'dueDate',
                  'astor-sortby-dir': '-1',
                  ...extraHeaders,
                },
                params,
              })
              .then(validateResponse(z.array(taskSchema)))
          : Promise.resolve([]),
        api
          .get('events', {
            headers: {
              'astor-sortby': 'createdOn',
              'astor-sortby-dir': '-1',
              ...extraHeaders,
            },
            params,
          })
          .then(validateResponse(z.array(eventSchema))),
        featureToggles.emails && user.hasPower(POWERS.viewEmails)
          ? api
              .get(
                `v1/email/${params.relatedCollection}/${params.relatedId}/emails`,
                { headers: { ...extraHeaders } },
              )
              .then(validateResponse(z.array(emailMessageSchema)))
          : ([] as EmailMessage[]),
        powers.hasAll(['run-and-schedule-recipes'])
          ? api
              .get('recipe-schedules', {
                headers: {
                  'astor-sortby': 'whenToRun',
                  'astor-sortby-dir': '-1',
                  ...extraHeaders,
                },
                params: {
                  runOnCollection: params.relatedCollection,
                  runOnId: params.relatedId,
                },
              })
              .then(validateResponse(z.array(recipeScheduleSchema)))
          : [],
      ]).then(([tasks, events, emails, schedules]) => {
        // Group `Task`s in "done" and "pending".
        // Note `Task`s are already sorted by `dueDate`.
        const { done = [], pending = [] } = groupBy<Task, 'done' | 'pending'>(
          (t) => (t.isDone ? 'done' : 'pending'),
        )(tasks)

        // Group `RecipeSchedules`s in "ran" and "scheduled".
        const { ran = [], scheduled = [] } = groupBy<
          RecipeSchedule,
          'scheduled' | 'ran'
        >((s) => (s.status === 'pending' ? 'scheduled' : 'ran'))(schedules)

        const feed: FeedItem[] = [
          ...events.map((i) => ({ ...i, sortBy: i.createdOn })),
          ...done.map((i) => ({ ...i, sortBy: i.doneOn || 0 })),
          ...emails.map((i) => ({
            ...i,
            sortBy: i.date,
            type: 'email' as const,
          })),
          ...ran.map((s) => ({
            ...s,
            sortBy: s.completedOn || 0,
            type: 'recipe-schedule' as const,
          })),
        ].sort((a, b) => b.sortBy - a.sortBy)

        return { feed, pending, scheduled }
      }),
  )
}
