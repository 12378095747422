import { format, isSameDay, isWeekend } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Button, Input } from 'semantic-ui-react'
import * as React from 'react'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

import { classNames, toUtc0Timestamp } from '../../utils'
import { useContractsApi } from '../../store/mainContext'
import { Holiday } from '../../types'

type FormData = {
  date: Date
  name: string
}

interface Props {
  onRemoveClick(holiday: Holiday): void
  selectedYearHolidays: Holiday[]
  selectedYear: number
  onSuccess(): Promise<void>
  hideLabels?: boolean
  holiday: Holiday
}

export default function UpdateHolidayForm(props: Props) {
  const { holiday } = props
  const api = useContractsApi()

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [lastEditedField, setLastEditedField] = React.useState<
    keyof FormData | null
  >(null)

  // form ref to manually trigger submits
  const formRef = React.useRef<HTMLFormElement>(null)
  const { handleSubmit, control, watch } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      date: holiday.date,
      name: holiday.name,
    },
  })

  const onSubmit = (data: FormData) => {
    setIsSubmitting(true)
    api
      .patch(`/holidays/${props.holiday._id}`, {
        ndate: toUtc0Timestamp(data.date),
        year: data.date.getFullYear(),
        date: format(data.date, 'yyyy-MM-dd'),
        name: data.name.trim(),
      })
      .then(() => props.onSuccess())
      .catch((err) => console.warn(err.message)) // TODO trigger toast on error
      .finally(() => setIsSubmitting(false))
  }

  // Subscribe to form updates and trigger form submits on change (debounced)
  React.useEffect(() => {
    let timeout: NodeJS.Timeout
    const subscription = watch((_, field) => {
      if (timeout) clearTimeout(timeout)
      // set the last edited field to pick where to render a spinner indicator
      if (field.name === 'date' || field.name === 'name') {
        setLastEditedField(field.name)
      }
      timeout = setTimeout(
        () => formRef.current?.requestSubmit(),
        // date picker does not require long debounce times
        field.name === 'date' ? 10 : 500,
      )
    })
    return () => {
      if (timeout) clearTimeout(timeout)
      subscription.unsubscribe()
    }
  }, [watch])

  return (
    <form
      className=" flex items-end"
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <Controller
        name="date"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <div>
            <label
              htmlFor={field.name}
              className={classNames(
                props.hideLabels && 'sr-only',
                'mb-0.5 ml-0.5 block text-base font-semibold text-slate-600',
              )}
            >
              Holiday Date
            </label>
            <SemanticDatepicker
              {...field}
              onChange={(_, { value }) => field.onChange(value)}
              minDate={new Date(props.selectedYear, 0, 1)}
              maxDate={new Date(props.selectedYear, 11, 31)}
              clearOnSameDateClick={false}
              filterDate={(date) =>
                !(
                  isWeekend(date) ||
                  props.selectedYearHolidays.some((h) =>
                    isSameDay(date, h.date),
                  )
                )
              }
              clearable={false}
              showToday={false}
              datePickerOnly
              loading={isSubmitting && lastEditedField === 'date'}
              error={!!fieldState.error}
            />
          </div>
        )}
      />

      <Controller
        name="name"
        control={control}
        rules={{ validate: (v) => v.trim().length > 0 }}
        render={({ field, fieldState }) => (
          <div className="ml-2 mr-2 grow">
            <label
              htmlFor={field.name}
              className={classNames(
                props.hideLabels && 'sr-only',
                'mb-0.5 ml-0.5 block text-base font-semibold text-slate-600',
              )}
            >
              Holiday Name
            </label>
            <Input
              {...field}
              fluid
              error={!!fieldState.error}
              id={field.name}
              loading={isSubmitting && lastEditedField === 'name'}
              /* disable 1Password */
              input={<input data-1p-ignore />}
            />
          </div>
        )}
      />

      <Button
        className="relative -top-0.5"
        onClick={() => props.onRemoveClick(holiday)}
        icon="trash alternate outline"
        color="red"
        type="button"
      />

      <button type="submit" className="hidden">
        Update
      </button>
    </form>
  )
}
