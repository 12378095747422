import React, { useState } from 'react'
import { Recipe, RecipeStepDefinition } from '../../../types'
import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  Icon,
  Popup,
} from 'semantic-ui-react'

import useRecipeSteps from '../../../hooks/useRecipeSteps'
import { Card } from '../ui/GUIElements'

export default function AddStepButton({
  recipe,
  index,
  onAddStep,
  isLastOne,
}: {
  recipe?: Recipe
  index: number
  onAddStep: (values: Partial<Recipe>) => Promise<void>
  isLastOne?: boolean
}) {
  const [isSaving, setIsSaving] = useState(false)

  const { data } = useRecipeSteps()

  if (!data) {
    return <></>
  }

  const handleAddStep = async (newStep: RecipeStepDefinition) => {
    const config: any = {}

    newStep.config.forEach((entry) => {
      config[entry.name] = {
        type: entry.type === 'number' ? 'number' : 'string',
        value: entry.type === 'number' ? 0 : '',
      }
    })

    const ns = {
      type: newStep.type,
      name: newStep.name,
      config,
    }

    const newSteps = [
      ...(recipe?.steps || []).slice(0, index),
      ns,
      ...(recipe?.steps || []).slice(index),
    ]

    setIsSaving(true)
    await onAddStep({ steps: newSteps })
    setIsSaving(false)
  }

  if (isSaving) {
    return (
      <div className="relative mt-3 flex flex-col items-center">
        <div className="h-[50px]  w-[1px] border border-solid border-slate-500"></div>
        <div className="mb-3 h-0 w-0 border-l-[6px] border-r-[6px] border-t-[8px] border-slate-500 border-l-transparent border-r-transparent"></div>

        <Card>Saving....</Card>

        <div className="h-[50px]  w-[1px] border border-solid border-slate-500"></div>
        <div className="mb-3 h-0 w-0 border-l-[6px] border-r-[6px] border-t-[8px] border-slate-500 border-l-transparent border-r-transparent"></div>
      </div>
    )
  }

  if (isLastOne) {
    return (
      <>
        <div className="relative flex flex-col items-center">
          <div className="h-[20px]  w-[1px] border border-solid border-slate-500"></div>
          <div className=" h-0 w-0 border-l-[6px] border-r-[6px] border-t-[8px] border-slate-500 border-l-transparent border-r-transparent"></div>

          <Dropdown
            className="absolute left-[1.5px]"
            trigger={
              <Button
                basic
                icon="add"
                circular
                size="mini"
                isLoading={isSaving}
              ></Button>
            }
            icon={null}
            pointing="left"
          >
            <DropdownMenu>
              <DropdownHeader className="!text-slate-500">Steps</DropdownHeader>

              {data
                .filter((s) => s.category !== 'tool')
                .map((s) => (
                  <Popup
                    key={s.type}
                    position="right center"
                    trigger={
                      <DropdownItem
                        content={
                          <span className="!text-slate-700">
                            <Icon name="add"></Icon>
                            {s.name}
                          </span>
                        }
                        onClick={() => handleAddStep(s)}
                      />
                    }
                    content={s.description}
                  />
                ))}

              <DropdownDivider></DropdownDivider>
              <DropdownHeader className="!text-slate-500">Tools</DropdownHeader>
              {data
                .filter((s) => s.category === 'tool')
                .map((s) => (
                  <Popup
                    key={s.type}
                    position="right center"
                    trigger={
                      <DropdownItem
                        content={
                          <span className="!text-slate-700">
                            <Icon name="add"></Icon>
                            {s.name}
                          </span>
                        }
                        onClick={() => handleAddStep(s)}
                      />
                    }
                    content={s.description}
                  />
                ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </>
    )
  }

  return (
    <div className="relative flex flex-col items-center">
      <div className="h-[50px]  w-[1px] border border-solid border-slate-500"></div>
      <div className="h-0 w-0 border-l-[6px] border-r-[6px] border-t-[8px] border-slate-500 border-l-transparent border-r-transparent"></div>

      <Dropdown
        className="!absolute !-left-[8px] !top-[10px]"
        trigger={
          <Button
            basic
            icon="add"
            circular
            size="mini"
            isLoading={isSaving}
          ></Button>
        }
        icon={null}
        pointing="left"
      >
        <DropdownMenu>
          <DropdownHeader className="!text-slate-500">Steps</DropdownHeader>

          {data
            .filter((s) => s.category !== 'tool')
            .map((s) => (
              <Popup
                key={s.type}
                position="right center"
                trigger={
                  <DropdownItem
                    content={
                      <span className="!text-slate-700">
                        <Icon name="add"></Icon>
                        {s.name}
                      </span>
                    }
                    onClick={() => handleAddStep(s)}
                  />
                }
                content={s.description}
              />
            ))}

          <DropdownDivider></DropdownDivider>
          <DropdownHeader className="!text-slate-500">Tools</DropdownHeader>
          {data
            .filter((s) => s.category === 'tool')
            .map((s) => (
              <Popup
                key={s.type}
                position="right center"
                trigger={
                  <DropdownItem
                    content={
                      <span className="!text-slate-700">
                        <Icon name="add"></Icon>
                        {s.name}
                      </span>
                    }
                    onClick={() => handleAddStep(s)}
                  />
                }
                content={s.description}
              />
            ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
