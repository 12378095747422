import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import * as React from 'react'

import { classNames, currencyFormat } from '../../utils'
import CreateBillingPeriodModal from '../../components/billing/CreateBillingPeriodModal'
import BillingPeriodStatusLabel from '../../components/billing/BillingPeriodStatusLabel'
import PagedTable, { Column } from '../../components/dataTable/pagedTable'
import BillingPeriodsFilters from '../../components/filters/BillingPeriodsFilters'
import { BillingPeriod } from '../../types'
import type { Filter } from '../../components/filters/BillingPeriodsFilters'
import PageHeader from '../../components/pageHeader/pageHeader'

type Dialog = 'create-billing-period'

const columns: Column[] = [
  {
    title: 'Description',
    field: 'description',
    render: (description: BillingPeriod['description']) =>
      description || <span className="text-slate-400">No description</span>,
  },
  {
    title: 'Total Billed',
    field: 'totalBilled',
    width: '1%',
    align: 'right',
    render: (totalBilled: BillingPeriod['totalBilled']) => (
      <span className={classNames(totalBilled === 0 && 'text-slate-400')}>
        {currencyFormat(totalBilled)}
      </span>
    ),
  },
  {
    title: 'Total Paid',
    field: 'totalPaid',
    width: '1%',
    align: 'right',
    render: (totalPaid: BillingPeriod['totalPaid']) => (
      <span className={classNames(totalPaid === 0 && 'text-slate-400')}>
        {currencyFormat(totalPaid)}
      </span>
    ),
  },
  {
    title: 'Status',
    align: 'center',
    width: '1%',
    field: 'status',
    render: (status: BillingPeriod['status']) => (
      <BillingPeriodStatusLabel status={status} />
    ),
  },
  {
    title: 'Period',
    width: '1%',
    field: 'periodStart',
    align: 'center',
    render: (_, billingPeriod: BillingPeriod) => (
      <span className="whitespace-nowrap">
        {billingPeriod.periodStart
          ? format(billingPeriod.periodStart, 'dd/MM/yyyy')
          : ''}{' '}
        &mdash;{' '}
        {billingPeriod.periodEnd
          ? format(billingPeriod.periodEnd, 'dd/MM/yyyy')
          : ''}
      </span>
    ),
  },
]

export default function BillingPage() {
  const history = useHistory()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)
  const [filter, setFilter] = React.useState<Filter>({})

  return (
    <React.Fragment>
      <Helmet>
        <title>Billing</title>
      </Helmet>

      <PageHeader
        title="Billing"
        sub="One screen to bill them all."
        breadcrumb={[{ text: 'Dashboard', link: '/' }, { text: 'Billing' }]}
        actions={
          <Button
            onClick={() => setCurrentDialog('create-billing-period')}
            content="New Billing Period"
            icon="add"
            basic
          />
        }
      />

      <div className="mx-14 mb-14">
        <div className="flex items-baseline justify-between">
          <BillingPeriodsFilters onChange={setFilter} value={filter} />
        </div>

        <PagedTable
          defaultSort="createdOn"
          collection="billing-periods"
          onRowClick={(row: BillingPeriod) =>
            history.push(`/billing/${row._id}`)
          }
          columns={columns}
          filter={filter}
          footer
          pageSize={20}
          pro={false}
        />
      </div>

      {currentDialog === 'create-billing-period' && (
        <CreateBillingPeriodModal open onClose={() => setCurrentDialog(null)} />
      )}
    </React.Fragment>
  )
}
