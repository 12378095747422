import { Popup, PopupProps } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import { EnhancedInvoice } from '../../hooks/useBillingPayments'
import PaymentStatusLabel from './PaymentStatusLabel'
import { currencyFormat } from '../../utils'

interface Props {
  invoice: EnhancedInvoice
  trigger: PopupProps['trigger']
}

export default function InvoicePaymentsPopup(props: Props) {
  const { invoice, trigger } = props

  const allocatedTotal = invoice.allocations.reduce(
    (sum, allocation) => sum + allocation.amount,
    0,
  )

  const unpaid = (invoice.totalBilled || 0) - allocatedTotal

  return (
    <Popup position="left center" hoverable trigger={trigger}>
      <div className="grid grid-cols-[auto_1fr] items-baseline gap-x-4 px-2 pt-2">
        <div className="text-nowrap py-0.5 text-right text-slate-600">
          Invoice Total Billed
        </div>
        <div className="py-0.5 pl-2 text-right text-slate-600">
          {currencyFormat(invoice.totalBilled || 0)}
        </div>
        {invoice.allocations.map((allocation) => (
          <React.Fragment key={allocation._id}>
            <div className="text-nowrap py-0.5 text-right text-sm text-slate-600">
              Payment on{' '}
              {allocation.payment?.date &&
                format(allocation.payment.date, 'yyyy-MM-dd')}
              {allocation.payment?.status && (
                <>
                  <span className="mx-1 text-slate-400">-</span>
                  <PaymentStatusLabel status={allocation.payment.status} />
                </>
              )}
            </div>
            <div className="py-0.5 pl-2 text-right text-slate-500">
              {currencyFormat(allocation.amount * -1)}
            </div>
          </React.Fragment>
        ))}

        <div className="border-t border-transparent py-2 text-right font-semibold text-slate-600">
          Unpaid
        </div>
        <div className="border-t border-slate-300 py-2 pl-2 text-right font-semibold text-slate-600">
          {currencyFormat(unpaid)}
        </div>
      </div>
    </Popup>
  )
}
