import { Modal, ModalProps } from 'semantic-ui-react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import useSWR from 'swr'

import { InvoiceScheduledEmailConfig } from '../../types'
import { useApi } from '../../store/mainContext'

interface Props extends ModalProps {
  email: InvoiceScheduledEmailConfig
}

export default function PreviewEmailModal(props: Props) {
  const { email, onClose, ...other } = props

  const api = useApi()

  const [iframeSrc, setIframeSrc] = React.useState<string | null>(null)

  const { data, isLoading, error } = useSWR<any>(
    ['delivery-preview', email._id],
    () =>
      api
        .get(`/scheduled-emails/${email._id}`, {
          headers: { Accept: 'text/html' },
          responseType: 'text',
          params: { preview: true },
        })
        .then((response) => response.data),
  )

  React.useEffect(() => {
    if (data) {
      const blob = new Blob([data], { type: 'text/html' })
      const url = URL.createObjectURL(blob)
      setIframeSrc(url)
      return () => URL.revokeObjectURL(url)
    }
  }, [data])

  return (
    <Modal {...other}>
      <Modal.Header>Preview scheduled email</Modal.Header>
      <Modal.Content className="!pt-0">
        {(email.from || email.to || email.cc || email.payload) && (
          <div className="-mt-2 mb-4 rounded-md border px-4 py-2">
            <table>
              <tbody>
                {email.from && (
                  <tr className="align-baseline">
                    <td className="py-1 pr-4 text-right text-base font-semibold">
                      From
                    </td>
                    <td>
                      {email.from.name}{' '}
                      <span className="text-slate-500">
                        ({email.from.email})
                      </span>
                    </td>
                  </tr>
                )}
                {email.to && (
                  <tr className="align-baseline">
                    <td className="py-1 pr-4 text-right text-base font-semibold">
                      To
                    </td>
                    <td>
                      {email.to.map((item, i) => (
                        <span key={item.email}>
                          {i > 0 && <span className="mr-1">,</span>}
                          {item.name}{' '}
                          <span className="text-slate-500">({item.email})</span>
                        </span>
                      ))}
                    </td>
                  </tr>
                )}
                {email.cc && email.cc.length > 0 && (
                  <tr className="align-baseline">
                    <td className="py-1 pr-4 text-right text-base font-semibold">
                      CC
                    </td>
                    <td>
                      {email.cc.map((item, i) => (
                        <span key={item.email}>
                          {i > 0 && <span className="mr-1">,</span>}
                          {item.name}{' '}
                          <span className="text-slate-500">({item.email})</span>
                        </span>
                      ))}
                    </td>
                  </tr>
                )}
                {email.payload && (
                  <tr className="align-baseline">
                    <td className="py-1 pr-4 text-right text-base font-semibold">
                      Subject
                    </td>
                    <td className="text-base">
                      {email.payload.subject ? (
                        email.payload.subject
                      ) : (
                        <span className="italic text-slate-400">
                          &mdash;empty&mdash;
                        </span>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {iframeSrc && (
          <iframe
            className="mt-2 border"
            src={iframeSrc}
            title="Email preview"
            width="100%"
            height="600px"
          />
        )}

        {isLoading && <div className="mt-4 text-center italic">Loading...</div>}

        {error && !data && (
          <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
            <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
            {error.message}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions
        actions={[{ key: 'close', content: 'Close', onClick: onClose }]}
      />
    </Modal>
  )
}
