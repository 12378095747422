import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { accountSchema, candidateSchema, paymentSetupSchema } from '../types'
import { currencyFormat, validateResponse } from '../utils'
import NewsSection from './NewsSection'
import { useApi } from '../store/mainContext'

interface Props {
  periodStart: number
  periodEnd: number
}

export default function PaymentSetupUpdatesNews(props: Props) {
  const { periodStart, periodEnd } = props

  const api = useApi()

  const { data, isLoading, error } = useSWR(
    ['period-payment-setup-updates', periodStart, periodEnd],
    async () => {
      const paymentSetupUpdates = await api
        .get('payment-setups/pro', {
          params: { lastUpdateOn: { $gte: periodStart, $lte: periodEnd } },
          headers: {
            'astor-sortby': 'lastUpdateOn',
            'astor-sortby-dir': 1,
          },
        })
        .then(validateResponse(z.array(paymentSetupSchema)))

      const [candidates, accounts] = await Promise.all([
        api
          .get('candidates/in', {
            params: {
              _id: paymentSetupUpdates.map((update) => update.candidateId),
            },
          })
          .then(validateResponse(z.array(candidateSchema))),
        api
          .get('accounts/in', {
            params: {
              _id: paymentSetupUpdates
                .map((setup) =>
                  'rest' in setup
                    ? [setup.rest, setup.fixed.account]
                    : [setup.fixed.account],
                )
                .flat(),
            },
          })
          .then(validateResponse(z.array(accountSchema))),
      ])

      return {
        accounts,
        paymentSetupUpdates: paymentSetupUpdates
          .map((update) => {
            return {
              ...update,
              candidate: candidates.find((c) => c._id === update.candidateId),
            }
          })
          .filter((update) => !!update.candidate), // filter out updates from deleted candidates
      }
    },
  )

  const paymentSetupUpdates = data?.paymentSetupUpdates || []
  const accounts = data?.accounts || []

  const getAccountAlias = (accountId: string): string => {
    const account = accounts.find((acc) => acc._id === accountId)
    if (!account) return 'unknown'
    if (account.type === 'payoneer') return 'Payoneer'
    return account.alias
  }

  return (
    <NewsSection
      title="Payment setup updates"
      isLoading={isLoading}
      error={error?.message}
      placeholderCount={2}
    >
      {paymentSetupUpdates.length > 0 ? (
        <ul className="space-y-2">
          {paymentSetupUpdates.map((setup) => (
            <li key={setup._id} className="rounded-md bg-slate-50 px-4 py-2">
              <div className="text-sm text-slate-500">
                {format(setup.lastUpdateOn, 'PPP')}
              </div>
              <div className="mt-1 text-slate-600">
                <span className="font-semibold">{setup.candidate?.name}</span>
              </div>
              {'rest' in setup ? (
                <div className="mt-1 text-sm leading-snug text-slate-500">
                  Requested to receive{' '}
                  <strong className="font-semibold text-slate-600">
                    {currencyFormat(setup.fixed.amount)}
                  </strong>{' '}
                  on{' '}
                  <strong className="font-semibold text-slate-600">
                    {getAccountAlias(setup.fixed.account)}
                  </strong>{' '}
                  account and the rest on{' '}
                  <strong className="font-semibold text-slate-600">
                    {getAccountAlias(setup.rest)}
                  </strong>{' '}
                  account.
                </div>
              ) : (
                <div className="mt-1 text-sm leading-snug text-slate-500">
                  Requested to receive{' '}
                  <strong className="font-semibold text-slate-600">ALL</strong>{' '}
                  on{' '}
                  <strong className="font-semibold text-slate-600">
                    {getAccountAlias(setup?.fixed.account)}
                  </strong>{' '}
                  account.
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="italic text-slate-500">
          No payment setups where updated on this period
        </div>
      )}
    </NewsSection>
  )
}
