import React, { ReactElement, useState } from 'react'
import { Icon } from 'semantic-ui-react'

export default function CollapsableSection({
  title,
  className,
  children,
  collapsedByDefault = false,
}: {
  className?: string
  title: string
  children: ReactElement
  collapsedByDefault?: boolean
}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsedByDefault)

  return (
    <div className={className}>
      <div>
        <h2
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="cursor-pointer"
        >
          {isCollapsed ? (
            <Icon name="caret right" className="text-slate-500" />
          ) : (
            <Icon name="caret down" className="text-slate-500" />
          )}{' '}
          {title}
        </h2>
      </div>

      {!isCollapsed && <div>{children}</div>}
    </div>
  )
}
