import { Icon, Popup, PopupProps } from 'semantic-ui-react'
import * as React from 'react'

import { Account } from '../../types'
import UsaBankIcon from '../UsaBankIcon'

interface Props extends PopupProps {
  account: Account
}

export default function AccountDetailsPopup(props: Props) {
  const { account, ...other } = props

  return (
    <Popup position="top center" {...other}>
      <Popup.Header className="!text-sm uppercase text-primary">
        Account Details
      </Popup.Header>
      <Popup.Content>
        <table className="text-sm">
          <tbody>
            <tr>
              <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-500">
                Alias
              </th>
              <td className="text-nowrap py-1.5 text-slate-600">
                {account.alias}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-500">
                Account Type
              </th>
              <td className="text-nowrap py-1.5">
                {account.type === 'international' && (
                  <span className="text-slate-600">
                    <Icon
                      name="globe"
                      color="grey"
                      className="relative left-[-2px]"
                    />
                    International Bank Account
                  </span>
                )}
                {account.type === 'usa' && (
                  <span className="text-slate-600">
                    <UsaBankIcon className="relative top-[-1px] -ml-0.5 mr-1 inline-block w-4 text-slate-600" />
                    USA Bank Account
                  </span>
                )}
              </td>
            </tr>
            {account.type !== 'payoneer' && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-500">
                  Account Number
                </th>
                <td className="text-nowrap py-1.5 text-slate-600">
                  {account.accountNumber.toString()}
                </td>
              </tr>
            )}
            {account.type === 'international' && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-500">
                  Bank SWIFT
                </th>
                <td className="text-nowrap py-1.5 text-slate-600">
                  {account.bankSwift}
                </td>
              </tr>
            )}
            {account.type === 'international' && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-500">
                  Account Country
                </th>
                <td className="text-nowrap py-1.5 text-slate-600">
                  {account.accountCountry}
                </td>
              </tr>
            )}
            {account.type === 'international' && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-500">
                  Currency
                </th>
                <td className="text-nowrap py-1.5 text-slate-600">
                  {account.accountCurrency}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Popup.Content>
    </Popup>
  )
}
