import useSWR from 'swr'
import { z } from 'zod'

import {
  billingTransactionSchema,
  billingPeriodSchema,
  customerSowSchema,
  candidateSchema,
  BillingPeriod,
  invoiceSchema,
  companySchema,
  contactSchema,
  dealSchema,
} from '../types'
import { validateResponse } from '../utils'
import { EnhancedInvoice } from './useInvoice'
import { useApi } from '../store/mainContext'

export type EnhancedBillingPeriod = BillingPeriod & {
  invoices: EnhancedInvoice[]
}

export default function useBillingPeriod(periodId: BillingPeriod['_id']) {
  const api = useApi()

  return useSWR(
    ['billing-period', periodId],
    async (): Promise<EnhancedBillingPeriod> => {
      // get billing period metadata and all invoices related to it
      const [billingPeriod, invoices] = await Promise.all([
        api
          .get('billing-periods/' + periodId)
          .then(validateResponse(billingPeriodSchema)),
        api
          .get('billing-invoices', {
            headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '-1' },
            params: { billingPeriodId: periodId },
          })
          .then(validateResponse(z.array(invoiceSchema))),
      ])

      // get companies metadata for each invoice and all transactions related to
      // the previously fetched invoices
      const [companies, transactions, contacts] = await Promise.all([
        api
          .get('companies/in', {
            params: { _id: invoices.map((i) => i.customerId) },
          })
          .then(validateResponse(z.array(companySchema))),
        api
          .get('billing-transactions/in', {
            headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '1' },
            params: { billingInvoiceId: invoices.map((i) => i._id) },
          })
          .then(validateResponse(z.array(billingTransactionSchema))),
        api
          .get('contacts/in', {
            params: {
              _id: invoices.reduce(
                (acc, invoice) => [
                  ...acc,
                  ...(invoice.sendToIds || []),
                  ...(invoice.sendToCopyIds || []),
                ],
                [] as string[],
              ),
            },
          })
          .then(validateResponse(z.array(contactSchema))),
      ])

      // get metadata for candidates, contracts, deals and customer SOWs of
      // previously fetched transactions
      const [candidates, deals, sows] = await Promise.all([
        api
          .get('candidates/in', {
            params: { _id: transactions.map((tx) => tx.candidateId) },
          })
          .then(validateResponse(z.array(candidateSchema))),
        api
          .get('deals/in', {
            params: { _id: transactions.map((tx) => tx.dealId).concat('') },
          })
          .then(validateResponse(z.array(dealSchema))),
        api
          .get('customerSOWs/in', {
            params: { _id: transactions.map((tx) => tx.customerSOWId) },
          })
          .then(validateResponse(z.array(customerSowSchema))),
      ])
      // merge all the thingz
      return {
        ...billingPeriod,
        invoices: invoices.map((invoice) => ({
          ...invoice,
          sendTo: contacts.filter((contact) =>
            invoice.sendToIds?.includes(contact._id),
          ),
          sendToCopy: contacts.filter((contact) =>
            invoice.sendToCopyIds?.includes(contact._id),
          ),
          customer: companies.find((c) => c._id === invoice.customerId),
          transactions: transactions
            .filter((tx) => tx.billingInvoiceId === invoice._id)
            .map((tx) => ({
              ...tx,
              candidate: candidates.find((c) => c._id === tx.candidateId),
              deal: deals.find((d) => d._id === tx.dealId),
              sow: sows.find((d) => d._id === tx.customerSOWId),
            })),
        })),
      }
    },
  )
}
