import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'

import RemoteLabels from '../../components/remoteValues/remoteLabels'
import { ENV_VARS } from '../../env'
import RemoteValue from '../../components/remoteValues/remoteValue'
import PagedTable from '../../components/dataTable/pagedTable'
import Period from '../../components/period'

const Container = styled.div`
  margin-top: 40px;
`

const RecommendedCandidates = ({
  profilesRequired = [],
  techsRequired = [],
  minimumYearsOfExperience = 0,
}) => {
  const history = useHistory()

  const filter = {
    yearsInTheIndustry: { $gt: Number(minimumYearsOfExperience) },
  }

  if (profilesRequired.length > 0) {
    filter.profiles = { $in: profilesRequired }
  }

  if (techsRequired.length > 0) {
    filter.mainTechs = { $all: techsRequired }
  }

  return (
    <Container>
      <h2>Recommended Candidates</h2>

      <p>Hi... here some candidates may be a good fit.</p>

      <PagedTable
        collection="candidates"
        filter={filter}
        onRowClick={(item) =>
          (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
            ? window.open
            : history.push)(`/candidates/${item._id}`)
        }
        defaultSort="lastEvent"
        columns={[
          {
            title: 'Name',
            width: 150,
            field: 'name',
          },
          {
            title: 'Level',
            width: 120,
            align: 'center',
            field: 'seniority',
            render: (seniority) => (
              <RemoteValue
                collection="seniority"
                id={seniority}
                key={seniority}
              />
            ),
          },
          {
            title: 'Profiles',
            width: 120,
            align: 'center',
            field: 'profiles',
            render: (profiles) => (
              <RemoteLabels ids={profiles} collection="profiles" />
            ),
          },
          {
            title: 'Main Techs',
            width: 120,
            align: 'center',
            field: 'mainTechs',
            render: (mainTechs) => (
              <RemoteLabels ids={mainTechs} collection="techs" />
            ),
          },
          {
            title: 'Intended Fee',
            width: 90,
            align: 'center',
            field: 'salary',
            render: (salary) => salary && `$ ${salary}`,
          },
          {
            title: 'Location',
            width: 130,
            align: 'center',
            field: 'location',
          },
          {
            title: 'Y of E',
            width: 90,
            align: 'center',
            field: 'yearsInTheIndustry',
          },
          {
            title: 'Last Touch',
            width: 120,
            align: 'center',
            field: 'lastEvent',
            render: (lastEvent) => <Period date={lastEvent} />,
          },
        ]}
      />
    </Container>
  )
}

export default RecommendedCandidates
