import { LinkProps, NavLink } from 'react-router-dom'
import * as React from 'react'
import { Icon } from 'semantic-ui-react'

import { Task, Event } from '../../types'
import DateFormat from '../dateFormat'
import UserName from '../remoteValues/userName'

interface Props<T> {
  title: string
  items: Array<T>
  tasks?: Task[]
  events?: Event[]
  onItemClick(): void
  linkTo(item: T): LinkProps['to']
  renderItemTitle(item: T): React.ReactNode
  renderItemRight?(item: T): React.ReactNode
  renderItemSub?(item: T): React.ReactNode
}

export default function ResultsSection<T extends { _id: string }>(
  props: Props<T>,
) {
  return (
    <section>
      <div className="pl-3 text-sm font-semibold uppercase leading-7 text-primary">
        {props.title}
      </div>

      <ul className="mt-1 space-y-4 text-base">
        {props.items.map((item) => {
          const itemEvents = (props.events || []).filter(
            (e) => e.relatedId === item._id,
          )
          const itemTasks = (props.tasks || []).filter(
            (t) => t.relatedId === item._id,
          )
          return (
            <li key={item._id}>
              <NavLink
                className="block overflow-hidden rounded-md border px-3 py-2 hover:bg-slate-100"
                onClick={props.onItemClick}
                to={props.linkTo(item)}
              >
                <div className="flex items-baseline justify-between">
                  <div className="text-xl text-slate-700">
                    {props.renderItemTitle(item)}
                  </div>
                  {props.renderItemRight && (
                    <div className="text-slate-500">
                      {props.renderItemRight(item)}
                    </div>
                  )}
                </div>

                {props.renderItemSub && (
                  <div className="text-sm leading-none text-slate-500">
                    {props.renderItemSub(item)}
                  </div>
                )}

                {itemTasks.length > 0 && (
                  <section className="-mx-3 -mb-3 mt-3 border-t bg-slate-50/50 px-3 pb-1 pt-2">
                    <div className="text-xs font-semibold uppercase text-slate-500">
                      Tasks
                    </div>
                    <ul className="mt-1 space-y-2 divide-y text-base">
                      {itemTasks.map((task) => (
                        <li
                          key={task._id}
                          className="flex items-baseline gap-1 pb-1.5 pt-1 "
                        >
                          {task.isDone ? (
                            <Icon name="check circle" color="blue" />
                          ) : (
                            <Icon name="circle outline" color="grey" />
                          )}
                          <div className="grow">
                            <div className="text-base text-slate-700">
                              {task.subject}
                            </div>
                            {task.details && (
                              <div
                                className="text-sm text-slate-600"
                                dangerouslySetInnerHTML={{
                                  __html: task.details,
                                }}
                              />
                            )}
                            {task.doneNote && (
                              <div className="text-sm text-slate-600">
                                <Icon
                                  className="relative -top-px"
                                  name="quote right"
                                  size="small"
                                />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: task.doneNote,
                                  }}
                                />
                              </div>
                            )}

                            <div className="mt-0.5 text-xs leading-none text-slate-500">
                              Assigned to{' '}
                              <strong>
                                <UserName id={task.assignedToId} />
                              </strong>
                              {task.doneOn ? (
                                <>
                                  <span className="mx-2 text-xs opacity-50">
                                    •
                                  </span>
                                  Done on{' '}
                                  <DateFormat date={task.doneOn} explain />
                                </>
                              ) : (
                                <>
                                  <span className="mx-2 text-xs opacity-50">
                                    •
                                  </span>
                                  Due on{' '}
                                  <DateFormat date={task.dueDate} explain />
                                </>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </section>
                )}

                {itemEvents.length > 0 && (
                  <section className="-mx-3 -mb-3 mt-3 border-t bg-slate-50/50 px-3 pb-3 pt-2">
                    <div className="text-xs font-semibold uppercase text-slate-500">
                      Events
                    </div>
                    <ul className="mt-1 space-y-2 divide-y text-base">
                      {itemEvents.map((event) => (
                        <li key={event._id} className="py-1">
                          {event.title && (
                            <div className="text-sm text-slate-700">
                              {event.title}
                            </div>
                          )}
                          {event.description && (
                            <div
                              className="text-sm text-slate-500"
                              dangerouslySetInnerHTML={{
                                __html: event.description,
                              }}
                            />
                          )}
                          <div className="mt-1 text-xs leading-none text-slate-500">
                            Created on{' '}
                            <DateFormat date={event.createdOn} explain />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </section>
                )}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
