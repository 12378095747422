import { Button, Icon } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import React from 'react'
import { z } from 'zod'

import { emailMessageSchema } from '../../types'
import { validateResponse } from '../../utils'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import EmailsPage from './emails'

const Toolbar = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const ThreadPage = () => {
  const { id } = useParams<{ id?: string }>()

  if (!id) {
    throw new Error('`id` param must be present in the URL.')
  }

  const api = useApi()

  const { data: thread, isLoading } = useSWR(['email-thread', id], () =>
    api
      .get('v1/email/emails', { params: { threadId: id } })
      .then(validateResponse(z.array(emailMessageSchema))),
  )

  if (isLoading && !thread) return <PageLoader />

  return (
    <EmailsPage>
      <React.Fragment>
        <Toolbar>
          <div>
            <Button icon="trash" color="red" disabled></Button>
            <Button icon="hide" color="black" disabled></Button>
          </div>
          <div>
            <Button color="black">
              <Icon name="pencil alternate"></Icon> Compose
            </Button>
          </div>
        </Toolbar>

        {(thread || []).map((x) => (
          <div key={x._id}>
            <hr />
            <br />
            <p>
              <b>Date:</b> {new Date(x.date).toLocaleString()}
            </p>
            <p>
              <b>From:</b> {x.from}
            </p>
            <p>
              <b>To:</b> {x.to}
            </p>
            {x.cc && (
              <p>
                <b>Cc:</b> {x.cc}
              </p>
            )}
            {x.bcc && (
              <p>
                <b>Bcc:</b> {x.bcc}
              </p>
            )}
            <p>
              <b>Subject:</b> {x.subject}
            </p>

            <p dangerouslySetInnerHTML={{ __html: x.bodyHtml }}></p>
          </div>
        ))}
      </React.Fragment>
    </EmailsPage>
  )
}

export default ThreadPage
