import { Button, Confirm, Message, Modal } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as React from 'react'

import { Recipe, RecipeSchedule, Task } from '../../types'
import UpdateRecipeScheduleForm from '../../components/recipes/UpdateRecipeScheduleForm'
import CandidateFunnelCard from '../../components/funnels/CandidateFunnelCard'
import CompleteTaskModal from '../../components/modals/completeTaskModal'
import RecipeScheduler from '../../components/feeds/feedEditor/RecipeScheduler'
import { useToasts } from '../../components/toasts/ToastsProvider'
import FunnelHeader from '../../components/funnels/FunnelHeader'
import AddTaskModal from '../../components/modals/addTaskModal'
import RemoteValue from '../../components/remoteValues/remoteValue'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import useFunnel from '../../hooks/useFunnel'
import Funnel from '../../components/funnels/Funnel'

type Dialog =
  | { type: 'create-task'; relatedId: string }
  | { type: 'complete-task'; task: Task }
  | { type: 'update-task'; task: Task }
  | { type: 'create-schedule'; relatedId: string }
  | { type: 'update-schedule'; schedule: RecipeSchedule }
  | { type: 'delete-schedule'; schedule: RecipeSchedule }

const relatedCollection = 'candidates'

export default function FunelDetailPage() {
  const { funnelName } = useParams<{ funnelName: string }>()

  if (!funnelName) {
    throw new Error('`funnelName` param must be present in the URL')
  }

  const { addToast } = useToasts()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const { data, isLoading, error, mutate } = useFunnel(funnelName)

  const [isRemoving, setIsRemoving] = React.useState(false)

  if (isLoading) return <PageLoader />

  if (!data || error) {
    return (
      <Message error>
        Funnel <code>{funnelName}</code> not found.
      </Message>
    )
  }

  const handleCardStatusChange = async (cardId: string, newStatus: string) => {
    return api
      .patch('candidates/' + cardId, { status: newStatus })
      .then(() => mutate())
  }

  const handleMarkTaskAsDone = async (task: Task) => {
    if (task.requireDoneNote && !task.doneNote) {
      return setCurrentDialog({ type: 'complete-task', task })
    }
    const { _id, ...rest } = task
    return mutate(
      () =>
        api
          .patch('tasks/' + _id, { ...rest, isDone: true })
          .then(() => mutate())
          .then(() => setCurrentDialog(null)),
      {
        populateCache: false,
        optimisticData: (_, displayedData) => {
          if (!displayedData) return
          return {
            ...displayedData,
            candidates: displayedData.candidates.map((c) => ({
              ...c,
              tasks: c.tasks.map((t) =>
                t._id === task._id ? { ...t, isDone: true } : t,
              ),
            })),
          }
        },
      },
    )
  }

  const handleConfirmRemove = async (scheduleId: string) => {
    setIsRemoving(true)
    return api
      .delete('recipe-schedules/' + scheduleId)
      .then(() => mutate())
      .then(() => addToast('Recipe Schedule deleted', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => {
        setIsRemoving(false)
        setCurrentDialog(null)
      })
  }

  return (
    <div className="flex h-screen flex-col">
      <Helmet>
        <title>{data.funnel.title} - Rome</title>
      </Helmet>

      <FunnelHeader collectionName={relatedCollection} />

      <Funnel
        key={funnelName}
        relatedCollection={relatedCollection}
        onCardStatusChange={handleCardStatusChange}
        CardComponent={CandidateFunnelCard}
        columns={data.funnel.columns}
        cards={data.candidates}
        onCreateScheduleClick={(relatedId) => {
          setCurrentDialog({ type: 'create-schedule', relatedId })
        }}
        onDeleteScheduleClick={(schedule) => {
          setCurrentDialog({ type: 'delete-schedule', schedule })
        }}
        onUpdateScheduleClick={(schedule) => {
          setCurrentDialog({ type: 'update-schedule', schedule })
        }}
        onCreateTaskClick={(relatedId) => {
          setCurrentDialog({ type: 'create-task', relatedId })
        }}
        onUpdateTaskClick={(task) => {
          setCurrentDialog({ type: 'update-task', task })
        }}
        onCompleteTaskClick={handleMarkTaskAsDone}
        onSuccess={() => mutate()}
      />

      <AddTaskModal
        relatedCollection={relatedCollection}
        relatedId={
          currentDialog?.type === 'create-task'
            ? currentDialog.relatedId
            : undefined
        }
        onCreated={() => mutate().then(() => setCurrentDialog(null))}
        onEdited={() => mutate().then(() => setCurrentDialog(null))}
        onCancel={() => setCurrentDialog(null)}
        taskToEdit={
          currentDialog?.type === 'update-task' ? currentDialog.task : undefined
        }
        show={
          currentDialog?.type === 'create-task' ||
          currentDialog?.type === 'update-task'
        }
      />

      {currentDialog?.type === 'complete-task' && (
        <CompleteTaskModal
          taskToFullfil={currentDialog.task}
          onMarkAsDone={handleMarkTaskAsDone}
          onCancel={() => setCurrentDialog(null)}
        />
      )}

      <Modal
        centered={false}
        open={currentDialog?.type === 'create-schedule'}
        size="tiny"
        onClose={() => setCurrentDialog(null)}
        onCancel={() => setCurrentDialog(null)}
      >
        <Modal.Header>Schedule a Recipe</Modal.Header>
        <Modal.Content className="!pt-0">
          {currentDialog?.type === 'create-schedule' && (
            <RecipeScheduler
              inlineDateTime
              relatedCollection={relatedCollection}
              relatedId={currentDialog.relatedId}
              onCancel={() => setCurrentDialog(null)}
              onSuccess={() => mutate().then(() => setCurrentDialog(null))}
            />
          )}
        </Modal.Content>
      </Modal>

      <Modal open={currentDialog?.type === 'update-schedule'} size="tiny">
        <Modal.Header>Edit Schedule</Modal.Header>
        <Modal.Content className="!pt-0">
          {currentDialog?.type === 'update-schedule' && (
            <RemoteValue
              collection="recipes"
              id={currentDialog.schedule.recipeId}
              predicate={(recipe: Recipe) => (
                <UpdateRecipeScheduleForm
                  onSuccess={() => mutate().then(() => setCurrentDialog(null))}
                  onCancel={() => setCurrentDialog(null)}
                  schedule={currentDialog.schedule}
                  recipe={recipe}
                />
              )}
            />
          )}
        </Modal.Content>
      </Modal>

      <Confirm
        header="Hey... removing the Recipe Schedule?"
        size="tiny"
        open={currentDialog?.type === 'delete-schedule'}
        closeOnDimmerClick={!isRemoving}
        closeOnEscape={!isRemoving}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => {
          if (currentDialog?.type === 'delete-schedule') {
            handleConfirmRemove(currentDialog.schedule._id)
          }
        }}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this Recipe Schedule.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        confirmButton={
          <Button
            primary={false}
            color="red"
            loading={isRemoving}
            disabled={isRemoving}
            content="Sure, remove."
          />
        }
      />
    </div>
  )
}
