import * as React from 'react'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import { z } from 'zod'

import { candidateSchema, accountSchema } from '../../types'
import { classNames, validateResponse } from '../../utils'
import PayoneerLogo from '../PayoneerLogo'
import NewsSection from '../../components/NewsSection'
import UsaBankIcon from '../UsaBankIcon'
import { useApi } from '../../store/mainContext'

export default function AccountsForReview() {
  const api = useApi()

  const { data, isLoading, error } = useSWR('pending-accounts', async () => {
    const accounts = await api
      .get('/accounts', {
        params: {
          beneficiaryType: 'candidate',
          status: 'Waiting for approval',
        },
        headers: {
          'astor-sortby': 'lastUpdateOn',
          'astor-sortby-dir': '-1',
        },
      })
      .then(validateResponse(z.array(accountSchema)))

    const candidates = await api
      .get('/candidates/in', {
        params: { _id: accounts.map((acc) => acc.beneficiaryId) },
      })
      .then(validateResponse(z.array(candidateSchema)))

    return accounts
      .map((account) => {
        return {
          ...account,
          candidate: candidates.find((c) => c._id === account.beneficiaryId),
        }
      })
      .filter((r) => r.candidate) // filter out accounts from deleted candidates
  })

  const accounts = data || []

  return (
    <NewsSection
      title="Bank accounts awaiting review"
      isLoading={isLoading}
      placeholderCount={2}
      error={error?.message}
    >
      {accounts.length > 0 ? (
        <ul className="space-y-2">
          {accounts.map((account) => (
            <li className="rounded-md bg-slate-50 px-4 py-2" key={account._id}>
              <div className="flex items-baseline justify-between gap-4">
                <Link
                  className="text-lg font-semibold !text-slate-600 hover:!text-primary"
                  to={'/candidates/' + account.candidate?._id}
                >
                  {account.candidate?.name}
                </Link>

                <div
                  className={classNames(
                    'inline-block rounded px-1.5 py-0.5 text-xs font-semibold uppercase',
                    account.status === 'Approved' &&
                      'bg-green-300 text-green-800',
                    account.status === 'Rejected' && 'bg-red-200 text-red-800',
                    account.status === 'Waiting for approval' &&
                      'bg-amber-200 text-amber-800',
                  )}
                >
                  {account.status}
                </div>
              </div>

              <div className="relative top-px">
                {account.type === 'usa' && (
                  <UsaBankIcon className="mr-0.5 inline-block w-5 text-slate-600" />
                )}
                {account.type === 'international' && (
                  <Icon name="globe" color="grey" className="scale-110" />
                )}
                {account.type === 'payoneer' && (
                  <PayoneerLogo className="inline-block w-24 align-text-bottom" />
                )}
                {account.type !== 'payoneer' && account.alias}
                {account.type !== 'payoneer' && (
                  <span className="ml-2 font-mono text-sm text-slate-500">
                    (****{account.accountNumber.toString().slice(-4)})
                  </span>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="italic text-slate-500">No accounts awaiting review</div>
      )}
    </NewsSection>
  )
}
