import {
  ModalContent,
  ModalHeader,
  ModalProps,
  Button,
  Select,
  Modal,
  Icon,
} from 'semantic-ui-react'
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import * as React from 'react'

import { BillingPeriod } from '../../types'
import { classNames } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

type PeriodOption = { text: string; value: number }

function createPeriodOptions(amount = 5): [PeriodOption, ...PeriodOption[]] {
  const firstPeriodTimestamp: number = startOfMonth(Date.now()).getTime()

  const options: [PeriodOption, ...PeriodOption[]] = [
    {
      value: firstPeriodTimestamp,
      text: format(firstPeriodTimestamp, 'MMMM yyyy'),
    },
  ]

  for (let i = 1; i < amount; i++) {
    const periodDate = addMonths(firstPeriodTimestamp, i)
    options.push({
      value: periodDate.getTime(),
      text: format(periodDate, 'MMMM yyyy'),
    })
  }

  return options
}

type FormValues = {
  startDate: Date
  endDate: Date
}

interface Props extends ModalProps {
  onClose(): void
}

export default function CreateBillingPeriodModal(props: Props) {
  const options = createPeriodOptions()

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isEditingDates, setIsEditingDates] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true)
    return api
      .post<BillingPeriod>('/billing-periods', {
        startDate: format(data.startDate, 'yyyy-MM-dd'),
        endDate: format(data.endDate, 'yyyy-MM-dd'),
      })
      .then((res) => history.push(`/billing/${res.data._id}`))
      .catch((err: AxiosError) => {
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const { handleSubmit, control, watch, setValue } = useForm<FormValues>({
    defaultValues: {},
  })

  const startDateInput = React.useRef<SemanticDatepicker>(null)
  const endDateInput = React.useRef<SemanticDatepicker>(null)

  const firstOption = options[0].value

  const [selectedPeriod, setSelectedPeriod] =
    React.useState<number>(firstOption)

  React.useEffect(() => {
    setValue('startDate', startOfMonth(selectedPeriod))
    setValue('endDate', endOfMonth(selectedPeriod))
  }, [selectedPeriod, setValue])

  return (
    <Modal
      {...props}
      closeOnDimmerClick={!isSubmitting}
      closeOnEscape={!isSubmitting}
      size="tiny"
    >
      <ModalHeader>New Billing Period</ModalHeader>

      <ModalContent className="!pt-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="text-base">
            Provide de starting and ending date for the period:
          </p>

          <div className="mt-6">
            <label className="block pb-1 text-base font-semibold text-slate-600">
              Period:
            </label>
            <div className="flex items-center gap-6">
              <Select
                value={selectedPeriod}
                onChange={(_, { value }) => {
                  if (typeof value === 'number') setSelectedPeriod(value)
                }}
                options={options}
                disabled={isSubmitting}
              />
              {!isEditingDates && (
                <button
                  className="text-slate-500 hover:text-primary"
                  onClick={() => setIsEditingDates(true)}
                  type="button"
                >
                  <Icon name="pencil alternate" className="pr-2" fitted />
                  Edit dates
                </button>
              )}
            </div>
          </div>

          <div
            className={classNames(
              'mt-6 grid grid-cols-2',
              isEditingDates ? 'block' : 'hidden',
            )}
          >
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <div>
                  <label
                    htmlFor={field.name}
                    className={classNames(
                      'mb-0.5 ml-0.5 block text-base font-semibold',
                      field.disabled ? 'text-slate-400' : 'text-slate-600',
                    )}
                  >
                    Start Date
                  </label>
                  <SemanticDatepicker
                    {...field}
                    onChange={(event, { value }) => {
                      field.onChange(value)
                      if (value && typeof event !== 'undefined') {
                        endDateInput.current?.focusOnInput()
                      }
                    }}
                    maxDate={watch('endDate')}
                    clearOnSameDateClick={false}
                    clearable
                    showToday={false}
                    datePickerOnly
                    disabled={field.disabled || isSubmitting}
                    error={!!fieldState.error}
                    ref={startDateInput}
                  />
                </div>
              )}
            />
            <Controller
              name="endDate"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <div>
                  <label
                    htmlFor={field.name}
                    className={classNames(
                      'mb-0.5 ml-0.5 block text-base font-semibold',
                      field.disabled ? 'text-slate-400' : 'text-slate-600',
                    )}
                  >
                    End Date
                  </label>
                  <SemanticDatepicker
                    {...field}
                    onChange={(_, { value }) => field.onChange(value)}
                    minDate={watch('startDate')}
                    clearOnSameDateClick={false}
                    clearable
                    showToday={false}
                    datePickerOnly
                    disabled={field.disabled || isSubmitting}
                    error={!!fieldState.error}
                    ref={endDateInput}
                  />
                </div>
              )}
            />
          </div>

          <div className="mt-8 flex gap-2">
            <Button
              content="Create"
              className="!mr-0"
              disabled={isSubmitting}
              loading={isSubmitting}
              primary
              type="submit"
            />
            <Button
              type="button"
              content="Cancel"
              className="!mr-0"
              onClick={props.onClose}
              disabled={isSubmitting}
              basic
            />
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}
