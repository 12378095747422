import * as React from 'react'

import {
  Recipe,
  RecipeArgs,
  RecipeStep,
  RecipeStepDefinition,
} from '../../../types'

import { Card, IndexLabel, KeyValueSection } from '../ui/GUIElements'
import useRecipeSteps from '../../../hooks/useRecipeSteps'

import { Button, Checkbox, Icon, Input, Label, Select } from 'semantic-ui-react'
import TextArea from '../../form/textArea'

import { Editor as JsonEditor } from '@monaco-editor/react'
import HtmlInput from '../../form/HtmlInput'

interface Props {
  recipe: Recipe
  step: RecipeStep
  index: number
  onSave: (recipe: Partial<Recipe>) => void
  funcs: string[]
}

const ConfigEditor = ({
  configDef,
  value,
  onChange,
}: {
  configDef: RecipeArgs
  value: any
  onChange: (newValue: any) => void
}) => {
  const [showExample, setShowExample] = React.useState(false)

  if (configDef.type === 'long-text') {
    return (
      <TextArea
        placeholder={configDef.displayName}
        value={value}
        onChange={(e: any, d: any) => onChange(d.value)}
      />
    )
  }

  if (configDef.type === 'enum') {
    return (
      <Select
        value={value}
        placeholder={configDef.displayName}
        options={(configDef.options || []).map((o) => ({
          text: o.charAt(0).toUpperCase() + o.slice(1),
          value: o,
        }))}
        onChange={(e, d: any) => onChange(d.value)}
      />
    )
  }

  if (configDef.type === 'boolean') {
    return (
      <>
        <p>{configDef.displayName}</p>
        <Checkbox
          toggle
          onChange={(e, t) => onChange(t.checked === true)}
          checked={value}
        />
      </>
    )
  }

  if (configDef.type === 'html') {
    return (
      <HtmlInput
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  }

  if (configDef.type === 'object') {
    return (
      <>
        <div className="mb-2 flex justify-between">
          <b>{configDef.displayName}:</b>

          <Button
            basic
            size="mini"
            onClick={() => setShowExample(!showExample)}
          >
            <Icon name="help circle"></Icon>{' '}
            {!showExample ? 'Show Example' : 'Hide Example'}
          </Button>
        </div>

        {showExample && (
          <pre className="mb-2 rounded-md bg-bg-grey p-4 text-sm">
            {JSON.stringify(JSON.parse(configDef.template || ''), null, 2)}
          </pre>
        )}

        <JsonEditor
          value={typeof value === 'object' ? JSON.stringify(value) : value}
          onChange={(code) => onChange(code as string)}
          defaultLanguage="json"
          height={350}
          width={700}
          theme="vs-dark"
          options={{
            showFoldingControls: 'always',
            scrollBeyondLastLine: false,
            lineNumbers: 'off',
            padding: { top: 5 },
            wordWrap: 'on',
            fontSize: 14,
          }}
        />
      </>
    )
  }

  return (
    <Input
      value={value}
      type="text"
      placeholder={configDef.displayName}
      onChange={(e, d) => onChange(d.value)}
    />
  )
}

export default function GenericStep({
  index,
  step,
  recipe,
  onSave,
  funcs,
}: Props) {
  const [editMode, setEditMode] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const { data } = useRecipeSteps()
  const [stepDef, setStepDef] = React.useState<RecipeStepDefinition>()

  const [unsavedValues, setUnsavedValues] = React.useState<RecipeStep>(step)

  const [showConfigDetails, setShowConfigDetails] = React.useState(false)

  React.useEffect(() => {
    if (!data) {
      return
    }

    setStepDef(data.find((x) => x.type === step.type))
  }, [recipe, step, data])

  const handleCancel = () => {
    setUnsavedValues(step)
    setEditMode(false)
  }

  const handleSave = async () => {
    if (!recipe.steps || !unsavedValues) {
      return
    }

    setIsSaving(true)

    const newSteps = recipe.steps.map((s, i) => {
      if (i === index - 1) {
        return unsavedValues
      }

      return s
    })

    await onSave({ steps: newSteps })

    setIsSaving(false)
    setEditMode(false)
  }

  if (!data || !unsavedValues) {
    return <div></div>
  }

  const handleChangeConfig = (
    key: string,
    value: string | boolean,
    type: string,
  ) => {
    const newValues: RecipeStep = { ...unsavedValues }
    newValues.config[key] = { value, type }

    setUnsavedValues(newValues as RecipeStep)
  }

  const handleRemove = async () => {
    setIsSaving(true)
    const newSteps = recipe.steps?.filter((e, i) => i !== index - 1)
    await onSave({ steps: newSteps })
    setIsSaving(false)
  }

  if (isSaving) {
    return (
      <Card>
        <p>Saving...</p>
      </Card>
    )
  }

  if (!editMode) {
    return (
      <Card>
        <div className="absolute right-[10px] top-[-15px] flex flex-col justify-start gap-2">
          <IndexLabel>{index}</IndexLabel>
        </div>
        <div className="absolute right-[-50px] top-0 flex flex-col justify-start gap-2">
          <Button
            icon="bars"
            circular
            basic
            onClick={() => setShowConfigDetails(!showConfigDetails)}
            className="!mr-0"
            size="tiny"
          />

          <Button
            icon="pencil"
            circular
            basic
            onClick={() => setEditMode(true)}
            className="!mr-0"
            size="tiny"
          />
        </div>
        <div className="flex justify-between text-lg font-bold">
          {unsavedValues.name}
        </div>
        <p className="mb-6 mt-0 text-sm text-slate-500">
          {stepDef?.description}
        </p>

        {showConfigDetails && <KeyValueSection values={step.config} />}
      </Card>
    )
  }

  return (
    <Card>
      <div className="absolute -left-[300px] w-[250px] rounded-md border border-border-grey bg-white p-5 shadow-md">
        <h3>
          <Icon name="code" /> Available Functions
        </h3>

        <div className="flex flex-col gap-2">
          {funcs.map((x) => (
            <Label
              key={x}
              className="cursor-pointer"
              onClick={() => navigator.clipboard.writeText(`{{${x}}}`)}
            >
              {x}
            </Label>
          ))}
        </div>
      </div>

      <div className="absolute right-[-50px] top-0 flex flex-col justify-start gap-2">
        <IndexLabel>{index}</IndexLabel>
      </div>
      <div className="mb-5 flex flex-col">
        <Input
          type="text"
          value={unsavedValues.name}
          className="!font-bold"
          onChange={(e, d) =>
            setUnsavedValues({ ...unsavedValues, name: d.value })
          }
        />

        <p className="mt-2 text-sm text-slate-500">
          <Icon name="help circle" />
          Step name
        </p>
      </div>

      {stepDef?.config.map((configDef) => (
        <div key={configDef.name} className="mb-5 flex flex-col">
          <ConfigEditor
            configDef={configDef}
            value={unsavedValues.config[configDef.name]?.value}
            onChange={(newValue) =>
              handleChangeConfig(configDef.name, newValue, configDef.type)
            }
          />

          <p className="mt-2 text-sm text-slate-500">
            <Icon name="help circle" />
            {configDef.description}
          </p>
        </div>
      ))}

      <div className="mt-8 flex justify-between">
        <div>
          <Button
            size="tiny"
            color="red"
            onClick={handleRemove}
            loading={isSaving}
          >
            <Icon name="trash alternate outline"></Icon> Remove
          </Button>
        </div>
        <div className="flex justify-end">
          <Button size="tiny" basic onClick={handleCancel}>
            Nevermind
          </Button>

          <Button
            size="tiny"
            color="black"
            onClick={handleSave}
            loading={isSaving}
          >
            <Icon name="save" /> Save
          </Button>
        </div>
      </div>
      {/* <KeyValueSection
        title="Configuration"
        values={step.config}
        emptyMessage="No configuration."
      /> */}
      {/* {Object.keys(step.config).map((c, i) => (
        <React.Fragment key={i}>
          <div className="w-40 border-gray-300 p-2 text-left text-slate-600">
            {c}
          </div>

          <div className=" border-gray-300 p-2">
            <Input
              fluid
              type="textarea"
              value={step.config[c].value}
              className="min-w-96"
            ></Input>
          </div>
        </React.Fragment>
      ))} */}
    </Card>
  )
}
