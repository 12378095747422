import { format, isBefore, isToday, startOfToday } from 'date-fns'
import { Button, Icon } from 'semantic-ui-react'
import * as React from 'react'

import { classNames } from '../../utils'
import UserName from '../remoteValues/userName'
import { Task } from '../../types'

interface Props {
  onCompleteTaskClick(task: Task): void
  onUpdateTaskClick(task: Task): void
  onCreateTaskClick(): void
  tasks: Task[]
}

export default function CardTasks(props: Props) {
  const { onCompleteTaskClick, onUpdateTaskClick, onCreateTaskClick, tasks } =
    props

  return (
    <React.Fragment>
      {tasks.length ? (
        <React.Fragment>
          <div className="sticky top-0 z-10 flex items-baseline justify-between border-b bg-white py-2 pl-4 pr-2 text-xs font-semibold uppercase text-slate-700">
            Tasks
            <Button
              className="!mr-0"
              onClick={onCreateTaskClick}
              basic
              compact
              size="mini"
              type="button"
              icon="plus"
            />
          </div>
          <ul className="pb-2 pt-1">
            {tasks.map((task) => {
              const isDueToday = isToday(task.dueDate)
              const isOverdue = isBefore(task.dueDate, startOfToday())
              return (
                <li
                  className={classNames(
                    'flex items-baseline gap-3 px-4 py-2',
                    task.isDone
                      ? 'cursor-auto'
                      : 'cursor-pointer hover:bg-slate-50',
                  )}
                  onClick={() => onUpdateTaskClick(task)}
                  key={task._id}
                >
                  <div>
                    {task.isDone ? (
                      <Icon
                        className="!mr-0 [&.icon.icon.icon]:!bg-primary [&.icon.icon.icon]:opacity-60"
                        name="check"
                        circular
                        inverted
                        size="small"
                      />
                    ) : (
                      <Icon
                        className="!mr-0 [&.icon]:![box-shadow:0_0_0_.1em_rgba(0,0,0,0.4)_inset] [&::before]:opacity-0 [&::before]:hover:opacity-20"
                        name="check"
                        circular
                        size="small"
                        link
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation()
                          onCompleteTaskClick(task)
                        }}
                      />
                    )}
                  </div>

                  <div>
                    <div
                      className={classNames(
                        task.isDone && 'text-slate-500 line-through',
                        'text-lg leading-snug',
                      )}
                    >
                      {task.subject}
                    </div>
                    <div
                      className="mt-1 line-clamp-2 text-sm text-slate-600"
                      dangerouslySetInnerHTML={{ __html: task.details || '' }}
                    />

                    {isOverdue ? (
                      <div className="mt-1.5 text-sm text-slate-500">
                        <span className="text-red-600">
                          Overdue ({format(task.dueDate, 'P')})
                        </span>{' '}
                        -{' '}
                        <span className="text-slate-600">
                          <UserName id={task.assignedToId} short />
                        </span>
                      </div>
                    ) : isDueToday ? (
                      <div className="mt-1.5 text-sm text-primary">
                        Due today! -{' '}
                        <span className="text-slate-600">
                          <UserName id={task.assignedToId} short />
                        </span>
                      </div>
                    ) : (
                      <div className="mt-1.5 text-sm text-slate-500">
                        Due on {format(task.dueDate, 'P')} -{' '}
                        <span className="text-slate-600">
                          <UserName id={task.assignedToId} short />
                        </span>
                      </div>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        </React.Fragment>
      ) : (
        <div className="px-4 py-3">
          <Button
            className="!mr-0"
            onClick={onCreateTaskClick}
            basic
            content="Schedule a Task"
            compact
            type="button"
            icon="check circle outline"
          />
        </div>
      )}
    </React.Fragment>
  )
}
