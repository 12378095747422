import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Icon, Input, Popup } from 'semantic-ui-react'
import * as React from 'react'

import { classNames } from '../../utils'
import { Company } from '../../types'

type FormValues = { name: string }

function Field(props: {
  suggestion?: string
  onCancel(): void
  onSubmit: SubmitHandler<FormValues>
  value?: Company['_id']
}) {
  const { onCancel, onSubmit } = props

  const form = useForm<FormValues>({
    defaultValues: { name: props.value },
  })

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} spellCheck="false">
      <Controller
        name="name"
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            fluid
            onChange={(_, { value }) => field.onChange(value)}
            error={!!fieldState.error}
            input={<input data-1p-ignore autoFocus />}
            icon={
              props.suggestion && field.value !== props.suggestion ? (
                <Popup
                  content="Use suggestion"
                  position="top center"
                  offset={[0, -8]}
                  size="mini"
                  trigger={
                    <Icon
                      name="magic"
                      link
                      onClick={() => {
                        form.setValue('name', props.suggestion || '')
                      }}
                    />
                  }
                />
              ) : undefined
            }
          />
        )}
      />

      <div className="col-span-2 flex justify-end gap-1 py-1">
        <Button
          onClick={onCancel}
          content="Cancel"
          className="!mr-0"
          size="tiny"
          compact
          type="button"
        />
        <Button
          content="Save"
          className="!mr-0"
          primary
          size="tiny"
          compact
          type="submit"
        />
      </div>
    </form>
  )
}

interface Props {
  suggestion?: string
  value?: string
  onChange(newValue: string): Promise<any>
  onClear(): Promise<any>
  isEditable?: boolean
}

export default function NameForm(props: Props) {
  const [isEditing, setIsEditing] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async ({ name }) => {
    setIsEditing(false)
    return name ? props.onChange(name) : props.onClear()
  }

  return (
    <div
      className={classNames(
        'group grid items-start gap-x-4',
        props.isEditable ? 'grid-cols-[80px_1fr]' : 'grid-cols-[80px_auto]',
      )}
    >
      <label className="relative top-px block text-sm font-semibold uppercase leading-[38px] text-slate-500">
        Invoice #
      </label>

      {!isEditing && (
        <div className="relative w-full overflow-hidden text-ellipsis leading-[38px]">
          {props.value ? (
            <span className="text-nowrap text-base">{props.value}</span>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="text-primary"
              type="button"
            >
              + Add value
            </button>
          )}

          {props.isEditable && (
            <div className="absolute -top-px right-0 opacity-0 group-hover:opacity-100">
              <Button
                onClick={() => setIsEditing(true)}
                className={classNames('!mr-0', !props.value && '!hidden')}
                icon="pencil"
                size="mini"
                compact
                basic
              />
            </div>
          )}
        </div>
      )}

      {isEditing && (
        <Field
          onSubmit={onSubmit}
          onCancel={() => setIsEditing(false)}
          value={props.value || ''}
          suggestion={props.suggestion}
        />
      )}
    </div>
  )
}
