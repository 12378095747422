import React from 'react'
import { Recipe } from '../../../types'
import { Input } from 'semantic-ui-react'

const Step0Webhook = ({
  recipe,
  onChange,
}: {
  recipe: Partial<Recipe>
  onChange: (newValues: Partial<Recipe>) => void
}) => (
  <div className="mt-6 flex flex-col">
    <label className="mb-2 font-bold">Webhook Id</label>

    <Input
      placeholder="webhook"
      type="text"
      value={recipe.webhookId}
      onChange={(e) =>
        onChange({
          ...recipe,
          webhookId: e.target.value,
        })
      }
    />
  </div>
)

export default Step0Webhook
