import { Button, Icon, IconGroup, Popup } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'

import { PaymentOrder } from '../../types'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

interface Props {
  paymentOrderId: PaymentOrder['_id']
  title: string
}

export default function PayoneerExportButton(props: Props) {
  const { paymentOrderId, title } = props

  const { addToast } = useToasts()
  const api = useApi()

  const [isDownloading, setIsDownloading] = React.useState(false)

  const handleClick = async () => {
    setIsDownloading(true)

    return api
      .post(`payment-orders/${paymentOrderId}/payoneer-csv`, {
        Accept: 'text/csv',
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title + '.csv')
        link.click()
        setTimeout(() => window.URL.revokeObjectURL(url), 0) // avoid memory leak
      })
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsDownloading(false))
  }

  return (
    <Popup
      content="Download Payoneer CSV"
      size="small"
      position="top center"
      trigger={
        <Button
          type="button"
          onClick={handleClick}
          className="!mr-0"
          disabled={isDownloading}
          loading={isDownloading}
          basic
          size="small"
          icon={
            <IconGroup>
              <Icon name="file powerpoint outline" color="grey" />
              <Icon name="arrow down" corner color="green" />
            </IconGroup>
          }
        />
      }
    />
  )
}
