import { Select, Button, Form, Icon, Modal, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useContractsApi } from '../../store/mainContext'
import { toUtc0Timestamp } from '../../utils'
import ContactSelect from '../form/contactSelect'
import ContactName from '../remoteValues/contactName'
import DateSelect from '../form/dateSelect'
import DateFormat from '../dateFormat'
import FormField from '../form/formField'
import Period from '../period'
import ShowIf from '../showIf'

const Title = styled.h2`
  font-weight: bold !important;
`

const terminationReasons = [
  {
    key: 'quit-better-offer',
    value: 'quit-better-offer',
    text: 'Quit. Better offer.',
  },
  {
    key: 'quit-unhappy',
    value: 'quit-unhappy',
    text: 'Quit. Unhappy.',
  },
  {
    key: 'fired-budget',
    value: 'fired-budget',
    text: 'Fired. Lack of Budget.',
  },
  {
    key: 'fired-performance',
    value: 'fired-performance',
    text: 'Fired. Performance issues.',
  },
  {
    key: 'fired-project-ended',
    value: 'fired-project-ended',
    text: 'Fired. Project finished.',
  },
]

const TerminateContractModal = ({
  candidate,
  show,
  contractId,
  onCancel,
  onSuccess,
}) => {
  const api = useContractsApi()

  const [errors, setErrors] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const [termination, setTermination] = useState({ contractId })

  const [isSaving, setIsSaving] = useState(false)

  const [showConfirmation, setShowConfirmation] = useState()

  useEffect(() => {
    setErrors(null)
    setTermination({})
    setShowConfirmation(false)
    setIsSaving(false)
    setErrorMessage(null)
  }, [show])

  const handleTerminate = () => {
    const e = ['lastDay', 'reason', 'details'].filter((x) => !termination[x])

    if (
      termination.reason &&
      termination.reason.indexOf('fired') === 0 &&
      !termination.firedBy
    ) {
      e.push('firedBy')
    }

    if (e.length > 0) {
      setErrors(e)
      return
    }

    setErrors([])

    setShowConfirmation(true)
  }

  const handleSave = async () => {
    setIsSaving(true)

    try {
      const { data } = await api.post(`contracts/${contractId}/terminate`, {
        ...termination,
        lastDay: toUtc0Timestamp(termination.lastDay),
      })

      onSuccess && onSuccess(data)
    } catch ({ response }) {
      setShowConfirmation(false)

      if (response.data.fields) {
        setErrors(response.data.fields)
        return
      }

      setErrorMessage(response.data.message)
    } finally {
      setIsSaving(false)
    }
  }

  if (!show) {
    return null
  }

  if (showConfirmation) {
    return (
      <Modal
        onClose={onCancel}
        open={true}
        size="tiny"
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Title>Terminate Contract... Are you sure?</Title>

          <p>
            You are about to terminate this contract. This action{' '}
            <b>can not be undone.</b>
          </p>

          <p>Please review following information:</p>

          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Contractor</Table.Cell>
                <Table.Cell>{candidate.name}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <p>Termination Details: </p>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Termination Date</Table.Cell>
                <Table.Cell>
                  {' '}
                  <DateFormat date={termination.lastDay} /> (
                  <Period date={termination.lastDay} />)
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Reason</Table.Cell>
                <Table.Cell>
                  {
                    terminationReasons.find(
                      (x) => x.value === termination.reason,
                    ).text
                  }
                </Table.Cell>
              </Table.Row>

              {termination.firedBy && (
                <Table.Row>
                  <Table.Cell>Terminated By</Table.Cell>
                  <Table.Cell>
                    <ContactName id={termination.firedBy} email />
                  </Table.Cell>
                </Table.Row>
              )}

              <Table.Row>
                <Table.Cell>Details</Table.Cell>
                <Table.Cell>{termination.details}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            onClick={() => setShowConfirmation(false)}
            disabled={isSaving}
          >
            Oh no no, wait... go back.
          </Button>
          <Button color="red" onClick={handleSave} loading={isSaving}>
            <Icon name="cut" /> Yes, I&apos;m sure... go ahead!
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Title>Terminate Contract</Title>

        <p>
          You are about to terminate this contract.{' '}
          <b>
            This action cannot be undone and it has an impact on Payroll &
            Invoicing
          </b>
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 40,
          }}
        >
          <div style={{ flexGrow: 1, marginRight: 20 }}>
            <FormField label="Last day of services">
              <DateSelect
                name="lastDay"
                fluid={true}
                error={errors && errors.find((x) => x === 'lastDay')}
                onChange={(e, target) =>
                  setTermination({ ...termination, lastDay: target.value })
                }
                value={termination.lastDay && new Date(termination.lastDay)}
                placeholder="Termination Date..."
                disabled={isSaving}
              />
            </FormField>
          </div>
          <div style={{ flexGrow: 1, width: 250 }}>
            <FormField label="Why?">
              <Select
                style={{ width: '100%' }}
                search={true}
                name="reason"
                placeholder="Choose a reason..."
                options={terminationReasons}
                disabled={false}
                onChange={(e, target) => {
                  setTermination({
                    ...termination,
                    firedBy: null,
                    reason: target.value,
                  })
                }}
                value={termination.reason}
                fluid={true}
                error={errors && errors.indexOf('reason') !== -1}
              ></Select>
            </FormField>
          </div>
        </div>

        <ShowIf
          if={termination.reason && termination.reason.indexOf('fired') === 0}
        >
          <FormField label="Who requested the termination?">
            <ContactSelect
              error={errors && errors.indexOf('firedBy') !== -1}
              name="firedBy"
              search
              placeholder="Fired by..."
              fluid={true}
              allowAdditions={true}
              value={termination.firedBy}
              onChange={(e, target) =>
                setTermination({ ...termination, firedBy: target.value })
              }
            />
          </FormField>
        </ShowIf>

        <FormField label="Tell me a bit more... explain the details.">
          <Form>
            <Form.TextArea
              name="details"
              placeholder="Explain why..."
              value={termination.details}
              onChange={(e) =>
                setTermination({ ...termination, details: e.target.value })
              }
              style={{ resize: `none`, fontSize: 14, height: 100 }}
              error={errors && errors.indexOf('details') !== -1}
            />
          </Form>
        </FormField>

        {errorMessage && <p style={{ color: 'var(--red)' }}>{errorMessage}</p>}
      </Modal.Content>

      <Modal.Actions>
        {/* <code>{JSON.stringify(termination)}</code> */}
        <Button basic onClick={onCancel}>
          Nervermind...
        </Button>
        <Button color="red" onClick={handleTerminate}>
          <Icon name="cut" /> Terminate!
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

// const TerminationForm = ({
//   termination,
//   setTermination,
//   errors,
//   errorMessage,
//   onCancel,
//   isSaving,
//   handleSave,
// }) => <React.Fragment></React.Fragment>

export default TerminateContractModal
