import {
  DropdownItemProps,
  Checkbox,
  Dropdown,
  Button,
  Input,
  Form,
  Ref,
  Icon,
} from 'semantic-ui-react'
import {
  SubmitHandler,
  useFieldArray,
  Controller,
  useForm,
} from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { AxiosError } from 'axios'
import * as React from 'react'

import {
  validateResponse,
  toUtc0Timestamp,
  currencyFormat,
  classNames,
} from '../../utils'
import AttachmentUploadField from './AttachmentUploadField'
import { EnhancedInvoice } from '../../hooks/useBillingPayments'
import InvoiceStatusLabel from '../invoices/InvoiceStatusLabel'
import { paymentSchema } from '../../types'
import { useCompanies } from '../../hooks/useCompany'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

const ALLOCATION_AMOUNTS_ERROR_KEY = 'allocation-amounts'

type FormValues = {
  transferDescription: string
  transferFrom: string
  customerId: string
  amount: number | null
  date: number
  attachment: string
  allocations: { enabled: boolean; invoiceId: string; amount: number | null }[]
  [ALLOCATION_AMOUNTS_ERROR_KEY]: any
}

interface Props {
  onSuccess(): Promise<any>
  onCancel(): void
  invoices: EnhancedInvoice[]
}

export default function CreatePaymentForm(props: Props) {
  const { onCancel, onSuccess, invoices } = props

  const { addToast } = useToasts()
  const companies = useCompanies()
  const api = useApi()

  const form = useForm<FormValues>({
    defaultValues: {
      transferDescription: '',
      transferFrom: '',
      customerId: '',
      amount: null,
      date: Date.now(),
      allocations: [],
    },
  })

  const allocationsField = useFieldArray({
    control: form.control,
    name: 'allocations',
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    return api
      .post('billing-payments', {
        transferDescription: data.transferDescription,
        transferFrom: data.transferFrom,
        customerId: data.customerId,
        amount: Number(data.amount || 0),
        date: toUtc0Timestamp(data.date),
        ...(data.attachment ? { attachment: data.attachment } : {}),
      })
      .then(validateResponse(paymentSchema))
      .then(async (payment) => {
        for (let i = 0; i < data.allocations.length; i++) {
          const currentAllocation = data.allocations[i]
          if (!currentAllocation?.enabled) continue
          await api.post('billing-payments-allocations', {
            invoiceId: currentAllocation.invoiceId,
            paymentId: payment._id,
            amount: Number(currentAllocation.amount || 0),
          })
        }
      })
      .then(onSuccess)
      .then(() => addToast('Payment created', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
  }

  const customerOptions: DropdownItemProps[] = (companies.data || [])
    .map((company) => {
      const invoicesCount = (invoices || []).filter(
        (i) =>
          i.customerId === company._id &&
          (i.status === 'Sent' || i.status === 'Uncollectible'),
      ).length
      return {
        value: company._id,
        text: company.name,
        description: `${invoicesCount} ${invoicesCount === 1 ? 'invoice' : 'invoices'}`,
        // disabled: invoicesCount === 0,
      }
    })
    .sort((a, b) => b.description.localeCompare(a.description))

  const unallocatedTotal =
    (form.watch('amount') || 0) -
    form.watch('allocations').reduce((sum, allocation) => {
      return sum + (allocation.enabled ? Number(allocation.amount) || 0 : 0)
    }, 0)

  const { setError, clearErrors } = form

  React.useEffect(() => {
    if (unallocatedTotal < 0) {
      setError(ALLOCATION_AMOUNTS_ERROR_KEY, {
        type: 'custom',
        message: 'Payment amount is not enough',
      })
    } else {
      clearErrors(ALLOCATION_AMOUNTS_ERROR_KEY)
    }
  }, [unallocatedTotal, setError, clearErrors])

  const [isUploading, setIsUploading] = React.useState(false)

  return (
    <Form spellCheck="false" onSubmit={form.handleSubmit(onSubmit)}>
      <div className="lg:grid lg:grid-cols-[1fr_450px] lg:gap-4">
        <section>
          <Controller
            name="customerId"
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <div>
                <label className="block py-1 font-semibold leading-none text-slate-600">
                  Customer
                </label>
                <Ref innerRef={field.ref}>
                  <Dropdown
                    {...field}
                    selection
                    search
                    selectOnBlur={false}
                    openOnFocus={false}
                    disabled={
                      companies.isLoading || form.formState.isSubmitting
                    }
                    onChange={(_, { value }) => {
                      field.onChange(value)
                      form.setValue(
                        'allocations',
                        invoices
                          .filter((invoice) => invoice.customerId === value)
                          .map((invoice) => ({
                            enabled: false,
                            invoiceId: invoice._id,
                            amount:
                              Number(
                                (
                                  (invoice.totalBilled || 0) -
                                  (invoice.totalPaid || 0)
                                ).toFixed(2),
                              ) || null,
                          })),
                      )
                    }}
                    placeholder="Select a company"
                    loading={companies.isLoading}
                    fluid
                    options={customerOptions}
                    error={!!fieldState.error}
                  />
                </Ref>
                <div className="text-base leading-normal text-red-600">
                  {fieldState.error?.message}
                </div>
              </div>
            )}
          />

          <div className="mt-4 grid grid-cols-[1fr_auto] gap-4">
            <Controller
              name="amount"
              control={form.control}
              rules={{
                validate: (v) => {
                  if (!v) return 'Required'
                  return Number(v) > 0 ? true : 'Amount must be positive'
                },
              }}
              render={({ field, fieldState }) => (
                <div>
                  <label className="block py-1 font-semibold leading-none text-slate-600">
                    Amount
                  </label>
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(e.target.valueAsNumber)}
                    icon="dollar"
                    iconPosition="left"
                    disabled={form.formState.isSubmitting}
                    value={field.value === null ? '' : field.value}
                    type="number"
                    min={0}
                    step={0.01}
                    fluid
                    error={!!fieldState.error}
                  />
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </div>
              )}
            />

            <Controller
              name="date"
              control={form.control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => (
                <div className="[&>.field>.field]:!mb-0 [&>.field]:!mb-0">
                  <label className="block py-1 font-semibold leading-none text-slate-600">
                    Date
                  </label>
                  <SemanticDatepicker
                    {...field}
                    disabled={form.formState.isSubmitting}
                    onChange={(_, { value }) => {
                      field.onChange(
                        value instanceof Date ? value.getTime() : undefined,
                      )
                    }}
                    value={field.value ? new Date(field.value) : undefined}
                    clearOnSameDateClick={false}
                    datePickerOnly
                    clearable={false}
                    required
                    error={!!fieldState.error}
                  />
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </div>
              )}
            />
          </div>

          <Controller
            name="transferFrom"
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <div className="mt-4">
                <label className="flex justify-between py-1 font-semibold leading-none text-slate-600">
                  Transfer From{' '}
                  <span className="mr-1 font-normal text-slate-500">
                    16 chars max
                  </span>
                </label>
                <Input
                  {...field}
                  disabled={form.formState.isSubmitting}
                  error={!!fieldState.error}
                  fluid
                  input={<input data-1p-ignore maxLength={16} />}
                />
                <div className="text-base leading-normal text-red-600">
                  {fieldState.error?.message}
                </div>
              </div>
            )}
          />

          <div className="mt-4">
            <label className="block py-1 font-semibold leading-none text-slate-600">
              Transfer Description
            </label>
            <textarea
              {...form.register('transferDescription', {
                required: 'Required',
              })}
              rows={2}
              style={{ minHeight: 70 }}
              className={classNames(
                'field !mb-0 placeholder:!font-semibold',
                form.formState.errors.transferDescription && 'error',
                form.formState.isSubmitting && 'disabled',
              )}
              disabled={form.formState.isSubmitting}
            />
            <div className="text-base leading-normal text-red-600">
              {form.formState.errors.transferDescription?.message}
            </div>
          </div>

          <div className="mt-4 pb-1">
            <AttachmentUploadField
              onUploadStart={() => setIsUploading(true)}
              onUploadEnd={() => setIsUploading(false)}
              registerProps={form.register('attachment')}
              onUpload={(url) => form.setValue('attachment', url)}
              onClear={() => form.resetField('attachment')}
              value={form.watch('attachment')}
            />
          </div>
        </section>

        <section className="mt-6 lg:mt-0 lg:border-l lg:pl-4">
          <header className="text-left font-semibold text-slate-600">
            Payment allocation{' '}
            <span className="block text-sm font-normal text-slate-500">
              Optionally assign invoices to this payment (can be updated later)
            </span>
          </header>

          {allocationsField.fields.length > 0 ? (
            <table className="w-full">
              <thead>
                <tr className="border-b text-sm font-semibold uppercase text-primary">
                  <th className="px-2 pb-1 pt-3.5">Invoice</th>
                  <th className="w-40 px-2 pb-1 pt-3.5 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {allocationsField.fields.map((field, index) => {
                  const currentInvoice = invoices.find(
                    (invoice) => invoice._id === field.invoiceId,
                  )
                  const isEnabled = form.watch(`allocations.${index}.enabled`)
                  return (
                    <tr key={field.id} className="border-b hover:bg-slate-50">
                      <td className="px-2 py-1.5 align-top">
                        <Controller
                          name={`allocations.${index}.enabled`}
                          control={form.control}
                          render={({
                            field: { value, onChange, ref, ...other },
                          }) => (
                            <label
                              className={classNames(
                                'flex gap-2',
                                !form.formState.isSubmitting &&
                                  'cursor-pointer',
                              )}
                            >
                              <Ref innerRef={ref}>
                                <Checkbox
                                  {...other}
                                  disabled={form.formState.isSubmitting}
                                  checked={!!value}
                                  onChange={(_, p) => onChange(p.checked)}
                                />
                              </Ref>
                              <div>
                                <span
                                  className={classNames(
                                    isEnabled
                                      ? 'text-slate-800'
                                      : 'text-slate-500',
                                  )}
                                >
                                  {currentInvoice?.name || 'Unknown invoice'}
                                  {currentInvoice?.status ===
                                    'Uncollectible' && (
                                    <>
                                      <span className="mx-0.5 text-slate-400">
                                        &ndash;
                                      </span>
                                      <InvoiceStatusLabel
                                        status="Uncollectible"
                                        small
                                      />
                                    </>
                                  )}
                                </span>
                                <div className="text-sm text-slate-500">
                                  <span className="text-xs font-semibold uppercase text-slate-400">
                                    Billed:{' '}
                                  </span>
                                  {currencyFormat(
                                    currentInvoice?.totalBilled || 0,
                                  )}
                                  {(currentInvoice?.totalPaid || 0) > 0 && (
                                    <>
                                      <span className="mx-0.5 text-slate-400">
                                        &ndash;
                                      </span>
                                      <span className="text-xs font-semibold uppercase text-slate-400">
                                        Paid:{' '}
                                      </span>
                                      {currencyFormat(
                                        currentInvoice?.totalPaid || 0,
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </label>
                          )}
                        />
                      </td>
                      <td className="px-2 py-1.5 align-top">
                        <Controller
                          name={`allocations.${index}.amount`}
                          control={form.control}
                          rules={{
                            validate: (v, formValues) => {
                              if (!formValues.allocations[index]?.enabled) {
                                return true
                              }
                              if (!v) return 'Required'
                              if (
                                Number(v) > (currentInvoice?.totalBilled || 0)
                              ) {
                                return 'Exceeds invoice total'
                              }
                              return Number(v) > 0 ? true : 'Must be positive'
                            },
                          }}
                          render={({ field: f, fieldState }) => {
                            const isDisabled =
                              form.formState.isSubmitting || !isEnabled
                            const hasError =
                              !isDisabled &&
                              (!!fieldState.error ||
                                ((form.watch('amount') || 0) > 0 &&
                                  unallocatedTotal < 0))
                            return (
                              <div>
                                <label className="sr-only">Amount</label>
                                <Input
                                  {...f}
                                  onChange={(e) =>
                                    f.onChange(e.target.valueAsNumber)
                                  }
                                  disabled={isDisabled}
                                  value={f.value === null ? '' : f.value}
                                  type="number"
                                  fluid
                                  min={0}
                                  step={0.01}
                                  icon="dollar"
                                  iconPosition="left"
                                  input={
                                    <input className="!text-right disabled:!bg-slate-100" />
                                  }
                                  error={hasError}
                                />
                                <div className="text-nowrap text-right text-sm leading-tight text-red-600">
                                  {fieldState.error?.message}
                                </div>
                              </div>
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr
                  className={classNames(
                    'text-sm',
                    form.watch('amount') || 0 ? '' : 'opacity-0',
                  )}
                >
                  {unallocatedTotal === 0 ? (
                    <td colSpan={2} className="py-2 pl-[40px] pr-2">
                      <span className="relative text-green-600">
                        <Icon
                          className="absolute -left-4 top-1"
                          name="check"
                          size="small"
                        />
                        Payment is fully allocated
                      </span>
                    </td>
                  ) : unallocatedTotal > 0 ? (
                    <>
                      <td className="py-2 pl-[40px] pr-2">
                        Unallocated payment
                      </td>
                      <td className="pl-2 pr-[22px] text-right text-slate-500">
                        {currencyFormat(unallocatedTotal)}
                      </td>
                    </>
                  ) : (
                    <td
                      colSpan={2}
                      className="py-2 pl-[40px] pr-2 text-red-500"
                    >
                      <>
                        {
                          form.formState.errors[ALLOCATION_AMOUNTS_ERROR_KEY]
                            ?.message
                        }
                      </>
                    </td>
                  )}
                </tr>
              </tfoot>
            </table>
          ) : form.watch('customerId') ? (
            <div className="mt-4 bg-slate-50 px-2 py-4 text-center text-base italic text-slate-600">
              No pending invoices for this customer!
            </div>
          ) : (
            <div className="mt-4 bg-slate-50 px-2 py-4 text-center text-base italic text-slate-600">
              Select a customer to see their pending invoices
            </div>
          )}
        </section>
      </div>

      <div className="mt-8 flex items-start gap-2 lg:mt-4 lg:border-t lg:pt-6">
        <Button
          content="Create"
          disabled={form.formState.isSubmitting || isUploading}
          loading={form.formState.isSubmitting}
          className="!mr-0"
          type="submit"
          primary
        />
        <Button
          content="Cancel"
          disabled={form.formState.isSubmitting || isUploading}
          onClick={onCancel}
          className="!mr-0"
          type="button"
          basic
        />
      </div>
    </Form>
  )
}
