import React from 'react'
import SettingsPage from './settingsPage'
import styled from 'styled-components'

const Frame = styled.iframe`
  width: 100%;
  border: dotted 1px var(--border-grey);
  height: 75vh;
  padding-top: -25px;
  border-radius: 4px;
`

const ExternalRecruitersSettings = () => {
  return (
    <SettingsPage>
      <h2>Access Control</h2>
      <p>
        External agencies we use for recruiting. This this is used to share
        positions updates:
      </p>

      <Frame
        src="https://docs.google.com/spreadsheets/d/1YjO9oSu00ajRkhrIz8jotmUZbqARFJk7rwgEOIzYTBs/edit#gid=0"
        // onLoad={() => setIsLoading(false)}
      />
    </SettingsPage>
  )
}

export default ExternalRecruitersSettings
