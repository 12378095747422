import {
  OnDragEndResponder,
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd'
import * as React from 'react'

import { Collection, FunnelColumn, RecipeSchedule, Task } from '../../types'
import { classNames, getDiffInDays } from '../../utils'
import { EnhancedContact } from '../../hooks/useContactsFunnel'

interface CardComponentProps {
  onCreateScheduleClick(): void
  onUpdateScheduleClick(schedule: RecipeSchedule): void
  onDeleteScheduleClick(schedule: RecipeSchedule): void
  onCompleteTaskClick(task: Task): void
  onUpdateTaskClick(task: Task): void
  onCreateTaskClick(): void
  onSuccess(): Promise<any>
  isRotten?: boolean
  isDragging?: boolean
  relatedCollection: Collection
  entity: EnhancedContact
}

interface Props {
  onCreateScheduleClick(cardId: string): void
  onUpdateScheduleClick(schedule: RecipeSchedule): void
  onDeleteScheduleClick(schedule: RecipeSchedule): void
  onCardStatusChange(cardId: string, newStatus: string): Promise<any>
  onCreateTaskClick(cardId: string): void
  onCompleteTaskClick(task: Task): void
  onUpdateTaskClick(task: Task): void
  relatedCollection: Collection
  CardComponent: React.ComponentType<CardComponentProps>
  columns: FunnelColumn[]
  onSuccess(): Promise<any>
  cards: EnhancedContact[]
}

export default function ContactsFunnel(props: Props) {
  const {
    onCreateScheduleClick,
    onUpdateScheduleClick,
    onDeleteScheduleClick,
    onCompleteTaskClick,
    onCardStatusChange,
    onCreateTaskClick,
    onUpdateTaskClick,
    relatedCollection,
    CardComponent,
    onSuccess,
    columns,
    cards,
  } = props

  const [cardIdsByColumn, setCardIdsByColumn] = React.useState(() =>
    cards.reduce(
      (acc, card) => ({
        ...acc,
        [card.status || '']: [...(acc[card.status || ''] || []), card._id],
      }),
      {} as Record<string, string[]>,
    ),
  )

  const handleDragEnd: OnDragEndResponder = (result) => {
    const cardId = result.draggableId
    const prevStatus = result.source.droppableId
    const newStatus = result.destination?.droppableId

    // don't do anything if the card is dropped outside the chart
    if (!newStatus) return

    const isSameColumn = newStatus === prevStatus
    // only update cards status if we're actually moving between columns
    if (!isSameColumn) onCardStatusChange(cardId, newStatus)

    setCardIdsByColumn((prev) => {
      const newIndex = result.destination?.index
      if (typeof newIndex !== 'number') return prev
      const originColumn = [...(prev[prevStatus] || [])]
      const destinationColumn = [...(prev[newStatus] || [])]
      const card = originColumn.splice(result.source.index, 1)
      if (isSameColumn) {
        originColumn.splice(newIndex, 0, ...card)
        return { ...prev, [newStatus]: originColumn }
      }
      return {
        ...prev,
        [prevStatus]: originColumn,
        [newStatus]: destinationColumn
          .slice(0, result.destination?.index)
          .concat(card)
          .concat(destinationColumn.slice(result.destination?.index)),
      }
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="relative flex grow border-t border-dotted  bg-slate-50">
        {columns.map((column, index, arr) => {
          return (
            <div
              className="flex flex-col border-r border-dotted last:border-r-0"
              style={{ width: `calc(100%/${arr.length})` }}
              key={'col-' + column.id}
            >
              <header className="sticky top-0 flex h-16 items-center justify-center overflow-hidden border-b border-dotted bg-white px-2 text-center text-sm font-semibold uppercase text-slate-700">
                {column.title}
              </header>

              <Droppable droppableId={column.id}>
                {(provided, snapshot) => {
                  return (
                    <ul
                      className={classNames(
                        'grow py-1',
                        snapshot.isDraggingOver && 'bg-slate-100',
                      )}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {(cardIdsByColumn[column.id] || []).map((cardId, i) => {
                        const card = cards.find((c) => c._id === cardId)
                        if (!card) return null
                        return (
                          <Draggable
                            draggableId={card._id}
                            index={i}
                            key={card._id}
                          >
                            {(p, s) => {
                              return (
                                <li
                                  className={classNames('px-2.5 py-1')}
                                  ref={p.innerRef}
                                  {...p.draggableProps}
                                  {...p.dragHandleProps}
                                >
                                  <CardComponent
                                    isRotten={
                                      typeof column.rotting === 'number' &&
                                      typeof card.lastStatusUpdate === 'number'
                                        ? column.rotting <=
                                          getDiffInDays(
                                            card.lastStatusUpdate,
                                            Date.now(),
                                          )
                                        : false
                                    }
                                    relatedCollection={relatedCollection}
                                    isDragging={
                                      s.isDragging && !s.isDropAnimating
                                    }
                                    entity={card}
                                    onUpdateScheduleClick={
                                      onUpdateScheduleClick
                                    }
                                    onDeleteScheduleClick={
                                      onDeleteScheduleClick
                                    }
                                    onCompleteTaskClick={onCompleteTaskClick}
                                    onUpdateTaskClick={(task) => {
                                      onUpdateTaskClick(task)
                                    }}
                                    onSuccess={onSuccess}
                                    onCreateScheduleClick={() => {
                                      onCreateScheduleClick(card._id)
                                    }}
                                    onCreateTaskClick={() => {
                                      onCreateTaskClick(card._id)
                                    }}
                                  />
                                </li>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ul>
                  )
                }}
              </Droppable>
            </div>
          )
        })}
      </div>
    </DragDropContext>
  )
}
