import React, {
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  ReactNode,
  useState,
} from 'react'
import { Input, Button, Form } from 'semantic-ui-react'

import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'
import { useApi } from '../../store/mainContext'

interface Props {
  name: string
  label: ReactNode
  placeholder?: string
  url: string
  type?: HTMLInputTypeAttribute | 'textarea'
  value: any
  errors?: string[]
  onSuccess(property: string, value: any): void
  onBlur?(): void
  disabled?: boolean
  readOnly?: boolean
  number?: boolean
  render?(value: Props['value']): ReactNode
}

const TrInput = ({
  name,
  label,
  placeholder,
  errors,
  value,
  onSuccess,
  onBlur,
  type = 'text',
  url,
  number,
  readOnly,
  disabled,
  render: renderFn,
}: Props) => {
  const api = useApi()

  const [showEdit, setShowEdit] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [, setCancelOnBlur] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [val, setVal] = useState(value)

  // default render
  const render = renderFn || ((x) => x)

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj: Record<string, any> = {}

    if (number) {
      const numberVal = Number(val)
      obj[name] = Number.isNaN(numberVal) ? null : numberVal
    } else {
      obj[name] = val
    }

    await api.patch(url, obj)

    setIsLoading(false)
    setEditMode(false)
    setShowAdd(false)

    onSuccess && onSuccess(name, obj[name])
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setShowEdit(false)
    setEditMode(false)
    setIsLoading(false)
  }

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') handleSave()
  }

  if (editMode) {
    return (
      <tr>
        <th>{label}</th>
        <td style={{ paddingTop: 4, textAlign: `right` }}>
          {type === 'textarea' && (
            <Form>
              <Form.TextArea
                name={name}
                placeholder={placeholder}
                error={
                  (errors || []).indexOf(name) !== -1 &&
                  'This field is required.'
                }
                value={val || ''}
                onChange={(e) => setVal(e.target.value)}
                autoFocus={true}
                onBlur={onBlur}
                type={type}
                disabled={isLoading}
                onKeyPress={handleKeyPress}
                onFocus={(e: FocusEvent) =>
                  (e.target as HTMLTextAreaElement)?.select()
                }
                style={{ resize: `none`, fontSize: 14, height: 270 }}
              />
            </Form>
          )}

          {type !== 'textarea' && (
            <Input
              name={name}
              placeholder={placeholder}
              error={(errors || []).indexOf(name) !== -1}
              value={val === 0 ? 0 : val || ''}
              onChange={(e) =>
                setVal(number ? e.target.valueAsNumber : e.target.value)
              }
              autoFocus={true}
              onBlur={onBlur}
              type={number ? 'number' : type}
              style={{ width: '100%' }}
              disabled={isLoading}
              onKeyPress={handleKeyPress}
              onFocus={(e: FocusEvent) =>
                (e.target as HTMLTextAreaElement)?.select()
              }
            />
          )}

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (['', null, undefined, NaN].includes(val)) {
    if (readOnly || disabled) {
      return (
        <tr>
          <th>{label}</th>
          <td></td>
        </tr>
      )
    }

    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
      >
        <th>{label}</th>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <td>{showAdd && <a>+ Add value</a>}&nbsp;</td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <tr
        className="align-baseline"
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <th>{label}</th>
        <td style={{ position: 'relative' }}>
          {render(val)}

          <TrCopyButton visible={showEdit} value={val} />
          {!readOnly && !disabled && (
            <TrEditButton
              onClick={() => setEditMode(true)}
              visible={showEdit}
            />
          )}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default TrInput
