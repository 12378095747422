import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Button } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import { classNames } from '../../utils'

type FormValues = { date: number }

function Field(props: {
  datePickerProps?: Partial<SemanticDatepickerProps>
  onCancel(): void
  onSubmit: SubmitHandler<FormValues>
  value?: number
}) {
  const { onCancel, onSubmit, datePickerProps } = props

  const form = useForm<FormValues>({
    defaultValues: { date: props.value },
  })

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      spellCheck="false"
      className="[&>.field]:!block"
    >
      <Controller
        name="date"
        control={form.control}
        render={({ field, fieldState }) => (
          <SemanticDatepicker
            {...field}
            value={field.value ? new Date(field.value) : undefined}
            onChange={(_, { value }) => {
              field.onChange(
                value instanceof Date ? value.getTime() : undefined,
              )
            }}
            datePickerOnly
            className="w-full"
            clearOnSameDateClick={false}
            clearable
            pointing="right"
            autoFocus
            error={!!fieldState.error}
            {...datePickerProps}
          />
        )}
      />

      <div className="col-span-2 flex justify-end gap-1 py-1">
        <Button
          onClick={onCancel}
          content="Cancel"
          className="!mr-0"
          size="tiny"
          compact
          type="button"
        />
        <Button
          content="Save"
          className="!mr-0"
          primary
          size="tiny"
          compact
          type="submit"
        />
      </div>
    </form>
  )
}

interface Props {
  datePickerProps?: Partial<SemanticDatepickerProps>
  label: string
  value?: number
  onChange(newValue: number): Promise<any>
  onClear(): Promise<any>
  isEditable?: boolean
}

export default function DateForm(props: Props) {
  const [isEditing, setIsEditing] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async ({ date }) => {
    setIsEditing(false)
    return date ? props.onChange(date) : props.onClear()
  }

  return (
    <div
      className={classNames(
        'group grid items-start gap-x-4',
        props.isEditable ? 'grid-cols-[80px_1fr]' : 'grid-cols-[80px_auto]',
      )}
    >
      <label className="relative top-px block text-sm font-semibold uppercase leading-[38px] text-slate-500">
        {props.label}
      </label>

      {!isEditing && (
        <div className="relative leading-[38px]">
          {props.value ? (
            <span className="text-base">
              {format(props.value, 'yyyy-MM-dd')}
            </span>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="text-primary"
              type="button"
            >
              + Add value
            </button>
          )}

          {props.isEditable && (
            <div className="absolute -top-px right-0 opacity-0 group-hover:opacity-100">
              <Button
                onClick={() => setIsEditing(true)}
                className={classNames('!mr-0', !props.value && '!hidden')}
                icon="pencil"
                size="mini"
                compact
                basic
              />
            </div>
          )}
        </div>
      )}

      {isEditing && (
        <Field
          onSubmit={onSubmit}
          onCancel={() => setIsEditing(false)}
          value={props.value}
          datePickerProps={props.datePickerProps}
        />
      )}
    </div>
  )
}
