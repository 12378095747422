import { Loader, Dropdown, Popup } from 'semantic-ui-react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { isPast, isSameDay } from 'date-fns'
import { partition } from 'ramda'
import * as React from 'react'
import format from 'date-fns/format'

import { Candidate, Contract, TimeoffRequest } from '../../types'
import { classNames, currencyFormat } from '../../utils'
import TimeoffRequestUpdateDialog from './TimeoffRequestUpdateDialog'
import useTimeoffRequests from '../../hooks/useTimeoffRequests'
import useTimeoffCoverage from '../../hooks/useTimeoffCoverage'
import useTimeoffBalance from '../../hooks/useTimeoffBalance'
import RelatedNotesPopup from '../RelatedNotesPopup'

interface ItemProps {
  timeoffRequest: TimeoffRequest
}

function Item(props: ItemProps) {
  const coverage = useTimeoffCoverage(props.timeoffRequest._id)

  const totalCost = (coverage.data || []).reduce(
    (sum, { dayValue }) => sum + (dayValue || 0),
    0,
  )

  return (
    <>
      <div className="inline-flex flex-wrap gap-x-2 pr-6">
        {props.timeoffRequest.dates.map((day, i) => {
          const tx = (coverage.data || []).find(
            (item) => isSameDay(item.date, day) && !item.coveredByPtoPolicy,
          )
          const isNotCovered = Boolean(tx)
          return (
            <span
              className={classNames(
                isNotCovered
                  ? 'cursor-help text-red-700'
                  : isPast(day)
                    ? 'text-slate-300'
                    : 'text-slate-600',
                'whitespace-nowrap text-base',
              )}
              key={day.toISOString()}
            >
              <Popup
                size="mini"
                content={
                  <div>
                    Not covered by PTO policy
                    {typeof tx?.dayValue === 'number' && (
                      <div>Cost: {currencyFormat(tx.dayValue)}</div>
                    )}
                  </div>
                }
                position="top center"
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                disabled={!isNotCovered}
                trigger={
                  <span
                    className={classNames(
                      isNotCovered &&
                        'underline decoration-red-600 decoration-dotted underline-offset-4',
                    )}
                  >
                    {format(day, 'MMM do')}
                  </span>
                }
              />

              {i < props.timeoffRequest.dates.length - 1 && (
                <span className="text-slate-800">,</span>
              )}
            </span>
          )
        })}
      </div>

      {totalCost > 0 && (
        <div className="-mb-0.5 mt-1 text-xs text-red-800/80">
          Total Cost: {currencyFormat(totalCost)}
        </div>
      )}

      <div className="relative flex justify-between gap-2">
        <div className="-ml-1.5 rounded-md px-1.5 pt-1 text-xs font-semibold">
          {props.timeoffRequest.status}
        </div>
        <span className="absolute -top-px right-0 text-base">
          <RelatedNotesPopup
            relatedCollection="timeoff-requests"
            relatedId={props.timeoffRequest._id}
          />
        </span>
      </div>
    </>
  )
}

interface Props {
  candidateId: Candidate['_id']
  contractId: Contract['_id']
}

export default function TimeoffRequests(props: Props) {
  const [selectedRequest, setSelectedRequest] =
    React.useState<TimeoffRequest | null>(null)

  const [isApprovedVisible, setIsApproveVisible] = React.useState(false)
  const balance = useTimeoffBalance(props.candidateId)

  const requests = useTimeoffRequests(props.contractId, {
    status: { $in: ['Approved', 'Waiting for approval'] },
  })

  const [approved, pending] = partition<TimeoffRequest>(
    (r) => r.status === 'Approved',
  )(requests.data || [])

  return (
    <div className="pb-1">
      {balance.data && (
        <dl className="mx-6 mb-5 mt-3 grid grid-cols-3 divide-x divide-slate-300 rounded-xl bg-slate-100 py-4">
          <div className="flex flex-col justify-between py-1">
            <dt className="px-1 text-center text-xs font-semibold uppercase text-slate-500 [letter-spacing:0.5px]">
              Accrued
            </dt>
            <dd className="px-1 text-center text-2xl text-green-600">
              {balance.data.accrued}
            </dd>
          </div>

          <div className="flex flex-col justify-between py-1">
            <dt className="px-1 text-center text-xs font-semibold uppercase text-slate-500 [letter-spacing:0.5px]">
              Carried over
            </dt>
            <dd className="px-1 text-center text-2xl">
              <span className="text-green-600">
                {balance.data.carriedOverFromLastYear > 0 && (
                  <span className="relative -top-0.5 text-xl">+</span>
                )}
                {balance.data.carriedOverFromLastYear}
              </span>
            </dd>
          </div>

          <div className="flex flex-col justify-between py-1">
            <dt className="px-1 text-center text-xs font-semibold uppercase text-slate-500 [letter-spacing:0.5px]">
              Consumed
            </dt>
            <dd
              className={classNames(
                'px-1 text-center text-2xl',
                balance.data.consumed > 0
                  ? 'text-orange-500'
                  : 'text-slate-500',
              )}
            >
              {balance.data.consumed}
            </dd>
          </div>
        </dl>
      )}

      {(pending.length > 0 || isApprovedVisible) && (
        <div>
          <h2 className="mb-0 mt-1 pl-9 text-base font-medium">
            Time-off Requests
          </h2>
          <ol className="-ml-3 mr-6 mt-2 space-y-3 pl-9">
            {pending.length > 0 &&
              pending.map((pto) => {
                return (
                  <li
                    className={classNames(
                      'rounded-md px-3 pb-2.5 pt-2 text-base ',
                      pto.status === 'Waiting for approval' &&
                        'bg-orange-100 text-amber-700',
                    )}
                    key={pto._id}
                  >
                    <Item timeoffRequest={pto} />
                  </li>
                )
              })}
            {isApprovedVisible &&
              approved.map((pto) => {
                if (pto.dates.length === 0) return null
                return (
                  <li
                    className="relative rounded-md bg-green-100 px-3 pb-2.5 pt-2 text-base text-green-800"
                    key={pto._id}
                  >
                    <Item timeoffRequest={pto} />
                    <div className="absolute right-2 top-1.5">
                      <Dropdown
                        pointing="top right"
                        icon="ellipsis horizontal"
                        basic
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            disabled={pto.dates.length <= 1}
                            text="Edit Dates"
                            icon="pencil"
                            onClick={() => setSelectedRequest(pto)}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                )
              })}
          </ol>
        </div>
      )}

      {!isApprovedVisible && approved.length > 0 && (
        <div className="mt-4 px-4">
          <button
            className="ml-6 text-blue-800 underline hover:text-blue-700"
            onClick={() => setIsApproveVisible(true)}
            type="button"
          >
            View approved {pending.length === 0 ? 'requests' : ''}
          </button>
        </div>
      )}

      {!requests.data && !requests.error && (
        <div className="text-center">
          <Loader active size="mini" inline />
        </div>
      )}

      {requests.error && !requests.data && (
        <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {requests.error.message}
        </div>
      )}

      {!!selectedRequest && (
        <TimeoffRequestUpdateDialog
          onConfirm={requests.mutate}
          onCancel={() => setSelectedRequest(null)}
          request={selectedRequest}
        />
      )}
    </div>
  )
}
