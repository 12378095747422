import * as React from 'react'

import SmallUserEvent from './SmallUserEvent'
import PositionLink from '../../remoteValues/positionLink'
import RemoteValue from '../../remoteValues/remoteValue'
import { Event } from '../../../types'

interface Props {
  event: Event
}

export default function PositionChangeEvent(props: Props) {
  const { event } = props
  const { fromPositionId, toPositionId } = event

  if (!fromPositionId && !toPositionId) return null

  if (toPositionId && !fromPositionId) {
    return (
      <SmallUserEvent event={event}>
        <PositionLink positionId={toPositionId} /> was assigned to{' '}
        <RemoteValue
          collection={event.relatedCollection}
          id={event.relatedId}
          predicate={(c) => c.name}
        />
        .
      </SmallUserEvent>
    )
  }

  if (!toPositionId && fromPositionId) {
    return (
      <SmallUserEvent event={event}>
        <PositionLink positionId={fromPositionId} /> was removed from{' '}
        <RemoteValue
          collection={event.relatedCollection}
          id={event.relatedId}
          predicate={(c) => c.name}
        />
        .
      </SmallUserEvent>
    )
  }

  return (
    <SmallUserEvent event={event}>
      <RemoteValue
        collection={event.relatedCollection}
        id={event.relatedId}
        predicate={(c) => c.name}
      />{' '}
      <b>changed</b> positions from{' '}
      <PositionLink positionId={fromPositionId!} /> to{' '}
      <PositionLink positionId={toPositionId!} />.
    </SmallUserEvent>
  )
}
