import React, { useState } from 'react'

import { Recipe, recipeSchema, RecipeStep } from '../../types'
import GenericStep from './steps/GenericStep'

import { WorkingSpace } from './ui/GUIElements'

import Step0 from './steps/Step0'

import PageHeader from '../pageHeader/pageHeader'
import EditableValue from './ui/EditableValue'
import { Button, Checkbox } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router-dom'
import { useApi } from '../../store/mainContext'
import useSWR from 'swr'
import { validateResponse } from '../../utils'
import ConfirmDeleteRecipeDialog from './ConfirmDeleteRecipeDialog'
import AddStepButton from './steps/AddStepButton'

type Dialog = { type: 'delete-recipe'; recipeId: Recipe['_id'] }

const StepDistpatcher = ({
  index,
  step,
  recipe,
  onSave,
  funcs,
}: {
  index: number
  step: RecipeStep
  recipe: Recipe
  onSave: (values: Partial<Recipe>) => {}
  funcs: string[]
}) => {
  switch (step.type) {
    // case 'getById':
    //   return <GetByIdStep index={index} step={step} recipe={recipe} />
    default:
      return (
        <GenericStep
          index={index}
          step={step}
          recipe={recipe}
          onSave={onSave}
          funcs={funcs}
        />
      )
  }
}

export default function RecipeEditorGUI() {
  const { recipeId } = useParams<{ recipeId?: string }>()

  if (!recipeId) {
    throw new Error('`recipeId` param must be present in the URL.')
  }

  const history = useHistory()
  const api = useApi()

  const { data, mutate } = useSWR(['recipe', recipeId], async () =>
    Promise.all([
      api.get('recipes/' + recipeId).then(validateResponse(recipeSchema)),
    ]).then(([recipe]) => ({ recipe })),
  )

  const { data: funcs } = useSWR(['funcs'], async () =>
    api.get('recipes/funcs'),
  )

  const [currentDialog, setCurrentDialog] = useState<Dialog | null>(null)

  const handleUpdate = async (values: Partial<Recipe>) => {
    await api.patch(`/recipes/${recipeId}`, values)
    await mutate()
  }

  if (!data || !data.recipe) {
    return <div>Loading...</div>
  }

  return (
    <>
      {/* <div className="relative">
        <Button
          className="!absolute right-0 top-[-60px] z-10 !mr-0"
          content={
            <>
              <span>
                Save
                <span className="pl-4 text-sm font-semibold opacity-70">
                  ⇧⌘S
                </span>
              </span>
            </>
          }
          type="submit"
          disabled={isSaving}
          loading={isSaving}
          primary
        />
      </div> */}

      <PageHeader
        title={
          <EditableValue
            type="input"
            className="min-w-[500px] max-w-[800px] text-4xl"
            value={data.recipe.name}
            onSave={(value) => handleUpdate({ name: value })}
          />
        }
        sub={
          <EditableValue
            type="textarea"
            className="min-w-[500px] max-w-[800px] leading-normal text-slate-500"
            value={data.recipe.description || ''}
            onSave={(value) => handleUpdate({ description: value })}
          />
        }
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Recipes', link: '/recipes' },
          { text: data.recipe.name || 'Unnamed Recipe' },
        ]}
        actions={
          <>
            <Button
              icon="trash alternate outline"
              onClick={() => {
                setCurrentDialog({ type: 'delete-recipe', recipeId })
              }}
              className="!mr-0"
              color="red"
              basic
            />
          </>
        }
      />

      <div className="flex">
        <WorkingSpace>
          <div className="absolute right-10 mr-10">
            <Checkbox
              toggle
              checked={data.recipe.triggerEnable}
              className="scale-125"
            />
          </div>

          <Step0
            recipe={data.recipe}
            onSave={(values) => handleUpdate(values)}
          />

          {data.recipe.steps?.map((step, i) => (
            <React.Fragment key={`${i}-${step.name}=${step.type}`}>
              <AddStepButton
                recipe={data.recipe}
                index={i}
                onAddStep={(values) => handleUpdate(values)}
              />

              <StepDistpatcher
                step={step}
                index={i + 1}
                recipe={data.recipe}
                onSave={handleUpdate}
                funcs={funcs?.data}
              />

              {/* {i + 1 !== (data.recipe.steps || []).length && <Arrow />} */}
            </React.Fragment>
          ))}

          <AddStepButton
            recipe={data.recipe}
            index={data.recipe.steps?.length || 0}
            onAddStep={(values) => handleUpdate(values)}
            isLastOne={true}
          />
        </WorkingSpace>

        {/* <div className="w-[500px]">
          <Editor
            defaultLanguage="json"
            height="calc(100vh)"
            theme="vs-dark"
            options={{
              padding: { top: 5 },
              wordWrap: 'on',
              fontSize: 14,
              readOnly: true,
            }}
            value={JSON.stringify(data.recipe, null, 2)}
          />
        </div> */}
      </div>

      <ConfirmDeleteRecipeDialog
        open={currentDialog?.type === 'delete-recipe'}
        onSuccess={() => history.replace('/recipes')}
        onCancel={() => setCurrentDialog(null)}
        recipeId={recipeId}
      />
    </>
  )
}
