import {
  DropdownItemProps,
  Dropdown,
  Button,
  Popup,
  Input,
  Icon,
} from 'semantic-ui-react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import useSWR, { useSWRConfig } from 'swr'
import { groupBy, path } from 'ramda'
import { AxiosError } from 'axios'
import FuzzySearch from 'fuzzy-search'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import * as React from 'react'
import { z } from 'zod'

import {
  paymentOrderWithTransactionsSchema,
  PaymentOrderTransactionStatus,
  PaymentOrderTransaction,
  candidateSchema,
  supplierSchema,
  accountSchema,
  PaymentOrder,
  Candidate,
} from '../../types'
import ConfirmCompletePaymentOrderModal from '../../components/payment-orders/ConfirmCompletePaymentOrderModal'
import { classNames, validateResponse } from '../../utils'
import ConfirmRemovePaymentOrderModal from '../../components/payment-orders/ConfirmRemovePaymentOrderModal'
import ConfirmMarkAsProcessingModal from '../../components/payment-orders/ConfirmMarkAsProcessingModal'
import ConfirmGenerateDebitsModal from '../../components/payment-orders/ConfirmGenerateDebitsModal'
import ConfirmRemoveCustomTxModal from '../../components/payment-orders/ConfirmRemoveCustomTxModal'
import TransactionsSummaryWidget from '../../components/payment-orders/TransactionsSummaryWidget'
import PaymentOrderStatusLabel from '../../components/payment-orders/PaymentOrderStatusLabel'
import TransactionStatusLabel from '../../components/payment-orders/TransactionStatusLabel'
import PayoneerExportButton from '../../components/payment-orders/PayoneerExportButton'
import AmountsSummaryWidget from '../../components/payment-orders/AmountsSummaryWidget'
import AddCustomDebitForm from '../../components/payment-orders/AddCustomDebitForm'
import PaymentOrderRow, {
  ExtraFields,
} from '../../components/payment-orders/PaymentOrderRow'
import { useToasts } from '../../components/toasts/ToastsProvider'
import SimpleFilter from '../../components/filters/simpleFilter'
import PayoneerLogo from '../../components/PayoneerLogo'
import { Summary } from '../../components/payment-orders/PaymentOrderProgressBar'
import StringField from '../../components/form/StringField'
import UsaBankIcon from '../../components/UsaBankIcon'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import UserName from '../../components/remoteValues/userName'
import BofaLogo from '../../components/BofaLogo'
import Feed from '../../components/feeds/feed'

type Dialog =
  | { type: 'delete-custom-tx'; txId: PaymentOrderTransaction['_id'] }
  | { type: 'complete-payment-order' }
  | { type: 'delete-payment-order' }
  | { type: 'mark-as-processing' }
  | { type: 'generate-debits' }

type Sort = { by: 'beneficiary'; dir: 'asc' | 'desc' }

function isPayoneer(tx: PaymentOrderTransaction & ExtraFields) {
  return tx.bankAccount?.type === 'payoneer'
}

function isBankOfAmerica(tx: PaymentOrderTransaction & ExtraFields) {
  return (
    tx.bankAccount?.type === 'usa' &&
    (tx.bankAccount?.bankName || '').toLocaleLowerCase() === 'bank of america'
  )
}

function isOtherUsa(tx: PaymentOrderTransaction & ExtraFields) {
  return tx.bankAccount?.type === 'usa' && !isBankOfAmerica(tx)
}

function isInternational(tx: PaymentOrderTransaction & ExtraFields) {
  return tx.bankAccount?.type === 'international'
}

function getAccountFilterOptions(
  txs: (PaymentOrderTransaction & ExtraFields)[],
): DropdownItemProps[] {
  return [
    {
      value: 'any',
      text: 'Any bank account',
      description: `${txs.length} transactions`,
    },
    {
      value: 'usa-bofa',
      text: 'Bank of America accounts',
      content: (
        <>
          <BofaLogo className="relative -left-1 mr-px inline-block w-6 text-slate-600" />{' '}
          Bank of America accounts
        </>
      ),
      description: `${txs.filter(isBankOfAmerica).length} transactions`,
    },
    {
      value: 'usa-other',
      text: 'Other USA bank accounts',
      content: (
        <>
          <UsaBankIcon className="mr-[3.5px] inline-block w-5 text-slate-600" />{' '}
          Other USA accounts
        </>
      ),
      description: `${txs.filter(isOtherUsa).length} transactions`,
    },
    {
      value: 'international',
      text: 'International bank accounts',
      content: (
        <>
          <Icon name="globe" color="grey" className="scale-110" />{' '}
          <span className="-ml-[5px]">International accounts</span>
        </>
      ),
      description: `${txs.filter(isInternational).length} transactions`,
    },
    {
      value: 'payoneer',
      text: 'Payoneer accounts',
      content: <PayoneerLogo className="inline-block w-24 align-text-bottom" />,
      description: `${txs.filter(isPayoneer).length} transactions`,
    },
  ]
}

function getSummary(txs: (PaymentOrderTransaction & ExtraFields)[]): Summary {
  const result: Summary = {
    total: 0,
    custom: 0,
    statuses: {
      Completed: 0,
      Approved: 0,
      Dismissed: 0,
      Processing: 0,
      Canceled: 0,
      Draft: 0,
    },
    amounts: { total: 0, Approved: 0, payoneer: 0, usa: 0, international: 0 },
  }
  txs.forEach((tx) => {
    if (tx.type !== 'debit') return
    result.statuses[tx.status] = (result.statuses[tx.status] || 0) + 1
    if (!tx.generatedByPaymentOrder) result.custom++
    result.total++
    // calculate amounts
    result.amounts.total += tx.amount
    if (
      tx.status === 'Processing' ||
      tx.status === 'Completed' ||
      tx.status === 'Approved'
    ) {
      result.amounts.Approved += tx.amount
      if (tx.bankAccount?.type === 'international') {
        result.amounts.international += tx.amount
      }
      if (tx.bankAccount?.type === 'payoneer') {
        result.amounts.payoneer += tx.amount
      }
      if (tx.bankAccount?.type === 'usa') {
        result.amounts.usa += tx.amount
      }
    }
  })
  return result
}

export default function PaymentOrderPage() {
  const { paymentOrderId } = useParams<{ paymentOrderId?: string }>()

  if (!paymentOrderId) {
    throw new Error('`paymentOrderId` param must be present in the URL.')
  }

  const { mutate } = useSWRConfig()

  const refreshFeed = () =>
    mutate(['events-feed', 'payment-orders', paymentOrderId])

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isDeletingTx, setIsDeletingTx] = React.useState(false)

  const [customTx, setCustomTx] = React.useState<{
    beneficiaryType: PaymentOrderTransaction['beneficiaryType']
    beneficiaryId: PaymentOrderTransaction['beneficiaryId']
  } | null>(null)

  const handleTransactionUpdate = async (
    id: PaymentOrderTransaction['_id'],
    status: PaymentOrderTransactionStatus,
  ) => {
    if (!paymentOrder.data) return
    return paymentOrder.mutate(
      () => api.patch('/payment-transactions/' + id, { status }),
      {
        optimisticData: {
          ...paymentOrder.data,
          transactions: paymentOrder.data.transactions.map((tx) =>
            tx._id === id ? { ...tx, status } : tx,
          ),
        },
        populateCache: false,
        rollbackOnError: true,
      },
    )
  }

  const [isCustomTxFormVisible, setIsCustomTxFormVisible] =
    React.useState(false)

  const handleDeleteCustomTx = async () => {
    if (currentDialog?.type !== 'delete-custom-tx') return
    setIsDeletingTx(true)
    return api
      .delete('payment-transactions/' + currentDialog.txId)
      .then(() => Promise.allSettled([refreshFeed(), paymentOrder.mutate()]))
      .then(() => setCurrentDialog(null))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsDeletingTx(false))
  }

  const [sort, setSort] = React.useState<Sort>({
    by: 'beneficiary',
    dir: 'asc',
  })

  const paymentOrder = useSWR(['payment-order', paymentOrderId], () =>
    api
      .get('payment-orders/' + paymentOrderId)
      .then(validateResponse(paymentOrderWithTransactionsSchema))
      // get all related entities for the current payment order transactions
      .then((o) =>
        Promise.all([
          Promise.resolve(o),
          api
            .get('candidates/in', {
              params: { _id: o.transactions.map((tx) => tx.beneficiaryId) },
            })
            .then(validateResponse(z.array(candidateSchema))),
          api
            .get('suppliers/in', {
              params: { _id: o.transactions.map((tx) => tx.beneficiaryId) },
            })
            .then(validateResponse(z.array(supplierSchema))),
          api
            .get('accounts/in', {
              params: { _id: o.transactions.map((tx) => tx.bankAccountId) },
            })
            .then(validateResponse(z.array(accountSchema))),
        ]),
      )
      .then(([o, candidates, suppliers, accounts]) => ({
        ...o,
        // enhance payment order transactions with related entities data
        transactions: o.transactions.map((tx) => {
          const bankAccount = accounts.find((a) => a._id === tx.bankAccountId)
          const candidate = candidates.find((c) => c._id === tx.beneficiaryId)
          const supplier = suppliers.find((c) => c._id === tx.beneficiaryId)
          const beneficiary = candidate?.name || supplier?.name
          return { ...tx, candidate, supplier, bankAccount, beneficiary }
        }),
      })),
  )

  const handleSaveField = async (data: Partial<PaymentOrder>) => {
    return api
      .patch(`/payment-orders/${paymentOrderId}`, data)
      .then(() => paymentOrder.mutate())
      .then(refreshFeed)
  }

  const [beneficiarySearch, setBeneficiarySearch] = React.useState<
    string | null
  >(null)

  const [statusFilter, setStatusFilter] = React.useState<
    PaymentOrderTransaction['status'] | null
  >(null)

  const [accountFilter, setAccountFilter] = React.useState<
    'international' | 'usa-bofa' | 'usa-other' | 'payoneer' | 'any'
  >('any')

  const [isCompletingPaymentOrder, setIsCompletingPaymentOrder] =
    React.useState(false)

  const [isMarkingAsProcessing, setIsMarkingAsProcessing] =
    React.useState(false)

  const handleDelete = async () => {
    setIsDeleting(true)
    return api
      .delete('payment-orders/' + paymentOrderId)
      .then(() => history.push('/payment-orders'))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsDeleting(false))
  }

  // Payment order row cells are combined to visually group by beneficiary.
  // In order to highlight all the beneficiary rows when hovering a group, we
  // need to track the beneficiary being hovered.
  const [hoveredBeneficiary, setHoveredBeneficiary] =
    React.useState<Candidate['_id']>('')

  if (paymentOrder.isLoading) return <PageLoader />

  if (!paymentOrder.data) {
    addToast('Payment Order not found', { variant: 'danger' })
    return <Redirect to="/payment-orders" />
  }

  // fuzzy search by transaction beneficiary name
  const filteredTx = new FuzzySearch(paymentOrder.data.transactions || [], [
    'candidate.name',
    'supplier.name',
  ])
    .search(beneficiarySearch || '')
    // also filter by transaction status
    .filter((tx) => {
      return statusFilter === null || tx.status === statusFilter
    })
    .filter((tx) => {
      return (
        accountFilter === 'any' ||
        (accountFilter === 'international' && isInternational(tx)) ||
        (accountFilter === 'usa-bofa' && isBankOfAmerica(tx)) ||
        (accountFilter === 'usa-other' && isOtherUsa(tx)) ||
        (accountFilter === 'payoneer' && isPayoneer(tx))
      )
    })

  // group transactions by beneficiary (we want 1 row for each beneficiary)
  const filteredTxByBeneficiary = groupBy((tx) => tx.beneficiaryId, filteredTx)

  // sort the already grouped and filtered transactions
  const sortedTxs = [...Object.entries(filteredTxByBeneficiary)].sort(
    ([, txsA], [, txsB]) => {
      const compA =
        path<string>([0, sort.by], sort.dir === 'asc' ? txsA : txsB) || ''
      const compB =
        path<string>([0, sort.by], sort.dir === 'asc' ? txsB : txsA) || ''
      return compA.localeCompare(compB)
    },
  )

  const handleMarkAsProcessing = async () => {
    setIsMarkingAsProcessing(true)
    return api
      .patch('payment-orders/' + paymentOrderId, { status: 'Processing' })
      .then(() => Promise.allSettled([refreshFeed(), paymentOrder.mutate()]))
      .then(() => setCurrentDialog(null))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsMarkingAsProcessing(false))
  }

  const handleCompletePaymentOrder = async () => {
    setIsCompletingPaymentOrder(true)
    return api
      .patch('payment-orders/' + paymentOrderId, { status: 'Completed' })
      .then(() => Promise.allSettled([refreshFeed(), paymentOrder.mutate()]))
      .then(() => setCurrentDialog(null))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsCompletingPaymentOrder(false))
  }

  const canGenerateDebits = !paymentOrder.data.transactions.some(
    (tx) => !!tx.generatedByPaymentOrder,
  )

  const canExportPayoneerCsv =
    paymentOrder.data.status !== 'Draft' &&
    paymentOrder.data.transactions.some(
      (tx) =>
        tx.bankAccount?.type === 'payoneer' &&
        (tx.status === 'Approved' || tx.status === 'Completed'),
    )

  const canDelete = paymentOrder.data.status !== 'Completed'

  const canMarkAsProcessing =
    paymentOrder.data.transactions.length > 0 &&
    paymentOrder.data.status === 'Draft' &&
    paymentOrder.data.transactions.every(
      (tx) => tx.status === 'Dismissed' || tx.status === 'Approved',
    )

  const canMarkAsComplete =
    paymentOrder.data.transactions.length > 0 &&
    paymentOrder.data.status === 'Processing' &&
    paymentOrder.data.transactions.every(
      (tx) =>
        tx.status === 'Completed' ||
        tx.status === 'Canceled' ||
        tx.status === 'Dismissed',
    )

  const title = paymentOrder.data.name || 'Unnamed Payment Order'

  const summary = getSummary(paymentOrder.data.transactions)

  const accountFilterOptions = getAccountFilterOptions(
    paymentOrder.data.transactions,
  )
  const selectedAccountOption = accountFilterOptions.find(
    (option) => option.value === accountFilter,
  )

  return (
    <>
      <div className="flex h-screen justify-between">
        <Helmet>
          <title>{title} - Payment Orders</title>
        </Helmet>

        <div className="sticky top-0 grow overflow-y-auto [&::-webkit-scrollbar]:hidden">
          <PageHeader
            title={
              paymentOrder.data.status === 'Completed' ? (
                paymentOrder.data.name
              ) : (
                <div className="group -ml-2 -mt-2 rounded px-2 pb-1 pt-2 hover:bg-slate-100 has-[form]:bg-slate-100">
                  <StringField
                    renderValue={(val) => (
                      <h1 className="mb-1 text-3xl">{val}</h1>
                    )}
                    inputProps={{
                      placeholder: 'Add a title...',
                      required: true,
                    }}
                    initialValue={paymentOrder.data.name || ''}
                    onClear={() => handleSaveField({ name: '' })}
                    onSave={(name) => handleSaveField({ name })}
                  />
                </div>
              )
            }
            sub={
              paymentOrder.data.status === 'Completed' ? (
                <p className="pb-3.5 pt-1.5 text-slate-500">
                  {paymentOrder.data.description}
                </p>
              ) : (
                <div className="group -ml-2 -mt-2 rounded px-2 pb-1.5 hover:bg-slate-100 has-[form]:bg-slate-100 has-[form]:pb-0.5 has-[form]:pt-2 has-[p]:pt-1.5">
                  <StringField
                    renderValue={(val) => (
                      <p className="m-0 pb-2 pt-2 text-slate-500">{val}</p>
                    )}
                    inputProps={{ placeholder: 'Add a description...' }}
                    initialValue={paymentOrder.data.description || ''}
                    onClear={() => handleSaveField({ description: '' })}
                    onSave={(description) => handleSaveField({ description })}
                    addBtnLabel="+ Add a description"
                  />
                </div>
              )
            }
            breadcrumb={[
              { text: 'Dashboard', link: '/' },
              { text: 'Payment Orders', link: '/payment-orders' },
              { text: title },
            ]}
            actions={
              <div className="flex min-h-[33.41px] shrink-0 gap-2">
                {canGenerateDebits && (
                  <Button
                    onClick={() =>
                      setCurrentDialog({ type: 'generate-debits' })
                    }
                    type="button"
                    size="small"
                    content="Generate Debits"
                    className="!mr-0 text-nowrap"
                  />
                )}

                {paymentOrder.data.status === 'Draft' && (
                  <Popup
                    content="Cannot mark as Processing until every transaction is either Approved or Dismissed."
                    position="left center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    disabled={canMarkAsProcessing}
                    trigger={
                      <div
                        className={classNames(
                          !canMarkAsProcessing && 'cursor-not-allowed',
                        )}
                      >
                        <Button
                          onClick={() =>
                            setCurrentDialog({ type: 'mark-as-processing' })
                          }
                          disabled={!canMarkAsProcessing}
                          type="button"
                          size="small"
                          primary
                          content="Mark as Processing"
                          className="!mr-0 text-nowrap"
                        />
                      </div>
                    }
                  />
                )}

                {paymentOrder.data.status === 'Processing' && (
                  <Popup
                    content="Cannot mark Payment Order as complete until every transaction is either Completed or Canceled."
                    position="left center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    disabled={canMarkAsComplete}
                    trigger={
                      <div
                        className={classNames(
                          !canMarkAsComplete && 'cursor-not-allowed',
                        )}
                      >
                        <Button
                          onClick={() =>
                            setCurrentDialog({
                              type: 'complete-payment-order',
                            })
                          }
                          disabled={!canMarkAsComplete}
                          type="button"
                          size="small"
                          primary
                          content="Mark as Complete"
                          className="!mr-0 text-nowrap"
                        />
                      </div>
                    }
                  />
                )}

                {canExportPayoneerCsv && (
                  <PayoneerExportButton
                    paymentOrderId={paymentOrder.data._id}
                    title={title}
                  />
                )}

                {canDelete && (
                  <Button
                    onClick={() =>
                      setCurrentDialog({ type: 'delete-payment-order' })
                    }
                    className="!mr-0"
                    type="button"
                    icon="trash alternate outline"
                    size="small"
                    color="red"
                    basic
                  />
                )}
              </div>
            }
          />

          <div className="mx-14 -mt-4 grid grid-cols-2 gap-x-6 pb-1">
            <div className="leading-6 text-slate-500">
              <span className="inline-block  pr-2 text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Status:
              </span>
              <PaymentOrderStatusLabel paymentOrder={paymentOrder.data} />
              {paymentOrder.data.completedOn && (
                <div className="text-base text-slate-500">
                  <span className="mr-1.5">
                    on {format(paymentOrder.data.completedOn, 'PPP')}
                  </span>
                  {paymentOrder.data.completedBy && (
                    <span className="inline-flex items-center gap-1">
                      by <UserName id={paymentOrder.data.completedBy} />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mx-14 mb-4 mt-5 grid grid-cols-2 gap-6 2xl:grid-cols-3">
            <div className="rounded-md bg-slate-50 px-4 py-3 shadow">
              <div className="mb-2 text-xl font-semibold text-slate-500">
                Transactions
              </div>
              <TransactionsSummaryWidget
                paymentOrderStatus={paymentOrder.data.status}
                summary={summary}
              />
            </div>
            <div className="rounded-md bg-slate-50 px-4 py-3 shadow">
              <div className="mb-2 text-xl font-semibold text-slate-500">
                Amounts
              </div>
              <AmountsSummaryWidget
                paymentOrderStatus={paymentOrder.data.status}
                summary={summary}
              />
            </div>
          </div>

          <div className="mx-14 mb-14">
            <div className="flex flex-wrap items-baseline gap-3 px-3 pb-6 pt-4">
              <div className="flex items-baseline">
                <span className="mr-2 whitespace-nowrap">Looking for</span>
                <Input
                  className="w-48"
                  size="mini"
                  onChange={(e) => setBeneficiarySearch(e.target.value)}
                  value={beneficiarySearch || ''}
                  input={
                    <input
                      style={{ fontSize: 'unset' }}
                      placeholder="All Candidates/Suppliers"
                      className="placeholder:!font-bold placeholder:![color:rgba(0,0,0,.6)] placeholder:![font-size:12px]"
                    />
                  }
                />
              </div>
              <div className="flex items-baseline gap-2">
                <span className="whitespace-nowrap">with</span>
                <SimpleFilter
                  onFilterChange={(value) => {
                    if (
                      value?.status === null ||
                      typeof value?.status === 'string'
                    ) {
                      setStatusFilter(value.status)
                    }
                  }}
                  name="status"
                  options={
                    paymentOrder.data.status === 'Completed'
                      ? [
                          { value: false, text: 'Any', key: 0 },
                          {
                            value: 'Completed',
                            text: 'Completed',
                            content: (
                              <TransactionStatusLabel status="Completed" />
                            ),
                          },
                          {
                            value: 'Dismissed',
                            text: 'Dismissed',
                            content: (
                              <TransactionStatusLabel status="Dismissed" />
                            ),
                          },
                          {
                            value: 'Canceled',
                            text: 'Canceled',
                            content: (
                              <TransactionStatusLabel status="Canceled" />
                            ),
                          },
                        ]
                      : [
                          { value: false, text: 'Any', key: 0 },
                          {
                            value: 'Draft',
                            text: 'Draft',
                            content: <TransactionStatusLabel status="Draft" />,
                          },
                          {
                            value: 'Approved',
                            text: 'Approved',
                            content: (
                              <TransactionStatusLabel status="Approved" />
                            ),
                          },
                          {
                            value: 'Dismissed',
                            text: 'Dismissed',
                            content: (
                              <TransactionStatusLabel status="Dismissed" />
                            ),
                          },
                          {
                            value: 'Processing',
                            text: 'Processing',
                            content: (
                              <TransactionStatusLabel status="Processing" />
                            ),
                          },
                          {
                            value: 'Canceled',
                            text: 'Canceled',
                            content: (
                              <TransactionStatusLabel status="Canceled" />
                            ),
                          },
                        ]
                  }
                />
                <span className="whitespace-nowrap">status</span>
              </div>

              <div className="flex items-baseline gap-2">
                and{' '}
                <Dropdown
                  pointing="top left"
                  selectOnBlur={false}
                  icon={false}
                  trigger={
                    <Button
                      basic={accountFilter === 'any'}
                      primary={accountFilter !== 'any'}
                      size="tiny"
                    >
                      <b className="whitespace-nowrap">
                        {selectedAccountOption?.text ||
                          selectedAccountOption?.content}
                      </b>
                    </Button>
                  }
                  onChange={(_, p) => {
                    if (typeof p.value === 'string') {
                      setAccountFilter(p.value as any)
                    }
                  }}
                  value={accountFilter}
                  options={accountFilterOptions}
                />
              </div>

              <div className="-mr-3 flex h-[36px] grow justify-end self-center">
                {paymentOrder.data.status !== 'Completed' &&
                  paymentOrder.data.status !== 'Processing' && (
                    <Button
                      content="Add custom debit"
                      className="!mr-0 items-baseline"
                      onClick={() => setIsCustomTxFormVisible((prev) => !prev)}
                      icon="plus"
                      size="small"
                      compact
                      basic
                    />
                  )}
              </div>
            </div>

            <table className="w-full">
              <thead>
                <tr className="border-b border-t border-slate-300">
                  <th className="p-0 text-left">
                    <button
                      className="min-w-52 px-3 py-2 text-left text-sm font-semibold uppercase leading-6 text-primary hover:opacity-90"
                      type="button"
                      onClick={() =>
                        setSort((prev) => ({
                          by: 'beneficiary',
                          dir: prev.dir === 'asc' ? 'desc' : 'asc',
                        }))
                      }
                    >
                      Beneficiary
                      <Icon
                        name={sort.dir === 'asc' ? 'sort up' : 'sort down'}
                        className={classNames(
                          'relative',
                          sort.by !== 'beneficiary' && '!opacity-0',
                          sort.dir === 'asc' ? 'top-0.5' : '-top-1',
                        )}
                      />
                    </button>
                  </th>
                  <th className="w-[1%] px-0 py-2 text-right text-sm font-semibold uppercase leading-6 text-primary">
                    <span className="sr-only">Actions</span>
                  </th>
                  <th style={{ minWidth: 116, width: 116 }} />
                  <th className="w-[1%] px-3 py-2 text-right text-sm font-semibold uppercase leading-6 text-primary">
                    Amount
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-semibold uppercase leading-6 text-primary">
                    Description
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-semibold uppercase leading-6 text-primary">
                    Account
                  </th>
                  <th className="min-w-32 py-2 pl-3 pr-7 text-right text-sm font-semibold uppercase leading-6 text-primary">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {isCustomTxFormVisible &&
                  paymentOrder.data.status !== 'Completed' && (
                    <AddCustomDebitForm
                      paymentOrderId={paymentOrderId}
                      onSuccess={() =>
                        Promise.allSettled([
                          refreshFeed(),
                          paymentOrder.mutate(),
                        ]).then(() => setIsCustomTxFormVisible(false))
                      }
                      onCancel={() => setIsCustomTxFormVisible(false)}
                    />
                  )}
                {sortedTxs.map(([beneficiaryId, beneficiaryTxs]) => {
                  const isHighlighted = hoveredBeneficiary === beneficiaryId
                  return (
                    <React.Fragment key={beneficiaryId}>
                      <PaymentOrderRow
                        onMouseEnter={() =>
                          setHoveredBeneficiary(beneficiaryId)
                        }
                        onMouseLeave={() => setHoveredBeneficiary('')}
                        isHighlighted={isHighlighted}
                        onTransactionUpdate={handleTransactionUpdate}
                        paymentOrderStatus={paymentOrder.data?.status}
                        transactions={beneficiaryTxs}
                        isAddingCustomTx={
                          customTx?.beneficiaryId === beneficiaryId
                        }
                        onDeleteCustomTx={(txId) =>
                          setCurrentDialog({ type: 'delete-custom-tx', txId })
                        }
                        onAddCustomTx={() => {
                          const beneficiaryType =
                            beneficiaryTxs?.[0]?.beneficiaryType
                          if (!beneficiaryType) return
                          setCustomTx({ beneficiaryId, beneficiaryType })
                        }}
                      />
                      {customTx?.beneficiaryId === beneficiaryId && (
                        <AddCustomDebitForm
                          beneficiaryType={customTx.beneficiaryType}
                          paymentOrderId={paymentOrderId}
                          beneficiaryId={beneficiaryId}
                          onSuccess={() =>
                            Promise.allSettled([
                              refreshFeed(),
                              paymentOrder.mutate(),
                            ]).then(() => setCustomTx(null))
                          }
                          onCancel={() => setCustomTx(null)}
                        />
                      )}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>

            {!paymentOrder.isLoading &&
              paymentOrder.data.transactions.length === 0 && (
                <div className="mt-4 px-4 py-12 text-center">
                  <div className="mb-3 text-lg text-slate-600">
                    No transactions in this Payment Order yet
                  </div>
                  <Button
                    content="Generate Debits"
                    onClick={() => {
                      setCurrentDialog({ type: 'generate-debits' })
                    }}
                    type="button"
                    primary
                  />
                </div>
              )}

            {!paymentOrder.isLoading &&
              paymentOrder.data.transactions.length > 0 &&
              sortedTxs.length === 0 && (
                <div className="my-4 px-4 py-12 text-center text-lg text-slate-600">
                  No matches for the current filters
                </div>
              )}
          </div>
        </div>

        <div className="w-full min-w-[300px] max-w-[500px] shrink-0 overflow-y-auto border-l bg-white p-6 [&::-webkit-scrollbar]:hidden">
          <Feed
            relatedCollection="payment-orders"
            relatedId={paymentOrderId}
            showEditor={false}
          />
        </div>
      </div>

      <ConfirmGenerateDebitsModal
        open={currentDialog?.type === 'generate-debits'}
        paymentOrderId={paymentOrderId}
        onSuccess={paymentOrder.mutate}
        onClose={() => setCurrentDialog(null)}
      />

      <ConfirmMarkAsProcessingModal
        open={currentDialog?.type === 'mark-as-processing'}
        isPending={isMarkingAsProcessing}
        onConfirm={handleMarkAsProcessing}
        onCancel={() => setCurrentDialog(null)}
      />

      <ConfirmCompletePaymentOrderModal
        open={currentDialog?.type === 'complete-payment-order'}
        isPending={isCompletingPaymentOrder}
        onConfirm={handleCompletePaymentOrder}
        onCancel={() => setCurrentDialog(null)}
      />

      <ConfirmRemovePaymentOrderModal
        open={currentDialog?.type === 'delete-payment-order'}
        isPending={isDeleting}
        onConfirm={handleDelete}
        onCancel={() => setCurrentDialog(null)}
      />

      <ConfirmRemoveCustomTxModal
        open={currentDialog?.type === 'delete-custom-tx'}
        isPending={isDeletingTx}
        onConfirm={handleDeleteCustomTx}
        onCancel={() => setCurrentDialog(null)}
      />
    </>
  )
}
