import {
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  ShieldExclamationIcon,
  ShieldCheckIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import { ButtonGroup, Button, Loader, Popup, Icon } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  paymentSetupSchema,
  accountSchema,
  PaymentSetup,
  Candidate,
  Account,
} from '../../types'
import { classNames, currencyFormat, validateResponse } from '../../utils'
import { useContractsApi } from '../../store/mainContext'
import PayoneerLogo from '../PayoneerLogo'
import UsaBankIcon from '../UsaBankIcon'

type Filter = 'all' | Account['status']

interface Props {
  onAccountReviewClick(account: Account): void
  onAccountViewClick(account: Account): void
  candidateId: Candidate['_id']
}

export default function AccountsSidePanel(props: Props) {
  const { candidateId, onAccountReviewClick, onAccountViewClick } = props
  const api = useContractsApi()

  const { data, isLoading, error } = useSWR<Account[], AxiosError>(
    ['accounts', candidateId],
    () => {
      return api
        .get('accounts/pro', {
          headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '-1' },
          params: { beneficiaryId: candidateId },
        })
        .then(validateResponse(z.array(accountSchema)))
    },
  )

  const paymentSetup = useSWR<PaymentSetup, AxiosError>(
    ['payment-setup', candidateId],
    () => {
      return api
        .get(`${candidateId}/last-payment-setup`)
        .then(validateResponse(paymentSetupSchema))
    },
  )

  const [currentFilter, setCurrentFilter] = React.useState<Filter>('all')

  const accountsInUse = paymentSetup.data
    ? 'rest' in paymentSetup.data
      ? [paymentSetup.data.fixed.account, paymentSetup.data.rest]
      : [paymentSetup.data.fixed.account]
    : []

  if (isLoading) {
    return (
      <div className="pb-3 text-center">
        <Loader active inline size="small" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="mx-6 my-2 rounded-md bg-red-100 p-4 text-red-800">
        <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
        {error.response?.data.message || error.message}
      </div>
    )
  }

  if (data?.length === 0) {
    return (
      <div className="px-9 text-base text-slate-600">
        Candidate has not configured bank accounts yet. Payments will be
        stalled.
      </div>
    )
  }

  const getAccountAlias = (accountId: Account['_id']): string => {
    const account = data?.find((acc) => acc._id === accountId)
    if (!account) return 'unknown'
    if (account.type === 'payoneer') return 'Payoneer'
    return account.alias
  }

  const filteredAccounts = (data || []).filter(
    (account) => currentFilter === 'all' || account.status === currentFilter,
  )

  return (
    <div className="pl-6">
      {paymentSetup.data &&
        ('rest' in paymentSetup.data ? (
          <div className="mb-4 ml-0.5 mt-4 text-slate-600">
            For the upcoming payroll, the candidate requested to receive{' '}
            <strong className="mx-0.5 inline-block rounded bg-sky-100 px-1.5 py-px text-sm font-semibold text-slate-600 shadow-sm [letter-spacing:1px]">
              {currencyFormat(paymentSetup.data.fixed.amount)}
            </strong>{' '}
            on{' '}
            <strong className="mx-0.5 inline-block rounded bg-sky-100 px-1.5 py-px text-sm font-semibold text-slate-600 shadow-sm [letter-spacing:1px]">
              {getAccountAlias(paymentSetup.data.fixed.account)}
            </strong>{' '}
            account and the rest on{' '}
            <strong className="mx-0.5 inline-block rounded bg-sky-100 px-1.5 py-px text-sm font-semibold text-slate-600 shadow-sm [letter-spacing:1px]">
              {getAccountAlias(paymentSetup.data.rest)}
            </strong>{' '}
            account.
          </div>
        ) : (
          <div className="mb-4 ml-0.5 mt-4 text-slate-600">
            For the upcoming payroll, the candidate requested to receive{' '}
            <strong className="mx-0.5 inline-block rounded bg-sky-100 px-1.5 py-px text-sm font-semibold text-slate-600 [letter-spacing:1px]">
              ALL
            </strong>{' '}
            on{' '}
            <strong className="mx-0.5 inline-block rounded bg-sky-100 px-1.5 py-px text-sm font-semibold text-slate-600 [letter-spacing:1px]">
              {getAccountAlias(paymentSetup.data?.fixed.account)}
            </strong>{' '}
            account.
          </div>
        ))}

      {!paymentSetup.data && (
        <div className="mb-4 ml-0.5 mt-4 text-base text-orange-700">
          <ExclamationTriangleIcon className="relative top-[-1px] mr-1 inline-block w-5" />
          Candidate has not configured a payment setup yet. Upcoming payments
          will be stalled.
        </div>
      )}

      <header className="relative mx-0.5 mb-2 flex items-baseline">
        <div className="m-0 grow text-sm uppercase text-slate-800">
          Candidate Accounts
        </div>
        <div>
          <span className="mr-3 text-sm font-semibold text-slate-500">
            View
          </span>
          <ButtonGroup size="mini" basic>
            <Popup
              trigger={
                <Button
                  onClick={() => setCurrentFilter('all')}
                  active={currentFilter === 'all'}
                  icon
                >
                  <Icon name="asterisk" />
                </Button>
              }
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              position="top center"
              content="All accounts"
              size="tiny"
            />
            <Popup
              trigger={
                <Button
                  onClick={() => setCurrentFilter('Waiting for approval')}
                  active={currentFilter === 'Waiting for approval'}
                  icon
                >
                  <ClockIcon className="-mx-0.5 -my-0.5 inline-block h-5 w-5 text-amber-500" />
                </Button>
              }
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              position="top center"
              content="Waiting for approval"
              size="tiny"
            />
            <Popup
              trigger={
                <Button
                  onClick={() => setCurrentFilter('Approved')}
                  active={currentFilter === 'Approved'}
                  icon
                >
                  <ShieldCheckIcon className="-mx-0.5 -my-0.5 inline-block h-5 w-5 text-green-500" />
                </Button>
              }
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              position="top center"
              content="Approved accounts"
              size="tiny"
            />
            <Popup
              trigger={
                <Button
                  onClick={() => setCurrentFilter('Rejected')}
                  active={currentFilter === 'Rejected'}
                  icon
                >
                  <ShieldExclamationIcon className="-mx-0.5 -my-0.5 inline-block h-5 w-5 text-red-500" />
                </Button>
              }
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              position="top center"
              content="Rejected accounts"
              size="tiny"
            />
          </ButtonGroup>
        </div>
      </header>

      {filteredAccounts.length === 0 && (
        <p className="ml-0.5 pb-2 text-base italic text-slate-500">
          No accounts
        </p>
      )}

      {filteredAccounts.length > 0 && (
        <ul className="space-y-1">
          {filteredAccounts.map((account) => (
            <li key={account._id}>
              <div
                className={classNames(
                  'cursor-pointer rounded-md px-3 py-3 hover:opacity-85',
                  account.status === 'Waiting for approval' &&
                    'bg-orange-100 text-amber-700',
                  account.status === 'Rejected' && 'bg-red-100 text-red-800',
                  account.status === 'Approved' &&
                    'bg-slate-100 text-slate-600',
                )}
                onClick={() =>
                  account.status === 'Waiting for approval'
                    ? onAccountReviewClick(account)
                    : onAccountViewClick(account)
                }
              >
                <div className="flex items-baseline">
                  <span className="grow">
                    {account.type === 'payoneer' && (
                      <PayoneerLogo className="inline-block w-24 align-text-bottom" />
                    )}
                    {account.type === 'usa' && (
                      <UsaBankIcon className="mr-0.5 inline-block w-5 text-slate-600" />
                    )}
                    {account.type === 'international' && (
                      <Icon name="globe" color="grey" className="scale-110" />
                    )}
                    {account.type !== 'payoneer' && account.alias}
                    {accountsInUse.includes(account._id) && (
                      <span className="ml-2 text-sm text-slate-500">
                        (in use)
                      </span>
                    )}
                  </span>
                  {account.type !== 'payoneer' && (
                    <span className="font-mono">
                      ****{account.accountNumber.toString().slice(-4)}
                    </span>
                  )}
                </div>
                {account.status !== 'Approved' && (
                  <div className="relative top-0.5 text-xs font-semibold">
                    {account.status}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
