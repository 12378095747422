import { NavLink, Redirect, useHistory } from 'react-router-dom'
import { Button, Checkbox } from 'semantic-ui-react'
import React, { useState } from 'react'
import { AxiosError } from 'axios'
import { isFuture } from 'date-fns'
import { Helmet } from 'react-helmet'
import useSWR from 'swr'
import { z } from 'zod'

import { Recipe, recipeScheduleSchema, recipeSchema } from '../../types'
import { validateResponse } from '../../utils'
import { useToasts } from '../../components/toasts/ToastsProvider'
import useSortable from '../../hooks/useSortable'
import PageHeader from '../../components/pageHeader/pageHeader'
import ThSortable from '../../components/tables/ThSortable'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import featureToggles from '../../featureToggles'
import CollapsableSection from '../../components/CollapsableSection'

export default function RecipesPage() {
  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const { data, isLoading, mutate } = useSWR('recipes', async () => {
    const [recipes, schedules] = await Promise.all([
      api.get('recipes').then(validateResponse(z.array(recipeSchema))),
      api
        .get('recipe-schedules', { params: { status: 'pending' } })
        .then(validateResponse(z.array(recipeScheduleSchema)))
        .then((pendingSchedules) =>
          pendingSchedules.filter(({ whenToRun }) => isFuture(whenToRun || 0)),
        ),
    ])
    return recipes.map((recipe) => ({
      ...recipe,
      schedules: schedules.filter((s) => s.recipeId === recipe._id),
    }))
  })

  const recipesSort = useSortable('recipes', {
    sortBy: 'triggerEnable',
    sortByDir: 'descending',
  })

  const [isCreating, setIsCreating] = useState(false)

  const handleAddRecipe = async () => {
    setIsCreating(true)
    return api
      .post('recipes', {
        name: 'New Recipe',
        collection: 'Candidates',
        description: 'Just another recipie to automate your job.',
        steps: [],
        args: [],
        type: 'manual',
      })
      .then(validateResponse(recipeSchema))
      .then((recipe) => history.push('/recipes/' + recipe._id))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreating(false))
  }

  const handleRecipeEnableChange = async (
    recipeId: Recipe['_id'],
    triggerEnable: boolean,
  ) => {
    return mutate(
      () =>
        api
          .patch(`recipes/${recipeId}`, { triggerEnable })
          .catch((err: AxiosError) =>
            addToast(err.response?.data.message || err.message, {
              variant: 'danger',
            }),
          ),
      {
        optimisticData(prev) {
          if (!prev) return []
          return prev.map((recipe) =>
            recipe._id === recipeId ? { ...recipe, triggerEnable } : recipe,
          )
        },
        populateCache: false,
        revalidate: false,
      },
    )
  }

  const filteredRecipes = (data || []).sort((first, second) => {
    const { sortByDir, sortBy } = recipesSort
    const a = sortByDir === 'ascending' ? first : second
    const b = sortByDir === 'ascending' ? second : first
    if (sortBy === 'name') return a.name.localeCompare(b.name)

    if (sortBy === 'description') {
      return (a.description || '').localeCompare(b.description || '')
    }
    if (sortBy === 'triggerEvent') {
      return (a.triggerEvent || '').localeCompare(b.triggerEvent || '')
    }
    if (sortBy === 'triggerEnable') {
      return (a.triggerEnable ? 1 : 0) - (b.triggerEnable ? 1 : 0)
    }
    return 0
  })

  if (!featureToggles.recipes) return <Redirect to="/" />

  if (isLoading) return <PageLoader />

  return (
    <React.Fragment>
      <Helmet>
        <title>Recipes</title>
      </Helmet>

      <PageHeader
        title="Recipes"
        sub="One screen to automate them all."
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Recipes', link: '/recipes' },
        ]}
        actions={
          <Button
            content="Add Recipe"
            onClick={handleAddRecipe}
            disabled={isCreating}
            loading={isCreating}
            icon="add"
            basic
          />
        }
      />

      <CollapsableSection className="mx-14 my-10" title="Recipes">
        <React.Fragment>
          <p className="ml-11 pt-1 text-base text-slate-500">
            Recipes designed to be ran manually.
          </p>

          <table className="mt-5 w-full">
            <thead>
              <tr className="border-b text-left text-sm uppercase text-primary">
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('name')}
                >
                  Name
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('description')}
                >
                  Description
                </ThSortable>
                <ThSortable
                  className="px-2 py-2 text-center"
                  {...recipesSort.register('collection')}
                >
                  Designed For
                </ThSortable>

                <ThSortable
                  className="px-2 py-2 text-center"
                  shrink
                  {...recipesSort.register('schedules')}
                >
                  Schedules
                </ThSortable>

                <ThSortable
                  className="px-2 py-2  text-center"
                  {...recipesSort.register('steps')}
                >
                  Steps
                </ThSortable>
                <ThSortable
                  className="w-[1%] px-2 py-2 text-center"
                  {...recipesSort.register('triggerEnable')}
                >
                  Enabled
                </ThSortable>
              </tr>
            </thead>
            <tbody>
              {filteredRecipes
                .filter((x) => !x.triggerEvent && !x.webhookId)
                .map((recipe) => {
                  return (
                    <tr
                      className="cursor-pointer border-b align-baseline hover:bg-slate-50"
                      onClick={() => history.push(`/recipes/${recipe._id}`)}
                      key={recipe._id}
                    >
                      <td className="text-nowrap px-2 py-3 text-base">
                        {recipe.name}
                      </td>
                      <td className="max-w-lg px-2 py-3 text-base leading-snug text-slate-700">
                        {recipe.description}
                      </td>
                      <td className="max-w-lg px-2 py-3 text-center text-base capitalize  leading-snug text-slate-700">
                        {recipe.collection}
                      </td>

                      <td className="py-0 text-center">
                        {recipe.schedules.length === 0 ? (
                          <span className="text-slate-400">0</span>
                        ) : (
                          <NavLink
                            className="block py-3"
                            onClick={(e) => e.stopPropagation()}
                            to={`/recipes/${recipe._id}/schedules`}
                          >
                            {recipe.schedules.length}
                          </NavLink>
                        )}
                      </td>

                      <td className="px-2 py-3 text-center">
                        {recipe.steps?.length}
                      </td>
                      <td className="px-2 py-0 text-center">
                        <Checkbox
                          className="relative -left-1 top-1.5"
                          toggle
                          checked={recipe.triggerEnable!!}
                          onChange={(event, p) => {
                            event.stopPropagation()
                            handleRecipeEnableChange(recipe._id, p.checked!!)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </React.Fragment>
      </CollapsableSection>

      <CollapsableSection className="mx-14 my-10" title="Webhook Recipes">
        <React.Fragment>
          <p className="ml-11 pt-1 text-base text-slate-500">
            Recipes designed react to external webhooks calls.
          </p>

          <table className="mt-5 w-full">
            <thead>
              <tr className="border-b text-left text-sm uppercase text-primary">
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('name')}
                >
                  Name
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('description')}
                >
                  Description
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('webhookId')}
                >
                  Webhook Id
                </ThSortable>

                <ThSortable
                  className="px-2 py-2  text-center"
                  {...recipesSort.register('steps')}
                >
                  Steps
                </ThSortable>

                <ThSortable
                  className="w-[1%] px-2 py-2 text-center"
                  {...recipesSort.register('triggerEnable')}
                >
                  Enabled
                </ThSortable>
              </tr>
            </thead>
            <tbody>
              {filteredRecipes
                .filter((x) => x.webhookId)
                .map((recipe) => {
                  return (
                    <tr
                      className="cursor-pointer border-b align-baseline hover:bg-slate-50"
                      onClick={() => history.push(`/recipes/${recipe._id}`)}
                      key={recipe._id}
                    >
                      <td className="text-nowrap px-2 py-3 text-base">
                        {recipe.name}
                      </td>
                      <td className="max-w-lg px-2 py-3 text-base leading-snug text-slate-700">
                        {recipe.description}
                      </td>

                      <td className="text-nowrap px-2 py-3 text-base">
                        <span className="relative top-[-1.5px] text-nowrap rounded-md border border-slate-300 bg-slate-50 px-1.5 py-0.5 font-mono text-xs  text-slate-700">
                          {recipe.webhookId}
                        </span>
                      </td>

                      <td className="px-2 py-3 text-center">
                        {recipe.steps?.length}
                      </td>
                      <td className="px-2 py-0 text-center">
                        <Checkbox
                          className="relative -left-1 top-1.5"
                          toggle
                          checked={recipe.triggerEnable!!}
                          onChange={(event, p) => {
                            event.stopPropagation()
                            handleRecipeEnableChange(recipe._id, p.checked!!)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </React.Fragment>
      </CollapsableSection>

      <CollapsableSection className="mx-14 my-10" title="Event Recipes">
        <React.Fragment>
          <p className="ml-11 pt-1 text-base text-slate-500">
            Recipes designed to run when something happens.
          </p>

          <table className=" mt-5 w-full">
            <thead>
              <tr className="border-b text-left text-sm uppercase text-primary">
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('name')}
                >
                  Name
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...recipesSort.register('description')}
                >
                  Description
                </ThSortable>

                <ThSortable
                  className="px-2 py-2 text-center"
                  {...recipesSort.register('triggerEvent')}
                >
                  Trigger on event
                </ThSortable>
                <ThSortable
                  className="px-2 py-2 text-center"
                  {...recipesSort.register('steps')}
                >
                  Steps
                </ThSortable>
                <ThSortable
                  className="w-[1%] px-2 py-2 text-center"
                  {...recipesSort.register('triggerEnable')}
                >
                  Enabled
                </ThSortable>
              </tr>
            </thead>
            <tbody>
              {filteredRecipes
                .filter((x) => x.triggerEvent)
                .map((recipe) => {
                  return (
                    <tr
                      className="cursor-pointer border-b align-baseline hover:bg-slate-50"
                      onClick={() => history.push(`/recipes/${recipe._id}`)}
                      key={recipe._id}
                    >
                      <td className="text-nowrap px-2 py-3 text-base">
                        {recipe.name}
                      </td>
                      <td className="max-w-lg px-2 py-3 text-base leading-snug text-slate-700">
                        {recipe.description}
                      </td>

                      <td className="px-2 py-3 text-center">
                        {recipe.triggerEvent && (
                          <span className="relative top-[-1.5px] text-nowrap rounded-md border border-slate-300 bg-slate-50 px-1.5 py-0.5 font-mono text-xs  text-slate-700">
                            {recipe.triggerEvent}
                          </span>
                        )}
                      </td>

                      <td className="px-2 py-3 text-center">
                        {recipe.steps?.length}
                      </td>
                      <td className="px-2 py-0 text-center">
                        <Checkbox
                          className="relative -left-1 top-1.5"
                          toggle
                          checked={recipe.triggerEnable!!}
                          onChange={(event, p) => {
                            event.stopPropagation()
                            handleRecipeEnableChange(recipe._id, p.checked!!)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </React.Fragment>
      </CollapsableSection>
    </React.Fragment>
  )
}
