import * as React from 'react'

import TransactionStatusLabel from './TransactionStatusLabel'
import { PaymentOrder } from '../../types'
import { Summary } from './PaymentOrderProgressBar'

interface Props {
  paymentOrderStatus: PaymentOrder['status']
  summary: Summary
}

export default function TransactionsSummaryWidget(props: Props) {
  const { summary, paymentOrderStatus } = props
  return (
    <table className="w-full">
      <tbody>
        <tr>
          <th className="pb-2 text-left text-sm font-semibold uppercase leading-6 text-slate-400">
            All
          </th>
          <td className="pb-2 text-right text-sm font-semibold text-slate-400">
            {summary.total}
          </td>
        </tr>
        {paymentOrderStatus === 'Draft' ? (
          <tr>
            <th className="text-left">
              <TransactionStatusLabel status="Approved" />
            </th>
            <td className="text-right text-sm text-slate-600">
              {summary.statuses.Approved}
            </td>
          </tr>
        ) : (
          <tr>
            <th className="text-left">
              <TransactionStatusLabel status="Completed" />
            </th>
            <td className="text-right text-sm font-semibold text-slate-600">
              {summary.statuses.Completed}
            </td>
          </tr>
        )}
        <tr>
          <th className="text-left">
            <TransactionStatusLabel status="Dismissed" />
          </th>
          <td className="text-right text-sm text-slate-600">
            {summary.statuses.Dismissed}
          </td>
        </tr>
        {(summary.statuses.Canceled || 0) > 0 && (
          <tr>
            <th className="text-left">
              <TransactionStatusLabel status="Canceled" />
            </th>
            <td className="text-right text-sm text-slate-600">
              {summary.statuses.Canceled}
            </td>
          </tr>
        )}
        <tr>
          <th className="text-left text-sm font-semibold uppercase leading-6 text-slate-400">
            Custom
          </th>
          <td className="text-right text-sm text-slate-600">
            {summary.custom}
          </td>
        </tr>
        {paymentOrderStatus === 'Draft' && (
          <tr>
            <th className="text-left">
              <TransactionStatusLabel status="Processing" />
            </th>
            <td className="text-right text-sm text-slate-600">
              {summary.statuses.Processing}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
