import useSWR from 'swr'
import { z } from 'zod'

import {
  recipeScheduleSchema,
  RecipeSchedule,
  contactSchema,
  companySchema,
  funnelSchema,
  taskSchema,
  Contact,
  Company,
  Funnel,
  Task,
} from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'
import usePowers from './usePowers'

export type EnhancedContact = Contact & {
  schedules: RecipeSchedule[]
  company?: Company
  tasks: Task[]
}

export default function useContactsFunnel(funnelName: string) {
  const api = useApi()
  const powers = usePowers()

  return useSWR(
    ['contacts-funnel', funnelName],
    async (): Promise<
      { funnel: Funnel; contacts: EnhancedContact[] } | undefined
    > => {
      const [funnel] = await api
        .get('funnels', { params: { name: funnelName } })
        .then(validateResponse(z.array(funnelSchema)))

      if (!funnel) return

      const contacts = await api
        .get('contacts/in', {
          params: { status: funnel.columns.map((c) => c.id) },
          headers: { 'astor-sortby': 'lastUpdateOn', 'astor-sortby-dir': '1' },
        })
        .then(validateResponse(z.array(contactSchema)))

      const [companies, tasks, schedules] = await Promise.all([
        api
          .get(`companies/in`, {
            params: { _id: contacts.map((c) => c.companyId) },
          })
          .then(validateResponse(z.array(companySchema))),
        api
          .get(`tasks/in`, {
            params: { relatedId: contacts.map((c) => c._id) },
            headers: { 'astor-sortby': 'dueDate', 'astor-sortby-dir': '-1' },
          })
          .then(validateResponse(z.array(taskSchema))),
        powers.hasAll(['run-and-schedule-recipes'])
          ? api
              .get('recipe-schedules/in', {
                headers: {
                  'astor-sortby': 'whenToRun',
                  'astor-sortby-dir': '-1',
                },
                params: {
                  runOnId: contacts.map((c) => c._id),
                  status: 'pending',
                },
              })
              .then(validateResponse(z.array(recipeScheduleSchema)))
          : [],
      ])

      return {
        funnel,
        contacts: contacts.map((contact) => {
          return {
            ...contact,
            company: companies.find(
              (company) => company._id === contact.companyId,
            ),
            schedules: schedules.filter((s) => s.runOnId === contact._id),
            tasks: tasks.filter(
              (t) => t.relatedId === contact._id && !t.isDone,
            ),
          }
        }),
      }
    },
  )
}
