import * as React from 'react'
import { Icon } from 'semantic-ui-react'

import { classNames } from '../../utils'
import { Candidate } from '../../types'
import RemoteValue from '../remoteValues/remoteValue'
import RemoteStars from '../remoteValues/remoteStars'

interface Props {
  selectedCandidateId?: Candidate['_id']
  onCandidateClick(candidateId: Candidate['_id']): void
  candidates: Candidate[]
}

export default function CandidatesSearchResultsCards(props: Props) {
  return (
    <div className="space-y-3">
      {props.candidates.map((candidate) => {
        return (
          <div
            className={classNames(
              'cursor-pointer rounded-md border px-5 py-4 hover:bg-slate-50',
              props.selectedCandidateId === candidate._id &&
                'border-primary bg-slate-50',
            )}
            onClick={() => props.onCandidateClick(candidate._id)}
            key={candidate._id}
          >
            <div className="text-nowrap text-xl font-semibold text-slate-700">
              {candidate.name}
            </div>

            {((candidate.profiles || []).length > 0 ||
              (candidate.mainTechs || []).length > 0) && (
              <div className="my-3">
                <div className="inline-flex flex-wrap gap-1">
                  {(candidate.profiles || []).map((id) => (
                    <span
                      className="rounded border bg-slate-100 px-1 py-0.5 text-sm text-slate-700"
                      key={id}
                    >
                      <RemoteValue collection="profiles" id={id} />
                    </span>
                  ))}
                  {(candidate.mainTechs || []).map((id) => (
                    <span
                      className="rounded border bg-slate-100 px-1 py-0.5 text-sm text-slate-700"
                      key={id}
                    >
                      <RemoteValue collection="techs" id={id} />
                    </span>
                  ))}
                </div>
              </div>
            )}

            <div className="mt-2 flex gap-8">
              {candidate.seniority && (
                <div className="text-base text-slate-600">
                  Seniority
                  <span className="ml-2 font-semibold">
                    <RemoteValue
                      collection="seniority"
                      id={candidate.seniority}
                      predicate={({ text }) => text}
                    />
                  </span>
                </div>
              )}
              <div>
                <span className="mr-2 text-base text-slate-600">
                  English level{' '}
                </span>
                <RemoteStars
                  collection="englishLevels"
                  maxRating={5}
                  value={candidate.englishLevel}
                  size="mini"
                />
              </div>
            </div>

            {(candidate.location ||
              typeof candidate.yearsInTheIndustry === 'number' ||
              typeof candidate.yearsInTheRole === 'number') && (
              <div className="mt-2 flex gap-8">
                {candidate.location && (
                  <div className="mt-2 text-nowrap text-base text-slate-600">
                    <span className="mr-1 text-slate-400">
                      <Icon name="map pin" fitted />
                    </span>{' '}
                    From{' '}
                    <span className="ml-1 font-semibold">
                      {candidate.location}
                    </span>
                  </div>
                )}

                {typeof candidate.yearsInTheIndustry === 'number' && (
                  <div className="mt-2 text-base text-slate-600">
                    <span className="font-semibold">
                      {candidate.yearsInTheIndustry}{' '}
                      {candidate.yearsInTheIndustry === 1 ? 'year' : 'years'}
                    </span>{' '}
                    in the industry
                  </div>
                )}

                {typeof candidate.yearsInTheRole === 'number' && (
                  <div className="mt-2 text-base text-slate-600">
                    <span className="font-semibold">
                      {candidate.yearsInTheRole}{' '}
                      {candidate.yearsInTheRole === 1 ? 'year' : 'years'}
                    </span>{' '}
                    in the role
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
