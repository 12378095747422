import { Icon, Loader } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { paymentAllocationSchema, paymentSchema } from '../../types'
import { currencyFormat, validateResponse } from '../../utils'
import PaymentStatusLabel from '../payments/PaymentStatusLabel'
import { ENV_VARS } from '../../env'
import { useApi } from '../../store/mainContext'

interface Props {
  invoiceId: string
}

export default function InvoicePayments(props: Props) {
  const { invoiceId } = props

  const api = useApi()

  const { data, isLoading } = useSWR(['invoice-payments', invoiceId], () =>
    api
      .get('billing-payments-allocations', { params: { invoiceId } })
      .then(validateResponse(z.array(paymentAllocationSchema)))
      .then(async (allocations) => {
        const payments = await api
          .get('billing-payments/in', {
            params: { _id: allocations.map((a) => a.paymentId) },
          })
          .then(validateResponse(z.array(paymentSchema)))
        return allocations.map((a) => ({
          ...a,
          payment: payments.find((p) => p._id === a.paymentId),
        }))
      }),
  )

  if (isLoading)
    return (
      <div className="my-12 text-center">
        <Loader active inline size="tiny" />
      </div>
    )

  return (
    <div>
      {data?.length === 0 ? (
        <div className="py-4 text-slate-700">No payments? weird...</div>
      ) : (
        <section>
          <header className="pb-4 pl-4 text-sm font-semibold text-slate-600">
            {(data || []).length}{' '}
            {(data || []).length === 1 ? 'Payment' : 'Payments'} for this
            invoice
          </header>
          <ul className="space-y-4">
            {data?.map((allocation) => (
              <li key={allocation._id}>
                <div className="rounded-md border bg-slate-50">
                  <div className="flex items-baseline gap-4 px-4 pb-1 pt-3 hover:bg-slate-100">
                    <div className="text-nowrap text-sm font-semibold uppercase leading-none text-slate-700">
                      Allocated amount
                    </div>
                    <div className="grow text-right font-semibold text-slate-700">
                      {currencyFormat(allocation.amount)}
                    </div>
                  </div>
                  {allocation.payment?.amount && (
                    <div className="mt-1 flex items-baseline gap-4 px-4 py-1  hover:bg-slate-100">
                      <div className="text-nowrap text-sm font-semibold text-slate-500">
                        Payment total amount
                      </div>
                      <div className="grow text-right text-slate-500">
                        {currencyFormat(allocation.payment.amount)}
                      </div>
                    </div>
                  )}
                  {allocation.payment?.status && (
                    <div className="flex items-baseline gap-4 px-4 py-1 hover:bg-slate-100">
                      <div className="text-nowrap text-sm font-semibold text-slate-500">
                        Payment status
                      </div>
                      <div className="grow text-right text-slate-500">
                        <PaymentStatusLabel
                          status={allocation.payment.status}
                        />
                      </div>
                    </div>
                  )}
                  {allocation.payment?.date && (
                    <div className="flex items-baseline gap-4 px-4 py-1 hover:bg-slate-100">
                      <div className="text-nowrap text-sm font-semibold text-slate-500">
                        Received on
                      </div>
                      <div className="grow text-right text-slate-500">
                        {format(allocation.payment?.date, 'PPP')}
                      </div>
                    </div>
                  )}
                  {allocation.payment?.attachment && (
                    <div className="flex items-baseline gap-4 px-4 py-1 hover:bg-slate-100">
                      <div className="text-nowrap text-sm font-semibold text-slate-500">
                        Receipt
                      </div>
                      <div className="grow text-right text-slate-500">
                        <a
                          className="hover:underline"
                          href={allocation.payment.attachment}
                          target={
                            ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                              ? '_blank'
                              : undefined
                          }
                          rel="noreferrer"
                        >
                          <Icon name="file alternate outline" />
                          View file
                        </a>
                      </div>
                    </div>
                  )}
                  {allocation.payment?.transferFrom && (
                    <div className="flex items-baseline gap-4 px-4 py-1 hover:bg-slate-100">
                      <div className="text-nowrap text-sm font-semibold text-slate-500">
                        Transfer from
                      </div>
                      <div className="grow text-right text-slate-500">
                        {allocation.payment.transferFrom}
                      </div>
                    </div>
                  )}

                  <div className="px-4 pb-5 pt-2 text-sm text-slate-700">
                    {allocation.payment?.transferDescription}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  )
}
