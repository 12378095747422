import React from 'react'
import { Recipe } from '../../../types'
import { Dropdown } from 'semantic-ui-react'

const eventList = [
  'candidate.status-changed',
  'candidate.position-changed',
  'candidate.created',
  'candidate.removed',

  'contact.created',
  'contact.removed',

  'company.created',
  'company.removed',

  'task.created',
  'user-account.created',
  'candidate.referred',
  'pto.requested',
  'pto.canceled',
  'pto.rejected',
  'pto.accepted',
  'pto.updated',
  'pto.modified',
  'taxAdvisor.requested',
  'account.created',
  'account.approved',
  'account.rejected',
  'account.removed',
  'paymentSetup.changed',
  'contract.created',
  'contract.terminated',
  'deal.updated',
  'paymentOrder.created',
  'paymentOrder.updated',
  'paymentOrder.removed',
  'paymentOrders.debitsCreated',
  'payroll.created',
  'payroll.updated',
  'payroll.removed',
  'invoice.created',
  'invoice.removed',
  'invoice.updated',
  'invoice.statusUpdated',
  'email.schedule_send',
  'email.schedule_send_completed',
  'email.schedule_send_failed',
]

const Step0Event = ({
  recipe,
  onChange,
}: {
  recipe: Partial<Recipe>
  onChange: (newValues: Partial<Recipe>) => void
}) => (
  <div className="mt-6 flex flex-col">
    <label className="mb-2 font-bold">Trigger event</label>
    <Dropdown
      selection
      allowAdditions
      search
      placeholder="Choose an event..."
      options={eventList.map((x) => ({
        key: x,
        value: x,
        text: x,
      }))}
      onAddItem={(e, d) =>
        onChange({
          ...recipe,
          triggerEvent: (d as any).value,
        })
      }
      value={recipe.triggerEvent}
      onChange={(e, d) =>
        onChange({
          ...recipe,
          triggerEvent: (d as any).value,
        })
      }
    />
  </div>
)

export default Step0Event
