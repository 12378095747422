import { Link, Redirect, useParams } from 'react-router-dom'
import { Breadcrumb, Button, Icon } from 'semantic-ui-react'
import useSWR, { useSWRConfig } from 'swr'
import React, { useState } from 'react'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'
import { z } from 'zod'

import { Account, Candidate, contractSchema } from '../../types'
import AccountModal, {
  Dialog as AccountDialog,
} from '../../components/accounts/AccountModal'
import TimeoffModal, {
  Dialog as TimeoffDialog,
} from '../../components/timeoff/TimeoffModal'
import { validateResponse } from '../../utils'
import CandidateSidePanel from '../../components/sidepanels/candidateSidePanel'
import CandidateDuplicate from '../../components/candidates/DuplicatesCard'
import AccountsSidePanel from '../../components/sidepanels/AccountsSidePanel'
import AccountsForReview from '../../components/accounts/AccountsForReview'
import TimeoffForReview from '../../components/timeoff/TimeoffForReview'
import CandidateActions from '../../components/actions/candidateActions'
import PositionLinker from '../../components/positionLinker'
import LinkedinViewer from '../../components/linkedinViewer'
import { useToasts } from '../../components/toasts/ToastsProvider'
import RequirePowers from '../../components/requirePowers'
import useCandidate from '../../hooks/useCandidate'
import CompanyName from '../../components/remoteValues/companyName'
import Submissions from '../../components/submissions/submissions'
import { Section } from '../../components/sidepanel/sidePanel'
import { useUser } from '../session/hooks'
import PageLoader from '../../components/pageLoader'
import DateFormat from '../../components/dateFormat'
import { useApi } from '../../store/mainContext'
import FunnelBar from '../../components/funnelBar/funnelBar'
import Contracts from '../../components/contracts/contracts'
import POWERS from '../../powers'
import Feed from '../../components/feeds/feed'

type Tab = 'profile' | 'contract' | 'devices'

const CandidatePage = () => {
  const { candidateId } = useParams<{ candidateId: string }>()

  if (!candidateId) {
    throw new Error('`candidateId` param must be present in the URL.')
  }

  const { mutate: globalMutate } = useSWRConfig()
  const { addToast } = useToasts()
  const user = useUser()
  const api = useApi()

  const { data: candidate, isLoading, mutate } = useCandidate(candidateId)

  const [currentDialog, setCurrentDialog] = React.useState<
    AccountDialog | TimeoffDialog | null
  >(null)
  const [selectedTab, setSelectedTab] = useState<Tab>('profile')
  const [isPending, setIsPending] = React.useState(false)

  const contracts = useSWR(
    user.hasPower(POWERS.contracts)
      ? ['candidate-contracts', candidateId]
      : null,
    () =>
      api
        .get(`/${candidateId}/contracts`)
        .then(validateResponse(z.array(contractSchema))),
  )

  const activeContract = (contracts.data || []).find((x) => x.active) || null
  const hasContracts = (contracts.data || [])?.length > 0

  const handleCandidateChange = (property: keyof Candidate, value: any) => {
    if (!candidate) return
    const updatedProps = { [property]: value }
    api
      .patch(`candidates/${candidateId}`, updatedProps)
      .then(() => mutate())
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
  }

  const handleAccountUpdate = async ({ _id, status, reason }: Account) => {
    setIsPending(true)
    return api
      .patch(`accounts/${_id}`, { status, ...(reason ? { reason } : {}) })
      .then(() => globalMutate(['accounts', candidateId]))
      .then(() => setCurrentDialog(null))
      .then(() => addToast('Account reviewed', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsPending(false))
  }

  if (isLoading) return <PageLoader />

  if (!candidate) {
    addToast('Candidate not found', { variant: 'danger' })
    return <Redirect to="/candidates" />
  }

  return (
    <div className="flex justify-between">
      <Helmet>
        <title>{candidate.name}</title>
      </Helmet>
      <div className="sticky top-0 z-50 ml-3 max-h-screen w-[400px] shrink-0 overflow-auto pb-28 pr-3 [&::-webkit-scrollbar]:hidden">
        <Breadcrumb className="py-4 pl-8 pr-2">
          <Breadcrumb.Section>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section>
            {!candidate.status && <Link to="/candidates">Candidates</Link>}

            {candidate.status && (
              <Link
                to={`/candidates/funnels/${candidate.status.split('.')[0]}`}
              >
                Funnel
              </Link>
            )}
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section active>
            {candidate.name.split(' ')[0]}
          </Breadcrumb.Section>
        </Breadcrumb>

        <h1 className="mb-0 ml-8 mr-6 mt-4">{candidate.name}</h1>

        <div className="ml-8 mr-6 mt-1 text-base text-slate-600">
          {candidate.location}
        </div>

        {activeContract?.active ? (
          <div className="ml-8 mr-6 mt-1 text-base text-slate-600">
            With us since{' '}
            <b>
              <DateFormat date={activeContract.startingDate} />{' '}
            </b>
            (<CompanyName id={activeContract.customerId} />)
          </div>
        ) : (
          candidate.referal && (
            <div className="ml-8 mr-6 mt-1 text-base text-slate-600">
              Referred by {candidate.referal}
            </div>
          )
        )}

        <CandidateActions
          candidate={candidate}
          contract={activeContract}
          onSuccess={handleCandidateChange}
          onContractCreated={() => contracts.mutate()}
        />

        <CandidateDuplicate candidate={candidate} />

        {hasContracts && (
          <AccountsForReview
            onReviewClick={(account) =>
              setCurrentDialog({ type: 'review-account', account })
            }
            candidateId={candidate._id}
          />
        )}

        {hasContracts && (
          <TimeoffForReview
            candidateId={candidate._id}
            onReviewClick={(request) =>
              setCurrentDialog({ type: 'review-timeoff-request', request })
            }
          />
        )}

        <FunnelBar
          key={`status-${candidate.status}`}
          entity={candidate}
          collection="candidates"
          onSuccess={mutate}
        />

        <PositionLinker
          key={`position-${candidate.positionId}`}
          candidate={candidate}
          onSuccess={handleCandidateChange}
        />

        {hasContracts && (
          <div style={{ padding: '0px 30px 10px 30px' }}>
            <Button.Group basic fluid size="tiny">
              <Button
                active={selectedTab === 'profile'}
                onClick={() => setSelectedTab('profile')}
              >
                <Icon name="user outline" />
                Profile
              </Button>

              <Button
                active={selectedTab === 'contract'}
                onClick={() => setSelectedTab('contract')}
              >
                <Icon name="file alternate outline" />
                Contract
              </Button>

              <Button
                active={selectedTab === 'devices'}
                onClick={() => setSelectedTab('devices')}
                disabled
              >
                <Icon name="tablet alternate" />
                Devices
              </Button>
            </Button.Group>
          </div>
        )}

        {selectedTab === 'profile' && (
          <React.Fragment>
            <Submissions
              candidateId={candidate._id}
              positionId={candidate.positionId}
            />

            <CandidateSidePanel
              candidate={candidate}
              onChangeSuccess={handleCandidateChange}
            />
          </React.Fragment>
        )}

        {selectedTab === 'contract' && (
          <React.Fragment>
            <RequirePowers powers={[POWERS.contracts]}>
              <Contracts
                onContractRemovedOrTerminated={() => contracts.mutate()}
                candidate={candidate}
              />
            </RequirePowers>

            <Section icon="dollar" title="Payment Setup & Accounts">
              <AccountsSidePanel
                candidateId={candidate._id}
                onAccountViewClick={(account) =>
                  setCurrentDialog({ type: 'account-details', account })
                }
                onAccountReviewClick={(account) =>
                  setCurrentDialog({ type: 'review-account', account })
                }
              />
            </Section>
          </React.Fragment>
        )}
      </div>

      <div className="w-full min-w-[500px] p-6">
        <Feed
          relatedObject={candidate}
          relatedId={candidate._id}
          relatedCollection="candidates"
          showFilter
        />
      </div>

      <LinkedinViewer url={candidate.linkedin} />

      <AccountModal
        onAccountUpdate={handleAccountUpdate}
        currentDialog={
          currentDialog?.type !== 'review-timeoff-request' &&
          currentDialog?.type !== 'approve-timeoff-request' &&
          currentDialog?.type !== 'reject-timeoff-request'
            ? currentDialog
            : null
        }
        isPending={isPending}
        onClose={() => setCurrentDialog(null)}
        onApprove={(account) =>
          setCurrentDialog({ type: 'approve-account-confirm', account })
        }
        onReject={(account) =>
          setCurrentDialog({ type: 'reject-account-confirm', account })
        }
      />

      <TimeoffModal
        candidateId={candidateId}
        currentDialog={
          currentDialog?.type !== 'approve-account-confirm' &&
          currentDialog?.type !== 'reject-account-confirm' &&
          currentDialog?.type !== 'account-details' &&
          currentDialog?.type !== 'review-account'
            ? currentDialog
            : null
        }
        onClose={() => setCurrentDialog(null)}
        onApprove={(request) =>
          setCurrentDialog({ type: 'approve-timeoff-request', request })
        }
        onReject={(request) =>
          setCurrentDialog({ type: 'reject-timeoff-request', request })
        }
      />
    </div>
  )
}

export default CandidatePage
