import { useContext, useEffect } from 'react'

import MainContext, { SortableState } from '../store/mainContext'

interface ReturnType {
  sortByDir: SortableState['sortByDir']
  sortBy: string
  register(propId: string): {
    'aria-sort': 'ascending' | 'descending' | 'none'
    onClick(): void
  }
}

export default function useSortable(
  listId: string,
  init: { sortBy: string; sortByDir: 'ascending' | 'descending' },
): ReturnType {
  const { state, actions } = useContext(MainContext)

  const setSortable = actions.setSortable

  const initialSortByDir = init.sortByDir
  const initialSortBy = init.sortBy

  const currentSortByDir = state.sortable[listId]?.sortByDir
  const currentSortBy = state.sortable[listId]?.sortBy

  useEffect(() => {
    if (currentSortBy) return
    setSortable((prev) => {
      return {
        ...prev,
        [listId]: { sortByDir: initialSortByDir, sortBy: initialSortBy },
      }
    })
  }, [listId, setSortable, currentSortBy, initialSortBy, initialSortByDir])

  const register = (propId: string) => {
    return {
      'aria-sort':
        currentSortBy === propId
          ? currentSortByDir === 'ascending'
            ? ('ascending' as const)
            : ('descending' as const)
          : ('none' as const),
      onClick: () => {
        setSortable((prev) => ({
          ...prev,
          [listId]: {
            sortByDir:
              prev[listId]?.sortByDir === 'ascending'
                ? 'descending'
                : 'ascending',
            sortBy: propId,
          },
        }))
      },
    }
  }

  return {
    sortByDir: currentSortByDir || initialSortByDir,
    sortBy: currentSortBy || initialSortBy,
    register,
  }
}
