import { Button, Confirm, ConfirmProps } from 'semantic-ui-react'
import * as React from 'react'

interface Props {
  onConfirm: ConfirmProps['onConfirm']
  onCancel: ConfirmProps['onCancel']
  isPending?: boolean
  open: ConfirmProps['open']
}

export default function ConfirmRemoveCustomTxModal(props: Props) {
  const { isPending, ...other } = props

  return (
    <Confirm
      header="Are you sure?"
      content={
        <div className="px-6 pb-4">
          <p className="text-base">This action will:</p>
          <ul className="list-disc space-y-2 pl-6 text-lg">
            <li>Delete the selected custom transaction.</li>
            <li>Re-calculate the amount the candidate will receive.</li>
          </ul>
          <p className="mt-4 text-base font-semibold">
            This action cannot be undone.
          </p>
        </div>
      }
      closeOnDimmerClick={!isPending}
      closeOnEscape={!isPending}
      size="tiny"
      cancelButton={<Button disabled={isPending} content="Cancel" />}
      confirmButton={
        <Button
          disabled={isPending}
          color="red"
          primary={false}
          loading={isPending}
        >
          Yes, delete the transaction
        </Button>
      }
      {...other}
    />
  )
}
