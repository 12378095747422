import React, { useEffect, useState } from 'react'
import { Menu, Breadcrumb } from 'semantic-ui-react'
import { Link, LinkProps } from 'react-router-dom'

interface Props {
  title?: React.ReactNode
  views?: string[]
  onViewChange?(view: string): void
  breadcrumb?: { text: string; sub?: string; link?: LinkProps['to'] }[]
  filters?: React.ReactNode
  actions?: React.ReactNode
  footer?: React.ReactNode
  search?: React.ReactNode
  sub?: React.ReactNode
}

const PageHeader = ({
  onViewChange,
  breadcrumb,
  filters,
  actions,
  search,
  footer,
  views,
  title,
  sub,
}: Props) => {
  const [activeView, setActiveView] = useState<string>()

  useEffect(() => {
    setActiveView((views || [])[0])
  }, [views])

  const handleViewClick = (view: string) => {
    setActiveView(view)
    onViewChange && onViewChange(view)
  }

  return (
    <React.Fragment>
      {breadcrumb && (
        <div className="mx-14 my-3">
          <Breadcrumb>
            {(breadcrumb || []).map((x, index) => {
              if (index === breadcrumb.length - 1) {
                return (
                  <Breadcrumb.Section active key={x.text}>
                    {x.text}{' '}
                    {x.sub && (
                      <span className="font-normal text-slate-500">
                        ({x.sub})
                      </span>
                    )}
                  </Breadcrumb.Section>
                )
              }

              return (
                <React.Fragment key={x.text}>
                  <Breadcrumb.Section>
                    {x.link ? <Link to={x.link}>{x.text}</Link> : x.text}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </div>
      )}

      {title && (
        <div>
          <div className="mx-14 my-6 flex justify-between gap-3">
            <div className="grow">
              {typeof title === 'string' ? (
                <h1 className="mb-1">{title}</h1>
              ) : (
                <div>{title}</div>
              )}
              {typeof sub === 'string' ? (
                <p className="max-w-3xl leading-normal text-slate-500">{sub}</p>
              ) : (
                <div>{sub}</div>
              )}
            </div>

            <div>{filters}</div>

            <div className="flex justify-between gap-3">
              <div>{search}</div>
              <div>{actions}</div>
            </div>
          </div>

          {views && (
            <Menu secondary pointing style={{ marginTop: -20 }}>
              <Menu.Item name=" " key={views.toString()} />
              {(views || []).map((x) => (
                <Menu.Item
                  key={x}
                  name={x}
                  active={x === activeView}
                  onClick={() => handleViewClick(x)}
                />
              ))}
            </Menu>
          )}

          {footer}
        </div>
      )}
    </React.Fragment>
  )
}

export default PageHeader
