import { Icon, Popup, PopupProps } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { Collection, relatedNoteSchema } from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'
import UserName from './remoteValues/userName'

interface Props extends PopupProps {
  relatedCollection: Collection
  relatedId: string
}

export default function RelatedNotesPopup(props: Props) {
  const { relatedCollection, relatedId, ...otherProps } = props

  const api = useApi()

  const { data, isLoading, error } = useSWR(
    ['related-notes', relatedCollection, relatedId],
    () =>
      api
        .get('related-notes', {
          params: { relatedCollection, relatedId },
          headers: {
            'astor-sortby': 'lastUpdateOn',
            'astor-sortby-dir': -1,
          },
        })
        .then(validateResponse(z.array(relatedNoteSchema))),
  )

  const notes = data || []

  if (isLoading || error || notes.length === 0) return null

  return (
    <Popup
      size="large"
      wide="very"
      position="top center"
      on="click"
      trigger={
        <span className="ml-1.5 cursor-pointer text-primary">
          <Icon name="sticky note outline" className="!mr-0" />{' '}
          {notes.length > 1 && (
            <span className="relative -left-0.5 text-sm">
              {notes.length.toString()}
            </span>
          )}
        </span>
      }
      {...otherProps}
    >
      <Popup.Header className="!text-sm uppercase text-primary">
        Related Notes
      </Popup.Header>
      <Popup.Content>
        <ul className="-mb-1 -mt-2 divide-y">
          {notes.map((note) => {
            return (
              <li key={note._id} className="py-3">
                <div className="text-base text-slate-700">{note.body}</div>
                <div className="pr-4 pt-1 text-sm text-slate-500">
                  Created on {format(note.createdOn, 'PPp')} by{' '}
                  <UserName id={note.createdBy} />
                </div>
              </li>
            )
          })}
        </ul>
      </Popup.Content>
    </Popup>
  )
}
