import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'
import ReactDOM from 'react-dom/client'
import React from 'react'

import { ENV_VARS } from './env'

import './variables.css'
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import './overrides.css'

import App from './App'

import { Provider as SessionProvider } from './pages/session/sessionContext'
import { Provider as MainProvider } from './store/mainContext'
import { ToastsProvider } from './components/toasts/ToastsProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <GoogleOAuthProvider
    onScriptLoadError={() => alert(`Couldn't load Google Sign In script`)}
    clientId={ENV_VARS.REACT_APP_GOOGLE_CLIENT_ID || ''}
  >
    <ToastsProvider>
      <MainProvider>
        <SessionProvider>
          <BrowserRouter>
            <SWRConfig
              value={{
                revalidateOnFocus:
                  ENV_VARS.REACT_APP_REVALIDATE_ON_FOCUS !== 'false',
                onError(error, key) {
                  console.warn(`SWR Error for key: ${key} (${error.message})`)
                },
                shouldRetryOnError: (err) => err.status !== 500,
              }}
            >
              <App />
            </SWRConfig>
          </BrowserRouter>
        </SessionProvider>
      </MainProvider>
    </ToastsProvider>
  </GoogleOAuthProvider>,
)
