import { Redirect, useHistory, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useSWRConfig } from 'swr'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'
import { Menu } from 'semantic-ui-react'

import { BillingTransaction, BillingTransactionStatus } from '../../types'
import useInvoice, { EnhancedInvoice } from '../../hooks/useInvoice'
import InvoiceDeliveryDetails from '../../components/invoices/InvoiceDeliveryDetails'
import InvoiceDeliveries from '../../components/invoices/InvoiceDeliveries'
import InvoiceComponent from '../../components/invoices/Invoice'
import InvoicePayments from '../../components/invoices/InvoicePayments'
import InvoiceActions from '../../components/invoices/InvoiceActions'
import { useToasts } from '../../components/toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'
import PageLoader from '../../components/pageLoader'
import ChangeLog from '../../components/changeLog'
import Feed from '../../components/feeds/feed'

const ENABLED_TABS = [
  'feed',
  'changelog',
  'payments',
  'scheduled-emails',
] as const

type Tab = (typeof ENABLED_TABS)[number]

export default function InvoicePage() {
  const { invoiceId, tab } = useParams<{ invoiceId?: string; tab?: Tab }>()

  if (!invoiceId) {
    throw new Error('`invoiceId` param must be present in the URL.')
  }

  const { addToast } = useToasts()
  const { mutate } = useSWRConfig()
  const history = useHistory()
  const api = useApi()

  const invoice = useInvoice(invoiceId)

  const [selectedTab, setSelectedTab] = useState<Tab>(() =>
    tab && ENABLED_TABS.includes(tab) ? tab : 'feed',
  )

  const refreshChangelog = () => mutate(['changelog', 'invoice', invoiceId])

  React.useEffect(() => {
    if ((invoice.data?.totalPaid || 0) === 0 && selectedTab === 'payments') {
      setSelectedTab('feed')
    }
    if (!invoice.data?.sendAt && selectedTab === 'scheduled-emails') {
      setSelectedTab('feed')
    }
  }, [invoice, selectedTab])

  if (invoice.isLoading) return <PageLoader />

  if (!invoice.data) {
    addToast('Invoice not found', { variant: 'danger' })
    return <Redirect to="/invoices" />
  }

  const handleFieldUpdate = async (data: Partial<EnhancedInvoice>) => {
    if (!invoice.data) return
    return invoice.mutate(
      () =>
        api
          .patch('billing-invoices/' + invoiceId, data)
          .then(refreshChangelog)
          .catch((err: AxiosError) =>
            addToast(err.response?.data.message || err.message, {
              variant: 'danger',
            }),
          ),
      {
        optimisticData: { ...invoice.data, ...data },
        populateCache: false,
      },
    )
  }

  const handleDeliveryDetailsUpdate = async (
    data: Partial<EnhancedInvoice>,
  ) => {
    if (!invoice.data) return
    return api
      .patch('billing-invoices/' + invoiceId, data)
      .then(() => Promise.all([refreshChangelog(), invoice.mutate()]))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
  }

  const handleFieldDelete = async (fieldName: keyof EnhancedInvoice) => {
    if (!invoice.data) return
    const optimisticData = { ...invoice.data }
    delete optimisticData[fieldName]
    return invoice.mutate(
      () =>
        api
          .delete(`billing-invoices/${invoiceId}/${fieldName}`)
          .then(refreshChangelog)
          .catch((err: AxiosError) =>
            addToast(err.response?.data.message || err.message, {
              variant: 'danger',
            }),
          ),
      { optimisticData, populateCache: false },
    )
  }

  const handleTxStatusUpdate = async (
    txId: BillingTransaction['_id'],
    status: BillingTransactionStatus,
  ) => {
    if (!invoice.data) return
    return invoice.mutate(
      () =>
        api
          .patch('/billing-transactions/' + txId, { status })
          .then(refreshChangelog)
          .catch((e: AxiosError) => {
            addToast(e.response?.data.message || e.message, {
              variant: 'danger',
            })
          }),
      {
        optimisticData: {
          ...invoice.data,
          transactions: (invoice.data.transactions || []).map((tx) =>
            tx._id === txId ? { ...tx, status } : tx,
          ),
        },
        populateCache: false,
      },
    )
  }

  const handleTxCreate = async (data: BillingTransaction) => {
    return api
      .post('billing-transactions', data)
      .then(() => invoice.mutate())
      .then(refreshChangelog)
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
  }

  const handleTxDelete = async (txId: BillingTransaction['_id']) => {
    if (!invoice.data) return
    return invoice.mutate(
      api.delete('billing-transactions/' + txId).catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      }),
      {
        optimisticData: {
          ...invoice.data,
          transactions: (invoice.data.transactions || []).filter(
            (tx) => tx._id !== txId,
          ),
        },
        populateCache: false,
      },
    )
  }

  return (
    <div className="flex h-screen justify-between bg-slate-100">
      <Helmet>
        <title>
          {invoice.data.name ? `${invoice.data.name} - Invoice` : 'New Invoice'}
        </title>
      </Helmet>

      <div className="sticky top-0 grow overflow-y-auto px-8 [&::-webkit-scrollbar]:hidden">
        <div className="relative z-20 mx-auto max-w-4xl py-6">
          <InvoiceActions
            invoice={invoice.data}
            onUpdateSuccess={() => invoice.mutate().then(refreshChangelog)}
            onDeleteSuccess={() => history.push('/invoices')}
            showFinalActions
          />
        </div>

        <div className="relative z-10 mx-auto max-w-4xl">
          <InvoiceDeliveryDetails
            onFieldUpdate={handleDeliveryDetailsUpdate}
            invoice={invoice.data}
            key={invoice.data._id}
          />
        </div>

        <div className="mx-auto max-w-4xl pb-8">
          <InvoiceComponent
            onTxStatusUpdate={handleTxStatusUpdate}
            onTxCreate={handleTxCreate}
            onTxDelete={handleTxDelete}
            onFieldUpdate={handleFieldUpdate}
            onFieldDelete={handleFieldDelete}
            invoice={invoice.data}
          />
        </div>
      </div>

      <div className="w-full min-w-[300px] max-w-[500px] overflow-y-auto border-l bg-white p-6 [&::-webkit-scrollbar]:hidden">
        <Menu tabular>
          <Menu.Item
            icon="feed"
            name="Feed"
            active={selectedTab === 'feed'}
            onClick={() => setSelectedTab('feed')}
          />
          <Menu.Item
            icon="history"
            name="Change Log"
            active={selectedTab === 'changelog'}
            onClick={() => setSelectedTab('changelog')}
          />
          <Menu.Item
            name="Deliveries"
            active={selectedTab === 'scheduled-emails'}
            onClick={() => setSelectedTab('scheduled-emails')}
            disabled={!invoice.data?.sendAt}
          />
          <Menu.Item
            name="Payments"
            active={selectedTab === 'payments'}
            onClick={() => setSelectedTab('payments')}
            disabled={(invoice.data.totalPaid || 0) === 0}
          />
        </Menu>
        {selectedTab === 'feed' && (
          <Feed
            relatedCollection="billing-invoices"
            relatedId={invoiceId}
            showEditor={false}
          />
        )}
        {selectedTab === 'changelog' && (
          <ChangeLog entityName="billing-invoices" id={invoiceId} />
        )}
        {selectedTab === 'payments' && (
          <InvoicePayments invoiceId={invoiceId} />
        )}
        {selectedTab === 'scheduled-emails' && (
          <InvoiceDeliveries invoiceId={invoiceId} />
        )}
      </div>
    </div>
  )
}
