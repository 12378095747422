import { Button, Input } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import FuzzySearch from 'fuzzy-search'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import * as React from 'react'

import PaymentOrderStatusLabel from '../../components/payment-orders/PaymentOrderStatusLabel'
import PagedTable, { Column } from '../../components/dataTable/pagedTable'
import { currencyFormat } from '../../utils'
import { PaymentOrder } from '../../types'
import { useToasts } from '../../components/toasts/ToastsProvider'
import PageHeader from '../../components/pageHeader/pageHeader'
import { useApi } from '../../store/mainContext'

const columns: Column[] = [
  {
    title: 'Name',
    field: 'name',
    render: (name: PaymentOrder['name']) =>
      name || <span className="text-slate-400">New Payment Order</span>,
  },
  {
    title: 'Description',
    field: 'description',
    render: (description: PaymentOrder['description']) =>
      description || <span className="text-slate-400">No description</span>,
  },
  {
    title: 'Total',
    align: 'center',
    width: '1%',
    field: 'total',
    render: (total: PaymentOrder['total']) => (
      <span className="text-nowrap">
        {typeof total === 'number' ? (
          currencyFormat(total)
        ) : (
          <span className="text-slate-400">Unknown total</span>
        )}
      </span>
    ),
  },
  {
    title: 'Status',
    align: 'center',
    width: '1%',
    field: 'status',
    render: (_, paymentOrder: PaymentOrder) => (
      <PaymentOrderStatusLabel paymentOrder={paymentOrder} />
    ),
  },
  {
    title: 'Completed on',
    width: '1%',
    field: 'completedOn',
    align: 'center',
    render: (date: PaymentOrder['completedOn']) =>
      date ? format(date, 'dd/MM/yyyy') : '-',
  },
]

export default function PaymentOrders() {
  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [paymentOrdersSearch, setPaymentOrdersSearch] = React.useState<
    string | null
  >('')

  const [isCreating, setIsCreating] = React.useState(false)

  const handleNewPaymentOrder = async () => {
    setIsCreating(true)
    return api
      .post<PaymentOrder>('payment-orders', {})
      .then((res) => history.push(`/payment-orders/${res.data._id}`))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreating(false))
  }

  return (
    <>
      <Helmet>
        <title>Payment Orders</title>
      </Helmet>

      <PageHeader
        title="Payment Orders"
        sub="One screen to pay them all."
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Payemnt Orders' },
        ]}
        actions={
          <Button
            onClick={handleNewPaymentOrder}
            disabled={isCreating}
            loading={isCreating}
            content="New Payment Order"
            className="!mr-0"
            icon="add"
            basic
          />
        }
      />

      <div className="mx-14 mb-14">
        <div className="flex flex-wrap gap-3 px-3 pb-5 pt-3">
          <div className="flex items-baseline">
            <span className="mr-2 whitespace-nowrap">Looking for</span>
            <Input
              className="w-48"
              size="mini"
              onChange={(e) => setPaymentOrdersSearch(e.target.value)}
              value={paymentOrdersSearch || ''}
              input={
                <input
                  style={{ fontSize: 'unset' }}
                  placeholder="All Payment Orders"
                  className="placeholder:![color:rgba(0,0,0,.6)] placeholder:![font-size:12px]"
                />
              }
            />
          </div>
        </div>

        <PagedTable
          defaultSort="lastUpdateOn"
          collection="payment-orders"
          onRowClick={(row: PaymentOrder) =>
            history.push(`/payment-orders/${row._id}`)
          }
          posthook={(paymentOrders) =>
            new FuzzySearch(paymentOrders || [], [
              'name',
              'description',
            ]).search(paymentOrdersSearch || '')
          }
          columns={columns}
          footer
          pro={false}
        />
      </div>
    </>
  )
}
