import { format, getYear, isPast } from 'date-fns'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { groupBy } from 'ramda'
import { Loader } from 'semantic-ui-react'
import * as React from 'react'

import useHolidaysForCandidate from '../../hooks/useHolidays'
import { classNames } from '../../utils'
import { Candidate } from '../../types'

interface Props {
  candidateId: Candidate['_id']
}

export default function TimeoffHolidays(props: Props) {
  const holidays = useHolidaysForCandidate(props.candidateId)

  // group holidays by year so we can render the list in sections
  const years = groupBy((holiday) => {
    return getYear(holiday.date).toString()
  }, holidays.data || [])

  return (
    <div className="px-6 pb-4">
      {holidays.data &&
        (holidays.data.length ? (
          Object.keys(years)
            .sort((a, b) => Number(a) - Number(b))
            .map((year) => {
              return (
                <section className="mt-5" key={year}>
                  <div className="px-3 text-base text-blue-900">{year}</div>
                  <ol className="mt-1 space-y-1">
                    {(years[year] || [])
                      .sort((a, b) => (a.ndate || 0) - (b.ndate || 0))
                      .map((holiday) => {
                        return (
                          <li
                            className={classNames(
                              isPast(holiday.date)
                                ? 'bg-slate-50 opacity-60'
                                : 'bg-slate-100',
                              'flex items-baseline gap-2 rounded-md px-3 py-2',
                            )}
                            key={`${holiday.date}-${holiday.name}`}
                          >
                            <div className="grow text-base font-semibold text-slate-500">
                              {format(holiday.date, 'MMMM do')}
                            </div>
                            <div className="text-base text-slate-500">
                              {holiday.name}
                            </div>
                          </li>
                        )
                      })}
                  </ol>
                </section>
              )
            })
        ) : (
          <p className="mt-6 text-center text-slate-400">
            This contracts has no holidays configured yet.
          </p>
        ))}

      {!holidays.data && !holidays.error && (
        <div className="text-center">
          <Loader active size="mini" inline />
        </div>
      )}

      {holidays.error && !holidays.data && (
        <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {holidays.error.message}
        </div>
      )}
    </div>
  )
}
