import { Button, Icon } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import { Collection, Recipe, RecipeSchedule } from '../../types'
import RemoteValue from '../remoteValues/remoteValue'
import usePowers from '../../hooks/usePowers'
import featureToggles from '../../featureToggles'

interface Props {
  onUpdateScheduleClick(schedule: RecipeSchedule): void
  onDeleteScheduleClick(schedule: RecipeSchedule): void
  onCreateScheduleClick(): void
  relatedCollection: Collection
  schedules: RecipeSchedule[]
}

export default function CardRecipes(props: Props) {
  const {
    onUpdateScheduleClick,
    onDeleteScheduleClick,
    onCreateScheduleClick,
    schedules,
  } = props

  const powers = usePowers()

  return (
    <React.Fragment>
      {schedules.length ? (
        <React.Fragment>
          <div className="sticky top-0 z-10 flex items-baseline justify-between border-b bg-white py-2 pl-4 pr-2 text-xs font-semibold uppercase text-slate-700">
            Recipes
            <Button
              className="!mr-0"
              onClick={onCreateScheduleClick}
              basic
              compact
              size="mini"
              type="button"
              icon="plus"
            />
          </div>

          <ul className="py-1">
            {schedules.map((schedule) => {
              return (
                <li
                  key={schedule._id}
                  className="group relative cursor-pointer py-2 pl-4 pr-12 hover:bg-slate-50"
                  onClick={() => onUpdateScheduleClick(schedule)}
                >
                  <RemoteValue
                    predicate={(r: Recipe) => (
                      <span className="flex gap-2 font-semibold !text-slate-700">
                        <span className="!text-slate-500">
                          <Icon name="lab" fitted />
                        </span>
                        {r.name}
                      </span>
                    )}
                    collection="recipes"
                    id={schedule.recipeId}
                  />{' '}
                  <div className="mt-0.5 text-sm text-slate-500">
                    Will run on {format(schedule.whenToRun || 0, 'PPp')}
                  </div>
                  <div className="absolute right-2 top-1.5 opacity-0 group-hover:opacity-100">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        onDeleteScheduleClick(schedule)
                      }}
                      className="!mr-0"
                      icon="trash alternate outline"
                      compact
                      basic
                      size="mini"
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </React.Fragment>
      ) : (
        powers.hasAll(['run-and-schedule-recipes']) &&
        featureToggles.recipes && (
          <div className="px-4 py-3">
            <Button
              className="!mr-0"
              onClick={onCreateScheduleClick}
              basic
              content="Schedule a Recipe"
              compact
              type="button"
              icon="lab"
            />
          </div>
        )
      )}
    </React.Fragment>
  )
}
