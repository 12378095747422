import React, { useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useTalentApi } from '../store/mainContext'
import { ENV_VARS } from '../env'
import StatusNames from './remoteValues/statusNames'
import FunnelName from './remoteValues/funnelName'
import DataTable from './dataTable/dataTable'
import UserName from './remoteValues/userName'
import ShowIf from './showIf'
import Period from './period'

const DetailPanel = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: inset 0 2px 8px 0px rgba(0, 0, 0, 0.2);
  border: solid 1px var(--border-grey);
  padding-bottom: 20px;
  background-color: #fff;
`

const Content = styled.div`
  padding: 25px;
`

const Info = styled.p`
  font-size: 14px;
  display: flex;

  div {
    margin-right: 25px;
  }
`

const SubTitle = styled.h2`
  margin-top: 40px;
  font-size: 22px;
`

const Loader = styled.img`
  width: 200px;
`

const ContentLoader = styled.div`
  text-align: center;
  padding: 150px 0px;
`

const PositionDetails = ({ show, position }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [candidates, setCandidates] = useState([])

  const history = useHistory()
  const api = useTalentApi()

  const refresh = React.useCallback(async () => {
    setIsLoading(true)

    const { data: cand } = await api.get(`candidates`, {
      params: { positionId: position._id },
    })

    const { data: tasks } = await api.get(`tasks/pro`, {
      params: {
        relatedId: { $in: cand.map((x) => x._id) },
        isDone: { $in: [null, false] },
      },
    })

    setCandidates(
      cand.map((c) => {
        c.nextTask = tasks
          .filter((t) => t.relatedId === c._id)
          .sort((a, b) => a.dueDate - b.dueDate)[0]
        return c
      }),
    )

    setIsLoading(false)
  }, [api, position._id])

  useEffect(() => {
    if (!position || !show) return
    refresh()
  }, [position, show, refresh])

  if (!show) return null

  return (
    <DetailPanel>
      <Content>
        <div
          style={{
            borderBottom: 'dotted 1px var(--border-grey)',
            paddingBottom: 20,
          }}
        >
          <Info>
            <div>
              <b>Openings:</b> <span> {position.openings}</span>{' '}
            </div>
            <div>
              {' '}
              <b>Fee (Average):</b> <span>$ {position.salary}</span>{' '}
            </div>
            <div>
              {' '}
              <b>Original JD:</b>{' '}
              <a
                rel="noreferrer"
                href={position.originalJD}
                target={
                  ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                    ? '_blank'
                    : undefined
                }
              >
                View
              </a>
            </div>
            <div>
              {' '}
              <b>Open By:</b> <UserName id={position.openBy} />
            </div>
          </Info>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginTop: 25,
          }}
        >
          <SubTitle> Candidates</SubTitle>
          <Button basic icon size="tiny" onClick={refresh}>
            <Icon name="refresh" /> Refresh
          </Button>
        </div>

        {isLoading ? (
          <ContentLoader>
            <Loader src="/img/loader1.gif" alt="loading..." />
          </ContentLoader>
        ) : candidates.length === 0 ? (
          <ContentLoader>
            <h1>No Candidates :(</h1>
          </ContentLoader>
        ) : (
          <DataTable>
            <thead>
              <tr>
                <DataTable.Th width={200}>Name</DataTable.Th>
                <DataTable.Th align="center">Fee</DataTable.Th>
                <DataTable.Th align="center" width={80}>
                  YoE
                </DataTable.Th>
                <DataTable.Th align="center">Location</DataTable.Th>
                <DataTable.Th align="center" width={150}>
                  Referal
                </DataTable.Th>
                <DataTable.Th align="center" width={150}>
                  Status
                </DataTable.Th>
                <DataTable.Th align="center" width={150}>
                  Next <br />
                  Activity
                </DataTable.Th>
              </tr>
            </thead>

            <tbody>
              {candidates.map((c) => (
                <tr
                  key={c._id}
                  onClick={() =>
                    (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? window.open
                      : history.push)(`/candidates/${c._id}`)
                  }
                >
                  <DataTable.Td>{c.name}</DataTable.Td>

                  <DataTable.Td align="center">$ {c.salary || 0}</DataTable.Td>

                  <DataTable.Td align="center">
                    {c.yearsInTheRole} - {c.yearsInTheIndustry}
                  </DataTable.Td>
                  <DataTable.Td align="center">{c.location}</DataTable.Td>
                  <DataTable.Td align="center">{c.referal}</DataTable.Td>

                  <DataTable.Td align="center">
                    <ShowIf if={c.status} false={<b>No Stage</b>}>
                      <React.Fragment>
                        <StatusNames status={c.status} /> <br />
                        <p style={{ fontSize: 14 }}>
                          <FunnelName status={c.status} />
                        </p>
                        <p style={{ fontSize: 14 }}>
                          <Period date={c.lastStatusUpdate} />
                        </p>
                      </React.Fragment>
                    </ShowIf>
                  </DataTable.Td>

                  <DataTable.Td align="center">
                    <ShowIf if={c.nextTask} false={<b>Nothing Scheduled</b>}>
                      <React.Fragment>
                        {c.nextTask && c.nextTask.subject}
                        <p style={{ fontSize: 14, marginTop: 10 }}>
                          <Period date={c.nextTask && c.nextTask.dueDate} />
                        </p>
                      </React.Fragment>
                    </ShowIf>
                  </DataTable.Td>
                </tr>
              ))}
            </tbody>
          </DataTable>
        )}
      </Content>
    </DetailPanel>
  )
}

export default PositionDetails
