import * as React from 'react'

import { classNames } from '../../utils'
import { Payment } from '../../types'

interface Props {
  status: Payment['status']
}

export default function PaymentStatusLabel(props: Props) {
  const { status } = props

  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase',
        status === 'Draft' && 'text-slate-400/90',
        status === 'Partially Allocated' && 'text-amber-600',
        status === 'Fully Allocated' && 'text-green-600',
      )}
    >
      {status}
    </span>
  )
}
