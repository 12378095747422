import React from 'react'

import SimpleFilter from './simpleFilter'

const statusOptions = [
  { key: 0, value: undefined, text: 'All Billing Periods' },
  { key: 1, value: 'Draft', text: 'Draft Billing Periods' },
  { key: 2, value: 'Completed', text: 'Completed Billing Periods' },
]

export interface Filter {
  status?: (typeof statusOptions)[number]['value']
}

interface Props {
  onChange: React.Dispatch<React.SetStateAction<Filter>>
  value: Filter
}

export default function BillingPeriodsFilter({ onChange, value }: Props) {
  const handleStatusChange = (
    _: Filter,
    name: 'status',
    newStatus: Filter['status'],
  ) => {
    onChange((prev) => ({ ...prev, status: newStatus }))
  }

  return (
    <div className="flex items-baseline gap-2 py-3">
      <div>Showing</div>
      <SimpleFilter
        name="status"
        onFilterChange={handleStatusChange}
        options={statusOptions}
        value={value.status}
      />
    </div>
  )
}
