import * as React from 'react'
import { Icon } from 'semantic-ui-react'

import { classNames } from '../../utils'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-sort': 'none' | 'ascending' | 'descending'
  onClick(): void
  shrink?: boolean
  children: React.ReactNode
}

export default function ThSortable(props: Props) {
  const { children, onClick, 'aria-sort': ariaSort, shrink, ...other } = props

  return (
    <th className={classNames('p-0', shrink && 'w-[1%]')} aria-sort={ariaSort}>
      <button
        {...other}
        className={classNames(
          'block w-full text-nowrap text-left text-sm uppercase text-primary hover:bg-slate-50',
          other.className || '',
        )}
        onClick={onClick}
        type="button"
      >
        {children}
        <Icon
          className={classNames(
            'relative left-1',
            ariaSort === 'none' ? '!opacity-0' : 'opacity-100',
          )}
          name={ariaSort === 'ascending' ? 'caret up' : 'caret down'}
          fitted
        />
      </button>
    </th>
  )
}
