import {
  SubmitHandler,
  useFieldArray,
  Controller,
  useForm,
} from 'react-hook-form'
import { Button, Checkbox, Form, Icon, Input, Ref } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { AxiosError } from 'axios'
import * as React from 'react'

import {
  EnhancedInvoice,
  EnhancedPayment,
} from '../../hooks/useBillingPayments'
import { classNames, currencyFormat, toUtc0Timestamp } from '../../utils'
import AttachmentUploadField from './AttachmentUploadField'
import InvoiceStatusLabel from '../invoices/InvoiceStatusLabel'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

const ALLOCATION_AMOUNTS_ERROR_KEY = 'allocation-amounts'

type AllocationField = {
  enabled: boolean
  invoiceId: string
  amount: number | null
}

type FormValues = {
  transferDescription: string
  transferFrom: string
  amount: number | null
  date: number
  attachment: string
  existingAllocations: (AllocationField & { id: string })[]
  newAllocations: AllocationField[]
  [ALLOCATION_AMOUNTS_ERROR_KEY]: any
}

interface Props {
  onSuccess(shouldCloseDialog?: boolean): Promise<any>
  onCancel(): void
  invoices: EnhancedInvoice[]
  payment: EnhancedPayment
}

export default function UpdatePaymentForm(props: Props) {
  const { onSuccess, onCancel, invoices, payment } = props

  const { addToast } = useToasts()
  const api = useApi()

  const form = useForm<FormValues>({
    defaultValues: {
      transferDescription: payment.transferDescription || '',
      transferFrom: payment.transferFrom || '',
      attachment: payment.attachment || '',
      amount: payment.amount,
      date: payment.date,
      existingAllocations: payment.allocations.map((allocation) => ({
        enabled: true,
        invoiceId: allocation.invoiceId,
        amount: allocation.amount,
        id: allocation._id,
      })),
      newAllocations: invoices
        // filter out any invoice that is already allocated by this payment
        .filter((invoice) => {
          return !payment.allocations.some(
            (allocation) => allocation.invoiceId === invoice._id,
          )
        })
        .map((invoice) => ({
          enabled: false,
          invoiceId: invoice._id,
          amount: Number(
            ((invoice.totalBilled || 0) - (invoice.totalPaid || 0)).toFixed(2),
          ),
        })),
    },
  })

  const existingAllocationsField = useFieldArray({
    control: form.control,
    name: 'existingAllocations',
  })

  const newAllocationsField = useFieldArray({
    control: form.control,
    name: 'newAllocations',
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    return (
      // First, delete any existindg allocation that is currently disabled as
      // this will modify the payment allocated amount
      (async () => {
        for (let i = 0; i < data.existingAllocations.length; i++) {
          const currentAllocation = data.existingAllocations[i]
          if (currentAllocation?.enabled === false) {
            await api.delete(
              'billing-payments-allocations/' + currentAllocation.id,
            )
          }
        }
      })()
        // Second, update the payment just in case the amount changed.
        .then(() =>
          api.patch('billing-payments/' + payment._id, {
            transferDescription: data.transferDescription,
            transferFrom: data.transferFrom,
            amount: Number(data.amount || 0),
            date: toUtc0Timestamp(data.date),
            ...(data.attachment ? { attachment: data.attachment } : {}),
          }),
        )
        .then(() => {
          if (!data.attachment) {
            return api.delete(`billing-payments/${payment._id}/attachment`)
          }
        })
        // Third, update the existing enabled allocations, this could also
        // update the payment unallocated amount.
        .then(async () => {
          for (let i = 0; i < data.existingAllocations.length; i++) {
            const currentAllocation = data.existingAllocations[i]
            if (currentAllocation?.enabled) {
              await api.patch(
                'billing-payments-allocations/' + currentAllocation.id,
                { amount: Number(currentAllocation.amount || 0) },
              )
            }
          }
        })
        // Lastly, create any new allocation.
        .then(async () => {
          for (let i = 0; i < data.newAllocations.length; i++) {
            const currentAllocation = data.newAllocations[i]
            if (!currentAllocation?.enabled) continue
            await api.post('billing-payments-allocations', {
              invoiceId: currentAllocation.invoiceId,
              paymentId: payment._id,
              amount: Number(currentAllocation.amount || 0),
            })
          }
        })
        .then(() => onSuccess())
        .then(() => addToast('Payment updated', { variant: 'success' }))
        .catch((e: AxiosError) => {
          addToast(e.response?.data.message || e.message, { variant: 'danger' })
        })
    )
  }

  const unallocatedTotal =
    (form.watch('amount') || 0) -
    form.watch('existingAllocations').reduce((sum, allocation) => {
      return sum + (allocation.enabled ? Number(allocation.amount) || 0 : 0)
    }, 0) -
    form.watch('newAllocations').reduce((sum, allocation) => {
      return sum + (allocation.enabled ? Number(allocation.amount) || 0 : 0)
    }, 0)

  const hasAllocations =
    existingAllocationsField.fields.length + newAllocationsField.fields.length >
    0

  const { setError, clearErrors } = form

  React.useEffect(() => {
    if (unallocatedTotal < 0) {
      setError(ALLOCATION_AMOUNTS_ERROR_KEY, {
        type: 'custom',
        message: 'Payment amount is not enough',
      })
    } else {
      clearErrors(ALLOCATION_AMOUNTS_ERROR_KEY)
    }
  }, [unallocatedTotal, setError, clearErrors])

  const [isUploading, setIsUploading] = React.useState(false)

  return (
    <Form spellCheck="false" onSubmit={form.handleSubmit(onSubmit)}>
      <div className="lg:grid lg:grid-cols-[1fr_450px] lg:gap-4">
        <section>
          <div>
            <label className="block py-1 font-semibold leading-none text-slate-600">
              Customer
            </label>
            <Input disabled fluid value={payment.customer?.name || ''} />
          </div>

          <div className="mt-4 grid grid-cols-[1fr_auto] gap-4">
            <Controller
              name="amount"
              control={form.control}
              rules={{
                validate: (v) => {
                  if (!v) return 'Required'
                  return Number(v) > 0 ? true : 'Must be positive'
                },
              }}
              render={({ field, fieldState }) => (
                <div>
                  <label className="block py-1 font-semibold leading-none text-slate-600">
                    Amount
                  </label>
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(e.target.valueAsNumber)}
                    icon="dollar"
                    iconPosition="left"
                    disabled={form.formState.isSubmitting}
                    value={field.value === null ? '' : field.value}
                    type="number"
                    min={0}
                    step={0.01}
                    fluid
                    error={!!fieldState.error}
                  />
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </div>
              )}
            />

            <Controller
              name="date"
              control={form.control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => (
                <div className="[&>.field>.field]:!mb-0 [&>.field]:!mb-0">
                  <label className="block py-1 font-semibold leading-none text-slate-600">
                    Date
                  </label>
                  <SemanticDatepicker
                    {...field}
                    disabled={form.formState.isSubmitting}
                    onChange={(_, { value }) => {
                      field.onChange(
                        value instanceof Date ? value.getTime() : undefined,
                      )
                    }}
                    value={field.value ? new Date(field.value) : undefined}
                    clearOnSameDateClick={false}
                    datePickerOnly
                    clearable={false}
                    required
                    error={!!fieldState.error}
                  />
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </div>
              )}
            />
          </div>

          <Controller
            name="transferFrom"
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <div className="mt-4">
                <label className="flex justify-between py-1 font-semibold leading-none text-slate-600">
                  Transfer From{' '}
                  <span className="mr-1 font-normal text-slate-500">
                    16 chars max
                  </span>
                </label>
                <Input
                  {...field}
                  disabled={form.formState.isSubmitting}
                  error={!!fieldState.error}
                  fluid
                  input={<input data-1p-ignore maxLength={16} />}
                />
                <div className="text-base leading-normal text-red-600">
                  {fieldState.error?.message}
                </div>
              </div>
            )}
          />

          <div className="mt-4">
            <label className="block py-1 font-semibold leading-none text-slate-600">
              Transfer Description
            </label>
            <textarea
              {...form.register('transferDescription', {
                required: 'Required',
              })}
              rows={2}
              style={{ minHeight: 70 }}
              className={classNames(
                'field !mb-0 placeholder:!font-semibold',
                form.formState.errors.transferDescription && 'error',
                form.formState.isSubmitting && 'disabled',
              )}
              disabled={form.formState.isSubmitting}
            />
            <div className="text-base leading-normal text-red-600">
              {form.formState.errors.transferDescription?.message}
            </div>
          </div>

          <div className="mt-4 pb-1">
            <AttachmentUploadField
              onUploadStart={() => setIsUploading(true)}
              onUploadEnd={() => setIsUploading(false)}
              registerProps={form.register('attachment')}
              onUpload={(url) => form.setValue('attachment', url)}
              onClear={() => form.setValue('attachment', '')}
              value={form.watch('attachment')}
            />
          </div>
        </section>

        <section className="mt-6 lg:mt-0 lg:border-l lg:pl-4">
          <header className="text-left font-semibold text-slate-600">
            Payment allocation{' '}
            <span className="block text-sm font-normal text-slate-500">
              Assign invoices to this payment
            </span>
          </header>
          {hasAllocations ? (
            <table className="w-full">
              <thead>
                <tr className="border-b text-sm font-semibold uppercase text-primary">
                  <th className="px-2 pb-1 pt-3.5">Invoice</th>
                  <th className="w-40 px-2 pb-1 pt-3.5 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {existingAllocationsField.fields.map((field, index) => {
                  const alloc = payment.allocations.find(
                    (allocation) => allocation.invoiceId === field.invoiceId,
                  )
                  const isPaid = alloc?.invoice?.status === 'Paid'
                  const isEnabled = form.watch(
                    `existingAllocations.${index}.enabled`,
                  )
                  return (
                    <tr
                      key={field.id}
                      className="border-b align-top hover:bg-slate-50"
                    >
                      <td className="px-2 py-1.5">
                        <Controller
                          name={`existingAllocations.${index}.enabled`}
                          control={form.control}
                          render={({
                            field: { value, onChange, ref, ...other },
                          }) => (
                            <label
                              className={classNames(
                                'flex gap-2',
                                !form.formState.isSubmitting &&
                                  'cursor-pointer',
                              )}
                            >
                              <Ref innerRef={ref}>
                                <Checkbox
                                  {...other}
                                  disabled={form.formState.isSubmitting}
                                  checked={!!value}
                                  onChange={(_, p) => onChange(p.checked)}
                                />
                              </Ref>
                              <div>
                                <span
                                  className={classNames(
                                    isEnabled
                                      ? 'text-slate-800'
                                      : 'text-slate-500',
                                  )}
                                >
                                  {alloc?.invoice?.name || 'Unknown invoice'}
                                </span>
                                {isPaid && (
                                  <>
                                    <span className="mx-0.5 text-slate-400">
                                      &ndash;
                                    </span>
                                    <InvoiceStatusLabel status="Paid" />
                                  </>
                                )}
                                <div className="text-sm text-slate-500">
                                  <span className="text-xs font-semibold uppercase text-slate-400">
                                    Billed:{' '}
                                  </span>
                                  {currencyFormat(
                                    alloc?.invoice?.totalBilled || 0,
                                  )}
                                  {(alloc?.invoice?.totalPaid || 0) > 0 && (
                                    <>
                                      <span className="mx-0.5 text-slate-400">
                                        &ndash;
                                      </span>
                                      <span className="text-xs font-semibold uppercase text-slate-400">
                                        Paid:{' '}
                                      </span>
                                      {currencyFormat(
                                        alloc?.invoice?.totalPaid || 0,
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </label>
                          )}
                        />
                      </td>
                      <td className="px-2 py-1.5 align-top">
                        <Controller
                          name={`existingAllocations.${index}.amount`}
                          control={form.control}
                          rules={{
                            validate: (v, formValues) => {
                              if (
                                !formValues.existingAllocations[index]?.enabled
                              ) {
                                return true
                              }
                              if (!v) return 'Required'
                              if (
                                Number(v) > (alloc?.invoice?.totalBilled || 0)
                              ) {
                                return 'Exceeds invoice total'
                              }
                              return Number(v) > 0 ? true : 'Must be positive'
                            },
                          }}
                          render={({ field: f, fieldState }) => {
                            const isDisabled =
                              form.formState.isSubmitting || !isEnabled
                            const hasError =
                              !isDisabled &&
                              (!!fieldState.error ||
                                ((form.watch('amount') || 0) > 0 &&
                                  unallocatedTotal < 0))
                            return (
                              <div>
                                <label className="sr-only">Amount</label>
                                <Input
                                  {...f}
                                  onChange={(e) =>
                                    f.onChange(e.target.valueAsNumber)
                                  }
                                  disabled={isDisabled}
                                  value={f.value === null ? '' : f.value}
                                  type="number"
                                  fluid
                                  min={0}
                                  step={0.01}
                                  icon="dollar"
                                  iconPosition="left"
                                  input={
                                    <input className="!text-right disabled:!bg-slate-100" />
                                  }
                                  error={hasError}
                                />
                                <div className="text-nowrap text-right text-sm leading-tight text-red-600">
                                  {fieldState.error?.message}
                                </div>
                              </div>
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}

                {newAllocationsField.fields.map((field, index) => {
                  const currentInvoice = invoices.find(
                    (invoice) => invoice._id === field.invoiceId,
                  )
                  const isEnabled = form.watch(
                    `newAllocations.${index}.enabled`,
                  )
                  return (
                    <tr
                      key={field.id}
                      className="border-b align-top hover:bg-slate-50"
                    >
                      <td className="px-2 py-1.5">
                        <Controller
                          name={`newAllocations.${index}.enabled`}
                          control={form.control}
                          render={({
                            field: { value, onChange, ref, ...other },
                          }) => (
                            <label
                              className={classNames(
                                'flex gap-2',
                                !form.formState.isSubmitting &&
                                  'cursor-pointer',
                              )}
                            >
                              <Ref innerRef={ref}>
                                <Checkbox
                                  {...other}
                                  disabled={form.formState.isSubmitting}
                                  checked={!!value}
                                  onChange={(_, p) => onChange(p.checked)}
                                />
                              </Ref>
                              <div>
                                <span
                                  className={classNames(
                                    isEnabled
                                      ? 'text-slate-800'
                                      : 'text-slate-500',
                                  )}
                                >
                                  {currentInvoice?.name || 'Unknown invoice'}
                                </span>
                                <div className="text-sm text-slate-500">
                                  <span className="text-xs font-semibold uppercase text-slate-400">
                                    Billed:{' '}
                                  </span>
                                  {currencyFormat(
                                    currentInvoice?.totalBilled || 0,
                                  )}
                                  {(currentInvoice?.totalPaid || 0) > 0 && (
                                    <>
                                      <span className="mx-0.5 text-slate-400">
                                        &ndash;
                                      </span>
                                      <span className="text-xs font-semibold uppercase text-slate-400">
                                        Paid:{' '}
                                      </span>
                                      {currencyFormat(
                                        currentInvoice?.totalPaid || 0,
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </label>
                          )}
                        />
                      </td>
                      <td className="px-2 py-1.5 align-top">
                        <Controller
                          name={`newAllocations.${index}.amount`}
                          control={form.control}
                          rules={{
                            validate: (v, formValues) => {
                              if (!formValues.newAllocations[index]?.enabled) {
                                return true
                              }
                              if (!v) return 'Required'
                              if (
                                Number(v) > (currentInvoice?.totalBilled || 0)
                              ) {
                                return 'Exceeds invoice total'
                              }
                              return Number(v) > 0 ? true : 'Must be positive'
                            },
                          }}
                          render={({ field: f, fieldState }) => {
                            const isDisabled =
                              form.formState.isSubmitting || !isEnabled
                            const hasError =
                              !isDisabled &&
                              (!!fieldState.error ||
                                ((form.watch('amount') || 0) > 0 &&
                                  unallocatedTotal < 0))
                            return (
                              <div>
                                <label className="sr-only">Amount</label>
                                <Input
                                  {...f}
                                  onChange={(e) =>
                                    f.onChange(e.target.valueAsNumber)
                                  }
                                  disabled={isDisabled}
                                  value={f.value === null ? '' : f.value}
                                  type="number"
                                  fluid
                                  min={0}
                                  step={0.01}
                                  icon="dollar"
                                  iconPosition="left"
                                  input={
                                    <input className="!text-right disabled:!bg-slate-100" />
                                  }
                                  error={hasError}
                                />
                                <div className="text-nowrap text-right text-sm leading-tight text-red-600">
                                  {fieldState.error?.message}
                                </div>
                              </div>
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr
                  className={classNames(
                    'h-10 text-sm',
                    form.watch('amount') || 0 ? '' : 'opacity-0',
                  )}
                >
                  {unallocatedTotal === 0 ? (
                    <td colSpan={2} className="py-2 pl-[40px] pr-2">
                      <span className="relative text-green-600">
                        <Icon
                          className="absolute -left-4 top-1"
                          name="check"
                          size="small"
                        />
                        Payment is fully allocated
                      </span>
                    </td>
                  ) : unallocatedTotal > 0 ? (
                    <>
                      <td className="py-2 pl-[40px] pr-2">
                        Unallocated amount
                      </td>
                      <td className="pl-2 pr-[22px] text-right text-slate-500">
                        {currencyFormat(unallocatedTotal)}
                      </td>
                    </>
                  ) : (
                    <td
                      colSpan={2}
                      className="py-2 pl-[40px] pr-2 text-red-500"
                    >
                      <>
                        {
                          form.formState.errors[ALLOCATION_AMOUNTS_ERROR_KEY]
                            ?.message
                        }
                      </>
                    </td>
                  )}
                </tr>
              </tfoot>
            </table>
          ) : (
            <div className="mt-4 bg-slate-50 px-2 py-4 text-center text-base italic text-slate-600">
              No pending invoices for this customer!
            </div>
          )}
        </section>
      </div>

      <div className="mt-8 flex items-start gap-2 lg:mt-4 lg:border-t lg:pt-6">
        <Button
          content="Save"
          disabled={form.formState.isSubmitting || isUploading}
          loading={form.formState.isSubmitting || isUploading}
          className="!mr-0"
          type="submit"
          primary
        />
        <Button
          content="Cancel"
          disabled={form.formState.isSubmitting || isUploading}
          onClick={onCancel}
          className="!mr-0"
          type="button"
          basic
        />
      </div>
    </Form>
  )
}
