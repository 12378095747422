import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PageLoader from '../../components/pageLoader'
import { useTalentApi } from '../../store/mainContext'
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb, Button, Icon, Menu } from 'semantic-ui-react'
import Feed from '../../components/feeds/feed'
import RequirePowers from '../../components/requirePowers'
import ContactSidePanel from '../../components/sidepanels/contactSidePanel'
import CompanyName from '../../components/remoteValues/companyName'
import ContactActions from '../../components/actions/contactActions'
import LinkedinViewer from '../../components/linkedinViewer'
import POWERS from '../../powers'
import ContractsDetail from '../../components/contractsDetail'
import FunnelBar from '../../components/funnelBar/funnelBar'

const Container = styled.div`
  display: flex;
  justify-content: space-between !important;
`

const Title = styled.h1`
  margin: 15px 20px 0px 30px !important;
`

const Sub = styled.div`
  margin: 5px 20px 0px 30px !important;
  font-size: 15px;
  color: #555;
`

const CriticalAlert = styled.div`
  margin: 15px 30px 20px 30px !important;
  border: dotted 1px #ffe1e1;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffe9e9;
  color: var(--red);
  text-align: center;
`

const Frame = styled.iframe`
  width: 100%;
  border: dotted 1px var(--border-grey);
  height: 75vh;
  padding-top: -25px;
  border-radius: 4px;
`

const LinkedinBar = styled.div`
  min-width: 500px;
  max-width: 500px;
  width: 500px;

  height: 100vh;
  border-left: dotted 1px var(--border-grey);

  position: sticky;
  top: 0;
  text-align: center;
  padding: 200px 100px;
  /* box-shadow: 0 0px 5px 0 rgb(34 36 38 / 15%); */

  background-color: var(--bg-grey);

  border: dotted 1px var(--border-grey);
`

const Logo = styled.img`
  width: 200px;
  cursor: pointer;
`

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [selectedTab, setSelectedTab] = useState('feed')

  const [contact, setContact] = useState()
  const [company, setCompany] = useState()

  const [showLinkedin, setShowLinkedin] = useState()

  const { contactId } = useParams()

  const api = useTalentApi()

  useEffect(() => {
    api
      .get(`contacts/${contactId}`)
      .then(({ data: c }) => {
        if (c.companyId) {
          api
            .get(`companies/${c.companyId}`)
            .then(({ data: comp }) => setCompany(comp))
        }
        setContact(c)
      })
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }, [api, contactId])

  useEffect(() => {
    if (!contact) return
    document.title = `${contact.name}`
  }, [contact])

  const handleOnChange = (name, value) => {
    const newObj = { ...contact }
    newObj[name] = value
    setContact(newObj)
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (!contact) {
    document.location.href = '/contacts'
    return null
  }

  return (
    <React.Fragment>
      <Container>
        <div className="sticky top-0 z-50 ml-3 max-h-screen w-[400px] shrink-0 overflow-auto pb-28 pr-3 [&::-webkit-scrollbar]:hidden">
          <Breadcrumb style={{ padding: 15, marginLeft: 25 }}>
            <Breadcrumb.Section link>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right chevron" />

            <Breadcrumb.Section link>
              {!contact.status && <Link to="/contacts">Contacts</Link>}

              {contact.status && (
                <Link to={`/contacts/funnels/${contact.status.split('.')[0]}`}>
                  Funnel
                </Link>
              )}
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right chevron" />

            <Breadcrumb.Section active>
              {contact.name.split(' ')[0]}
            </Breadcrumb.Section>
          </Breadcrumb>

          <Title>{contact.name}</Title>

          {contact.position ? (
            <Sub>
              <b>{contact.position}</b> at{' '}
              <b>
                <CompanyName id={contact.companyId} />
              </b>
            </Sub>
          ) : (
            <Sub>
              <b>
                <CompanyName id={contact.companyId} key={contact.companyId} />
              </b>
            </Sub>
          )}

          <ContactActions contact={contact} onSuccess={handleOnChange} />

          {contact.isCritical && (
            <CriticalAlert>
              <p>
                <b>
                  <Icon name="warning circle" />
                  This is a Critical Contact
                </b>
              </p>

              <p>Validate any communication with Management Team.</p>
            </CriticalAlert>
          )}

          <FunnelBar
            key={contact.status}
            entity={contact}
            collection="contacts"
            onSuccess={handleOnChange}
          />

          <ContactSidePanel
            contact={contact}
            onChangeSuccess={handleOnChange}
          />
        </div>

        <div className="w-full min-w-[500px] p-6">
          <RequirePowers powers={[POWERS.contracts]}>
            <Menu tabular>
              <Menu.Item
                icon="feed"
                name="Feed"
                active={selectedTab === 'feed'}
                onClick={() => setSelectedTab('feed')}
              />

              <Menu.Item
                icon="file alternate outline"
                name="Contracts"
                active={selectedTab === 'candidates'}
                onClick={() => setSelectedTab('candidates')}
              />

              <RequirePowers powers={[POWERS.customerSOWs]}>
                <Menu.Item
                  icon="address card outline"
                  name="Rate Card"
                  active={selectedTab === 'rate-card'}
                  onClick={() => setSelectedTab('rate-card')}
                  disabled={!company || !company.rateCard}
                />
              </RequirePowers>
            </Menu>
          </RequirePowers>

          {selectedTab === 'feed' && (
            <Feed
              relatedId={contact._id}
              relatedCollection="contacts"
              relatedObject={contact}
            />
          )}

          {selectedTab === 'candidates' && (
            <ContractsDetail
              contractsFilter={{ hiringManagerId: contact._id }}
              submissionsFilter={{ submittedTo: contact._id }}
            />
          )}

          {selectedTab === 'rate-card' && company.rateCard && (
            <Frame src={company.rateCard} onLoad={() => setIsLoading(false)} />
          )}
        </div>

        {selectedTab === 'feed' && (
          <React.Fragment>
            {showLinkedin ? (
              <LinkedinViewer url={contact.linkedin} />
            ) : (
              <LinkedinBar>
                <div>
                  <Logo
                    src="/img/linkedin-logo.png"
                    alt="linkedin"
                    onClick={() => setShowLinkedin(!showLinkedin)}
                  />
                </div>
                <Button basic onClick={() => setShowLinkedin(!showLinkedin)}>
                  <p>Revelio!</p>
                </Button>
              </LinkedinBar>
            )}
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  )
}

export default Contact
