import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Ref } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { Editor } from '@monaco-editor/react'
import * as React from 'react'
import { omit } from 'ramda'

import useKeyboardShortcut from '../../hooks/useKeyboardShortcut'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'
import { Recipe } from '../../types'

const omitProps = (r: Recipe) =>
  omit(['lastUpdateBy', 'lastUpdateOn', 'createdOn', 'createdBy', '_id'], r)

interface FormValues {
  code: string
}

interface Props {
  onSuccess(): Promise<any>
  recipe: Recipe
}

export default function RecipeEditor(props: Props) {
  const { onSuccess, recipe } = props

  const { addToast } = useToasts()
  const api = useApi()

  const form = useForm<FormValues>({
    reValidateMode: 'onSubmit',
    defaultValues: { code: JSON.stringify(omitProps(recipe), null, 2) },
  })

  const [isSaving, setIsSaving] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    setIsSaving(true)
    return api
      .patch('recipes/' + recipe._id, JSON.parse(formValues.code))
      .then(onSuccess)
      .then(() =>
        addToast('Recipe updated', {
          autoCloseDelay: 1000,
          variant: 'success',
        }),
      )
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsSaving(false))
  }

  const formRef = React.useRef<HTMLFormElement>(null)

  useKeyboardShortcut(() => formRef.current?.requestSubmit(), {
    code: 'KeyS',
    shiftKey: true,
    metaKey: true,
  })

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} ref={formRef}>
      <div className="relative">
        <Button
          className="!absolute right-0 top-[-60px] z-10 !mr-0"
          content={
            <>
              <span>
                Save
                <span className="pl-4 text-sm font-semibold opacity-70">
                  ⇧⌘S
                </span>
              </span>
            </>
          }
          type="submit"
          disabled={isSaving}
          loading={isSaving}
          primary
        />
      </div>
      <Controller
        name="code"
        rules={{
          required: 'Required',
          validate(value) {
            try {
              JSON.parse(value)
            } catch (error: any) {
              return error.message
            }
            return true
          },
        }}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, ...other } = field
          return (
            <div>
              {fieldState.error && (
                <div className="pb-3 text-base font-semibold text-red-600">
                  {fieldState.error.message}
                </div>
              )}
              <Ref innerRef={ref}>
                <Editor
                  {...other}
                  onChange={(code) => field.onChange(code || '')}
                  defaultLanguage="json"
                  height="calc(100vh - 195px)"
                  theme="vs-dark"
                  options={{
                    showFoldingControls: 'always',
                    scrollBeyondLastLine: false,
                    lineNumbers: 'off',
                    padding: { top: 5 },
                    wordWrap: 'on',
                    fontSize: 14,
                    readOnly: isSaving,
                  }}
                />
              </Ref>
            </div>
          )
        }}
      />
    </form>
  )
}
