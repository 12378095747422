export enum EnvironmentMode {
  local = 'local',
  development = 'development',
  production = 'production',
}

const getString = (
  name: string,
  allowedValues: string[] = [],
  warnIfMissing: boolean = true,
): string | null => {
  const value =
    typeof process.env[name] === 'string' ? process.env[name]!.trim() : null
  if (warnIfMissing && !value) {
    console.warn(`Environment variable ${name} is not present`)
  }
  if (
    warnIfMissing &&
    allowedValues.length &&
    !!value &&
    !allowedValues.includes(value)
  ) {
    console.warn(
      `Environment variable ${name} with value (${value}) is not allowed, should be one of (${allowedValues.join(
        ', ',
      )})`,
    )
  }
  return value
}

// type ENVS = {
//   [key in `${EnvironmentMode}`]: string
// }

// const getStringByEnv = (envs: ENVS): string => {
//   const nodeEnv = getString('REACT_APP_STAGE', [
//     'local',
//     'development',
//     'production',
//   ]) as keyof ENVS
//   return envs[nodeEnv]
// }

/**
 * Environmental variables used throughout the application.
 */
export const ENV_VARS = {
  /**
   * Current environment mode: development | testing | staging | production
   */
  NODE_ENV: getString('NODE_ENV'),
  /**
   * This is here only to inform that the environment variable PORT is used at the file ../index.js and that the default value is 3232
   */
  PORT: 3232,
  /**
   * SWR: Revalidate on focus.
   * Use this to prevent auto revalidations while coding.
   */
  REACT_APP_REVALIDATE_ON_FOCUS: getString(
    'REACT_APP_REVALIDATE_ON_FOCUS',
    [],
    false,
  ),
  /**
   * Open some links in new tabs
   * Use this to prevent opening new tabs while coding
   */
  REACT_APP_USE_NEW_TABS: getString('REACT_APP_USE_NEW_TABS', [], false),
  /**
   * If true the talent API websocket will reconnect
   */
  REACT_APP_TALENT_WS_RECONNECTION: getString(
    'REACT_APP_TALENT_WS_RECONNECTION',
    [],
    false,
  ),
  /**
   * The romebotie user id.
   *
   * Romebotie user doesn't actually exist, so let's avoid the 404 and return the fallback data in that case.
   *
   * TODO: or maybe better to just create the user in the DB?
   */
  REACT_APP_ROMEBOTIE_ID: getString('REACT_APP_ROMEBOTIE_ID'),
  /**
   * Google authenticator App client ID
   */
  REACT_APP_GOOGLE_CLIENT_ID: getString('REACT_APP_GOOGLE_CLIENT_ID'),
  /**
   * API base url
   */
  REACT_APP_API_BASE: getString('REACT_APP_API_BASE'),
  // @TODO: Development build has the NODE_ENV as 'production', we need to update that environment variable before using this method
  // REACT_APP_API_BASE: getStringByEnv({
  //   local: 'http://localhost:8080',
  //   development: 'https://api-dev.southteam.io',
  //   production: 'https://api.southteams.io',
  // }),
  /**
   * Talent API websocket domain
   */
  REACT_APP_TALENT_WS_DOMAIN: getString('REACT_APP_TALENT_WS_DOMAIN'),
  // @TODO: Development build has the NODE_ENV as 'production', we need to update that environment variable before using this method
  // REACT_APP_API_BASE: getStringByEnv({
  //   local: 'ws://localhost:8080/',
  //   development: 'ws://api-dev.southteam.io/',
  //   production: 'ws://api.southteam.io/',
  // }),
}

export const inProd = () => {
  return ENV_VARS.NODE_ENV === EnvironmentMode.production
}
