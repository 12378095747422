import * as React from 'react'

import { PaymentOrderStatus } from '../../types'
import { classNames } from '../../utils'

interface Props {
  paymentOrder: { status: PaymentOrderStatus }
}

export default function PaymentOrderStatusLabel(props: Props) {
  const { status } = props.paymentOrder

  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase',
        status === 'Processing' && 'text-cyan-500',
        status === 'Completed' && 'text-green-600',
        status === 'Draft' && 'text-slate-400',
      )}
    >
      {status}
    </span>
  )
}
