import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import styled from 'styled-components'
import {
  DropdownItem,
  Dropdown,
  Confirm,
  Button,
  Icon,
} from 'semantic-ui-react'

import { useTalentApi } from '../../../store/mainContext'
import { SmallEvent } from './event'
import DateFormat from '../../dateFormat'
import UserName from '../../remoteValues/userName'
import { Task } from '../../../types'
import Period from '../../period'

const Content = styled.div`
  position: relative;
  border: dotted 1px var(--border-grey);
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  &:hover {
    background-color: #fcfcfc;
  }
`

const EditContent = styled.div`
  position: relative;
  box-shadow: var(--active-shadow) !important;
  border-color: var(--primary) !important;

  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  align-items: flex-start;
  cursor: pointer;
`

const Title = styled.header`
  margin: 0px;
  width: 100%;
  padding-left: 15px;
`

const Details = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Split = styled.div`
  border-top: dotted 1px var(--border-grey);
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
`

const DoneNote = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Sub = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 20px;
`

const Date = styled.h3`
  margin-top: 0px !important;
  margin-bottom: 5px !important;
  padding-right: 48px;
`

const Menu = styled.div`
  position: absolute;
  font-size: 15px;
  top: 0px;
  right: 0px;
  z-index: 90;
`

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
`

const Editor = styled(ContentEditable)`
  &:focus {
    outline: none;
  }
`

const ContentBottom = styled.div`
  height: 50px;
  border-left: dotted 1px var(--border-grey);
  margin-left: 40px;
`

const ViewMoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
`

interface Props {
  onChangeSuccess(): void
  isFirstOne?: boolean
  disabled?: boolean
  task: Task
}

const TaskComponent = ({
  onChangeSuccess,
  isFirstOne,
  disabled,
  task,
}: Props) => {
  const editor = useRef<ContentEditable>(null)
  const api = useTalentApi()

  const [currentTask, setCurrentTask] = useState({ ...task })

  const [isCollapsed, setIsCollapsed] = useState(
    !task.details && !task.doneNote,
  )
  const [editMode, setEditMode] = useState(false)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showConfirmNotDoneModal, setShowConfirmNotDoneModal] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)

  const collapse = !editMode && isCollapsed
  const Contaier = editMode ? EditContent : Content

  useEffect(() => {
    setCurrentTask({ ...task })
  }, [task])

  useEffect(() => {
    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }, [editMode])

  const handleCancel = () => {
    setCurrentTask({ ...task })
    setEditMode(false)
  }

  const handleSave = async () => {
    setIsSaving(true)

    const { subject, details, doneNote } = currentTask

    const update: Partial<Task> = {}

    if (task.subject !== subject) {
      update.subject = subject
    }

    if (task.details !== details) {
      update.details = details
    }

    if (task.doneNote !== doneNote) {
      update.doneNote = doneNote
    }

    if (update.subject || update.details || update.doneNote) {
      await api.patch(`tasks/${task._id}`, update)
    }

    onChangeSuccess && onChangeSuccess()

    setIsSaving(false)
    setEditMode(false)
  }

  const handleMarkNotDone = async () => {
    setIsRemoving(true)

    await api.patch(`tasks/${task._id}`, { isDone: false })
    await api.delete(`tasks/${task._id}/doneNote`)

    setIsRemoving(false)
    setShowConfirmNotDoneModal(false)
    onChangeSuccess && onChangeSuccess()
  }

  const handleConfirmRemove = async () => {
    setIsRemoving(true)
    await api.delete(`tasks/${task._id}`)

    onChangeSuccess && onChangeSuccess()

    setIsRemoving(false)
    setShowConfirmModal(false)
  }

  return (
    <React.Fragment>
      {!task.doneNote && !editMode && (
        <SmallEvent event={task} userId={task.doneBy}>
          <b>completed</b> &quot;{currentTask.subject}&quot; task.
          <div className="absolute right-0 top-0">
            <Dropdown
              icon="ellipsis horizontal"
              direction="left"
              style={{ marginLeft: 10 }}
            >
              <Dropdown.Menu>
                <DropdownItem onClick={() => setEditMode(true)}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => setShowConfirmNotDoneModal(true)}>
                  Mark as <b>Not Done</b>
                </DropdownItem>
                <DropdownItem onClick={() => setShowConfirmModal(true)}>
                  <span style={{ color: 'red' }}>Remove</span>
                </DropdownItem>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {task.details && (
            <p style={{ fontSize: '14px', margin: 10 }}>
              <b>Details:</b>{' '}
              <span dangerouslySetInnerHTML={{ __html: task.details }} />
            </p>
          )}
        </SmallEvent>
      )}

      {(task.doneNote || editMode) && (
        <>
          <Contaier>
            <Header onClick={() => !editMode && setIsCollapsed(!isCollapsed)}>
              <UserName id={currentTask.doneBy!} image height={45} />

              <Title>
                <Date>
                  <b>
                    <Period date={currentTask.doneOn} />
                  </b>{' '}
                  - <DateFormat date={currentTask.doneOn!} hours /> -{' '}
                  <span className="whitespace-nowrap">
                    by <UserName id={currentTask.doneBy!} />
                  </span>
                </Date>
                <div style={{ display: 'flex', fontSize: '1.4rem' }}>
                  <Editor
                    html={currentTask.subject}
                    disabled={!editMode}
                    onChange={(e) =>
                      setCurrentTask({
                        ...currentTask,
                        subject: e.target.value,
                      })
                    }
                  />
                  &nbsp;<span>(Done)</span>
                </div>
              </Title>

              {!disabled && !editMode && (
                <Menu>
                  <ViewMoreButton>
                    {collapse ? (
                      <Icon name="chevron down" />
                    ) : (
                      <Icon name="chevron up" />
                    )}
                  </ViewMoreButton>

                  <Dropdown icon="ellipsis horizontal" direction="left">
                    <Dropdown.Menu>
                      <DropdownItem onClick={() => setEditMode(true)}>
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setShowConfirmNotDoneModal(true)}
                      >
                        Mark as <b>Not Done</b>
                      </DropdownItem>
                      <DropdownItem onClick={() => setShowConfirmModal(true)}>
                        <span style={{ color: 'red' }}>Remove</span>
                      </DropdownItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
              )}
            </Header>

            {!collapse && (
              <React.Fragment>
                {(currentTask.details || editMode) && (
                  <Details>
                    <h3>
                      <Icon name="clipboard outline" />
                      <b>Details</b>
                    </h3>
                    <Editor
                      html={currentTask.details || ''}
                      disabled={!editMode}
                      ref={editor}
                      onChange={(e) =>
                        setCurrentTask({
                          ...currentTask,
                          details: e.target.value,
                        })
                      }
                    />
                  </Details>
                )}

                {currentTask.requireDoneNote && (
                  <React.Fragment>
                    <Split />
                    <DoneNote>
                      <h3>
                        <Icon name="clipboard outline" />
                        <b>Notes</b>
                      </h3>

                      <Editor
                        html={currentTask.doneNote || ''}
                        disabled={!editMode}
                        ref={editor}
                        onChange={(e) =>
                          setCurrentTask({
                            ...currentTask,
                            doneNote: e.target.value,
                          })
                        }
                      />

                      {currentTask.doneNote?.match(
                        /https:\/\/fathom\.video\/share\/[^\s<>"']+/,
                      ) && (
                        <iframe
                          title="fathom"
                          className="mt-10 min-h-[800px] w-full"
                          src={
                            currentTask.doneNote?.match(
                              /https:\/\/fathom\.video\/share\/[^\s<>"']+/,
                            )?.[0] || ''
                          }
                        />
                      )}
                    </DoneNote>
                  </React.Fragment>
                )}

                {editMode && (
                  <ButtonBar>
                    <Button basic disabled={isSaving} onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      color="black"
                      onClick={handleSave}
                      loading={isSaving}
                    >
                      <Icon name="save"></Icon>Save
                    </Button>
                  </ButtonBar>
                )}
                <Sub>
                  Task created by{' '}
                  <b>
                    <UserName id={currentTask.createdBy} />
                  </b>{' '}
                  on <DateFormat date={currentTask.createdOn} hours /> and
                  assigned to{' '}
                  <b>
                    <UserName id={currentTask.assignedToId} />
                  </b>
                  .
                </Sub>
              </React.Fragment>
            )}
          </Contaier>

          {!isFirstOne && <ContentBottom />}
        </>
      )}

      <Confirm
        open={showConfirmModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmRemove}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this task.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... removing the task?"
        confirmButton={
          <Button primary={false} color="red" loading={isRemoving}>
            Sure, remove.
          </Button>
        }
      />

      <Confirm
        open={showConfirmNotDoneModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmNotDoneModal(false)}
        onConfirm={handleMarkNotDone}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to mark this task as <b>Not Done</b>.
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... mark as Not done?"
        confirmButton={
          <Button primary={false} color="black" loading={isRemoving}>
            Sure, mark as not done.
          </Button>
        }
      />
    </React.Fragment>
  )
}

export default TaskComponent
