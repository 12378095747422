import { useGoogleLogin } from '@react-oauth/google'

import React from 'react'
import './login.css'

import { useSessionActions, useSessionState } from '../hooks'
import PageLoader from '../../../components/pageLoader'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

const Container = styled.div`
  text-align: center;
`

const Login = () => {
  const { handleOnSuccess, handleOnError } = useSessionActions()
  const { error, loaded } = useSessionState()

  const login = useGoogleLogin({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
    flow: 'auth-code',
    scope:
      'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://mail.google.com/',
  })

  if (!loaded) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  if (error) {
    return (
      <Container>
        <div style={{ margin: 'auto', marginTop: '40vh', width: 200 }}>
          <p>{error.message}</p>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <div style={{ margin: 'auto', marginTop: '40vh', width: 200 }}>
        <Button basic size="large" onClick={() => login()} content="Sign in." />
      </div>
    </Container>
  )
}

export default Login
