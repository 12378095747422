import { Dropdown, Icon } from 'semantic-ui-react'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AddTaskModal from '../modals/addTaskModal'
import { useTalentApi } from '../../store/mainContext'
import AddContactModal from '../modals/addContactModal'
import styled from 'styled-components'

const Divider = styled.hr`
  border: none;
  border-bottom: dotted 1px var(--border-grey);
`

const QuickAddMenu = (props: any) => {
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [showAddTask, setShowAddTask] = useState(false)

  const [showAddContact, setShowAddContact] = useState(false)

  const history = useHistory()
  const api = useTalentApi()

  const handleAddCandidate = async () => {
    const { data } = await api.post('candidates', {
      name: 'New Candidate',
    })

    history.push(`/candidates/${data._id}`)
    setShowSubMenu(false)
  }

  const handleAddPosition = async () => {
    const { data } = await api.post('positions', {
      title: 'New Position',
      status: 'open',
    })

    history.push(`/positions/${data._id}`)
    setShowSubMenu(false)
  }

  const handleAddCompany = async () => {
    const { data } = await api.post('companies', {
      name: 'New Company',
    })

    history.push(`/companies/${data._id}`)

    setShowSubMenu(false)
  }

  return (
    <React.Fragment>
      <Dropdown
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
        open={showSubMenu}
        icon={null}
        pointing="left"
        fluid
        style={{ left: -25, top: 40 }}
      >
        <Dropdown.Menu style={{ zIndex: 9999 }}>
          <Dropdown.Item
            onClick={() => setShowAddTask(true)}
            style={{ zIndex: 9999 }}
            key="task"
          >
            <Icon name="check circle outline" /> Add Task
          </Dropdown.Item>
          <Divider />

          <Dropdown.Item
            onClick={handleAddPosition}
            style={{ zIndex: 9999 }}
            key="position"
          >
            <Icon name="newspaper outline" /> Add Position
          </Dropdown.Item>

          <Dropdown.Item
            onClick={handleAddCandidate}
            style={{ zIndex: 9999 }}
            key="candidate"
          >
            <Icon name="user circle outline" /> Add Candidate
          </Dropdown.Item>

          <Divider />

          <Dropdown.Item
            onClick={() => setShowAddContact(true)}
            style={{ zIndex: 9999 }}
            key="contact"
          >
            <Icon name="user circle" /> Add a Contact
          </Dropdown.Item>

          <Dropdown.Item
            onClick={handleAddCompany}
            style={{ zIndex: 9999 }}
            key="company"
          >
            <Icon name="building" /> Add a Company
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Link
        className="sideMenuItem"
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
        to=""
      >
        <i className="icon plus"></i>
      </Link>

      <AddTaskModal
        show={showAddTask}
        onCancel={() => setShowAddTask(false)}
        onCreated={async (task) => setShowAddTask(false)}
      />

      <AddContactModal
        open={showAddContact}
        onCancel={() => setShowAddContact(false)}
        onCreated={async (contact) => {
          setShowAddContact(false)
          history.push(`/contacts/${contact._id}`)
        }}
      />
    </React.Fragment>
  )
}

export default QuickAddMenu
