import { endOfToday, isBefore, isToday } from 'date-fns'
import { Icon, Popup } from 'semantic-ui-react'
import * as React from 'react'

import { Collection, RecipeSchedule, Task } from '../../types'
import { classNames } from '../../utils'
import CardRecipes from './CardRecipes'
import CardTasks from './CardTasks'

interface Props {
  onCreateScheduleClick(): void
  onUpdateScheduleClick(schedule: RecipeSchedule): void
  onDeleteScheduleClick(schedule: RecipeSchedule): void
  onCompleteTaskClick(task: Task): void
  onUpdateTaskClick(task: Task): void
  onCreateTaskClick(): void
  relatedCollection: Collection
  schedules: RecipeSchedule[]
  tasks: Task[]
}

export default function CardPopup(props: Props) {
  const {
    onCreateScheduleClick,
    onUpdateScheduleClick,
    onDeleteScheduleClick,
    onCompleteTaskClick,
    onUpdateTaskClick,
    onCreateTaskClick,
    relatedCollection,
    schedules,
    tasks,
  } = props

  const unDoneTasks = tasks.filter((task) => !task.isDone)

  const anythingToday =
    unDoneTasks.some((task) => isToday(task.dueDate)) ||
    schedules.some((schedule) => isToday(schedule.whenToRun || 0))

  const hasOverdueTasks = unDoneTasks.some((task) =>
    isBefore(task.dueDate, endOfToday()),
  )

  const [open, setOpen] = React.useState(false)

  return (
    <Popup
      closeOnTriggerMouseLeave={false}
      openOnTriggerMouseEnter={false}
      closeOnPortalMouseLeave={false}
      openOnTriggerClick
      on="click"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <button
          className={classNames(
            'block text-[17px] leading-none hover:text-slate-700',
            unDoneTasks.length === 0 && schedules.length === 0
              ? 'text-yellow-300'
              : hasOverdueTasks
                ? 'text-red-600'
                : anythingToday
                  ? 'text-primary'
                  : 'text-slate-500',
          )}
          type="button"
        >
          <Icon
            name={
              unDoneTasks.length === 0 && schedules.length === 0
                ? 'exclamation circle'
                : 'chevron circle right'
            }
            className="!mr-0"
          />{' '}
        </button>
      }
      position="right center"
      style={{ padding: 0 }}
      wide="very"
      content={
        <div className="relative z-10 max-h-[50vh] overflow-y-auto">
          <CardTasks
            tasks={tasks}
            onCompleteTaskClick={(task) => {
              if (task.requireDoneNote) setOpen(false)
              onCompleteTaskClick(task)
            }}
            onCreateTaskClick={() => {
              setOpen(false)
              onCreateTaskClick()
            }}
            onUpdateTaskClick={(task) => {
              if (task.isDone) return
              setOpen(false)
              onUpdateTaskClick(task)
            }}
          />

          <CardRecipes
            onCreateScheduleClick={() => {
              setOpen(false)
              onCreateScheduleClick()
            }}
            onDeleteScheduleClick={(schedule) => {
              setOpen(false)
              onDeleteScheduleClick(schedule)
            }}
            onUpdateScheduleClick={(schedule) => {
              setOpen(false)
              onUpdateScheduleClick(schedule)
            }}
            relatedCollection={relatedCollection}
            schedules={schedules}
          />
        </div>
      }
    />
  )
}
