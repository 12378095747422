import { Button, Confirm, Icon, Loader, Popup } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  invoiceScheduledEmailConfigSchema,
  InvoiceScheduledEmailConfig,
  Invoice,
} from '../../types'
import { classNames, validateResponse } from '../../utils'
import InvoiceDeliveryStatusLabel from './InvoiceDeliveryStatusLabel'
import PreviewEmailModal from './PreviewEmailModal'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'
import UserName from '../remoteValues/userName'

type Dialog =
  | { type: 'delivery-unschedule'; deliveryId: string }
  | { type: 'delivery-preview'; email: InvoiceScheduledEmailConfig }

interface Props {
  isManuallySent?: boolean
  invoiceId: Invoice['_id']
}

export default function InvoiceDeliveries(props: Props) {
  const { isManuallySent, invoiceId } = props

  const { addToast } = useToasts()
  const api = useApi()

  const { data, isLoading, mutate } = useSWR(
    ['scheduled-emails', invoiceId],
    () =>
      api
        .get('/scheduled-emails/in', {
          params: { billingInvoiceId: invoiceId },
        })
        .then(validateResponse(z.array(invoiceScheduledEmailConfigSchema)))
        .then((emails) => emails.sort((a, b) => a.sendAt - b.sendAt)),
  )
  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isUnscheduling, setIsUnscheduling] = React.useState(false)

  const handleDeliveryUnschedule = () => {
    if (currentDialog?.type !== 'delivery-unschedule') return
    setIsUnscheduling(true)
    return api
      .post(`/scheduled-emails/${currentDialog.deliveryId}/unschedule`)
      .then(() => mutate())
      .then(() => setCurrentDialog(null))
      .then(() =>
        addToast('Email delivery unscheduled', { variant: 'success' }),
      )
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsUnscheduling(false))
  }

  if (isLoading)
    return (
      <div className="my-12 text-center">
        <Loader active inline size="tiny" />
      </div>
    )

  return (
    <div>
      {data?.length === 0 ? (
        <div className="py-4 text-slate-700">No deliveries? weird...</div>
      ) : (
        <section>
          <header className="pb-4 pt-2 text-base font-semibold text-slate-600">
            E-mails scheduled about this invoice:
          </header>

          {isManuallySent ? (
            <div className="text-base italic text-slate-600">
              No scheduled emails. Invoice was manually sent.
            </div>
          ) : (
            <ul className="mt-2 space-y-4">
              {(data || []).map((email) => {
                return (
                  <li key={email._id}>
                    <div className="flex items-baseline justify-between gap-2">
                      {email.status === 'Completed' ||
                      email.status === 'Sent' ? (
                        <Icon name="check" fitted color="green" />
                      ) : (
                        <Icon name="mail outline" fitted color="grey" />
                      )}
                      <div className="grow">
                        {email.type === 'invoice_link' && (
                          <div
                            className={classNames(
                              'text-base leading-none',
                              email.status === 'Completed'
                                ? 'font-semibold text-slate-800'
                                : 'text-slate-600',
                            )}
                          >
                            Initial delivery
                          </div>
                        )}
                        {email.type === 'duedate_reminder' && (
                          <div
                            className={classNames(
                              'text-base leading-none',
                              email.status === 'Completed' ||
                                email.status === 'Sent'
                                ? 'font-semibold text-slate-800'
                                : 'text-slate-600',
                              email.status === 'Unscheduled' && 'line-through',
                            )}
                          >
                            Reminder: Invoice due soon
                          </div>
                        )}
                        {email.type === 'invoice_overdue' && (
                          <div
                            className={classNames(
                              'text-base leading-none',
                              email.status === 'Completed' ||
                                email.status === 'Sent'
                                ? 'font-semibold text-slate-800'
                                : 'text-slate-600',
                              email.status === 'Unscheduled' && 'line-through',
                            )}
                          >
                            Reminder: Invoice overdue
                          </div>
                        )}
                        <span
                          className={classNames(
                            'text-sm leading-none',
                            email.status === 'Completed' ||
                              email.status === 'Sent'
                              ? 'text-slate-700'
                              : 'text-slate-500',
                          )}
                        >
                          {email.status === 'Unscheduled' ? (
                            <>
                              Unscheduled by{' '}
                              <UserName id={email.lastUpdateBy} /> on{' '}
                              {format(email.lastUpdateOn, 'Pp')}
                            </>
                          ) : (
                            format(email.sendAt, 'PPPp')
                          )}
                        </span>
                      </div>
                      {email.status === 'Scheduled' &&
                        email.type !== 'invoice_link' && (
                          <Popup
                            size="mini"
                            position="top center"
                            content="Unschedule"
                            trigger={
                              <Button
                                onClick={() =>
                                  setCurrentDialog({
                                    type: 'delivery-unschedule',
                                    deliveryId: email._id,
                                  })
                                }
                                color="red"
                                icon="times"
                                size="mini"
                                compact
                                basic
                                className="!mr-0"
                              />
                            }
                          />
                        )}
                      {email.type !== 'invoice_link' &&
                        email.status !== 'Unscheduled' && (
                          <Popup
                            content="Preview email"
                            position="top center"
                            size="mini"
                            trigger={
                              <Button
                                onClick={() =>
                                  setCurrentDialog({
                                    type: 'delivery-preview',
                                    email,
                                  })
                                }
                                icon="eye"
                                size="mini"
                                compact
                                basic
                                className="!mr-0"
                              />
                            }
                          />
                        )}
                      <InvoiceDeliveryStatusLabel status={email.status} />
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </section>
      )}
      {currentDialog?.type === 'delivery-preview' && (
        <PreviewEmailModal
          onClose={() => setCurrentDialog(null)}
          email={currentDialog.email}
          open
        />
      )}
      <Confirm
        header="Hey... unscheduling the email delivery?"
        content="This action cannot be undone."
        open={currentDialog?.type === 'delivery-unschedule'}
        size="tiny"
        closeOnDimmerClick={!isUnscheduling}
        closeOnEscape={!isUnscheduling}
        confirmButton={
          <Button
            disabled={isUnscheduling}
            loading={isUnscheduling}
            type="button"
            content="Sure, unschedule"
            primary={false}
            color="red"
          />
        }
        cancelButton={<Button disabled={isUnscheduling} content="Cancel" />}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={handleDeliveryUnschedule}
      />
    </div>
  )
}
