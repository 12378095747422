import * as React from 'react'

import { PaymentOrderTransactionStatus } from '../../types'
import { classNames } from '../../utils'

interface Props {
  status: PaymentOrderTransactionStatus
}

export default function TransactionStatusLabel(props: Props) {
  const { status } = props

  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase leading-6',
        status === 'Draft' && 'text-slate-400/90',
        status === 'Dismissed' && 'text-amber-600',
        status === 'Approved' && 'text-blue-600',
        status === 'Processing' && 'text-cyan-500',
        status === 'Completed' && 'text-green-600',
        status === 'Canceled' && 'text-slate-500',
      )}
    >
      {status}
    </span>
  )
}
