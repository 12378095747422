import { Button, Input, Popup } from 'semantic-ui-react'
import { useHistory } from 'react-router'
import FuzzySearch from 'fuzzy-search'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { classNames, currencyFormat, validateResponse } from '../../utils'
import { Payroll, payrollSchema } from '../../types'
import PagedTable, { Column } from '../../components/dataTable/pagedTable'
import { useApi } from '../../store/mainContext'
import PayrollStatusLabel from '../../components/payrolls/PayrollStatusLabel'
import CreatePayrollModal from '../../components/payrolls/CreatePayrollModal'
import PageHeader from '../../components/pageHeader/pageHeader'

type Dialog = 'create-payroll'

const columns: Column[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Total',
    align: 'center',
    width: '1%',
    field: 'total',
    render: (_, payroll: Payroll) => (
      <span className="text-nowrap">
        {typeof payroll.total === 'number'
          ? currencyFormat(payroll.total)
          : 'Unknown total'}
      </span>
    ),
  },
  {
    title: 'Status',
    align: 'center',
    width: '1%',
    field: 'status',
    render: (_, payroll: Payroll) => <PayrollStatusLabel payroll={payroll} />,
  },
  {
    title: 'Period',
    width: '1%',
    field: 'periodStart',
    align: 'center',
    render: (_, payroll: Payroll) => (
      <span className="whitespace-nowrap">
        {payroll.periodStart ? format(payroll.periodStart, 'dd/MM/yyyy') : ''}{' '}
        &mdash;{' '}
        {payroll.periodEnd ? format(payroll.periodEnd, 'dd/MM/yyyy') : ''}
      </span>
    ),
  },
  {
    title: 'Due Date',
    width: '1%',
    field: 'dueDate',
    align: 'center',
    render: (date: Payroll['dueDate']) =>
      date ? format(date, 'dd/MM/yyyy') : '',
  },
]

export default function Payrolls() {
  const history = useHistory()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const { data, isLoading } = useSWR('payrolls', () =>
    api.get('payrolls').then(validateResponse(z.array(payrollSchema))),
  )

  const [payrollsSearch, setPayrollsSearch] = React.useState<string | null>('')

  const canCreatePayroll =
    !isLoading &&
    (data || []).every((payroll) => payroll.status === 'Completed')

  return (
    <>
      <Helmet>
        <title>Payrolls</title>
      </Helmet>

      <PageHeader
        title="Payrolls"
        sub="One screen to pay them all."
        breadcrumb={[{ text: 'Dashboard', link: '/' }, { text: 'Payrolls' }]}
        actions={
          <Popup
            content="Cannot create a new payroll while one is in progress"
            position="left center"
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            offset={[0, -15]}
            disabled={canCreatePayroll}
            trigger={
              <div
                className={classNames(
                  !canCreatePayroll && 'cursor-not-allowed',
                )}
              >
                <Button
                  onClick={() => setCurrentDialog('create-payroll')}
                  disabled={!canCreatePayroll}
                  content="New Payroll"
                  icon="add"
                  basic
                />
              </div>
            }
          />
        }
      />

      <div className="mx-14 mb-14">
        <div className="flex flex-wrap gap-3 px-3 pb-5 pt-3">
          <div className="flex items-baseline">
            <span className="mr-2 whitespace-nowrap">Looking for</span>
            <Input
              className="w-48"
              size="mini"
              onChange={(e) => setPayrollsSearch(e.target.value)}
              value={payrollsSearch || ''}
              input={
                <input
                  style={{ fontSize: 'unset' }}
                  placeholder="All Payrolls"
                  className="placeholder:![color:rgba(0,0,0,.6)] placeholder:![font-size:12px]"
                />
              }
            />
          </div>
        </div>

        <PagedTable
          defaultSort="lastUpdateOn"
          collection="payrolls"
          onRowClick={(row: Payroll) => history.push(`/payrolls/${row._id}`)}
          posthook={(payrolls) =>
            new FuzzySearch(payrolls || [], ['name']).search(
              payrollsSearch || '',
            )
          }
          columns={columns}
          footer
          pro={false}
        />
      </div>

      {data && (
        <CreatePayrollModal
          open={currentDialog === 'create-payroll'}
          onClose={() => setCurrentDialog(null)}
          payrolls={data}
        />
      )}
    </>
  )
}
