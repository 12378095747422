import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'

import { ENV_VARS } from '../env'
import RemoteValue from './remoteValues/remoteValue'
import PagedTable from './dataTable/pagedTable'
import Period from './period'

const Container = styled.div`
  margin-right: 40px;
`

const SectionTitle = styled.h2`
  margin-top: 0 !important;
  margin-bottom: 5px !important;
`

const Sub = styled.p`
  color: #666;
`

const Section = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
`

const ContactsDetail = ({ companyId }) => {
  const history = useHistory()

  return (
    <Container>
      <Section>
        <SectionTitle>Contacts</SectionTitle>

        <Sub>Here you will find contacts related to this company.</Sub>

        <PagedTable
          onRowClick={(item) =>
            (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
              ? window.open
              : history.push)(`/contacts/${item._id}`)
          }
          defaultSort="lastUpdateOn"
          collection="contacts"
          filter={{ companyId }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              width: 200,
            },
            {
              title: 'Position',
              field: 'position',
              width: 200,
            },
            {
              title: 'Company',
              field: 'companyId',
              width: 200,
              align: 'center',

              render: (cid) => (
                <RemoteValue
                  collection="companies"
                  id={cid}
                  key={cid}
                  predicate={(x) => x.name}
                  showLoading={true}
                />
              ),
            },
            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Timezone',
              field: 'timezoneId',
              render: (timezoneId) => (
                <RemoteValue
                  collection="timezones"
                  id={timezoneId}
                  key={timezoneId}
                  predicate={(x) => x.text}
                />
              ),
            },
            {
              title: 'Last Update',
              field: 'lastUpdateOn',
              render: (lastUpdateOn) => <Period date={lastUpdateOn} />,
            },
          ]}
        />
      </Section>
    </Container>
  )
}

export default ContactsDetail
