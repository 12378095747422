import { Button, ButtonGroup, Icon, IconProps, Popup } from 'semantic-ui-react'
import { subDays } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { Event, eventSchema } from '../../types'
import { validateResponse } from '../../utils'
import PositionChangeEvent from './events/PositionChangeEvent'
import StatusChangeEvent from './events/StatusChangeEvent'
import SmallUserEvent from './events/SmallUserEvent'
import NewsSection from '../../components/NewsSection'
import ActionEvent from './events/ActionEvent'
import { useApi } from '../../store/mainContext'
import SmallEvent from './events/SmallEvent'
import NoteEvent from './events/NoteEvent'

type Filter = 'user-events' | 'rome-events'

const filterEvents = (currentFilter: Filter[] | 'all') => (item: Event) => {
  const isRomeEvent = item.type !== 'user-event'
  if (currentFilter === 'all') return true
  if (currentFilter.includes('user-events') && item.type === 'user-event') {
    return true
  }
  if (currentFilter.includes('rome-events') && isRomeEvent) return true
  return false
}

interface FilterBtnProps {
  setCurrentFilter: React.Dispatch<React.SetStateAction<Filter[] | 'all'>>
  currentFilter: Filter[] | 'all'
  tooltip: string
  filter: Filter | 'all'
  icon: IconProps['name']
}

function FilterBtn(props: FilterBtnProps) {
  const { setCurrentFilter, currentFilter, icon, tooltip, filter } = props
  return (
    <Popup
      trigger={
        <Button
          onClick={() => {
            setCurrentFilter((prev) => {
              if (filter === 'all') return 'all'
              if (prev === 'all') return [filter]
              if (!prev.includes(filter)) return [...prev, filter]
              return prev.length > 1
                ? prev.filter((item) => item !== filter)
                : 'all'
            })
          }}
          active={
            filter === 'all'
              ? currentFilter === 'all'
              : Array.isArray(currentFilter) && currentFilter.includes(filter)
          }
          icon
        >
          <Icon name={icon} />
        </Button>
      }
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      position="top center"
      content={tooltip}
      size="tiny"
    />
  )
}

interface Props {
  title: string
  days: number
}

export default function LastDaysEvents(props: Props) {
  const { title, days } = props

  const api = useApi()

  const { data, isLoading, error } = useSWR(['last-days-events', days], () =>
    api
      .get('/events/pro', {
        headers: {
          'astor-sortby': 'lastUpdateOn',
          'astor-sortby-dir': '-1',
          'astor-limit': 25,
        },
        params: {
          relatedCollection: 'candidates',
          lastUpdateOn: { $gte: subDays(Date.now(), days).getTime() },
        },
      })
      .then(validateResponse(z.array(eventSchema))),
  )

  const events = data || []

  const [currentFilter, setCurrentFilter] = React.useState<Filter[] | 'all'>(
    'all',
  )

  return (
    <NewsSection
      title={
        <div className="flex items-baseline justify-between">
          {title}
          <div>
            <span className="mr-3 text-xs text-slate-500">View</span>
            <ButtonGroup size="mini" basic compact>
              <FilterBtn
                setCurrentFilter={setCurrentFilter}
                currentFilter={currentFilter}
                tooltip="All events"
                filter="all"
                icon="asterisk"
              />
              <FilterBtn
                setCurrentFilter={setCurrentFilter}
                currentFilter={currentFilter}
                tooltip="Admin events"
                filter="rome-events"
                icon="user secret"
              />
              <FilterBtn
                setCurrentFilter={setCurrentFilter}
                currentFilter={currentFilter}
                tooltip="Candidate events"
                filter="user-events"
                icon="user"
              />
            </ButtonGroup>
          </div>
        </div>
      }
      isLoading={isLoading}
      placeholderCount={10}
      error={error?.message}
    >
      {events.length > 0 ? (
        <div className="space-y-2">
          {events.filter(filterEvents(currentFilter)).map((event) => {
            if (
              event.type === 'paymentSetup-changed' ||
              event.type === 'account-created' ||
              event.type === 'pto-requested' ||
              event.type === 'user-event'
            ) {
              return (
                <div
                  className="rounded-md bg-slate-50 px-2 py-2"
                  key={event._id}
                >
                  <SmallUserEvent event={event} />
                </div>
              )
            }

            if (event.type === 'event' || event.type === 'small-event') {
              return (
                <div
                  className="rounded-md bg-slate-50 px-2 py-2"
                  key={event._id}
                >
                  <SmallEvent event={event} />
                </div>
              )
            }

            if (event.type === 'pipedrive-note' || event.type === 'note') {
              return (
                <div
                  className="rounded-md bg-slate-50 px-2 py-2"
                  key={event._id}
                >
                  <NoteEvent event={event} />
                </div>
              )
            }

            if (event.type === 'position-candidate-changed') {
              return (
                <div
                  className="rounded-md bg-slate-50 px-2 py-2"
                  key={event._id}
                >
                  <PositionChangeEvent event={event} />
                </div>
              )
            }

            if (event.type === 'status-candidate-changed') {
              return (
                <div
                  className="rounded-md bg-slate-50 px-2 py-2"
                  key={event._id}
                >
                  <StatusChangeEvent event={event} />
                </div>
              )
            }

            if (event.type === 'action') {
              return (
                <div
                  className="rounded-md bg-slate-50 px-2 py-2"
                  key={event._id}
                >
                  <ActionEvent event={event} />
                </div>
              )
            }

            return (
              <pre
                className="text-wrap border bg-slate-50 p-2 text-xs leading-none"
                key={event._id}
              >
                {JSON.stringify(event, null, 2)}
              </pre>
            )
            // return null
          })}
        </div>
      ) : (
        <div className="italic text-slate-500">
          No candidate events last {days} days
        </div>
      )}
    </NewsSection>
  )
}
