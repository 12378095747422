import { NavLink } from 'react-router-dom'
import * as React from 'react'

import RemoteValue from '../../remoteValues/remoteValue'
import DateFormat from '../../dateFormat'
import { Event } from '../../../types'
import UserName from '../../remoteValues/userName'
import Period from '../../period'

interface Props {
  children?: React.ReactNode
  event: Event
}

export default function SmallEvent(props: Props) {
  const { children, event } = props

  return (
    <div className="flex items-start gap-2">
      <div className="shrink-0">
        <UserName id={event.createdBy} image height={24} />
      </div>

      <div>
        <div className="text-base">
          <span
            className="font-semibold"
            dangerouslySetInnerHTML={{ __html: event.title || '' }}
          />{' '}
          for{' '}
          <NavLink
            to={'/candidates/' + event.relatedId}
            className="!text-slate-600"
          >
            <RemoteValue
              id={event.relatedId}
              collection={event.relatedCollection}
              predicate={(v) => v.name}
            />
          </NavLink>
        </div>

        {children ||
          (event.description && (
            <div className="mt-0.5 text-sm text-slate-600">
              <span dangerouslySetInnerHTML={{ __html: event.description }} />
            </div>
          ))}

        <div className="mt-1 text-sm">
          <span className="font-semibold text-slate-500">
            <Period date={event.createdOn} />
          </span>{' '}
          -{' '}
          <span className="text-slate-500">
            <DateFormat date={event.createdOn} hours />
          </span>
        </div>
      </div>
    </div>
  )
}
