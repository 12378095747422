import useSWR from 'swr'
import { z } from 'zod'

import {
  recipeScheduleSchema,
  candidateSchema,
  RecipeSchedule,
  positionSchema,
  companySchema,
  contactSchema,
  funnelSchema,
  taskSchema,
  Candidate,
  Position,
  Contact,
  Company,
  Funnel,
  Task,
} from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'
import usePowers from './usePowers'

export type EnhancedPosition = Position & { hiringManager?: Contact }

export type EnhancedCandidate = Candidate & {
  relatedPosition?: EnhancedPosition
  schedules: RecipeSchedule[]
  company?: Company
  tasks: Task[]
}

export default function useFunnel(funnelName: string) {
  const api = useApi()
  const powers = usePowers()

  return useSWR(
    ['funnel', funnelName],
    async (): Promise<
      { funnel: Funnel; candidates: EnhancedCandidate[] } | undefined
    > => {
      const [funnel] = await api
        .get('funnels', { params: { name: funnelName } })
        .then(validateResponse(z.array(funnelSchema)))

      if (!funnel) return

      const candidates = await api
        .get('candidates/in', {
          params: { status: funnel.columns.map((c) => c.id) },
          headers: { 'astor-sortby': 'lastUpdateOn', 'astor-sortby-dir': '1' },
        })
        .then(validateResponse(z.array(candidateSchema)))

      const [tasks, positions, schedules] = await Promise.all([
        api
          .get(`tasks/in`, {
            params: { relatedId: candidates.map((c) => c._id) },
            headers: { 'astor-sortby': 'dueDate', 'astor-sortby-dir': '-1' },
          })
          .then(validateResponse(z.array(taskSchema))),
        api
          .get(`positions/in`, {
            params: { _id: candidates.map((c) => c.positionId) },
          })
          .then(validateResponse(z.array(positionSchema))),
        powers.hasAll(['run-and-schedule-recipes'])
          ? api
              .get('recipe-schedules/in', {
                headers: {
                  'astor-sortby': 'whenToRun',
                  'astor-sortby-dir': '-1',
                },
                params: {
                  runOnId: candidates.map((c) => c._id),
                  status: 'pending',
                },
              })
              .then(validateResponse(z.array(recipeScheduleSchema)))
          : [],
      ])

      const [companies, contacts] = await Promise.all([
        api
          .get(`companies/in`, {
            params: { _id: positions.map((p) => p.companyId) },
          })
          .then(validateResponse(z.array(companySchema))),
        api
          .get(`contacts/in`, {
            params: { _id: positions.map((p) => p.hiringManagerId) },
          })
          .then(validateResponse(z.array(contactSchema))),
      ])

      return {
        funnel,
        candidates: candidates.map((candidate) => {
          const relatedPosition = positions.find(
            (position) => position._id === candidate.positionId,
          )
          return {
            ...candidate,
            relatedPosition: relatedPosition
              ? {
                  ...relatedPosition,
                  hiringManager: contacts.find(
                    (c) => c._id === relatedPosition?.hiringManagerId,
                  ),
                }
              : undefined,
            schedules: schedules.filter((s) => s.runOnId === candidate._id),
            tasks: tasks.filter(
              (t) => t.relatedId === candidate._id && !t.isDone,
            ),
            company:
              relatedPosition &&
              companies.find(
                (company) => company._id === relatedPosition.companyId,
              ),
          }
        }),
      }
    },
  )
}
