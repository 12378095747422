import { Button } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import { EnhancedPayment } from '../../hooks/useBillingPayments'
import PaymentStatusLabel from './PaymentStatusLabel'
import PaymentAmountPopup from './PaymentAmountPopup'
import { currencyFormat } from '../../utils'
import PaymentsFilters from '../invoices/PaymentsFilters'
import { useList } from '../../store/mainContext'
import useSortable from '../../hooks/useSortable'
import ThSortable from '../tables/ThSortable'

interface Props {
  onUpdateClick(payment: EnhancedPayment): void
  onDeleteClick(payment: EnhancedPayment): void
  payments: EnhancedPayment[]
}

export default function PaymentsTable(props: Props) {
  const { onUpdateClick, onDeleteClick, payments } = props

  const paymentsList = useList('payments', {
    filters: { status: 'All', customerId: [] },
  })

  const paymentsSort = useSortable('payments', {
    sortByDir: 'descending',
    sortBy: 'date',
  })

  const paymentsSelectedCustomers = paymentsList.state.filters.customerId
  const paymentsSelectedStatus = paymentsList.state.filters.status

  const filteredPayments = payments
    .filter((payment) => {
      return paymentsSelectedStatus === 'All'
        ? true
        : payment.status === paymentsSelectedStatus
    })
    .filter((payment) => {
      if (!Array.isArray(paymentsSelectedCustomers)) return true
      if (paymentsSelectedCustomers.length === 0) return true
      return (paymentsSelectedCustomers || []).includes(payment.customerId)
    })
    .sort((first, second) => {
      const { sortByDir, sortBy } = paymentsSort
      const a = sortByDir === 'ascending' ? first : second
      const b = sortByDir === 'ascending' ? second : first
      if (sortBy === 'status') return a.status.localeCompare(b.status)
      if (sortBy === 'amount') return a.amount - b.amount
      if (sortBy === 'date') return a.date - b.date
      if (sortBy === 'customer') {
        return (a.customer?.name || '').localeCompare(b.customer?.name || '')
      }
      if (sortBy === 'unallocatedAmount') {
        const unallocatedAmountA = Number(
          a.allocations
            .reduce((sum, allocation) => sum - allocation.amount, a.amount)
            .toFixed(2),
        )
        const unallocatedAmountB = Number(
          b.allocations
            .reduce((sum, allocation) => sum - allocation.amount, b.amount)
            .toFixed(2),
        )
        return unallocatedAmountA - unallocatedAmountB
      }
      return 0
    })

  return (
    <section className="mx-14">
      {payments.length > 0 ? (
        <>
          <div className="px-2 py-6">
            <PaymentsFilters
              payments={payments}
              status={paymentsList.state.filters.status}
              customerId={paymentsList.state.filters.customerId}
              onChange={(filters) =>
                paymentsList.update({
                  ...paymentsList.state,
                  filters: { ...paymentsList.state.filters, ...filters },
                })
              }
            />
          </div>

          <table className="w-full">
            <thead>
              <tr className="border-b text-left text-sm uppercase text-primary">
                <ThSortable
                  {...paymentsSort.register('customer')}
                  className="px-2 py-2"
                >
                  Customer
                </ThSortable>
                <ThSortable
                  {...paymentsSort.register('amount')}
                  className="w-[1%] px-4 py-2 text-right"
                >
                  Amount
                </ThSortable>
                <ThSortable
                  {...paymentsSort.register('unallocatedAmount')}
                  className="w-[1%] text-nowrap py-2 pl-4 pr-10 text-right"
                >
                  $ Unallocated
                </ThSortable>
                <ThSortable
                  {...paymentsSort.register('date')}
                  className="px-4 py-2"
                >
                  Date
                </ThSortable>
                <ThSortable
                  {...paymentsSort.register('status')}
                  className="w-[1%] px-2 py-2"
                >
                  Status
                </ThSortable>
                <th className="sr-only w-[1%] px-2 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {filteredPayments.length === 0 && (
                <tr>
                  <td
                    className="px-2 py-8 text-center text-base text-slate-500"
                    colSpan={6}
                  >
                    No payments for the current filters
                  </td>
                </tr>
              )}
              {filteredPayments.map((payment) => {
                return (
                  <tr
                    className="group cursor-pointer border-b align-baseline hover:bg-slate-50"
                    onClick={() => onUpdateClick(payment)}
                    key={payment._id}
                  >
                    <td className="text-nowrap px-2 py-3">
                      {payment.customer?.name || 'Unknown customer'}
                    </td>
                    <td className="text-nowrap px-4 text-right font-semibold">
                      {currencyFormat(payment.amount)}
                    </td>
                    <td className="text-nowrap px-4 py-0 text-right">
                      <PaymentAmountPopup payment={payment} />
                    </td>
                    <td className="text-nowrap px-4 py-3">
                      {format(payment.date, 'yyyy-MM-dd')}
                    </td>
                    <td className="px-2 py-3">
                      <PaymentStatusLabel status={payment.status} />
                    </td>
                    <td className="px-2 py-3 text-right">
                      <div className="-my-2 flex justify-end gap-1.5 opacity-0 group-hover:opacity-100">
                        <Button
                          onClick={() => onUpdateClick(payment)}
                          icon="pencil"
                          size="mini"
                          compact
                          className="!mr-0"
                          basic
                        />
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            onDeleteClick(payment)
                          }}
                          icon="trash alternate outline"
                          size="mini"
                          compact
                          className="!mr-0"
                          color="red"
                          basic
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div className="mt-8 text-base text-slate-600">
          Nothing to see here...
        </div>
      )}
    </section>
  )
}
