import * as React from 'react'

import DateFormat from '../../dateFormat'
import { Event } from '../../../types'
import UserName from '../../remoteValues/userName'
import Period from '../../period'

interface Props {
  children?: React.ReactNode
  event: Event
}

export default function SmallUserEvent(props: Props) {
  const { children, event } = props

  return (
    <div className="flex items-start gap-2">
      <div className="shrink-0">
        {event.userImage ? (
          <img
            className="h-[24px] w-[24px] rounded-full"
            src={event.userImage}
            alt=""
          />
        ) : (
          <UserName id={event.createdBy} image height={24} />
        )}
      </div>

      <div>
        <div
          className="text-base"
          dangerouslySetInnerHTML={{ __html: event.title || '' }}
        />

        {children ||
          (event.description && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: event.description }} />
            </div>
          ))}

        <div className="mt-0.5 text-sm">
          <span className="font-semibold text-slate-500">
            <Period date={event.createdOn} />
          </span>{' '}
          -{' '}
          <span className="text-slate-500">
            <DateFormat date={event.createdOn} hours />
          </span>
        </div>
      </div>
    </div>
  )
}
