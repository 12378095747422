import { isPast } from 'date-fns'
import * as React from 'react'

import { classNames } from '../../utils'
import { Invoice } from '../../types'

interface Props {
  dueDate?: Invoice['dueDate']
  status: Invoice['status']
  small?: boolean
}

export default function InvoiceStatusLabel(props: Props) {
  const { status, dueDate, small } = props

  const isOverdue = Boolean(status === 'Sent' && dueDate && isPast(dueDate))

  return (
    <span
      className={classNames(
        'whitespace-nowrap font-semibold uppercase',
        small ? 'text-xs' : 'text-sm',
        status === 'Ready to be Sent' && 'text-teal-500',
        status === 'Failed to Send' && 'text-red-700/80',
        status === 'Uncollectible' && 'text-rose-800',
        status === 'Scheduled' && 'text-violet-500/90',
        status === 'Canceled' && 'text-slate-500',
        status === 'Draft' && 'text-slate-400/90',
        status === 'Sent' && !isOverdue && 'text-blue-500',
        status === 'Sent' && isOverdue && 'text-red-500',
        status === 'Paid' && 'text-green-600',
      )}
    >
      {isOverdue ? 'overdue' : status}
    </span>
  )
}
