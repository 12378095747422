import { Button, Confirm, ConfirmProps } from 'semantic-ui-react'
import * as React from 'react'

import TransactionStatusLabel from './TransactionStatusLabel'
import PayrollStatusLabel from './PayrollStatusLabel'

interface Props {
  onConfirm: ConfirmProps['onConfirm']
  onCancel: ConfirmProps['onCancel']
  isPending?: boolean
  open: ConfirmProps['open']
}

export default function ConfirmCompletePayrollModal(props: Props) {
  const { isPending, ...other } = props

  return (
    <Confirm
      header="Are you sure?"
      content={
        <div className="px-6 pb-4">
          <p className="text-base">This action will:</p>
          <ul className="list-disc space-y-2 pl-6 text-lg">
            <li>
              Change this payroll status to{' '}
              <PayrollStatusLabel payroll={{ status: 'Completed' }} />.
            </li>
            <li>
              Update the balance of every candidate with{' '}
              <TransactionStatusLabel status="Approved" /> transactions.
            </li>
            <li>Prevent this Payroll from being deleted.</li>
          </ul>
          <p className="mt-4 text-base font-semibold">
            This action cannot be undone.
          </p>
        </div>
      }
      closeOnDimmerClick={!isPending}
      closeOnEscape={!isPending}
      size="tiny"
      cancelButton={<Button disabled={isPending} content="Cancel" />}
      confirmButton={
        <Button disabled={isPending} primary loading={isPending}>
          Yes, mark as complete
        </Button>
      }
      {...other}
    />
  )
}
