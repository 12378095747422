import * as React from 'react'

import StartedDealsNews from '../StartedDealsNews'
import EndedDealsNews from '../EndedDealsNews'
import TimeoffNews from '../TimeoffNews'

interface Props {
  periodStart: number
  periodEnd: number
}

export default function PeriodNews(props: Props) {
  const { periodStart, periodEnd } = props

  return (
    <div className="space-y-8 py-2">
      <EndedDealsNews periodStart={periodStart} periodEnd={periodEnd} />

      <StartedDealsNews periodStart={periodStart} periodEnd={periodEnd} />

      <TimeoffNews periodStart={periodStart} periodEnd={periodEnd} />
    </div>
  )
}
