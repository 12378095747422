import React from 'react'
import { classNames } from '../../../utils'

export const Arrow = () => (
  <React.Fragment>
    <div className="h-min-20 mt-3 w-[1px] border border-solid border-slate-500"></div>
    <div className="mb-3 h-0 w-0 border-l-[6px] border-r-[6px] border-t-[8px] border-slate-500 border-l-transparent border-r-transparent"></div>
  </React.Fragment>
)

export const Card = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  children: React.ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) => (
  <div
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={classNames(
      `relative min-w-[400px] rounded-lg border border-border-grey bg-white p-5 text-slate-700 shadow-md`,
      onClick && 'cursor-pointer',
    )}
  >
    {children}
  </div>
)

export const WorkingSpace = ({ children }: { children: React.ReactNode }) => (
  <div
    className="relative flex flex-grow flex-col items-center  bg-[var(--bg-grey)] p-[50px] pb-[500px] text-[#bbb] [&::-webkit-scrollbar]:hidden"
    style={{
      backgroundImage: 'radial-gradient(currentColor 1px, transparent 1px)',
      backgroundSize: '20px 20px',
    }}
  >
    {children}
  </div>
)

export const IndexLabel = ({ children }: { children: React.ReactNode }) => (
  <div className="min-w-[30px] rounded-full border border-border-grey bg-slate-100 py-1 text-center font-bold text-slate-500 shadow-sm">
    {children}
  </div>
)

export const KeyValueSection = ({
  values,
  title,
  emptyMessage,
  sub,
}: {
  values: Record<string, any>
  title?: string
  emptyMessage?: string
  sub?: string
}) => (
  <div className="border-border-grey ">
    {title && <header className="text-lg font-bold">{title}</header>}

    {sub && <p className="mb-5 text-sm text-slate-500">{sub}</p>}

    {Object.keys(values).length === 0 ? (
      <div>{emptyMessage}</div>
    ) : (
      <table className="w-full">
        {Object.keys(values)
          .filter((x) => values[x].value)
          .map((k, si) => (
            <tr key={si} className="border  border-dotted">
              <th className="w-32 p-3 text-left align-text-top">{k}</th>
              {values[k] ? (
                <td className="p-3">
                  {JSON.stringify(values[k].value || values[k]).length > 45 ? (
                    <div className="max-w-[400px] whitespace-pre-wrap  break-words text-sm">
                      {JSON.stringify(values[k].value, null, 2)}
                    </div>
                  ) : (
                    JSON.stringify(values[k].value, null, 2)
                  )}
                </td>
              ) : (
                <td className="p-3">No value.</td>
              )}
            </tr>
          ))}
      </table>
    )}
  </div>
)
