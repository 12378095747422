import {
  DropdownItemProps,
  DropdownDivider,
  DropdownHeader,
  Dropdown,
  Button,
  Label,
  Input,
  Icon,
} from 'semantic-ui-react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  PaymentOrderTransaction,
  candidateSchema,
  supplierSchema,
  accountSchema,
  PaymentOrder,
} from '../../types'
import { validateResponse } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import PayoneerLogo from '../PayoneerLogo'
import UsaBankIcon from '../UsaBankIcon'
import { useApi } from '../../store/mainContext'

type FormValues = {
  beneficiaryType: 'supplier' | 'candidate'
  beneficiaryId: string
  bankAccountId: string
  description: string
  amount: number | null
}

const defaultValues: FormValues = {
  beneficiaryType: 'supplier',
  beneficiaryId: '',
  bankAccountId: '',
  description: '',
  amount: null,
}

interface Props {
  beneficiaryType?: PaymentOrderTransaction['beneficiaryType']
  beneficiaryId?: PaymentOrderTransaction['beneficiaryId']
  paymentOrderId: PaymentOrder['_id']
  onSuccess(): Promise<any>
  onCancel(): void
}

export default function AddCustomDebitForm(props: Props) {
  const { addToast } = useToasts()
  const api = useApi()

  const beneficiaries = useSWR('beneficiaries', () =>
    Promise.allSettled([
      api.get('candidates').then(validateResponse(z.array(candidateSchema))),
      api.get('suppliers').then(validateResponse(z.array(supplierSchema))),
    ]).then(([c, s]) => ({
      candidates: c.status === 'fulfilled' ? c.value : [],
      suppliers: s.status === 'fulfilled' ? s.value : [],
    })),
  )

  const { handleSubmit, register, control, setValue, watch } =
    useForm<FormValues>({
      defaultValues: {
        ...defaultValues,
        beneficiaryType: props.beneficiaryType || defaultValues.beneficiaryType,
        beneficiaryId: props.beneficiaryId || defaultValues.beneficiaryId,
      },
    })

  const [isCreatingTx, setIsCreatingTx] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsCreatingTx(true)
    return api
      .post('payment-transactions', {
        beneficiaryType: data.beneficiaryType,
        paymentOrderId: props.paymentOrderId,
        beneficiaryId: data.beneficiaryId,
        bankAccountId: data.bankAccountId,
        description: data.description,
        amount: Number(data.amount || 0),
        type: 'debit',
      })
      .then(props.onSuccess)
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsCreatingTx(false))
  }

  const beneficiaryOptions: DropdownItemProps[] = [
    ...((beneficiaries.data?.suppliers || []).length > 0
      ? [
          {
            key: 'suppliers-section',
            as: () => (
              <>
                <DropdownDivider />
                <DropdownHeader content="Suppliers" icon="factory" />
              </>
            ),
          },
        ]
      : []),
    ...(beneficiaries.data?.suppliers || []).map(({ _id, name }) => ({
      key: _id,
      value: _id,
      text: name,
    })),
    ...((beneficiaries.data?.candidates || []).length > 0
      ? [
          {
            key: 'candidates-section',
            as: () => (
              <>
                <DropdownDivider />
                <DropdownHeader content="Candidates" icon="user circle" />
              </>
            ),
          },
        ]
      : []),
    ...(beneficiaries.data?.candidates || []).map(({ _id, name }) => ({
      key: _id,
      value: _id,
      text: name,
    })),
  ]

  const beneficiaryInput = React.useRef(null)
  const currentBeneficiaryId = watch('beneficiaryId')

  const accounts = useSWR(
    currentBeneficiaryId ? ['accounts', currentBeneficiaryId] : null,
    () =>
      api
        .get('accounts/pro', {
          params: { beneficiaryId: currentBeneficiaryId, status: 'Approved' },
        })
        .then(validateResponse(z.array(accountSchema))),
  )

  const accountOptions: DropdownItemProps[] = (accounts.data || []).map(
    (account) =>
      account.type === 'payoneer'
        ? {
            key: account._id,
            value: account._id,
            text: (
              <div className="h-[12px] ">
                <PayoneerLogo className="relative -top-px w-24" />
              </div>
            ),
          }
        : {
            key: account._id,
            value: account._id,
            description: account.accountNumber.toString().slice(-4),
            text: `${account.bankName} (${account.alias})`,
            icon:
              account.type === 'international' ? (
                <Icon name="globe" color="grey" />
              ) : (
                <UsaBankIcon className="ml-[1px] mr-[11px] inline-block w-[15px] text-slate-600" />
              ),
          },
  )

  return (
    <React.Fragment>
      <tr className="bg-slate-100">
        {!props.beneficiaryId && (
          <td className="px-2 py-2 align-baseline" colSpan={2}>
            <Controller
              name="beneficiaryId"
              control={control}
              rules={{ required: 'Required' }}
              disabled={isCreatingTx || beneficiaries.isLoading}
              render={({ field, fieldState }) => (
                <Dropdown
                  {...field}
                  loading={beneficiaries.isLoading}
                  error={!!fieldState.error}
                  placeholder="Select beneficiary..."
                  clearable
                  selection
                  options={beneficiaryOptions}
                  search
                  fluid
                  onChange={(_, { value }) => {
                    field.onChange(value)
                    setValue('bankAccountId', '')
                    if (
                      beneficiaries.data?.candidates.some(
                        (c) => c._id === value,
                      )
                    ) {
                      setValue('beneficiaryType', 'candidate')
                    } else if (
                      beneficiaries.data?.suppliers.some((s) => s._id === value)
                    ) {
                      setValue('beneficiaryType', 'supplier')
                    }
                  }}
                  ref={beneficiaryInput}
                />
              )}
            />
          </td>
        )}

        <td className="text-nowrap px-2 py-2 align-baseline">
          <Label circular basic color="blue" content="Custom debit" />
        </td>

        <td className="py-2 text-right align-baseline">
          <form
            onSubmit={handleSubmit(onSubmit)}
            id={`add-custom-tx-${props.beneficiaryId}`}
          >
            <Controller
              name="amount"
              control={control}
              rules={{
                required: 'Required',
                validate: (v) =>
                  (v || 0) > 0 ? true : 'Amount must be greater than 0',
              }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value === null ? '' : field.value}
                  type="number"
                  fluid
                  disabled={isCreatingTx}
                  error={!!fieldState.error}
                  input={
                    <input
                      data-1p-ignore
                      className="!text-right"
                      style={{ fontSize: 'unset' }}
                      placeholder="Amount..."
                    />
                  }
                />
              )}
            />
            <input
              type="hidden"
              {...register('beneficiaryType', { required: true })}
            />
          </form>
        </td>

        <td className="px-3 py-2 align-baseline">
          <Controller
            name="description"
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                disabled={isCreatingTx}
                fluid
                error={!!fieldState.error}
                input={
                  <input
                    form={`add-custom-tx-${props.beneficiaryId}`}
                    style={{ fontSize: 'unset' }}
                    placeholder="Add a description..."
                  />
                }
              />
            )}
          />
        </td>

        <td className="px-3 py-2 align-baseline" colSpan={2}>
          <Controller
            name="bankAccountId"
            disabled={
              isCreatingTx ||
              !currentBeneficiaryId ||
              accounts.isLoading ||
              accountOptions.length === 0
            }
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                error={!!fieldState.error}
                placeholder={
                  currentBeneficiaryId
                    ? !accounts.isLoading && accountOptions.length === 0
                      ? `Beneficiary has no accounts`
                      : 'Select an account...'
                    : 'Select a beneficiary first'
                }
                loading={accounts.isLoading}
                selectOnBlur={false}
                selection
                options={accountOptions}
                fluid
                onChange={(_, { value }) => field.onChange(value)}
                ref={beneficiaryInput}
                className="[&.disabled]:!cursor-not-allowed [&.disabled]:!opacity-90 [&_[role=alert]]:text-ellipsis [&_[role=alert]]:text-nowrap"
              />
            )}
          />
        </td>
      </tr>

      <tr className="bg-slate-100">
        <td className="pb-2 pr-3 text-right align-baseline" colSpan={7}>
          <Button
            content="Cancel"
            onClick={props.onCancel}
            disabled={isCreatingTx}
            compact
            basic
          />
          <Button
            content="Save"
            type="submit"
            form={`add-custom-tx-${props.beneficiaryId}`}
            className="!mr-0"
            disabled={isCreatingTx}
            loading={isCreatingTx}
            compact
            primary
          />
        </td>
      </tr>
    </React.Fragment>
  )
}
