const POWERS = {
  master: 'master',
  talent: 'talent', // basic permission needed to work with rome.

  settings: 'settings',

  // contracts
  contracts: 'contracts',
  createContract: 'create-contract',
  updateContract: 'update-contract',
  terminateContract: 'terminate-contract',
  removeContract: 'remove-contract',

  // This is the permision to provide salary raise
  updateDeal: 'update-deal',

  // This is the permission to see customer rates
  customerSOWs: 'customer-sows',

  // TODO: Pending
  createSubmission: 'create-submission',

  contactsAndCompanies: 'contacts-and-companies',

  userAccounts: 'user-accounts',

  managePtos: 'manage-ptos',
  paymentTransactions: 'payment-transactions',
  paymentOrders: 'payment-orders',
  payrolls: 'payrolls',
  invoicing: 'invoicing',

  runAndScheduleRecipes: 'run-and-schedule-recipes',
  manageRecipes: 'manage-recipes',

  viewEmails: 'viewEmails',
} as const

export default POWERS
