import { useHistory } from 'react-router-dom'
import * as React from 'react'

import { Collection, RecipeSchedule, Task } from '../../types'
import { EnhancedContact } from '../../hooks/useContactsFunnel'
import { classNames } from '../../utils'
import { ENV_VARS } from '../../env'
import CardPopup from './CardPopup'
import Period from '../period'

interface Props {
  onCreateScheduleClick(): void
  onUpdateScheduleClick(schedule: RecipeSchedule): void
  onDeleteScheduleClick(schedule: RecipeSchedule): void
  onCompleteTaskClick(task: Task): void
  onUpdateTaskClick(task: Task): void
  onCreateTaskClick(): void
  relatedCollection: Collection
  isRotten?: boolean
  isDragging?: boolean
  entity: EnhancedContact
}

export default function ContactFunnelCard(props: Props) {
  const {
    onCreateScheduleClick,
    onUpdateScheduleClick,
    onDeleteScheduleClick,
    onCompleteTaskClick,
    onCreateTaskClick,
    onUpdateTaskClick,
    relatedCollection,
    isRotten,
    isDragging,
    entity: contact,
  } = props

  const history = useHistory()

  return (
    <div
      className={classNames(
        'cursor-pointer rounded bg-white p-1 [transition:box-shadow_0.1s_ease]',
        isDragging
          ? '[box-shadow:var(--active-shadow)]'
          : 'shadow-[0_5px_20px_0_var(--border-grey),0_0_0_1px_var(--border-grey)] hover:shadow-[0_1px_5px_0_var(--border-grey),0_0_1px_1px_var(--border-grey)]',
      )}
      onClick={() =>
        (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
          ? window.open
          : history.push)(`/contacts/${contact._id}`)
      }
    >
      <header
        className={classNames(
          'px-3 py-3 text-lg font-semibold leading-snug',
          isRotten ? 'text-red-600' : 'text-slate-700',
        )}
      >
        {contact.name}
      </header>

      <div className="-mt-0.5 flex gap-1.5 pb-2.5 pl-3 pr-2">
        <div className="grow">
          {contact.position && (
            <div className="text-base leading-tight text-slate-500">
              {contact.position}
            </div>
          )}

          {contact.company && (
            <div className="mt-1 text-base font-semibold leading-tight text-slate-500">
              {contact.company.name}
            </div>
          )}
          {isRotten && (
            <div className="mt-2 text-sm text-red-600">
              Moved <Period date={contact.lastStatusUpdate} />!
            </div>
          )}
        </div>
        <div className="self-end pb-px" onClick={(e) => e.stopPropagation()}>
          <CardPopup
            onCreateScheduleClick={onCreateScheduleClick}
            onDeleteScheduleClick={onDeleteScheduleClick}
            onUpdateScheduleClick={onUpdateScheduleClick}
            onCompleteTaskClick={onCompleteTaskClick}
            onUpdateTaskClick={onUpdateTaskClick}
            onCreateTaskClick={onCreateTaskClick}
            relatedCollection={relatedCollection}
            schedules={contact.schedules}
            tasks={contact.tasks}
          />
        </div>
      </div>
    </div>
  )
}
