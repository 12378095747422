import { Button, Form, FormField, Icon } from 'semantic-ui-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { validateResponse } from '../../utils'
import { supplierSchema } from '../../types'
import { useToasts } from '../../components/toasts/ToastsProvider'
import ContactSelect from '../../components/form/contactSelect'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'

type FormData = {
  name: string
  address?: string
  website?: string
  location?: string
  sector?: string
  mainContactId?: string
}

const defaultValues: FormData = {
  name: '',
  address: '',
  website: '',
  location: '',
  sector: '',
  mainContactId: '',
}

export default function Suppliers() {
  const { addToast } = useToasts()
  const api = useApi()

  const suppliers = useSWR('suppliers', () =>
    api.get('suppliers').then(validateResponse(z.array(supplierSchema))),
  )

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues })

  const [isPending, setIsPending] = React.useState(false)

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsPending(true)
    const body = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) acc[key as keyof FormData] = value
      return acc
    }, {} as Partial<FormData>)
    return api
      .post('suppliers', body)
      .then(() => suppliers.mutate())
      .then(() => reset())
      .then(() => addToast('Supplier created', { variant: 'success' }))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsPending(false))
  }

  if (suppliers.isLoading) return <PageLoader />

  return (
    <div>
      <Helmet>
        <title>Suppliers</title>
      </Helmet>

      <PageHeader
        title="Suppliers"
        breadcrumb={[{ text: 'Dashboard', link: '/' }, { text: 'Suppliers' }]}
      />

      <div className="mx-14 mb-14 flex items-start gap-4">
        <div className="w-[400px] rounded-md bg-slate-100 p-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormField error={!!errors.name}>
              <label>Name*</label>
              <input
                data-1p-ignore
                {...register('name', { required: 'Required' })}
                disabled={isPending}
              />
              <ErrorMessage errors={errors} name="name" />
            </FormField>
            <FormField>
              <label>Sector</label>
              <input
                data-1p-ignore
                {...register('sector')}
                disabled={isPending}
              />
            </FormField>
            <FormField>
              <label>Location</label>
              <input
                data-1p-ignore
                {...register('location')}
                disabled={isPending}
              />
            </FormField>
            <FormField>
              <label>Main contact</label>
              <ContactSelect
                {...register('mainContactId')}
                onChange={(_, p) => setValue('mainContactId', p.value || '')}
                fluid
                disabled={isPending}
              />
            </FormField>
            <FormField>
              <label>Website</label>
              <input
                data-1p-ignore
                {...register('website')}
                type="url"
                disabled={isPending}
              />
            </FormField>
            <FormField>
              <label>Address</label>
              <textarea
                data-1p-ignore
                className="resize-y"
                rows={4}
                {...register('address')}
                disabled={isPending}
              />
            </FormField>
            <div className="mt-4">
              <Button
                type="submit"
                content="Save"
                primary
                disabled={isPending}
                loading={isPending}
              />
              <Button type="reset" content="Clear" basic disabled={isPending} />
            </div>
          </Form>
        </div>
        <ol className="space-y-2">
          {(suppliers.data || []).map((s) => (
            <li key={s._id} className="relative rounded-md bg-slate-100 p-2">
              <Icon
                className="absolute right-1.5 top-2.5"
                onClick={() =>
                  api
                    .delete('suppliers/' + s._id)
                    .then(() => suppliers.mutate())
                }
                name="times"
                link
              />
              <div className="font-semibold">{s.name}</div>
              <pre className="text-xs">{JSON.stringify(s, null, 2)}</pre>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}
