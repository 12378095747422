import useSWRInfinite from 'swr/infinite'
import React, {
  MouseEventHandler,
  CSSProperties,
  ReactNode,
  useState,
} from 'react'
import { Icon } from 'semantic-ui-react'

import { Collection } from '../../types'
import { useApi } from '../../store/mainContext'
import PageLoader from '../pageLoader'
import DataTable from './dataTable'

const Footer = ({
  onShowMore,
  isLoading,
  showing,
  total,
}: {
  onShowMore: MouseEventHandler
  isLoading?: boolean
  showing?: number
  total?: number
}) => {
  if (isLoading) {
    return (
      <span className="text-base">
        Showing <b>{showing}</b> of <b>{total}</b> - Loading...
      </span>
    )
  }

  if (Number(showing) === Number(total)) {
    return (
      <span className="text-base">
        <b>Showing {showing}</b> - That&apos;s all we have.
      </span>
    )
  }

  return (
    <span className="text-base">
      Showing <b>{showing}</b> of <b>{total}</b> -{' '}
      <button
        className="text-indigo-700 hover:text-indigo-600"
        onClick={onShowMore}
        type="button"
      >
        Show more...
      </button>
    </span>
  )
}

export type Column = {
  sortable?: boolean
  title: string
  field: string
  width?: CSSProperties['width']
  align?: CSSProperties['textAlign']
  render?(fieldValue: any, collectionItem?: any): ReactNode
}

interface Props<T> {
  defaultSort?: string
  collection?: Collection
  onRowClick(data: T): void
  posthook?(data?: T[]): Promise<T[]> | T[]
  pageSize?: number
  columns: Array<Column>
  footer?: boolean
  filter?: Record<string, any>
  pro?: boolean
}

export default function PagedTable<
  T extends { _id: string; [k: string]: any },
>({
  defaultSort,
  onRowClick,
  collection = 'contacts',
  pageSize = 30,
  posthook,
  columns = [],
  filter,
  footer = true,
  pro = true,
}: Props<T>) {
  const api = useApi()

  const [order, setOrder] = useState<{ sortBy: string; sortByDir: '1' | '-1' }>(
    { sortBy: defaultSort || 'lastEvent', sortByDir: '-1' },
  )

  const {
    isValidating,
    data: table,
    isLoading,
    setSize,
    size,
  } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (previousPageData?.data && !previousPageData.data.length) return null // reached the end
      return [
        'paged-table',
        collection,
        pro,
        filter,
        pageSize,
        pageIndex,
        order.sortBy,
        order.sortByDir,
        posthook,
      ]
    },
    async (key) => {
      return api
        .get<T[]>(`${collection}${pro ? '/pro' : ''}`, {
          params: filter,
          headers: {
            'astor-limit': pageSize,
            'astor-skip': key[5] * pageSize,
            'astor-sortby': order.sortBy,
            'astor-sortby-dir': order.sortByDir,
          },
        })
        .then(async (response) => ({
          ...response,
          data: posthook ? await posthook(response.data) : response.data,
        }))
    },
    { keepPreviousData: true },
  )

  const handleOrderChange = (sortBy: string) => {
    setOrder((prev) => ({
      sortBy,
      sortByDir:
        prev.sortBy !== sortBy ? '-1' : prev.sortByDir === '1' ? '-1' : '1',
    }))
  }

  if (isLoading && !table) return <PageLoader />

  const total = Number(table?.[0]?.headers['astor-total'] || '')

  if (total === 0) {
    return (
      <div className="flex h-96 items-center justify-center text-2xl ">
        Oops... No Match, sorry. :(
      </div>
    )
  }

  return (
    <DataTable>
      <thead>
        <tr>
          {columns.map((h) => (
            <DataTable.Th width={h.width} align={h.align} key={h.title}>
              {h.sortable !== false && (
                <button
                  className="whitespace-nowrap uppercase text-indigo-800 hover:text-indigo-600"
                  onClick={() => handleOrderChange(h.field)}
                  type="button"
                >
                  {h.title}{' '}
                  {order.sortBy === h.field && (
                    <Icon
                      className={`relative -left-1 !mr-[-22.52px] ${order.sortByDir === '1' ? 'top-0.5' : '-top-0.5'}`}
                      name={order.sortByDir === '1' ? 'sort up' : 'sort down'}
                    />
                  )}
                </button>
              )}
              {h.sortable === false && h.title}
            </DataTable.Th>
          ))}
        </tr>
      </thead>

      <tbody>
        {table?.map((page) => {
          return page.data.map((d) => (
            <tr key={d._id} onClick={() => onRowClick && onRowClick(d)}>
              {columns.map((h) => {
                return (
                  <DataTable.Td key={`${h.field}-${d._id}`} align={h.align}>
                    {h.render ? h.render(d[h.field], d) : d[h.field]}
                  </DataTable.Td>
                )
              })}
            </tr>
          ))
        })}
      </tbody>

      {footer && (
        <tfoot>
          <tr>
            <DataTable.Td colspan={11}>
              <Footer
                onShowMore={() => setSize(size + 1)}
                isLoading={isValidating}
                showing={(table || [])?.reduce((sum, page) => {
                  return sum + page.data.length
                }, 0)}
                total={total}
              />
            </DataTable.Td>
          </tr>
        </tfoot>
      )}
    </DataTable>
  )
}
