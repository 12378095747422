import { Button, Icon, Input, Label, Menu, Popup } from 'semantic-ui-react'
import useSWR, { useSWRConfig } from 'swr'
import { Redirect, useParams } from 'react-router'
import { groupBy, path } from 'ramda'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import FuzzySearch from 'fuzzy-search'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import * as React from 'react'
import { z } from 'zod'

import {
  payrollWithTransactionsSchema,
  PayrollTransactionStatus,
  PayrollTransaction,
  paymentSetupSchema,
  candidateSchema,
  companySchema,
  dealSchema,
  Candidate,
  Company,
} from '../../types'
import { classNames, currencyFormat, validateResponse } from '../../utils'
import ConfirmCompletePayrollModal from '../../components/payrolls/ConfirmCompletePayrollModal'
import ConfirmRemoveCustomTxModal from '../../components/payrolls/ConfirmRemoveCustomTxModal'
import ConfirmRemovePayrollModal from '../../components/payrolls/ConfirmRemovePayrollModal'
import TransactionsSummaryWidget from '../../components/payrolls/TransactionsSummaryWidget'
import TransactionStatusLabel from '../../components/payrolls/TransactionStatusLabel'
import AmountsSummaryWidget from '../../components/payrolls/AmountsSummaryWidget'
import PayrollStatusLabel from '../../components/payrolls/PayrollStatusLabel'
import { useToasts } from '../../components/toasts/ToastsProvider'
import SimpleFilter from '../../components/filters/simpleFilter'
import { Summary } from '../../components/payrolls/PayrollProgressBar'
import PageHeader from '../../components/pageHeader/pageHeader'
import PayrollRow from '../../components/payrolls/PayrollRow'
import PeriodNews from '../../components/payrolls/PeriodNews'
import DataFilter from '../../components/filters/dataFilter'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import UserName from '../../components/remoteValues/userName'
import Feed from '../../components/feeds/feed'

type Dialog =
  | { type: 'delete-custom-tx'; txId: PayrollTransaction['_id'] }
  | { type: 'complete-payroll' }
  | { type: 'delete-payroll' }

type Sort = { by: 'customer' | 'candidate'; dir: 'asc' | 'desc' }

type Tab = 'feed' | 'period-news'

function getSummary(txs: PayrollTransaction[]): Summary {
  const result: Summary = {
    total: 0,
    custom: 0,
    statuses: {
      Completed: 0,
      Approved: 0,
      Dismissed: 0,
      Draft: 0,
    },
    amounts: { total: 0, Approved: 0 },
  }
  txs.forEach((tx) => {
    if (tx.type !== 'credit' && tx.type !== 'discount') return
    result.statuses[tx.status] = (result.statuses[tx.status] || 0) + 1
    if (!tx.generatedByPayroll) result.custom++
    result.total++
    // calculate amounts
    if (tx.type === 'discount') {
      result.amounts.total -= tx.amount
      if (tx.status === 'Approved' || tx.status === 'Completed') {
        result.amounts.Approved -= tx.amount
      }
    }
    if (tx.type === 'credit') {
      result.amounts.total += tx.amount
      if (tx.status === 'Approved' || tx.status === 'Completed') {
        result.amounts.Approved += tx.amount
      }
    }
  })
  return result
}

export default function PayrollPage() {
  const { payrollId } = useParams<{ payrollId?: string }>()

  if (!payrollId) {
    throw new Error('`payrollId` param must be present in the URL.')
  }

  const { mutate } = useSWRConfig()

  const refreshFeed = () => mutate(['events-feed', 'payrolls', payrollId])

  const { addToast } = useToasts()

  const api = useApi()

  const [selectedTab, setSelectedTab] = React.useState<Tab>('feed')

  const [sort, setSort] = React.useState<Sort>({ by: 'candidate', dir: 'asc' })

  const payroll = useSWR(['payroll', payrollId], () =>
    api
      .get(`payrolls/${payrollId}`)
      .then(validateResponse(payrollWithTransactionsSchema))
      // get all related entities for the current payroll transactions
      .then((p) =>
        Promise.all([
          Promise.resolve(p),
          api
            .get('candidates/in', {
              params: { _id: p.transactions.map((tx) => tx.beneficiaryId) },
            })
            .then(validateResponse(z.array(candidateSchema))),
          api
            .get('deals/in', {
              params: { _id: p.transactions.map((tx) => tx.dealId) },
            })
            .then(validateResponse(z.array(dealSchema))),
          api
            .get('companies/in', {
              params: { _id: p.transactions.map((tx) => tx.customerId) },
            })
            .then(validateResponse(z.array(companySchema))),
          api
            .get('payment-setups/in', {
              params: {
                candidateId: p.transactions.map((tx) => tx.beneficiaryId),
              },
            })
            .then(validateResponse(z.array(paymentSetupSchema))),
        ]),
      )
      .then(([p, candidates, deals, companies, setups]) => ({
        ...p,
        // enhance payroll transactions with related entities data
        transactions: p.transactions.map((tx) => ({
          ...tx,
          paymentSetup: setups.find((s) => s.candidateId === tx.beneficiaryId),
          candidate: candidates.find((c) => c._id === tx.beneficiaryId),
          customer: companies.find((c) => c._id === tx.customerId),
          deal: deals.find((d) => d._id === tx.dealId),
        })),
      })),
  )

  const history = useHistory()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isCompletingPayroll, setIsCompletingPayroll] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isDeletingTx, setIsDeletingTx] = React.useState(false)

  const [customTx, setCustomTx] = React.useState<{
    candidateId: Candidate['_id']
    description: string
    amount: number
  } | null>(null)

  const handleDelete = async () => {
    setIsDeleting(true)
    return api
      .delete('payrolls/' + payrollId)
      .then(() => history.push('/payrolls'))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsDeleting(false))
  }

  const handleCompletePayroll = async () => {
    setIsCompletingPayroll(true)
    return api
      .patch('payrolls/' + payrollId, { status: 'Completed' })
      .then(() => Promise.allSettled([refreshFeed(), payroll.mutate()]))
      .then(() => setCurrentDialog(null))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsCompletingPayroll(false))
  }

  const handleTransactionUpdate = async (
    id: PayrollTransaction['_id'],
    status: PayrollTransactionStatus,
  ) => {
    if (!payroll.data) return
    return payroll
      .mutate(() => api.patch('/payment-transactions/' + id, { status }), {
        optimisticData: {
          ...payroll.data,
          transactions: payroll.data.transactions.map((tx) =>
            tx._id === id ? { ...tx, status } : tx,
          ),
        },
        populateCache: false,
        rollbackOnError: true,
      })
      .then(refreshFeed)
  }

  const [isCreatingTx, setIsCreatingTx] = React.useState(false)

  const handleCustomTxCreate = async () => {
    if (!customTx) return
    setIsCreatingTx(true)
    return api
      .post('payment-transactions', {
        beneficiaryType: 'candidate',
        beneficiaryId: customTx.candidateId,
        description: customTx.description,
        payrollId,
        amount: customTx.amount > 0 ? customTx.amount : customTx.amount * -1,
        type: customTx.amount > 0 ? 'credit' : 'discount',
      })
      .then(() => Promise.allSettled([refreshFeed(), payroll.mutate()]))
      .then(() => setCustomTx(null))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsCreatingTx(false))
  }

  const handleDeleteCustomTx = async () => {
    if (currentDialog?.type !== 'delete-custom-tx') return
    setIsDeletingTx(true)
    return api
      .delete('payment-transactions/' + currentDialog.txId)
      .then(() => Promise.allSettled([refreshFeed(), payroll.mutate()]))
      .then(() => setCurrentDialog(null))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsDeletingTx(false))
  }

  const [candidateSearch, setCandidateSearch] = React.useState<string | null>(
    null,
  )

  const [customerFilter, setCustomerFilter] = React.useState<
    Company['_id'][] | null
  >(null)

  const [statusFilter, setStatusFilter] = React.useState<
    PayrollTransaction['status'] | null
  >(null)

  // Payroll row cells are combined to visually group by candidate. In order to
  // highlight all the candidate rows when hovering a group, we need to track
  // the candidate being hovered.
  const [hoveredCandidate, setHoveredCandidate] =
    React.useState<Candidate['_id']>('')

  if (payroll.isLoading) return <PageLoader />

  if (!payroll.data) {
    addToast('Payroll not found', { variant: 'danger' })
    return <Redirect to="/payrolls" />
  }

  // fuzzy search by transaction candidate name
  const filteredTx = new FuzzySearch(payroll.data.transactions || [], [
    'candidate.name',
  ])
    .search(candidateSearch || '')
    // also filter by customer selection
    .filter((tx) => {
      return (
        customerFilter === null ||
        (tx.customerId && customerFilter.includes(tx.customerId))
      )
    })
    // also filter by transaction status
    .filter((tx) => {
      return statusFilter === null || tx.status === statusFilter
    })

  // group transactions by candidate (we want 1 row for each candidate)
  const filteredTxByCandidate = groupBy((tx) => tx.beneficiaryId, filteredTx)

  // sort the already grouped and filtered transactions
  const sortedTxs = [...Object.entries(filteredTxByCandidate)].sort(
    ([, txsA], [, txsB]) => {
      const compA =
        path<string>([0, sort.by, 'name'], sort.dir === 'asc' ? txsA : txsB) ||
        ''
      const compB =
        path<string>([0, sort.by, 'name'], sort.dir === 'asc' ? txsB : txsA) ||
        ''
      return compA.localeCompare(compB)
    },
  )

  const isOverdue = Boolean(payroll.data.dueDate < Date.now())

  const canMarkAsComplete =
    payroll.data.status === 'Draft' &&
    payroll.data.transactions.every(
      (tx) => tx.status === 'Approved' || tx.status === 'Dismissed',
    )

  const summary = getSummary(payroll.data.transactions)

  return (
    <>
      <div className="flex h-screen justify-between">
        <Helmet>
          <title>{payroll.data.name} - Payrolls</title>
        </Helmet>

        <div className="sticky top-0 grow overflow-y-auto [&::-webkit-scrollbar]:hidden">
          <PageHeader
            title={payroll.data.name}
            breadcrumb={[
              { text: 'Dashboard', link: '/' },
              { text: 'Payrolls', link: '/payrolls' },
              { text: payroll.data.name },
            ]}
            actions={
              payroll.data.status !== 'Completed' && (
                <div className="flex min-h-[33.41px] gap-2">
                  <Popup
                    content="Cannot mark as complete until every transaction is reviewed"
                    position="left center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    disabled={canMarkAsComplete}
                    trigger={
                      <div
                        className={classNames(
                          !canMarkAsComplete && 'cursor-not-allowed',
                        )}
                      >
                        <Button
                          onClick={() =>
                            setCurrentDialog({ type: 'complete-payroll' })
                          }
                          disabled={!canMarkAsComplete}
                          type="button"
                          size="small"
                          primary
                          className="!mr-0"
                          content="Mark as Complete"
                        />
                      </div>
                    }
                  />
                  <Button
                    onClick={() => setCurrentDialog({ type: 'delete-payroll' })}
                    type="button"
                    icon="trash alternate outline"
                    className="!mr-0"
                    size="small"
                    color="red"
                    basic
                  />
                </div>
              )
            }
          />

          <div className="mx-14 -mt-2 grid grid-cols-3 gap-x-6 pb-1">
            <div className="leading-6 text-slate-500">
              <span className="inline-block  pr-2 text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Status:
              </span>
              <PayrollStatusLabel payroll={payroll.data} />
              {payroll.data.completedOn && (
                <div className="text-base text-slate-500">
                  <span className="mr-1.5">
                    on {format(payroll.data.completedOn, 'PPP')}
                  </span>
                  {payroll.data.completedBy && (
                    <span className="inline-flex items-center gap-1">
                      by <UserName id={payroll.data.completedBy} />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="leading-6 text-slate-500">
              <span className="inline-block pr-2 text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Period:
              </span>
              <span className="text-nowrap">
                {payroll.data.periodStart
                  ? format(payroll.data.periodStart, 'PPP')
                  : ''}{' '}
                &mdash;
              </span>
              <span className="text-nowrap">
                {payroll.data.periodEnd
                  ? format(payroll.data.periodEnd, 'PPP')
                  : ''}
              </span>
            </div>
            <div
              className={classNames(
                'leading-6',
                isOverdue ? 'text-red-500' : 'text-slate-500',
              )}
            >
              <span className="inline-block pr-2 text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Due on:
              </span>
              {format(payroll.data.dueDate, 'PPP')}
              {isOverdue && (
                <span className="text-xs font-semibold uppercase text-red-500">
                  (Overdue)
                </span>
              )}
            </div>
          </div>

          <div className="mx-14 mb-4 mt-5 grid grid-cols-3 gap-6">
            <div className="rounded-md bg-slate-50 px-4 py-3 shadow">
              <div className="mb-2 text-xl font-semibold text-slate-500">
                Transactions
              </div>
              <TransactionsSummaryWidget
                payrollStatus={payroll.data.status}
                summary={summary}
              />
            </div>
            <div className="rounded-md bg-slate-50 px-4 py-3 shadow">
              <div className="mb-2 text-xl font-semibold text-slate-500">
                Amounts
              </div>
              <AmountsSummaryWidget
                payrollStatus={payroll.data.status}
                summary={summary}
              />
            </div>
          </div>

          <div className="mx-14 mb-14">
            <div className="flex flex-wrap gap-3 px-3 pb-6 pt-4">
              <div className="flex items-baseline">
                <span className="mr-2 whitespace-nowrap">Looking for</span>
                <Input
                  className="w-48"
                  size="mini"
                  onChange={(e) => setCandidateSearch(e.target.value)}
                  value={candidateSearch || ''}
                  input={
                    <input
                      style={{ fontSize: 'unset' }}
                      placeholder="All Candidates"
                      className="placeholder:!font-bold placeholder:![color:rgba(0,0,0,.6)] placeholder:![font-size:12px]"
                    />
                  }
                />
              </div>
              <div className="flex max-w-80 items-baseline">
                <span className="mr-2 whitespace-nowrap">working with</span>
                <DataFilter
                  onFilterChange={(value) => {
                    if (value?.customerId === null) {
                      setCustomerFilter(null)
                    } else if (Array.isArray(value?.customerId?.$in)) {
                      setCustomerFilter(value?.customerId.$in)
                    }
                  }}
                  name="customerId"
                  label="All Customers"
                  collection="companies"
                  dataSelectUrl="companies/in"
                  dataSelectFilter={{
                    _id: payroll.data.transactions.map((tx) => tx.customerId),
                  }}
                  customSort={(a: Company, b: Company) =>
                    a.name.localeCompare(b.name)
                  }
                  optionRender={(x: { name: string }) => x.name}
                />
              </div>
              <div className="flex items-baseline gap-2">
                <span className="whitespace-nowrap">and</span>
                <SimpleFilter
                  onFilterChange={(value) => {
                    if (
                      value?.status === null ||
                      typeof value?.status === 'string'
                    ) {
                      setStatusFilter(value.status)
                    }
                  }}
                  name="status"
                  options={
                    payroll.data.status === 'Completed'
                      ? [
                          { value: false, text: 'Any', key: 0 },
                          {
                            value: 'Completed',
                            text: 'Completed',
                            content: (
                              <TransactionStatusLabel status="Completed" />
                            ),
                          },
                          {
                            value: 'Dismissed',
                            text: 'Dismissed',
                            content: (
                              <TransactionStatusLabel status="Dismissed" />
                            ),
                          },
                        ]
                      : [
                          { value: false, text: 'Any', key: 0 },
                          {
                            value: 'Draft',
                            text: 'Draft',
                            content: <TransactionStatusLabel status="Draft" />,
                          },
                          {
                            value: 'Approved',
                            text: 'Approved',
                            content: (
                              <TransactionStatusLabel status="Approved" />
                            ),
                          },
                          {
                            value: 'Dismissed',
                            text: 'Dismissed',
                            content: (
                              <TransactionStatusLabel status="Dismissed" />
                            ),
                          },
                        ]
                  }
                />
                <span className="mr-2 whitespace-nowrap">status</span>
              </div>
            </div>

            <table className="w-full">
              <thead>
                <tr className="border-b border-t border-slate-300">
                  <th className="p-0 text-left">
                    <button
                      className="min-w-52 px-3 py-2 text-left text-sm font-semibold uppercase leading-6 text-primary hover:opacity-90"
                      type="button"
                      onClick={() =>
                        setSort((prev) => ({
                          by: 'candidate',
                          dir: prev.dir === 'asc' ? 'desc' : 'asc',
                        }))
                      }
                    >
                      Candidate
                      <Icon
                        name={sort.dir === 'asc' ? 'sort up' : 'sort down'}
                        className={classNames(
                          'relative',
                          sort.by !== 'candidate' && '!opacity-0',
                          sort.dir === 'asc' ? 'top-0.5' : '-top-1',
                        )}
                      />
                    </button>
                  </th>
                  <th className="w-[1%] px-0 py-2 text-right text-sm font-semibold uppercase leading-6 text-primary">
                    <span className="sr-only">Actions</span>
                  </th>
                  <th className="p-0 text-left">
                    <button
                      className="min-w-40 px-3 py-2 text-left text-sm font-semibold uppercase leading-6 text-primary hover:opacity-90"
                      type="button"
                      onClick={() =>
                        setSort((prev) => ({
                          by: 'customer',
                          dir: prev.dir === 'asc' ? 'desc' : 'asc',
                        }))
                      }
                    >
                      Customer
                      <Icon
                        name={sort.dir === 'asc' ? 'sort up' : 'sort down'}
                        className={classNames(
                          'relative',
                          sort.by !== 'customer' && '!opacity-0',
                          sort.dir === 'asc' ? 'top-0.5' : '-top-1',
                        )}
                      />
                    </button>
                  </th>
                  <th className="w-[1%] px-3 py-2 text-right text-sm font-semibold uppercase leading-6 text-primary">
                    Amount
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-semibold uppercase leading-6 text-primary">
                    Description
                  </th>
                  <th className="min-w-32 py-2 pl-3 pr-7 text-right text-sm font-semibold uppercase leading-6 text-primary">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedTxs.map(([candidateId, candidateTxs]) => {
                  const isDisplayingTotal = Boolean(
                    customTx?.candidateId === candidateId ||
                      (candidateTxs || []).length > 1,
                  )
                  const isHighlighted = hoveredCandidate === candidateId
                  return (
                    <React.Fragment key={candidateId}>
                      <PayrollRow
                        onMouseEnter={() => setHoveredCandidate(candidateId)}
                        onMouseLeave={() => setHoveredCandidate('')}
                        isHighlighted={isHighlighted}
                        onTransactionUpdate={handleTransactionUpdate}
                        payrollStatus={payroll.data?.status}
                        transactions={candidateTxs}
                        isAddingCustomTx={customTx?.candidateId === candidateId}
                        isDisplayingTotal={isDisplayingTotal}
                        onDeleteCustomTx={(txId) =>
                          setCurrentDialog({ type: 'delete-custom-tx', txId })
                        }
                        onAddCustomTx={() =>
                          setCustomTx({
                            description: '',
                            candidateId,
                            amount: 0,
                          })
                        }
                      />
                      {customTx?.candidateId === candidateId && (
                        <>
                          <tr
                            key="custom"
                            className="bg-slate-100"
                            onMouseEnter={() =>
                              setHoveredCandidate(candidateId)
                            }
                            onMouseLeave={() => setHoveredCandidate('')}
                          >
                            <td className="text-nowrap px-3 py-2 align-baseline">
                              <Label
                                circular
                                basic
                                color={
                                  customTx.amount > 0
                                    ? 'green'
                                    : customTx.amount < 0
                                      ? 'red'
                                      : 'grey'
                                }
                              >
                                Custom{' '}
                                {customTx.amount > 0
                                  ? 'credit'
                                  : customTx.amount < 0
                                    ? 'discount'
                                    : 'tx'}
                              </Label>
                            </td>
                            <td className="py-2 pr-3 text-right align-baseline">
                              <form
                                id="add-custom-tx"
                                onSubmit={(e) => {
                                  e.preventDefault()
                                  handleCustomTxCreate()
                                }}
                              >
                                <Input
                                  disabled={isCreatingTx}
                                  size="small"
                                  fluid
                                  type="number"
                                  onChange={(e) =>
                                    setCustomTx((prev) =>
                                      prev
                                        ? {
                                            ...prev,
                                            amount: e.target.valueAsNumber,
                                          }
                                        : null,
                                    )
                                  }
                                  value={customTx.amount || ''}
                                  input={
                                    <input
                                      autoFocus
                                      required
                                      className="!text-right"
                                      placeholder="Amount..."
                                      style={{ fontSize: 'unset' }}
                                    />
                                  }
                                />
                              </form>
                            </td>
                            <td
                              className="px-3 py-2 align-baseline"
                              colSpan={4}
                            >
                              <Input
                                disabled={isCreatingTx}
                                fluid
                                onChange={(e) =>
                                  setCustomTx((prev) =>
                                    prev
                                      ? { ...prev, description: e.target.value }
                                      : null,
                                  )
                                }
                                size="small"
                                value={customTx.description || ''}
                                input={
                                  <input
                                    required
                                    form="add-custom-tx"
                                    style={{ fontSize: 'unset' }}
                                    placeholder="Add a description..."
                                  />
                                }
                              />
                            </td>
                          </tr>
                          <tr
                            key="custom-help-text"
                            onMouseEnter={() =>
                              setHoveredCandidate(candidateId)
                            }
                            onMouseLeave={() => setHoveredCandidate('')}
                          >
                            <td className="bg-slate-100" />
                            <td
                              colSpan={4}
                              className="bg-slate-100 text-sm text-slate-500"
                            >
                              Use a negative amount to create a discount
                            </td>
                          </tr>
                          <tr
                            onMouseEnter={() =>
                              setHoveredCandidate(candidateId)
                            }
                            onMouseLeave={() => setHoveredCandidate('')}
                          >
                            <td
                              className="bg-slate-100 py-2 pr-3 text-right align-baseline"
                              colSpan={5}
                            >
                              <Button
                                onClick={() => setCustomTx(null)}
                                content="Cancel"
                                compact
                                basic
                                disabled={isCreatingTx}
                              />
                              <Button
                                disabled={isCreatingTx}
                                loading={isCreatingTx}
                                content="Save"
                                type="submit"
                                form="add-custom-tx"
                                compact
                                primary
                                className="!mr-0"
                              />
                            </td>
                          </tr>
                        </>
                      )}
                      {isDisplayingTotal && (
                        <tr
                          className={classNames(
                            'border border-l-0 border-r-0',
                            isHighlighted && 'bg-slate-50',
                          )}
                          onMouseEnter={() => setHoveredCandidate(candidateId)}
                          onMouseLeave={() => setHoveredCandidate('')}
                        >
                          <td />
                          <td className="px-3 py-3.5 text-right text-sm font-semibold text-slate-400">
                            {currencyFormat(
                              (candidateTxs || []).reduce((sum, tx) => {
                                if (tx.status === 'Dismissed') return sum
                                return (
                                  sum +
                                  tx.amount * (tx.type === 'credit' ? 1 : -1)
                                )
                              }, customTx?.amount || 0),
                            )}
                          </td>
                          <td
                            className="px-3 py-3.5 text-sm font-semibold uppercase text-slate-400"
                            colSpan={3}
                          >
                            Total
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>

            {!payroll.isLoading &&
              payroll.data.transactions.length > 0 &&
              sortedTxs.length === 0 && (
                <div className="my-4 px-4 py-12 text-center text-lg text-slate-600">
                  No matches for the current filters
                </div>
              )}
          </div>
        </div>

        <div className="w-full min-w-[300px] max-w-[500px] shrink-0 overflow-y-auto border-l bg-white [&::-webkit-scrollbar]:hidden">
          <div className="px-6 py-3">
            <Menu tabular>
              <Menu.Item
                icon="feed"
                name="Feed"
                active={selectedTab === 'feed'}
                onClick={() => setSelectedTab('feed')}
              />
              <Menu.Item
                icon="newspaper outline"
                name="Period News"
                active={selectedTab === 'period-news'}
                onClick={() => setSelectedTab('period-news')}
              />
            </Menu>
            {selectedTab === 'feed' && (
              <Feed
                relatedCollection="payrolls"
                relatedId={payrollId}
                showEditor={false}
              />
            )}
            {selectedTab === 'period-news' && (
              <PeriodNews payroll={payroll.data} />
            )}
          </div>
        </div>
      </div>

      <ConfirmCompletePayrollModal
        open={currentDialog?.type === 'complete-payroll'}
        isPending={isCompletingPayroll}
        onConfirm={handleCompletePayroll}
        onCancel={() => setCurrentDialog(null)}
      />

      <ConfirmRemovePayrollModal
        open={currentDialog?.type === 'delete-payroll'}
        isPending={isDeleting}
        onConfirm={handleDelete}
        onCancel={() => setCurrentDialog(null)}
      />

      <ConfirmRemoveCustomTxModal
        open={currentDialog?.type === 'delete-custom-tx'}
        isPending={isDeletingTx}
        onConfirm={handleDeleteCustomTx}
        onCancel={() => setCurrentDialog(null)}
      />
    </>
  )
}
