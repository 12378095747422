import { Modal, ModalProps } from 'semantic-ui-react'
import * as React from 'react'

import { EnhancedInvoice } from '../../hooks/useBillingPayments'
import CreatePaymentForm from './CreatePaymentForm'

interface Props extends ModalProps {
  onSuccess(): Promise<any>
  onCancel(): void
  invoices: EnhancedInvoice[]
}

export default function CreatePaymentDialog(props: Props) {
  const { onSuccess, onCancel, invoices, ...other } = props

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      centered={false}
      onClose={onCancel}
      {...other}
    >
      <Modal.Header>Add New Payment</Modal.Header>
      <Modal.Content className="!pt-2">
        <CreatePaymentForm
          onSuccess={onSuccess}
          onCancel={onCancel}
          invoices={invoices}
        />
      </Modal.Content>
    </Modal>
  )
}
