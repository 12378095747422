import useSWR from 'swr'
import { z } from 'zod'

import {
  paymentAllocationSchema,
  PaymentAllocation,
  paymentSchema,
  companySchema,
  invoiceSchema,
  Company,
  Invoice,
  Payment,
} from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'

export type EnhancedInvoice = Invoice & {
  customer?: Company
  allocations: (PaymentAllocation & { payment?: Payment })[]
}

export type EnhancedAllocation = PaymentAllocation & { invoice?: Invoice }

export type EnhancedPayment = Payment & {
  customer?: Company
  allocations: EnhancedAllocation[]
}

export default function useBillingPayments() {
  const api = useApi()

  return useSWR(
    'billing-payments',
    async (): Promise<{
      payments: EnhancedPayment[]
      invoices: EnhancedInvoice[]
    }> => {
      const [payments, invoices, companies, allocations] = await Promise.all([
        api
          .get('billing-payments')
          .then(validateResponse(z.array(paymentSchema))),
        api
          .get('billing-invoices')
          .then(validateResponse(z.array(invoiceSchema))),
        api.get('companies').then(validateResponse(z.array(companySchema))),
        api
          .get('billing-payments-allocations', {
            headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '-1' },
          })
          .then(validateResponse(z.array(paymentAllocationSchema))),
      ])

      return {
        payments: payments.map((payment) => ({
          ...payment,
          customer: companies.find((c) => c._id === payment.customerId),
          allocations: allocations
            .filter((alloc) => alloc.paymentId === payment._id)
            .map((alloc) => ({
              ...alloc,
              invoice: invoices.find(
                (invoice) => invoice._id === alloc.invoiceId,
              ),
            })),
        })),
        invoices: invoices.map((invoice) => ({
          ...invoice,
          customer: companies.find((c) => c._id === invoice.customerId),
          allocations: allocations
            .filter((alloc) => alloc.invoiceId === invoice._id)
            .map((alloc) => ({
              ...alloc,
              payment: payments.find(
                (payment) => payment._id === alloc.paymentId,
              ),
            })),
        })),
      }
    },
  )
}
