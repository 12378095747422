import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  Recipe,
  RecipeExecution,
  recipeExecutionSchema,
  RecipeResultStep,
} from '../../types'
import { classNames, validateResponse } from '../../utils'
import { useApi } from '../../store/mainContext'

import { Button, ButtonGroup, Popup } from 'semantic-ui-react'

import UserName from '../remoteValues/userName'
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut'
import { Editor } from '@monaco-editor/react'
import { Card, WorkingSpace } from './ui/GUIElements'

interface Props {
  recipe: Recipe
}

const Arrow = () => (
  <React.Fragment>
    <div className="mt-3 h-10 w-[1px] border border-solid border-slate-500"></div>
    <div className="mb-3 h-0 w-0 border-l-[6px] border-r-[6px] border-t-[8px] border-slate-500 border-l-transparent border-r-transparent"></div>
  </React.Fragment>
)

const KeyValueSection = ({
  values,
  title,
  emptyMessage,
}: {
  values: Record<string, any>
  title: string
  emptyMessage: string
}) => (
  <div className="border-border-grey ">
    <header className="mb-4 text-lg font-bold">{title}</header>

    {Object.keys(values).length === 0 ? (
      <div>{emptyMessage}</div>
    ) : (
      <table className="w-full">
        {Object.keys(values).map((k, si) => (
          <tr key={si} className="border  border-dotted">
            <th className="w-32 p-3 text-right align-text-top">{k}</th>
            {values[k] ? (
              <td className="p-3">
                {JSON.stringify(values[k].value || values[k]).length > 45 ? (
                  <div className="max-w-[400px] whitespace-pre-wrap  break-words text-sm">
                    {JSON.stringify(values[k].value || values[k], null, 2)}
                  </div>
                ) : (
                  JSON.stringify(values[k].value || values[k], null, 2)
                )}
              </td>
            ) : (
              <td className="p-3">No value.</td>
            )}
          </tr>
        ))}
      </table>
    )}
  </div>
)

const ExecutionCard = ({
  onClick,
  isSelected,
  execution,
}: {
  onClick: (execution: RecipeExecution) => void
  isSelected: boolean
  execution: RecipeExecution
}) => (
  <div
    key={execution._id}
    className={classNames(
      'border-b border-dotted border-border-grey p-3 hover:cursor-pointer hover:bg-bg-grey',
      isSelected && 'bg-[var(--bg-grey)]',
    )}
    onClick={() => onClick(execution)}
  >
    <b>Started on</b>
    <p>
      {new Date(execution.ranOn || 0).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}
    </p>
    <b>Ran as:</b> <br />
    <UserName id={execution.ranAs} />
    {execution.completed ? (
      <div className="whitespace-nowrap text-right text-sm font-semibold uppercase text-green-600">
        Completed (
        {Math.round(
          (((execution.completedOn || 0) - (execution.ranOn || 0)) / 1000) *
            100,
        ) / 100}{' '}
        secs)
      </div>
    ) : (
      <div className=" whitespace-nowrap text-right text-sm font-semibold uppercase text-red-600">
        Failed
      </div>
    )}
  </div>
)

const StepCard = ({
  step,
  isLastOne,
  index,
  startedOn,
}: {
  startedOn: number
  step: RecipeResultStep
  isLastOne: boolean
  index: number
}) => {
  const [tab, setTab] = useState('replaced-config')

  return (
    <React.Fragment>
      <Card>
        <div className="mb-4 flex justify-between text-xl font-bold ">
          <div className={'mr-7'}>
            {index} - {step.name}
          </div>

          <ButtonGroup basic size="mini">
            <Popup
              trigger={
                <Button
                  onClick={() => setTab('config')}
                  active={tab === 'config'}
                  icon="setting"
                ></Button>
              }
              on="hover"
              content={'Step Original Configuration'}
            />

            <Popup
              trigger={
                <Button
                  onClick={() => setTab('replaced-config')}
                  active={tab === 'replaced-config'}
                  icon="microchip"
                ></Button>
              }
              on="hover"
              content={'Step Configuration'}
            />

            <Popup
              trigger={
                <Button
                  onClick={() => setTab('result')}
                  active={tab === 'result'}
                  icon="gem"
                ></Button>
              }
              on="hover"
              content={'Step Result'}
            />
          </ButtonGroup>
        </div>

        <React.Fragment>
          {tab === 'config' && (
            <KeyValueSection
              values={step.config}
              title="Step Original Configuration"
              emptyMessage="No original configuration."
            />
          )}
          {tab === 'replaced-config' && (
            <KeyValueSection
              values={step.replacedConfig}
              title="Step Configuration"
              emptyMessage="No configuration."
            />
          )}
          {tab === 'result' && (
            <KeyValueSection
              values={step.result}
              title="Step Result"
              emptyMessage="No results."
            />
          )}
        </React.Fragment>

        <div className="mt-5 text-right text-sm text-slate-500">
          {' '}
          Completed on {step.completedOn - startedOn} ms.
        </div>
      </Card>

      {!isLastOne && <Arrow />}
    </React.Fragment>
  )
}

export default function RecipeHistory(props: Props) {
  const { recipe } = props

  const api = useApi()

  const [selected, setSelected] = useState<RecipeExecution>()

  const [executions, setExecutions] = useState<RecipeExecution[]>([])

  const [show, setShow] = useState(5)

  const [showEditor, setShowEditor] = useState(false)

  useSWR(['recipe-history', recipe._id, show], () =>
    api
      .get(`recipes-history`, {
        params: { recipeId: recipe._id },
        headers: {
          'astor-limit': show,
          'astor-sortby': 'ranOn',
          'astor-sortby-dir': -1,
        },
      })
      .then(validateResponse(z.array(recipeExecutionSchema)))
      .then((d) => {
        setExecutions(d)
        setSelected(d[d.length - 1])
      }),
  )

  useEffect(() => {}, [selected])

  useKeyboardShortcut(
    () =>
      setSelected(
        executions[
          Math.min(
            executions.findIndex((e) => e._id === selected?._id) + 1,
            executions.length - 1,
          )
        ],
      ),
    {
      code: 'ArrowDown',
      shiftKey: true,
    },
  )

  useKeyboardShortcut(
    () =>
      setSelected(
        executions[
          Math.max(executions.findIndex((e) => e._id === selected?._id) - 1, 0)
        ],
      ),
    {
      code: 'ArrowUp',
      shiftKey: true,
    },
  )
  return (
    <React.Fragment>
      <section className="relative">
        <div className="flex">
          <div className="max-h-screen w-[300px] shrink-0">
            {(executions || [])
              .sort((a, b) => (b.ranOn || 0) - (a.ranOn || 0))
              .map((h, i) => (
                <ExecutionCard
                  execution={h}
                  isSelected={h._id === selected?._id}
                  onClick={(e) => setSelected(e)}
                  key={i}
                />
              ))}

            <div className="my-10 text-center">
              <Button basic onClick={() => setShow(show + 10)}>
                View More
              </Button>
            </div>
          </div>
          <WorkingSpace>
            <Button
              className="absolute right-5 top-5"
              basic
              onClick={() => setShowEditor(!showEditor)}
              icon="code"
            ></Button>

            {showEditor && (
              <Editor
                className="mt-14"
                defaultLanguage="json"
                height="calc(100vh - 195px)"
                theme="vs-dark"
                options={{
                  padding: { top: 5 },
                  wordWrap: 'on',
                  fontSize: 14,
                  readOnly: true,
                }}
                value={JSON.stringify(executions, null, 2)}
              />
            )}

            {!showEditor && (
              <>
                {selected?.args && Object.keys(selected?.args).length > 0 && (
                  <React.Fragment>
                    <div className="min-w-[400px] rounded-lg border border-border-grey bg-white text-slate-700 shadow-md hover:cursor-pointer">
                      <KeyValueSection
                        values={selected?.args || {}}
                        title="Arguments"
                        emptyMessage="No arguments."
                      />
                    </div>
                    <Arrow />
                  </React.Fragment>
                )}

                {selected?.steps.map((step, i) => (
                  <StepCard
                    startedOn={
                      (i === 0
                        ? selected.ranOn
                        : selected?.steps[i - 1]?.completedOn) || 0
                    }
                    key={i}
                    step={step}
                    index={i}
                    isLastOne={i + 1 === selected?.steps.length}
                  />
                ))}

                {selected?.error && (
                  <React.Fragment>
                    <Arrow />

                    <div className=" rounded-lg border border-red-100 bg-red-50 text-slate-700 shadow-md hover:cursor-pointer">
                      <div className="whitespace-pre-wrap break-words text-sm">
                        <div className="flex justify-between p-4 text-xl font-bold text-red-500 ">
                          Opps! An error ocurred.
                        </div>

                        <pre className="w-[600px] whitespace-pre-wrap break-words p-4">
                          {JSON.stringify(JSON.parse(selected?.error), null, 2)}
                        </pre>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </>
            )}
          </WorkingSpace>
        </div>
      </section>
    </React.Fragment>
  )
}
