import {
  DropdownItem,
  Dropdown,
  Confirm,
  Button,
  Icon,
} from 'semantic-ui-react'
import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import styled from 'styled-components'

import { useTalentApi } from '../../../store/mainContext'
import DateFormat from '../../dateFormat'
import { Event } from '../../../types'
import UserName from '../../remoteValues/userName'
import Period from '../../period'

const Content = styled.div`
  position: relative;
  border: dotted 1px var(--border-grey);
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  &:hover {
    background-color: #fcfcfc;
  }
`

const EditContent = styled.div`
  position: relative;
  box-shadow: var(--active-shadow) !important;
  border-color: var(--primary) !important;

  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
`

const NoteHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`

const NoteTitle = styled.header`
  margin: 0px;
  width: 100%;
  padding-left: 15px;
  margin-right: 48px;
  font-size: 1.4rem;
`

const NoteContent = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
`

const Title = styled.span`
  text-transform: initial;
`

const Date = styled.h3`
  margin-top: 0px !important;
  margin-bottom: 5px !important;
`

const NoteMenu = styled.div`
  position: absolute;
  font-size: 15px;
  top: 0px;
  right: 0px;
  z-index: 90;
`

const Editor = styled(ContentEditable)`
  min-height: 100px;
  padding-bottom: 100px;

  &:focus {
    outline: none;
  }
`

const ContentBottom = styled.div`
  height: 50px;
  border-left: dotted 1px var(--border-grey);
  margin-left: 40px;
`

const ViewMoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
`

interface Props {
  event: Event
  disabled?: boolean
  isFirstOne?: boolean
  onRemoveSuccess?(): void
}

const Note = ({ event, onRemoveSuccess, isFirstOne, disabled }: Props) => {
  const api = useTalentApi()
  const editor = useRef<HTMLDivElement>(null)

  const [editMode, setEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [originalContent, setOrigintalContent] = useState(event.description)
  const [content, setContent] = useState(event.description)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    if (editor && editor.current) {
      editor.current.focus()
    }
  }, [editMode])

  const handleSave = async () => {
    setIsSaving(true)

    await api.patch(`events/${event._id}`, {
      description: content,
    })

    setOrigintalContent(content)

    setIsSaving(false)
    setEditMode(false)
  }

  const handleCancel = () => {
    setContent(originalContent)
    setEditMode(false)
  }

  const handleConfirmRemove = async () => {
    setIsRemoving(true)

    await api.delete(`events/${event._id}`)

    onRemoveSuccess && onRemoveSuccess()

    setIsRemoving(false)
    setShowConfirmModal(false)
  }

  const collapse = !editMode && isCollapsed

  const Container = editMode ? EditContent : Content

  return (
    <React.Fragment>
      <Container>
        <NoteHeader onClick={() => setIsCollapsed(!isCollapsed)}>
          <UserName id={event.createdBy} image height={45} />

          <NoteTitle>
            <Date>
              <b>
                <Period date={event.createdOn} />
              </b>{' '}
              - <DateFormat date={event.createdOn} hours /> -{' '}
              <span className="whitespace-nowrap">
                by <UserName id={event.createdBy} />
              </span>
            </Date>

            <Title>
              {(event.title || '').substr(0, 1).toUpperCase() +
                (event.title || '').substr(1, (event.title || '').length)}{' '}
            </Title>
          </NoteTitle>

          {!disabled && !editMode && (
            <NoteMenu>
              <ViewMoreButton>
                {collapse && <Icon name="chevron down" />}
                {!collapse && <Icon name="chevron up" />}
              </ViewMoreButton>

              <Dropdown icon="ellipsis horizontal" direction="left">
                <Dropdown.Menu>
                  <DropdownItem onClick={() => setEditMode(true)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem onClick={() => setShowConfirmModal(true)}>
                    <span style={{ color: 'red' }}>Remove</span>
                  </DropdownItem>
                </Dropdown.Menu>
              </Dropdown>
            </NoteMenu>
          )}
        </NoteHeader>

        {!collapse && (
          <React.Fragment>
            {!editMode && (
              <NoteContent>
                <ContentEditable
                  html={content || ''}
                  disabled
                  onChange={(e) => setContent(e.target.value)}
                />
              </NoteContent>
            )}

            {editMode && (
              <React.Fragment>
                <NoteContent>
                  <Editor
                    innerRef={editor}
                    html={content || ''}
                    autoFocus
                    onChange={(e) => setContent(e.target.value)}
                  />
                </NoteContent>

                <ButtonBar>
                  <Button basic disabled={isSaving} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button color="black" onClick={handleSave} loading={isSaving}>
                    <Icon name="save"></Icon>Save
                  </Button>
                </ButtonBar>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>

      {!isFirstOne && <ContentBottom />}
      <Confirm
        open={showConfirmModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmRemove}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this note.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... removing the note?"
        confirmButton={
          <Button primary={false} color="red" loading={isRemoving}>
            Sure, remove.
          </Button>
        }
      ></Confirm>
    </React.Fragment>
  )
}

export default Note
