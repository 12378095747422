import { UseFormRegisterReturn } from 'react-hook-form'
import { Button, Icon, Progress } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'

import { useToasts } from '../toasts/ToastsProvider'
import { ENV_VARS } from '../../env'
import { useApi } from '../../store/mainContext'

interface Props {
  onUploadStart?(): void
  onUploadEnd?(): void
  registerProps: UseFormRegisterReturn
  onUpload(url: string): void
  onClear(): void
  value?: string
}

export default function AttachmentUploadField(props: Props) {
  const {
    registerProps,
    onUploadStart,
    onUploadEnd,
    onUpload,
    onClear,
    value,
  } = props

  const { addToast } = useToasts()
  const api = useApi()

  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const [uploadProgress, setUploadProgress] = React.useState<number | null>(
    null,
  )

  const handleFileSelect = async (file: File) => {
    setUploadProgress(0)
    onUploadStart?.()
    const formData = new FormData()
    formData.append('file', file)
    return api
      .post('cdn/payments-attachments/' + file.name, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (e) => setUploadProgress((e.loaded / e.total) * 100),
      })
      .then(({ data }) => onUpload(data.location))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => {
        setUploadProgress(null)
        onUploadEnd?.()
      })
  }

  return (
    <div>
      <label className="mb-1 block py-1 font-semibold leading-none text-slate-600">
        Receipt{' '}
        <span className="ml-1 text-sm font-normal leading-none text-slate-500">
          (Optional)
        </span>
      </label>

      <div className="h-[40px]">
        {typeof uploadProgress === 'number' ? (
          <div className="flex h-[31px] items-center">
            <Progress
              percent={uploadProgress}
              content={
                <span className="font-normal italic text-slate-400">
                  Uploading...
                </span>
              }
              indicating
              size="small"
              className="!mb-0 grow"
            />
          </div>
        ) : value ? (
          <div className="rounded-md border border-slate-200 bg-slate-50 px-2 py-2.5">
            <div className="center flex items-center justify-between">
              <a
                className="hover:underline"
                href={value}
                target={
                  ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                    ? '_blank'
                    : undefined
                }
                rel="noreferrer"
              >
                <Icon name="file alternate outline" />
                View attachment
              </a>
              <Icon
                name="times"
                className="relative top-px !mr-0.5 cursor-pointer"
                size="small"
                color="grey"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.value = ''
                  }
                  onClear()
                }}
              />
            </div>
          </div>
        ) : (
          <div className="h-[31px]">
            <Button
              content="Upload"
              onClick={() => fileInputRef.current?.click()}
              icon="upload"
              compact
              basic
              type="button"
            />
          </div>
        )}
      </div>

      <input type="hidden" {...registerProps} readOnly />
      <input
        className="hidden"
        ref={fileInputRef}
        type="file"
        onChange={(e) => {
          if (e.target.files?.[0]) handleFileSelect(e.target.files[0])
        }}
      />
    </div>
  )
}
