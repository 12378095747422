import {
  DropdownItemProps,
  Dropdown,
  Button,
  Label,
  Popup,
  Icon,
} from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import * as React from 'react'
import { Link } from 'react-router-dom'

import {
  PaymentOrderTransactionStatus,
  PaymentOrderTransaction,
  PaymentOrderStatus,
  Candidate,
  Supplier,
  Account,
} from '../../types'
import { classNames, currencyFormat } from '../../utils'
import TransactionStatusLabel from './TransactionStatusLabel'
import AccountDetailsPopup from './AccountDetailsPopup'
import { useToasts } from '../toasts/ToastsProvider'
import PayoneerLogo from '../PayoneerLogo'
import UserName from '../remoteValues/userName'

function getStatusOptions(tx: PaymentOrderTransaction): DropdownItemProps[] {
  const option = (value: PaymentOrderTransactionStatus): DropdownItemProps => ({
    content: <TransactionStatusLabel status={value} />,
    text: value,
    value,
    key: value,
  })
  if (['Draft', 'Approved', 'Dismissed'].includes(tx.status)) {
    return [option('Approved'), option('Dismissed')]
  }
  if (['Processing', 'Completed', 'Canceled'].includes(tx.status)) {
    return [option('Completed'), option('Canceled')]
  }
  console.warn('Unknown transaction status:', tx.status, tx)
  return []
}

export type ExtraFields = {
  bankAccount?: Account
  candidate?: Candidate
  supplier?: Supplier
}

interface Props {
  paymentOrderStatus?: PaymentOrderStatus
  onTransactionUpdate(
    id: PaymentOrderTransaction['_id'],
    status: PaymentOrderTransactionStatus,
  ): Promise<any>
  transactions?: Array<PaymentOrderTransaction & ExtraFields>
  isAddingCustomTx?: boolean
  onDeleteCustomTx(txId: PaymentOrderTransaction['_id']): void
  onAddCustomTx(): void
  onMouseEnter(): void
  onMouseLeave(): void
  isHighlighted?: boolean
}

export default function PaymentOrderRow(props: Props) {
  const {
    onTransactionUpdate,
    onDeleteCustomTx,
    isAddingCustomTx,
    onAddCustomTx,
    isHighlighted,
    transactions,
  } = props

  const { addToast } = useToasts()

  const [currentActions, setCurrentActions] = React.useState<
    Record<string, PaymentOrderTransactionStatus | null>
  >({})

  const handleTransactionStatusUpdate = async (
    id: PaymentOrderTransaction['_id'],
    status: PaymentOrderTransactionStatus,
  ) => {
    setCurrentActions((prev) => ({ ...prev, [id]: status }))
    return onTransactionUpdate(id, status)
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setCurrentActions((prev) => ({ ...prev, [id]: null })))
  }

  return (
    <React.Fragment>
      {(transactions || []).map((tx, i, allTxs) => (
        <tr
          className={classNames(
            'group border border-l-0 border-r-0 border-slate-300',
            isHighlighted && 'bg-slate-50',
          )}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          key={tx._id}
        >
          {i === 0 && (
            <td
              rowSpan={allTxs.length + (isAddingCustomTx ? 2 : 0)}
              className={classNames(
                'px-3 align-baseline',
                allTxs.every(
                  (t) => t.status === 'Dismissed' || t.status === 'Canceled',
                ) && 'line-through',
              )}
            >
              {tx.beneficiaryType === 'candidate' &&
                (tx.candidate ? (
                  <Link
                    className="text-nowrap !text-slate-600 hover:!text-primary"
                    to={'/candidates/' + tx.candidate._id}
                  >
                    {tx.candidate.name}
                  </Link>
                ) : (
                  <span className="text-slate-400">No Candidate</span>
                ))}
              {tx.beneficiaryType === 'supplier' &&
                (tx.supplier ? (
                  <span className="text-nowrap text-slate-600">
                    {tx.supplier.name}
                  </span>
                ) : (
                  <span className="text-slate-400">No Supplier</span>
                ))}
            </td>
          )}

          {i === 0 && (
            <td
              className="pr-3 align-baseline"
              rowSpan={allTxs.length + (isAddingCustomTx ? 2 : 0)}
            >
              {props.paymentOrderStatus !== 'Completed' &&
                props.paymentOrderStatus !== 'Processing' && (
                  <Popup
                    content="Add custom transaction"
                    disabled={isAddingCustomTx}
                    position="top center"
                    size="small"
                    trigger={
                      <Button
                        onClick={onAddCustomTx}
                        icon="add"
                        className={classNames(
                          'relative top-[-1px] !mr-0 text-nowrap',
                          isHighlighted && !isAddingCustomTx
                            ? 'opacity-100'
                            : 'pointer-events-none opacity-0',
                        )}
                        size="mini"
                        compact
                        basic
                      />
                    }
                  />
                )}
            </td>
          )}
          <td className="px-2 align-baseline">
            {tx.generatedByPaymentOrder === false && (
              <Popup
                content={
                  <div className="text-base">
                    Created by <UserName id={tx.createdBy} />
                    <br />
                    on {format(tx.createdOn, 'PPP')}
                  </div>
                }
                position="top center"
                trigger={
                  <Label
                    className="cursor-help"
                    circular
                    color="blue"
                    basic
                    content="Custom debit"
                  />
                }
              />
            )}
          </td>
          <td className="px-3 py-3.5 text-right align-baseline">
            <span
              className={classNames(
                (tx.status === 'Dismissed' || tx.status === 'Canceled') &&
                  'line-through',
              )}
            >
              {currencyFormat(tx.amount)}
            </span>
          </td>

          <td className="relative px-3 py-3.5 align-baseline">
            <span
              className={classNames(
                tx.status === 'Dismissed' || tx.status === 'Canceled'
                  ? 'text-slate-400 line-through decoration-black'
                  : 'text-slate-500',
              )}
            >
              {tx.description}
            </span>
          </td>

          <td className="relative text-left align-baseline">
            {tx.bankAccount ? (
              tx.bankAccount.type === 'payoneer' ? (
                <div
                  className={classNames(
                    'relative top-0.5 px-2.5',
                    (tx.status === 'Dismissed' || tx.status === 'Canceled') &&
                      'line-through decoration-slate-500',
                  )}
                >
                  <PayoneerLogo
                    className="w-24 opacity-80"
                    lineThrough={
                      tx.status === 'Dismissed' || tx.status === 'Canceled'
                    }
                  />
                </div>
              ) : (
                <AccountDetailsPopup
                  hoverable
                  account={tx.bankAccount}
                  trigger={
                    <div className="cursor-help px-3 py-3.5 hover:bg-slate-100">
                      <span
                        className={classNames(
                          tx.status === 'Dismissed' || tx.status === 'Canceled'
                            ? 'text-slate-400'
                            : 'text-slate-500',
                        )}
                      >
                        <>
                          <span
                            className={classNames(
                              (tx.status === 'Dismissed' ||
                                tx.status === 'Canceled') &&
                                'line-through decoration-black',
                            )}
                          >
                            {tx.bankAccount.bankName || 'Unknown bank'}
                          </span>{' '}
                          <span className="relative -top-px left-1 rounded border bg-slate-50 px-1 py-0.5 text-xs ">
                            {tx.bankAccount.accountNumber.toString().slice(-4)}
                          </span>
                          <br />
                          <span
                            className={classNames(
                              'text-xs',
                              (tx.status === 'Dismissed' ||
                                tx.status === 'Canceled') &&
                                'line-through decoration-black',
                            )}
                          >
                            {tx.bankAccount.alias}
                          </span>
                        </>
                      </span>
                      &nbsp;
                      <Icon
                        name="info"
                        bordered
                        circular
                        size="tiny"
                        className="relative left-1.5 top-[-2.5px] text-slate-400"
                      />
                    </div>
                  }
                />
              )
            ) : (
              <div
                className={classNames(
                  'px-3 py-3.5 text-slate-400',
                  (tx.status === 'Dismissed' || tx.status === 'Canceled') &&
                    'line-through',
                )}
              >
                N/A
              </div>
            )}
            {props.paymentOrderStatus !== 'Completed' &&
              tx.generatedByPaymentOrder === false && (
                <Button
                  className={classNames(
                    'absolute right-0 top-3 !mr-0',
                    isHighlighted && !isAddingCustomTx
                      ? 'opacity-100'
                      : 'opacity-0',
                  )}
                  onClick={() => onDeleteCustomTx(tx._id)}
                  icon="times"
                  size="mini"
                  compact
                  basic
                />
              )}
          </td>

          <td className="px-3 text-right align-baseline">
            {props.paymentOrderStatus === 'Completed' ||
            (props.paymentOrderStatus === 'Processing' &&
              tx.status === 'Dismissed') ? (
              <TransactionStatusLabel status={tx.status} />
            ) : (
              <Dropdown
                className="relative -top-0.5 whitespace-nowrap"
                pointing
                item
                disabled={!!currentActions[tx._id]}
                loading={!!currentActions[tx._id]}
                selectOnBlur={false}
                options={getStatusOptions(tx)}
                value={tx.status}
                trigger={<TransactionStatusLabel status={tx.status} />}
                onChange={(_, { value }) => {
                  if (typeof value === 'string') {
                    handleTransactionStatusUpdate(
                      tx._id,
                      value as PaymentOrderTransactionStatus,
                    )
                  }
                }}
              />
            )}
          </td>
        </tr>
      ))}
    </React.Fragment>
  )
}
