import { isBefore, startOfToday, isToday as isTodayLib } from 'date-fns'
import { groupBy, partition } from 'ramda'
import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import useSWRMutation from 'swr/mutation'
import { Helmet } from 'react-helmet'

import { useTalentApi } from '../../store/mainContext'
import { useUser } from '../../pages/session/hooks'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'
import DataTable from '../../components/dataTable/dataTable'
import TaskRow from './taskRow'
import useTasksAssignedToUser from '../../hooks/useTasksAssignedToUser'
import CompleteTaskModal from '../../components/modals/completeTaskModal'
import { Task, User } from '../../types'
import RequirePowers from '../../components/requirePowers'
import AddTaskModal from '../../components/modals/addTaskModal'
import UserSelect from '../../components/form/userSelect'
import UserName from '../../components/remoteValues/userName'
import POWERS from '../../powers'
import CollapsableSection from '../../components/CollapsableSection'

type Dialog =
  | { type: 'complete-task'; task: Task }
  | { type: 'edit-task'; task: Task }
  | { type: 'create-task' }

const DONE_TASKS_PAGE_LENGTH = 10

export default function Tasks() {
  const user: any = useUser()
  const api = useTalentApi()

  const [selectedUserId, setSelectedUserId] = useState<User['_id']>(user._id)
  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)
  const [doneTasksCount, setDoneTasksCount] = React.useState(3)

  const { trigger: removeTask } = useSWRMutation<Task[], Error, string[], Task>(
    ['tasks-assignedToId', selectedUserId],
    (_, { arg }) => api.delete(`tasks/${arg._id}`),
  )

  const { trigger: completeTask } = useSWRMutation<
    Task[],
    Error,
    string[],
    Task
  >(['tasks-assignedToId', selectedUserId], (_, { arg }) =>
    api.patch(`tasks/${arg._id}`, { isDone: true, doneNote: arg.doneNote }),
  )

  const { trigger: markAsNotDone } = useSWRMutation<
    Task[],
    Error,
    string[],
    Task
  >(['tasks-assignedToId', selectedUserId], (_, { arg }) =>
    api
      .patch(`tasks/${arg._id}`, { isDone: false })
      .then(() => api.delete(`tasks/${arg._id}/doneNote`)),
  )

  const tasks = useTasksAssignedToUser(selectedUserId)

  const [toggligTasks, setTogglingTasks] = useState<
    Record<Task['_id'], boolean>
  >({})

  const handleDone = async (task: Task) => {
    if (task.requireDoneNote && !task.doneNote) {
      setCurrentDialog({ type: 'complete-task', task })
      return
    }
    if (toggligTasks[task._id]) return
    setTogglingTasks((prev) => ({ ...prev, [task._id]: true }))
    return new Promise((resolve) => window.setTimeout(resolve, 1000)).then(
      async () => {
        return completeTask(task, {
          revalidate: false,
          optimisticData: (current) =>
            (current || []).map((t) =>
              task._id === t._id ? { ...t, isDone: true } : t,
            ),
        })
          .then(() => tasks.mutate())
          .then(() =>
            setTogglingTasks((prev) => ({ ...prev, [task._id]: false })),
          )
          .then(() => setCurrentDialog(null))
      },
    )
  }

  const handleNotDone = async (task: Task) => {
    return markAsNotDone(task, {
      optimisticData: (current) =>
        (current || []).map((t) => {
          const { doneNote, ...other } = t
          return task._id === t._id ? { ...other, isDone: false } : t
        }),
    })
  }

  const handleCreated = async () => {
    return tasks.mutate().then(() => setCurrentDialog(null))
  }

  const handleEdited = async () => {
    return tasks.mutate().then(() => setCurrentDialog(null))
  }

  const handleRemove = (task: Task) => {
    return removeTask(task, {
      optimisticData: (current) =>
        (current || []).filter((t) => task._id !== t._id),
    })
  }

  if (tasks.isLoading) return <PageLoader />

  const [doneTasks, notDoneTasks] = partition<Task>((task) => !!task.isDone)(
    tasks.data || [],
  )

  // Group pending tasks in `past`, `today` & `future` based on their `dueDate`
  const { today, past, future } = groupBy<Task>((task) => {
    return isTodayLib(task.dueDate)
      ? 'today'
      : isBefore(task.dueDate, startOfToday())
        ? 'past'
        : 'future'
  })(notDoneTasks || [])

  const overdueAndDueTodayCount = (today?.length || 0) + (past?.length || 0)

  return (
    <React.Fragment>
      <Helmet>
        <title>Tasks</title>
      </Helmet>

      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Templates', link: '/templates' },
        ]}
        title="Tasks"
        sub="One list to do it all."
        actions={
          <div className="flex">
            <RequirePowers powers={[POWERS.master]}>
              <UserSelect
                onChange={(userId) => setSelectedUserId(userId)}
                clerable={false}
                value={selectedUserId}
              />
            </RequirePowers>

            <Button
              onClick={() => setCurrentDialog({ type: 'create-task' })}
              style={{ marginLeft: 10 }}
              basic
            >
              <Icon name="add" /> Add Task
            </Button>
          </div>
        }
      />

      <div className="mx-14 my-8">
        <CollapsableSection
          title={`Today & Overdue (${overdueAndDueTodayCount})`}
        >
          <React.Fragment>
            {overdueAndDueTodayCount > 0 && (
              <>
                {(past || []).length > 0 ? (
                  <p className="ml-11 pb-4 pt-1 text-base text-slate-500">
                    Hey... came on! catch up with your{' '}
                    <b style={{ color: 'red' }}>due tasks!</b>
                  </p>
                ) : (
                  <p className="ml-11 pb-4 pt-1 text-base text-slate-500">
                    <b style={{ color: 'var(--primary)' }}>Awesome!</b> you are
                    up to date! don&apos;t let your tasks to expire!
                  </p>
                )}
                <DataTable>
                  <thead>
                    <tr>
                      <DataTable.Th width="1%" align="center">
                        Done
                      </DataTable.Th>
                      <DataTable.Th width={200}>Subject</DataTable.Th>
                      <DataTable.Th>Details</DataTable.Th>
                      <DataTable.Th width="1%" className="whitespace-nowrap">
                        Related To
                      </DataTable.Th>
                      <DataTable.Th width="1%" className="whitespace-nowrap">
                        Due Date
                      </DataTable.Th>
                      <DataTable.Th
                        width="1%"
                        className="text-nowrap"
                        align="left"
                      >
                        Assigned By
                      </DataTable.Th>
                      <DataTable.Th width="1%"></DataTable.Th>
                    </tr>
                  </thead>

                  <tbody>
                    {[...(past || []), ...(today || [])].map((task) => {
                      const isToggling = Boolean(toggligTasks[task._id])
                      return (
                        <TaskRow
                          key={task._id}
                          task={task}
                          onEdit={() =>
                            setCurrentDialog({ type: 'edit-task', task })
                          }
                          onRemove={handleRemove}
                          onToggle={handleDone}
                          isToggling={isToggling}
                        />
                      )
                    })}
                  </tbody>
                </DataTable>
              </>
            )}

            {overdueAndDueTodayCount === 0 && (
              <p className="ml-11 pb-4 pt-1 text-base text-slate-500">
                No tasks yet! :)
              </p>
            )}
          </React.Fragment>
        </CollapsableSection>

        <CollapsableSection className="mb-1 mt-16" title="Coming Next">
          <React.Fragment>
            {(future || []).length > 0 && (
              <>
                <div className="ml-11 pb-8 pt-1 text-base text-slate-500">
                  <UserName id={selectedUserId} short />
                  &apos;s future problems, don&apos;t worry about them now.
                </div>
                <DataTable>
                  <thead>
                    <tr>
                      <DataTable.Th width={85} align="center">
                        Done
                      </DataTable.Th>
                      <DataTable.Th width={200}>Subject</DataTable.Th>
                      <DataTable.Th>Details</DataTable.Th>
                      <DataTable.Th width={180}>Related To</DataTable.Th>
                      <DataTable.Th width={180} align="center">
                        Due Date
                      </DataTable.Th>
                      <DataTable.Th width={200} align="left">
                        Assigned By
                      </DataTable.Th>
                      <DataTable.Th width={250}></DataTable.Th>
                    </tr>
                  </thead>
                  <tbody>
                    {(future || []).map((task) => {
                      const isToggling = Boolean(toggligTasks[task._id])
                      return (
                        <TaskRow
                          key={task._id}
                          task={task}
                          onEdit={() =>
                            setCurrentDialog({ type: 'edit-task', task })
                          }
                          onRemove={handleRemove}
                          onToggle={handleDone}
                          isToggling={isToggling}
                        />
                      )
                    })}
                  </tbody>
                </DataTable>
              </>
            )}

            {(future || []).length === 0 && (
              <p className="pb-4 text-base text-slate-500">No tasks yet! :)</p>
            )}
          </React.Fragment>
        </CollapsableSection>

        {doneTasks.length > 0 && (
          <CollapsableSection className="mb-1 mt-16" title="Done">
            <React.Fragment>
              <p className="ml-11 pb-4 pt-1 text-base text-slate-500">
                Task you completed. :)
              </p>

              <DataTable>
                <thead>
                  <tr>
                    <DataTable.Th width="1%" align="center">
                      Done
                    </DataTable.Th>
                    <DataTable.Th width={200}>Subject</DataTable.Th>
                    <DataTable.Th>Details</DataTable.Th>
                    <DataTable.Th width="1%" className="whitespace-nowrap">
                      Related To
                    </DataTable.Th>
                    <DataTable.Th width="1%" className="whitespace-nowrap">
                      Done Date
                    </DataTable.Th>
                    <DataTable.Th
                      width="1%"
                      className="text-nowrap"
                      align="left"
                    >
                      Assigned By
                    </DataTable.Th>
                    <DataTable.Th width="1%"></DataTable.Th>
                  </tr>
                </thead>
                <tbody>
                  {doneTasks
                    .sort((a, b) => (b.doneOn || 0) - (a.doneOn || 0))
                    .slice(0, doneTasksCount)
                    .map((task) => (
                      <TaskRow
                        noLineThrough
                        key={task._id}
                        task={task}
                        onEdit={() =>
                          setCurrentDialog({ type: 'edit-task', task })
                        }
                        onToggle={handleNotDone}
                        onRemove={handleRemove}
                      />
                    ))}
                </tbody>
              </DataTable>
              {doneTasksCount <= doneTasks.length && (
                <div className="mt-6">
                  <Button
                    onClick={() =>
                      setDoneTasksCount((prev) => prev + DONE_TASKS_PAGE_LENGTH)
                    }
                    content="View more"
                    compact
                    basic
                  />
                </div>
              )}
            </React.Fragment>
          </CollapsableSection>
        )}

        <AddTaskModal
          key={currentDialog?.type}
          show={
            currentDialog?.type === 'create-task' ||
            currentDialog?.type === 'edit-task'
          }
          onCancel={() => setCurrentDialog(null)}
          onCreated={handleCreated}
          onEdited={handleEdited}
          taskToEdit={
            currentDialog?.type === 'edit-task' ? currentDialog.task : null
          }
          assignedToId={selectedUserId}
        />

        {currentDialog?.type === 'complete-task' && (
          <CompleteTaskModal
            taskToFullfil={currentDialog.task}
            onMarkAsDone={handleDone}
            onCancel={() => setCurrentDialog(null)}
          />
        )}
      </div>
    </React.Fragment>
  )
}
