import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'

import RecipeScheduler from './RecipeScheduler'
import { Collection } from '../../../types'
import { useUser } from '../../../pages/session/hooks'
import NoteCreator from './noteCreator'
import TaskCreator from './taskCreator'
import POWERS from '../../../powers'
import featureToggles from '../../../featureToggles'

type Tab = 'Note' | 'Task' | 'Email' | 'Recipe'

interface Props {
  relatedCollection: Collection
  relatedId: string
  onSuccess(): Promise<any>
}

export default function FeedEditor(props: Props) {
  const { relatedId, relatedCollection, onSuccess } = props

  const [activeTab, setActiveTab] = useState<Tab>('Note')
  const user = useUser()

  return (
    <div className="relative z-20">
      <Button.Group
        basic
        className="w-full overflow-x-auto [&::-webkit-scrollbar]:hidden"
      >
        <Button
          active={activeTab === 'Note'}
          onClick={() => setActiveTab('Note')}
        >
          <Icon name="bookmark outline" />
          Note
        </Button>
        <Button
          active={activeTab === 'Task'}
          onClick={() => setActiveTab('Task')}
        >
          <Icon name="tasks" /> Task
        </Button>
        <Button
          active={activeTab === 'Email'}
          disabled
          onClick={() => setActiveTab('Email')}
        >
          <Icon name="mail outline" /> Email
        </Button>
        {featureToggles.recipes && (
          <Button
            active={activeTab === 'Recipe'}
            disabled={!user.hasPower(POWERS.runAndScheduleRecipes)}
            onClick={() => setActiveTab('Recipe')}
          >
            <Icon name="lab" /> Recipe
          </Button>
        )}
      </Button.Group>

      {activeTab === 'Note' && (
        <NoteCreator
          relatedCollection={relatedCollection}
          relatedId={relatedId}
          onSuccess={onSuccess}
        />
      )}

      {activeTab === 'Task' && (
        <TaskCreator
          relatedCollection={relatedCollection}
          relatedId={relatedId}
          onSuccess={onSuccess}
        />
      )}

      {activeTab === 'Recipe' && (
        <div className="mt-6 border-b pb-6">
          <RecipeScheduler
            relatedCollection={relatedCollection}
            relatedId={relatedId}
            onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  )
}
