import {
  DropdownItemProps,
  Dropdown,
  Button,
  Select,
  Popup,
  Ref,
} from 'semantic-ui-react'
import { Controller, useForm } from 'react-hook-form'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { companySchema, Payment } from '../../types'
import { validateResponse } from '../../utils'
import PaymentStatusLabel from '../payments/PaymentStatusLabel'
import { useApi } from '../../store/mainContext'

function getStatusOptions(payments: Payment[]): DropdownItemProps[] {
  return [
    {
      value: 'All',
      text: 'All payments',
      description: `${payments.length} payments`,
    },
    {
      value: 'Draft',
      text: 'Draft payments',
      content: <PaymentStatusLabel status="Draft" />,
      description: `${payments.filter((payment) => payment.status === 'Draft').length} payments`,
    },
    {
      value: 'Partially Allocated',
      text: 'Partially Allocated payments',
      content: <PaymentStatusLabel status="Partially Allocated" />,
      description: `${payments.filter((payment) => payment.status === 'Partially Allocated').length} payments`,
    },
    {
      value: 'Fully Allocated',
      text: 'Fully Allocated payments',
      content: <PaymentStatusLabel status="Fully Allocated" />,
      description: `${payments.filter((payment) => payment.status === 'Fully Allocated').length} payments`,
    },
  ]
}

interface Props {
  onChange(newValue: Partial<{ status: string; customerId: string[] }>): void
  customerId: string[]
  payments: Payment[]
  status: string
}

export default function PaymentsFilters(props: Props) {
  const { payments, status, onChange, customerId } = props

  const api = useApi()
  const options = getStatusOptions(payments)
  const selectedStatusOption = options.find((option) => option.value === status)

  const companies = useSWR('companies', () =>
    api.get('companies').then(validateResponse(z.array(companySchema))),
  )

  const [isCustomerFilterOpen, setIsCustomerFilterOpen] = React.useState(false)
  const form = useForm({ defaultValues: { customerId } })
  return (
    <div className="flex items-baseline gap-2">
      <div className="flex items-baseline gap-2">
        Showing{' '}
        <Dropdown
          pointing="top left"
          selectOnBlur={false}
          icon={false}
          trigger={
            <Button
              basic={status === 'All'}
              compact
              primary={status !== 'All'}
              size="tiny"
              content={
                selectedStatusOption?.text ||
                selectedStatusOption?.content ||
                'Nothing'
              }
            />
          }
          onChange={(_, p) => {
            if (typeof p.value === 'string') onChange({ status: p.value })
          }}
          value={status}
          options={options}
        />
      </div>
      <div className="flex items-baseline gap-2">
        for{' '}
        <Popup
          open={isCustomerFilterOpen}
          trigger={
            <Button
              onClick={() => setIsCustomerFilterOpen(true)}
              basic={customerId.length === 0}
              compact
              primary={customerId.length > 0}
              size="tiny"
              content={
                customerId?.length === 0
                  ? 'All customers'
                  : companies.data
                      ?.filter((c) => customerId.includes(c._id))
                      .map((c) => c.name)
                      .join(', ')
              }
            />
          }
          position="bottom left"
        >
          <form
            className="flex w-[350px] items-start gap-2"
            onSubmit={form.handleSubmit((data) => {
              onChange({ customerId: data.customerId })
              setIsCustomerFilterOpen(false)
            })}
          >
            <Controller
              name="customerId"
              control={form.control}
              render={({ field }) => {
                const { ref, ...other } = field
                return (
                  <Ref innerRef={ref}>
                    <Select
                      {...other}
                      loading={companies.isLoading}
                      clearable
                      multiple
                      search
                      fluid
                      placeholder="Select one or more customers"
                      className="grow"
                      options={(companies.data || [])?.map((company) => ({
                        value: company._id,
                        text: company.name,
                      }))}
                      onChange={(_, p) => field.onChange(p.value)}
                    />
                  </Ref>
                )
              }}
            />
            <Button
              type="button"
              icon="times"
              basic
              size="small"
              className="!mr-0"
              onClick={() => setIsCustomerFilterOpen(false)}
            />
            <Button
              type="submit"
              icon="check"
              size="small"
              primary
              className="!mr-0"
            />
          </form>
        </Popup>
      </div>
    </div>
  )
}
