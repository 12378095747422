import {
  DropdownItemProps,
  Placeholder,
  Dropdown,
  Button,
  Popup,
} from 'semantic-ui-react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as React from 'react'

import { useCompanies } from '../../hooks/useCompany'
import { classNames } from '../../utils'
import { ENV_VARS } from '../../env'
import { Company } from '../../types'

type FormValues = { customerId: string }

function Field(props: {
  isLoading?: boolean
  options: DropdownItemProps[]
  onCancel(): void
  onSubmit: SubmitHandler<FormValues>
  value?: Company['_id']
}) {
  const { isLoading, options, onCancel, onSubmit } = props

  const form = useForm<FormValues>({
    defaultValues: { customerId: props.value },
  })

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Controller
        name="customerId"
        control={form.control}
        render={({ field, fieldState }) => (
          <Dropdown
            {...field}
            clearable
            selection
            disabled={isLoading || field.disabled}
            onChange={(_, { value }) => field.onChange(value)}
            placeholder="Select a company"
            loading={isLoading}
            fluid
            options={options}
            error={!!fieldState.error}
          />
        )}
      />

      <div className="flex justify-end gap-1 pt-1.5">
        <Button
          onClick={onCancel}
          content="Cancel"
          className="!mr-0"
          size="tiny"
          compact
          type="button"
        />
        <Button
          content="Save"
          className="!mr-0"
          primary
          size="tiny"
          compact
          type="submit"
        />
      </div>
    </form>
  )
}

interface Props {
  isGeneratedByPeriod?: boolean
  isEditable?: boolean
  value?: string
  onChange(newValue: string): Promise<any>
  onClear(): Promise<any>
}

export default function CompanyField(props: Props) {
  const { isGeneratedByPeriod, isEditable, onChange, onClear } = props

  const companies = useCompanies()

  const [isEditing, setIsEditing] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async ({ customerId }) => {
    setIsEditing(false)
    return customerId ? onChange(customerId) : onClear()
  }

  const selectedCompany = companies.data?.find((c) => c._id === props.value)

  return (
    <div
      className={classNames(
        'group rounded-md px-2 pb-3.5 pt-2',
        isEditable && 'hover:bg-slate-100',
        isEditing && 'bg-slate-100',
      )}
    >
      <label className="block py-1.5 text-sm font-semibold uppercase leading-none text-slate-500">
        Customer
      </label>

      {!isEditing && (
        <div className="relative leading-[38px]">
          {selectedCompany?.name ? (
            <span className="text-lg">{selectedCompany.name}</span>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="text-primary"
              type="button"
            >
              + Add value
            </button>
          )}

          {isEditable && (
            <div className="absolute right-0 top-0 opacity-0 group-hover:opacity-100">
              <Popup
                content="Cannot change the customer of automatically generated invoices"
                disabled={!isGeneratedByPeriod}
                position="top center"
                trigger={
                  <span>
                    <Button
                      disabled={isGeneratedByPeriod}
                      onClick={() => setIsEditing(true)}
                      className={classNames('!mr-0', !props.value && '!hidden')}
                      icon={isGeneratedByPeriod ? 'ban' : 'pencil'}
                      size="mini"
                      compact
                      basic
                    />
                  </span>
                }
              />
            </div>
          )}
        </div>
      )}

      {!isEditing && selectedCompany && (
        <div className="text-base text-slate-500">
          {selectedCompany?.address && <div>{selectedCompany.address}</div>}
          {selectedCompany?.location && <div>{selectedCompany.location}</div>}
          {selectedCompany?.website && (
            <a
              className="!text-slate-500 underline decoration-slate-300"
              target={
                ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                  ? '_blank'
                  : undefined
              }
              rel="noreferrer"
              href={selectedCompany.website}
            >
              {selectedCompany.website.split('//')[1]}
            </a>
          )}
        </div>
      )}

      {!isEditing && props.value && !selectedCompany && (
        <div>
          <Placeholder>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="long" />
            <Placeholder.Line length="short" />
          </Placeholder>
        </div>
      )}

      {isEditing && (
        <Field
          onSubmit={onSubmit}
          onCancel={() => setIsEditing(false)}
          options={(companies.data || []).map((company) => ({
            value: company._id,
            text: company.name,
          }))}
          value={props.value}
        />
      )}
    </div>
  )
}
