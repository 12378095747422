import { ModalContent, ModalProps, Input, Modal } from 'semantic-ui-react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  quickSearchResultSchema,
  candidateSchema,
  companySchema,
  contactSchema,
  Position,
  Company,
} from '../../types'
import { validateResponse } from '../../utils'
import ResultsSection from './ResultsSection'
import RemoteValue from '../remoteValues/remoteValue'
import { useApi } from '../../store/mainContext'

interface Props extends ModalProps {
  onClose(): void
}

export default function QuickSearchDialog(props: Props) {
  const { open, onClose, ...other } = props

  const api = useApi()

  const [inputValue, setInputValue] = React.useState('')

  const results = useSWR(
    ['quick-search', inputValue],
    () =>
      inputValue && inputValue.length > 3
        ? api
            .get('quick-search', { params: { q: inputValue } })
            .then(validateResponse(quickSearchResultSchema))
            .then((data) => {
              // fetch candidates/contacts/companies from tasks and events.
              // The API will handle the dupes
              const tasksAndEvents = [...data.tasks, ...data.events]
              const allCandidateIds = data.candidates
                .map((c) => c._id)
                .concat(
                  tasksAndEvents
                    .filter((e) => e.relatedCollection === 'candidates')
                    .map((e) => e.relatedId || ''),
                )
                .concat('') // prevent fetching everything if array is empty
              const allContactIds = data.contacts
                .map((c) => c._id)
                .concat(
                  tasksAndEvents
                    .filter((e) => e.relatedCollection === 'contacts')
                    .map((e) => e.relatedId || ''),
                )
                .concat('') // prevent fetching everything if array is empty
              const allCompanyIds = data.companies
                .map((c) => c._id)
                .concat(
                  tasksAndEvents
                    .filter((e) => e.relatedCollection === 'companies')
                    .map((e) => e.relatedId || ''),
                )
                .concat('') // prevent fetching everything if array is empty

              return Promise.all([
                api
                  .get('candidates/in', { params: { _id: allCandidateIds } })
                  .then(validateResponse(z.array(candidateSchema))),
                api
                  .get('contacts/in', { params: { _id: allContactIds } })
                  .then(validateResponse(z.array(contactSchema))),
                api
                  .get('companies/in', { params: { _id: allCompanyIds } })
                  .then(validateResponse(z.array(companySchema))),
                Promise.resolve(data.events),
                Promise.resolve(data.tasks),
              ])
            })
            .then(([candidates, contacts, companies, events, tasks]) => ({
              candidates,
              companies,
              contacts,
              events,
              tasks,
            }))
        : null,
    // keep previous results visible while a new search is in-flight
    { keepPreviousData: true },
  )

  // Reset search input field when modal opens
  React.useEffect(() => {
    if (open) setInputValue('')
  }, [open])

  const totalResultsLength = Object.values(results.data || {}).reduce(
    (sum, category) => sum + category.length,
    0,
  )

  return (
    <Modal
      centered={false}
      onClose={onClose}
      dimmer="blurring"
      size="small"
      open={open}
      {...other}
    >
      <ModalContent style={{ padding: 0 }}>
        <Input
          fluid
          size="huge"
          icon="search"
          onChange={(_, p) => setInputValue(p.value)}
          loading={results.isLoading}
          value={inputValue}
          placeholder="What are you looking for?"
          input={
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              autoFocus
              style={{ fontSize: 'unset' }}
            />
          }
        />

        {results.error && !results.data && (
          <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
            <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
            {results.error.message}
          </div>
        )}

        {results.data &&
          !results.error &&
          !results.isLoading &&
          inputValue.length > 0 &&
          totalResultsLength === 0 && (
            <div className="mt-7 text-base text-slate-500">No results</div>
          )}
      </ModalContent>

      {results.data && totalResultsLength > 0 && (
        <ModalContent scrolling className="mt-4">
          <div className="space-y-6">
            {results.data.candidates.length > 0 && (
              <ResultsSection
                title="Candidates"
                items={results.data.candidates}
                events={results.data.events.filter(
                  (e) => e.relatedCollection === 'candidates',
                )}
                tasks={results.data.tasks.filter(
                  (t) => t.relatedCollection === 'candidates',
                )}
                onItemClick={onClose}
                linkTo={(candidate) => `/candidates/${candidate._id}`}
                renderItemTitle={(candidate) => candidate.name}
                renderItemRight={(candidate) =>
                  candidate.positionId && (
                    <RemoteValue
                      collection="positions"
                      predicate={(p: Position) => p.title}
                      id={candidate.positionId}
                    />
                  )
                }
                renderItemSub={(candidate) => candidate.email}
              />
            )}

            {results.data.contacts.length > 0 && (
              <ResultsSection
                title="Contacts"
                items={results.data.contacts}
                events={results.data.events.filter(
                  (e) => e.relatedCollection === 'contacts',
                )}
                tasks={results.data.tasks.filter(
                  (t) => t.relatedCollection === 'contacts',
                )}
                onItemClick={onClose}
                linkTo={(contact) => `/contacts/${contact._id}`}
                renderItemTitle={(contact) => contact.name}
                renderItemRight={(contact) =>
                  contact.companyId && (
                    <RemoteValue
                      collection="companies"
                      predicate={(c: Company) => c.name}
                      id={contact.companyId}
                    />
                  )
                }
                renderItemSub={(contact) => contact.email}
              />
            )}

            {results.data.companies.length > 0 && (
              <ResultsSection
                title="Companies"
                items={results.data.companies}
                events={results.data.events.filter(
                  (e) => e.relatedCollection === 'companies',
                )}
                tasks={results.data.tasks.filter(
                  (t) => t.relatedCollection === 'companies',
                )}
                onItemClick={onClose}
                linkTo={(company) => `/companies/${company._id}`}
                renderItemTitle={(company) => company.name}
                renderItemSub={(company) => company.website}
              />
            )}
          </div>
        </ModalContent>
      )}
    </Modal>
  )
}
