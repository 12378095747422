import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import {
  candidateSchema,
  contractSchema,
  companySchema,
  dealSchema,
} from '../types'
import { validateResponse } from '../utils'
import NewsSection from './NewsSection'
import { useApi } from '../store/mainContext'

interface Props {
  periodStart: number
  periodEnd: number
}

export default function EndedDealsNews(props: Props) {
  const { periodStart, periodEnd } = props

  const api = useApi()

  const { data, isLoading, error } = useSWR(
    ['period-ended-deals', periodStart, periodEnd],
    async () => {
      const deals = await api
        .get('/deals/pro', {
          params: { endDate: { $gte: periodStart, $lte: periodEnd } },
          headers: { 'astor-sortby': 'endDate', 'astor-sortby-dir': 1 },
        })
        .then(validateResponse(z.array(dealSchema)))

      const contracts = await api
        .get('contracts/in', {
          params: { _id: deals.map((d) => d.contractId) },
        })
        .then(validateResponse(z.array(contractSchema)))

      const [candidates, customers] = await Promise.all([
        api
          .get('candidates/in', {
            params: { _id: contracts.map((c) => c.candidateId) },
          })
          .then(validateResponse(z.array(candidateSchema))),
        api
          .get('companies/in', {
            params: { _id: contracts.map((c) => c.customerId) },
          })
          .then(validateResponse(z.array(companySchema))),
      ])

      return deals
        .map((deal) => {
          const contract = contracts.find((c) => c._id === deal.contractId)
          return {
            ...deal,
            contract,
            candidate: candidates.find((c) => c._id === contract?.candidateId),
            customer: customers.find((c) => c._id === contract?.customerId),
          }
        })
        .filter((d) => !!d.contract) // filter out deals from deleted contracts
    },
  )

  const endedDeals = data || []

  return (
    <NewsSection
      title="Ended Deals"
      isLoading={isLoading}
      error={error?.message}
      placeholderCount={2}
    >
      {endedDeals.length > 0 ? (
        <ul className="space-y-2">
          {endedDeals.map((deal) => (
            <li key={deal._id} className="rounded-md bg-slate-50 px-4 py-2">
              <div className="text-sm text-slate-500">
                {format(deal.endDate || 0, 'PPP')}
              </div>
              <div className="mt-1 text-slate-600">
                <span className="font-semibold">{deal.candidate?.name}</span> -{' '}
                {deal.position} at{' '}
                <span className="font-semibold">{deal.customer?.name}</span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="italic text-slate-500">No ended deals this period</div>
      )}
    </NewsSection>
  )
}
