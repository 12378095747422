import { Popup, PopupProps } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import { classNames, currencyFormat } from '../utils'
import { CustomerSow, Deal } from '../types'
import Period from './period'

interface Props extends PopupProps {
  deal: Deal
  sow?: CustomerSow
}

export default function DealDetailsPopup(props: Props) {
  const { deal, sow, ...other } = props

  return (
    <Popup position="top center" {...other}>
      <Popup.Header className="!text-sm uppercase text-primary">
        Deal Details
      </Popup.Header>
      <Popup.Content>
        <table className="text-base">
          <tbody>
            <tr>
              <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-600">
                Status
              </th>
              {deal.active ? (
                <td className="text-nowrap py-1.5 font-bold text-[var(--olive)]">
                  Active{' '}
                  {deal.effectiveDate > Date.now() && ' - Not started yet'}
                </td>
              ) : (
                <td className="text-nowrap py-1.5 font-bold text-[var(--red)]">
                  Terminated{' '}
                  {(deal.endDate || 0) > Date.now() && '- Not ended yet'}
                </td>
              )}
            </tr>

            {deal.effectiveDate && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-600">
                  SOW Effective Date
                </th>
                <td
                  className={classNames(
                    'py-1.5',
                    deal.effectiveDate > Date.now() && 'text-[var(--green)]',
                  )}
                >
                  <span className="text-nowrap">
                    {format(deal.effectiveDate, 'PPP')} (
                    <Period date={deal.effectiveDate} />)
                  </span>
                </td>
              </tr>
            )}

            {deal.endDate && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-600">
                  SOW End Date
                </th>
                <td
                  className={classNames(
                    'py-1.5',
                    deal.endDate > Date.now() && 'text-[var(--green)]',
                  )}
                >
                  <span className="text-nowrap">
                    {format(deal.endDate, 'PPP')} (
                    <Period date={deal.endDate} />)
                  </span>
                </td>
              </tr>
            )}

            {deal.position && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-600">
                  Position
                </th>
                <td className="text-nowrap py-1.5">{deal.position}</td>
              </tr>
            )}

            {deal.monthlySalary && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-600">
                  Contractor Fee
                </th>
                <td className="py-1.5">{currencyFormat(deal.monthlySalary)}</td>
              </tr>
            )}

            {sow?.monthlyRate && (
              <tr>
                <th className="text-nowrap py-1.5 pr-6 text-left align-baseline text-slate-600">
                  Monthly Rate
                </th>
                <td className="py-1.5">{currencyFormat(sow.monthlyRate)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Popup.Content>
    </Popup>
  )
}
