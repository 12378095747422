import {
  NavLink,
  Redirect,
  Route,
  useHistory,
  useParams,
} from 'react-router-dom'
import { Label, Menu } from 'semantic-ui-react'
import React, { useState } from 'react'
import { isFuture } from 'date-fns'
import { Helmet } from 'react-helmet'
import useSWR from 'swr'
import { z } from 'zod'

import {
  recipeScheduleSchema,
  RecipeSchedule,
  recipeSchema,
  userSchema,
  Recipe,
} from '../../types'
import ConfirmDeleteRecipeScheduleDialog from '../../components/recipes/ConfirmDeleteRecipeScheduleDialog'
import ConfirmDeleteRecipeDialog from '../../components/recipes/ConfirmDeleteRecipeDialog'
import { validateResponse } from '../../utils'
import RecipeScheduledRuns from '../../components/recipes/RecipeScheduledRuns'
import RecipeHistory from '../../components/recipes/RecipeHistory'
import RecipeEditor from '../../components/recipes/RecipeEditor'

import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import featureToggles from '../../featureToggles'
import RecipeEditorGUI from '../../components/recipes/RecipeEditorGUI'

type Dialog =
  | { type: 'delete-schedule'; schedule: RecipeSchedule }
  | { type: 'delete-recipe'; recipeId: Recipe['_id'] }

export default function RecipePage() {
  const { recipeId } = useParams<{ recipeId?: string }>()

  if (!recipeId) {
    throw new Error('`recipeId` param must be present in the URL.')
  }

  const history = useHistory()
  const api = useApi()

  const { data, isLoading, mutate } = useSWR(['recipe', recipeId], async () =>
    Promise.all([
      api.get('recipes/' + recipeId).then(validateResponse(recipeSchema)),
      api
        .get('recipe-schedules', {
          params: { status: 'pending', recipeId },
        })
        .then(validateResponse(z.array(recipeScheduleSchema)))
        .then(async (schedules) => {
          const users = await api
            .get('users')
            .then(validateResponse(z.array(userSchema)))
          return (
            schedules
              // TODO: for some reason {params: { whenToRun: {$gte: Date.now()}}}
              // is not working as a filter
              .filter((schedule) => isFuture(schedule.whenToRun || 0))
              .map((schedule) => ({
                ...schedule,
                createdByUser: users.find(
                  (user) => user._id === schedule.createdBy,
                ),
              }))
          )
        }),
    ]).then(([recipe, schedules]) => ({ recipe, schedules })),
  )

  const [currentDialog, setCurrentDialog] = useState<Dialog | null>(null)

  if (!featureToggles.recipes) return <Redirect to="/" />

  if (isLoading || !data) return <PageLoader />

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {data?.recipe.name ? `${data.recipe.name} - Recipe` : 'New Recipe'}
        </title>
      </Helmet>

      <div className="mx-14 my-5">
        <Menu tabular>
          <Menu.Item to={'/recipes/' + recipeId} as={NavLink} exact>
            Recipe Editor
          </Menu.Item>
          <Menu.Item to={`/recipes/${recipeId}/raw`} as={NavLink} exact>
            Recipe Raw
          </Menu.Item>
          <Menu.Item to={`/recipes/${recipeId}/history`} as={NavLink}>
            Execution History
          </Menu.Item>
          {data.recipe.type === 'manual' && (
            <Menu.Item to={`/recipes/${recipeId}/schedules`} as={NavLink}>
              Scheduled Runs{' '}
              {data.schedules.length > 0 && (
                <Label className="no-overrides" color="green" size="small">
                  {data.schedules.length.toString()}
                </Label>
              )}
            </Menu.Item>
          )}
        </Menu>
      </div>

      <div className="mx-14">
        <Route path="/recipes/:recipeId" exact>
          <RecipeEditorGUI />
        </Route>

        <Route path="/recipes/:recipeId/raw" exact>
          <RecipeEditor onSuccess={() => mutate()} recipe={data.recipe} />
        </Route>

        <Route path="/recipes/:recipeId/history" exact>
          <RecipeHistory recipe={data.recipe} />
        </Route>

        <Route path="/recipes/:recipeId/schedules" exact>
          <RecipeScheduledRuns
            schedules={data.schedules}
            onDeleteClick={(schedule) => {
              setCurrentDialog({ type: 'delete-schedule', schedule })
            }}
          />
        </Route>
      </div>

      <ConfirmDeleteRecipeDialog
        open={currentDialog?.type === 'delete-recipe'}
        onSuccess={() => history.replace('/recipes')}
        onCancel={() => setCurrentDialog(null)}
        recipeId={recipeId}
      />

      {currentDialog?.type === 'delete-schedule' && (
        <ConfirmDeleteRecipeScheduleDialog
          open
          onSuccess={() => mutate().then(() => setCurrentDialog(null))}
          onCancel={() => setCurrentDialog(null)}
          schedule={currentDialog.schedule}
        />
      )}
    </React.Fragment>
  )
}
