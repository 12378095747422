import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
} from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ConfirmRemoveModal from '../modals/confirmRemoveModal'
import { useTalentApi } from '../../store/mainContext'
import AddAccountModal from '../modals/addAccountModal'
import RequirePowers from '../requirePowers'
import POWERS from '../../powers'
import AddContractModal from '../modals/addContractModal'
import usePowers from '../../hooks/usePowers'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`

const B = styled.div`
  text-align: center;
  margin-right: 20px;
  min-width: 55px;

  &:last-child {
    margin-right: 0px;
  }
`

const LabelText = styled.div`
  margin-top: 5px;
`

const CandidateActions = ({
  candidate,
  contract,
  onSuccess,
  onContractCreated,
}) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false)
  const [showConfirmRemoveAccount, setShowConfirmRemoveAccount] =
    useState(false)
  const [showAddContractModal, setShowAddContractModal] = useState(false)

  const [errorMessage, setErrorMessage] = useState(null)

  const [recipes, setRecipes] = useState([])

  const history = useHistory()
  const api = useTalentApi()

  const userPowers = usePowers()
  const hasRecipesPermissions = userPowers.hasAny(['run-and-schedule-recipes'])

  const hasMoreActions = userPowers.hasAny([
    POWERS.runAndScheduleRecipes,
    POWERS.createContract,
    POWERS.userAccounts,
  ])

  useEffect(() => {
    if (!hasRecipesPermissions) return
    api
      .get(`recipes`, { params: { status: 'Draft' } })
      .then(({ data }) => setRecipes(data))
  }, [api, hasRecipesPermissions])

  const handleRemove = async () => {
    try {
      await api.delete(`candidates/${candidate._id}`)

      setShowConfirmRemove(false)

      history.push(`/candidates`)
    } catch ({ response }) {
      setErrorMessage(response.data.message)
    }
  }

  const handleAccountCreated = (data) => {
    setShowCreateAccountModal(false)
    onSuccess && onSuccess('southEmail', data.southEmail)
  }

  const handleRemoveAccount = async () => {
    await api.delete(`/user-accounts/${candidate._id}`)
    setShowConfirmRemoveAccount(false)
    onSuccess && onSuccess('southEmail', null)
  }

  const handleContractCreated = (c) => {
    onContractCreated && onContractCreated(c)
    setShowAddContractModal(false)
  }

  const runRecipe = async (id) => {
    api
      .post(`recipes/${id}/run`, {
        runOnCollection: 'candidates',
        runOnId: candidate._id,
        args: {
          note: 'Hola mundo.',
          title: 'Un titulo mas.',
        },
      })
      .then(() => alert('Done!'))
  }

  return (
    <React.Fragment>
      <Container>
        <B>
          <Button
            color="red"
            circular
            icon="trash alternate outline"
            onClick={() => setShowConfirmRemove(true)}
          ></Button>
          <LabelText>Remove</LabelText>
        </B>

        <B>
          <Button
            basic
            circular
            icon="mail"
            onClick={() =>
              (document.location.href = `mailto:${candidate.email}`)
            }
          ></Button>
          <LabelText>Mail</LabelText>
        </B>

        <B>
          <Button
            basic
            circular
            icon="share"
            onClick={() => alert('Not ready yet. Sorry.')}
          ></Button>
          <LabelText>Share</LabelText>
        </B>

        <B>
          <Dropdown
            pointing="top right"
            icon={null}
            disabled={!hasMoreActions}
            trigger={
              <Button
                color="black"
                circular
                icon="ellipsis horizontal"
              ></Button>
            }
          >
            <Dropdown.Menu>
              <RequirePowers powers={[POWERS.createContract]}>
                <DropdownItem
                  disabled={contract && contract.active}
                  onClick={() => setShowAddContractModal(true)}
                >
                  <Icon name="flag checkered" /> Start contract
                </DropdownItem>
              </RequirePowers>
              <RequirePowers powers={[POWERS.userAccounts]}>
                {!candidate.southEmail && (
                  <DropdownItem
                    disabled={
                      !candidate.email ||
                      candidate.southEmail ||
                      !contract ||
                      !contract.active
                    }
                    onClick={() => setShowCreateAccountModal(true)}
                  >
                    <Icon name="add" /> Create Account
                  </DropdownItem>
                )}

                {candidate.southEmail && (
                  <DropdownItem
                    disabled={contract && contract.active}
                    onClick={() => setShowConfirmRemoveAccount(true)}
                  >
                    <div style={{ color: 'var(--red)' }}>
                      <Icon name="trash alternate outline" /> Remove Account
                    </div>
                  </DropdownItem>
                )}
              </RequirePowers>
              <RequirePowers powers={[POWERS.runAndScheduleRecipes]}>
                <Divider />
                <DropdownItem>
                  <Dropdown
                    pointing="left"
                    trigger={
                      <React.Fragment>
                        {' '}
                        <span
                          style={{
                            color: 'var(--primary)',
                            fontWeight: 'bold',
                          }}
                        >
                          <Icon name="lab" /> Run Recipies{' '}
                        </span>
                      </React.Fragment>
                    }
                  >
                    <DropdownMenu>
                      {recipes.map((recipe) => (
                        <DropdownItem
                          key={recipe._id}
                          onClick={() => runRecipe(recipe._id)}
                        >
                          {recipe.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </DropdownItem>
              </RequirePowers>
            </Dropdown.Menu>
          </Dropdown>

          <LabelText>More</LabelText>
        </B>
      </Container>

      <ConfirmRemoveModal
        onConfirm={handleRemove}
        onCancel={() => {
          setShowConfirmRemove(false)
          setErrorMessage(null)
        }}
        show={showConfirmRemove}
        validationValue={candidate.name}
        header="Hey... Removing this candidate?"
        errorMessage={errorMessage}
        content={
          <React.Fragment>
            <p>
              You are about to remove{' '}
              <b className="highlight">{candidate.name}</b>.{' '}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter candidate&apos;s name to confirm.</b>
            </p>
          </React.Fragment>
        }
      />

      <AddAccountModal
        show={showCreateAccountModal}
        candidate={candidate}
        onCancel={() => setShowCreateAccountModal(false)}
        onSuccess={handleAccountCreated}
      />

      <ConfirmRemoveModal
        show={showConfirmRemoveAccount}
        validationValue={candidate.southEmail}
        content={
          <React.Fragment>
            <p>
              You are about to remove{' '}
              <b className="highlight">{candidate.southEmail}</b>.{' '}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter email address to confirm.</b>
            </p>
          </React.Fragment>
        }
        header="Hey... Removing this account?"
        onCancel={() => setShowConfirmRemoveAccount(false)}
        onConfirm={handleRemoveAccount}
      />

      <AddContractModal
        show={showAddContractModal}
        candidate={candidate}
        onCancel={() => setShowAddContractModal(false)}
        onSuccess={handleContractCreated}
      />
    </React.Fragment>
  )
}

export default CandidateActions
