import { Loader, Menu, MenuItem } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { EnhancedInvoice } from '../../hooks/useInvoice'
import {
  billingTransactionSchema,
  customerSowSchema,
  candidateSchema,
  invoiceSchema,
  dealSchema,
} from '../../types'
import { validateResponse } from '../../utils'
import InvoiceStatusLabel from './InvoiceStatusLabel'
import { useApi } from '../../store/mainContext'

interface Props {
  currentInvoice: EnhancedInvoice
  selectedItem: EnhancedInvoice
  onClick(invoice: EnhancedInvoice): any
  maxInvoices?: number
}

export default function PreviousInvoicesMenu(props: Props) {
  const { onClick, selectedItem, currentInvoice, maxInvoices = 2 } = props

  const api = useApi()

  const { data, isLoading } = useSWR(
    currentInvoice.customerId && currentInvoice.issueDate
      ? [
          'prev-invoices',
          currentInvoice._id,
          currentInvoice.customerId,
          /**
           * Formatting for the key is required because timestamps from
           * optimistic updates (which are local) are different from timestamps
           * returned from revalidations (which are UTC-0) and thus would end up
           * triggering this hook twice when updating the issueDate field.
           */
          format(currentInvoice.issueDate, 'yyyy-MM-dd'),
        ]
      : null,
    async () => {
      const invoices = await api
        .get('billing-invoices/pro', {
          headers: {
            'astor-sortby': 'issueDate',
            'astor-sortby-dir': '-1',
            'astor-limit': 5,
          },
          params: {
            customerId: currentInvoice.customerId,
            issueDate: { $lt: currentInvoice.issueDate },
            status: { $ne: 'Draft' },
          },
        })
        .then(validateResponse(z.array(invoiceSchema)))
        .then((all) =>
          all
            .filter(
              (item) =>
                item.status !== 'Ready to be Sent' &&
                item.status !== 'Scheduled' &&
                item.status !== 'Draft' &&
                item._id !== currentInvoice._id,
            )
            .slice(0, maxInvoices),
        )

      const transactions = await api
        .get('billing-transactions/in', {
          headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '1' },
          params: { billingInvoiceId: invoices.map((i) => i._id) },
        })
        .then(validateResponse(z.array(billingTransactionSchema)))

      const [candidates, deals, sows] = await Promise.all([
        api
          .get('candidates/in', {
            params: { _id: transactions.map((tx) => tx.candidateId) },
          })
          .then(validateResponse(z.array(candidateSchema))),
        api
          .get('deals/in', {
            params: { _id: transactions.map((tx) => tx.dealId).concat('') },
          })
          .then(validateResponse(z.array(dealSchema))),
        api
          .get('customerSOWs/in', {
            params: { _id: transactions.map((tx) => tx.customerSOWId) },
          })
          .then(validateResponse(z.array(customerSowSchema))),
      ])

      return invoices.map((invoice) => ({
        ...invoice,
        customer: currentInvoice.customer,
        sendToCopy: [],
        sendTo: [],
        transactions: transactions
          .filter((tx) => tx.billingInvoiceId === invoice._id)
          .map((tx) => ({
            ...tx,
            candidate: candidates.find((c) => c._id === tx.candidateId),
            deal: deals.find((d) => d._id === tx.dealId),
            sow: sows.find((d) => d._id === tx.customerSOWId),
          })),
      }))
    },
  )

  return (
    <Menu size="mini" tabular compact className="!-ml-px !min-h-[52px]">
      <MenuItem
        content={<span className="font-semibold">Current period</span>}
        active={selectedItem._id === currentInvoice._id}
        name={currentInvoice.name}
        onClick={() => onClick(currentInvoice)}
        className="!self-stretch"
      />
      {(data || []).map((invoice) => (
        <MenuItem
          key={invoice._id}
          name={invoice.name}
          className="[&.active]:!bg-orange-50"
          content={
            invoice.issueDate ? (
              <div>
                <span className="mr-2 text-slate-600">{invoice.name}</span>
                <InvoiceStatusLabel status={invoice.status} small />
                <div className="text-xs text-slate-500">
                  Issue date: {format(invoice.issueDate, 'yyyy-MM-dd')}
                </div>
              </div>
            ) : (
              invoice.name
            )
          }
          onClick={() => onClick(invoice)}
          active={selectedItem._id === invoice._id}
        />
      ))}
      {isLoading && (data || []).length === 0 && (
        <MenuItem
          content={
            <>
              <Loader inline active size="mini" />{' '}
              <span className="ml-2 text-slate-500">
                Fetching previous invoices...
              </span>
            </>
          }
          disabled
        />
      )}
      {!isLoading && (data || []).length === 0 && (
        <MenuItem content="No previous invoices" disabled />
      )}
    </Menu>
  )
}
