import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import AddCompanyModal from '../../components/modals/addCompanyModal'
import { ENV_VARS } from '../../env'
import RemoteValue from '../../components/remoteValues/remoteValue'
import PageHeader from '../../components/pageHeader/pageHeader'
import PagedTable from '../../components/dataTable/pagedTable'
import SearchBox from '../../components/search/searchBox'
import Period from '../../components/period'

const PageContent = styled.div`
  margin: 0px 50px 50px 50px;
`

const Name = styled.p`
  font-size: 1.3em;
  margin-bottom: 3px;
  font-weight: bold;
`

const Sub = styled.div`
  color: #61605f;

  p {
    line-height: 22px;
  }
`

const SearchCompanies = () => (
  <SearchBox
    collection="companies"
    render={({ name, website, address, lastEvent }) => (
      <React.Fragment>
        <Name>{name}</Name>
        <Sub>
          <p>
            {address} <br />
            {website}
          </p>

          <p>
            <b>Last Touch:</b> <Period date={lastEvent} />
          </p>
        </Sub>
      </React.Fragment>
    )}
  />
)

const Companies = () => {
  document.title = 'Companies'

  const history = useHistory()

  const [showAddModal, setShowAddModal] = useState(false)

  const handleCreated = (c) => {
    history.push(`/companies/${c._id}`)
  }

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Companies', link: '/companies' },
        ]}
        title="Companies"
        sub="One screen to partner them all."
        search={<SearchCompanies />}
        actions={
          <React.Fragment>
            <Button basic onClick={() => setShowAddModal(true)}>
              <Icon name="add" /> Add Company
            </Button>
          </React.Fragment>
        }
      />

      <PageContent>
        <PagedTable
          onRowClick={(item) =>
            (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
              ? window.open
              : history.push)(`/companies/${item._id}`)
          }
          defaultSort="lastUpdateOn"
          collection="companies"
          columns={[
            {
              title: 'Name',
              field: 'name',
              width: 300,
              sortable: true,
            },
            {
              title: 'Website',
              field: 'website',
              render: (website) => (
                <a
                  className="inline-block max-w-[300px] break-all text-base"
                  rel="noreferrer"
                  href={website}
                  target={
                    ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? '_blank'
                      : undefined
                  }
                >
                  {website}
                </a>
              ),
              sortable: true,
            },
            {
              title: 'Linkedin',
              field: 'linkedin',
              render: (linkedin) => (
                <a
                  className="inline-block max-w-[300px] break-all text-base"
                  rel="noreferrer"
                  href={linkedin}
                  target={
                    ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? '_blank'
                      : undefined
                  }
                >
                  {linkedin}
                </a>
              ),
              sortable: true,
            },
            {
              title: 'Timezone',
              field: 'timezoneId',
              width: '1%',
              render: (timezoneId) => (
                <RemoteValue
                  collection="timezones"
                  id={timezoneId}
                  key={timezoneId}
                  predicate={(x) => (
                    <span className="whitespace-nowrap text-base">
                      {x.text}
                    </span>
                  )}
                />
              ),
              sortable: true,
            },
            {
              title: 'Last Update',
              field: 'lastUpdateOn',
              render: (lastUpdate) => <Period date={lastUpdate} />,
              sortable: true,
            },
          ]}
        />
      </PageContent>

      <AddCompanyModal
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        onCreated={handleCreated}
      />
    </React.Fragment>
  )
}

export default Companies
