import { Button, Icon, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Task } from '../../types'

const Title = styled.h2`
  font-weight: bold !important;
`

const Label = styled.label`
  font-weight: bold;
  margin: 20px 0 2px 0;
  display: block;
`

const Editable = styled.textarea`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
  resize: none;
  width: 100%;
  display: block;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: none;
    border: solid 1px var(--primary);
    box-shadow: var(--active-shadow);
  }
`

type Props = {
  taskToFullfil?: Task
  onMarkAsDone(task: Task): Promise<any>
  onCancel(): void
}

const CompleteTaskModal = ({
  taskToFullfil,
  onMarkAsDone,
  onCancel,
}: Props) => {
  const [doneNote, setDoneNote] = useState('')

  const [isPending, setIsPending] = useState(false)

  const handleMarkAsDone = () => {
    if (!taskToFullfil) return
    setIsPending(true)
    onMarkAsDone({ ...taskToFullfil, doneNote }).finally(() =>
      setIsPending(false),
    )
  }

  if (!taskToFullfil) return null

  return (
    <Modal onClose={onCancel} open size="small">
      <Modal.Content>
        <Title>Fullfill Task</Title>

        <div>
          <Label>Task</Label>
          {taskToFullfil.subject}
        </div>

        {taskToFullfil.details && (
          <div>
            <Label>Details</Label>
            <div dangerouslySetInnerHTML={{ __html: taskToFullfil.details }} />
          </div>
        )}

        <div>
          <Label>Provide a note to mark this task as done:</Label>
          <Editable
            placeholder="Say something smart..."
            autoFocus
            disabled={isPending}
            onChange={(e) => setDoneNote(e.target.value)}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="false"
            required
            value={doneNote}
            rows={3}
          />
        </div>
      </Modal.Content>

      <Modal.Actions style={{ textAlign: 'right' }}>
        <Button basic onClick={onCancel}>
          Nevermind...
        </Button>
        <Button
          loading={isPending}
          disabled={doneNote.length < 4 || isPending}
          color="black"
          onClick={handleMarkAsDone}
        >
          <Icon name="save" /> Mark as done!
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CompleteTaskModal
