import useSWR from 'swr'
import { z } from 'zod'

import {
  billingTransactionSchema,
  BillingTransaction,
  customerSowSchema,
  candidateSchema,
  invoiceSchema,
  contactSchema,
  CustomerSow,
  dealSchema,
  Candidate,
  Invoice,
  Company,
  Contact,
  Deal,
} from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'

export type EnhancedInvoice = Invoice & {
  transactions?: EnhancedBillingTransaction[]
  customer?: Company
  sendTo: Contact[]
  sendToCopy: Contact[]
}

export type EnhancedBillingTransaction = BillingTransaction & {
  candidate?: Candidate
  deal?: Deal
  sow?: CustomerSow
}

export default function useInvoice(invoiceId: Invoice['_id']) {
  const api = useApi()

  return useSWR<EnhancedInvoice>(['billing-invoices', invoiceId], async () => {
    const [invoice, transactions] = await Promise.all([
      api
        .get('billing-invoices/' + invoiceId)
        .then(validateResponse(invoiceSchema)),
      api
        .get('billing-transactions/in', {
          headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '1' },
          params: { billingInvoiceId: invoiceId },
        })
        .then(validateResponse(z.array(billingTransactionSchema))),
    ])

    const [candidates, contacts, deals, sows] = await Promise.all([
      api
        .get('candidates/in', {
          params: { _id: transactions.map((tx) => tx.candidateId) },
        })
        .then(validateResponse(z.array(candidateSchema))),
      api
        .get('contacts/in', {
          params: {
            _id: [
              ...(invoice.sendToIds || []),
              ...(invoice.sendToCopyIds || []),
            ],
          },
        })
        .then(validateResponse(z.array(contactSchema))),
      api
        .get('deals/in', {
          params: { _id: transactions.map((tx) => tx.dealId).concat('') },
        })
        .then(validateResponse(z.array(dealSchema))),
      api
        .get('customerSOWs/in', {
          params: { _id: transactions.map((tx) => tx.customerSOWId) },
        })
        .then(validateResponse(z.array(customerSowSchema))),
    ])

    return {
      ...invoice,
      sendTo: contacts.filter((contact) =>
        invoice.sendToIds?.includes(contact._id),
      ),
      sendToCopy: contacts.filter((contact) =>
        invoice.sendToCopyIds?.includes(contact._id),
      ),
      transactions: transactions.map((tx) => ({
        ...tx,
        candidate: candidates.find((c) => c._id === tx.candidateId),
        deal: deals.find((d) => d._id === tx.dealId),
        sow: sows.find((d) => d._id === tx.customerSOWId),
      })),
    }
  })
}
