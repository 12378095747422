import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import { AxiosInstance } from 'axios'

import { setupApi } from './setupApi'
import { ApiError } from '../types'

interface ListState {
  filters: any
}

export interface SortableState {
  sortByDir: 'ascending' | 'descending'
  sortBy: string
}

type MainContextType = {
  state: {
    error?: ApiError
    isLoading: boolean
    lists: Record<string, ListState>
    sortable: Record<string, SortableState>
  }
  actions: {
    setIsLoading(newValue: boolean): void
    setError(newValue: ApiError | undefined): void
    setLists: React.Dispatch<
      React.SetStateAction<MainContextType['state']['lists']>
    >
    setSortable: React.Dispatch<
      React.SetStateAction<MainContextType['state']['sortable']>
    >
  }
  contractsApi: AxiosInstance
  mergedApis: AxiosInstance
  talentApi: AxiosInstance
  authApi: AxiosInstance
}

const MainContext = React.createContext<MainContextType>({} as MainContextType)

const MainContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<ApiError>()
  const [isLoading, setIsLoading] = useState(false)
  const [lists, setLists] = useState<MainContextType['state']['lists']>({})
  const [sortable, setSortable] = useState<
    MainContextType['state']['sortable']
  >({})

  const state = { error, isLoading, lists, sortable }
  const actions = { setError, setIsLoading, setLists, setSortable }

  const contractsApi = setupApi('/v1/contracts', actions)
  const mergedApis = setupApi('', actions, true)
  const talentApi = setupApi('/v1/talent', actions)
  const authApi = setupApi('/v2/auth', actions)

  return (
    <MainContext.Provider
      value={{ state, actions, talentApi, authApi, contractsApi, mergedApis }}
    >
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: `center`,
            alignItems: `center`,
            height: '100vh',
          }}
        >
          <img src="/img/loader1.gif" alt="loading..."></img>
        </div>
      ) : (
        children
      )}

      {!isLoading && error && (
        <Modal
          onClose={() => setError(undefined)}
          closeOnDimmerClick={false}
          dimmer="blurring"
          size="small"
          basic
          open
        >
          <Header icon>
            <Icon name="bolt" />
            Oops...
          </Header>
          <Modal.Content>
            <p className="text-2xl">{error.message}</p>

            {error.axiosError && (
              <div className="mb-4">
                <span className="font-semibold">Request:</span>{' '}
                <span className="font-mono">
                  {error.axiosError?.config.method?.toUpperCase()}{' '}
                  {error.axiosError?.config.url}
                </span>
              </div>
            )}

            {error.requestId && (
              <p>
                <span className="font-semibold">RequestId:</span>{' '}
                {error.requestId} (Send this code to Lean please)
              </p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button inverted color="blue" onClick={() => setError(undefined)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </MainContext.Provider>
  )
}

const useContractsApi = () => React.useContext(MainContext).contractsApi
const useMainActions = () => React.useContext(MainContext).actions
const useMainState = () => React.useContext(MainContext).state
const useTalentApi = () => React.useContext(MainContext).talentApi
const useAuthApi = () => React.useContext(MainContext).authApi
const useApi = () => React.useContext(MainContext).mergedApis

const useList = (key: string, defaults: ListState) => {
  const { state, actions } = React.useContext(MainContext)
  const update = (newState: Partial<ListState>) =>
    actions.setLists((prev) => {
      return { ...prev, [key]: { ...prev[key]!, ...newState } }
    })
  return { state: state.lists[key] || defaults, update }
}

export {
  MainContextProvider as Provider,
  MainContext as default,
  useMainActions,
  useMainState,
  useContractsApi,
  useTalentApi,
  useAuthApi,
  useApi,
  useList,
}
