import React, { useState } from 'react'
import * as utils from '../../../utils'

interface Props {
  className?: string
  value: string
  onSave?: (value: string) => void
  onChange?: (value: string) => void
  type: 'input' | 'textarea'
}

export default function EditableValue({
  type,
  className,
  value,
  onSave,
  onChange,
}: Props) {
  const [unsavedValue, setUnsavedValue] = useState(value)

  // useEffect(() => {
  //   onChange && onChange(unsavedValue)
  // }, [unsavedValue, onChange])

  if (type === 'textarea') {
    return (
      <div
        onInput={(e) => {
          setUnsavedValue((e.target as any).innerText)
        }}
        onBlur={() => onSave && onSave(unsavedValue)}
        className={utils.classNames(
          className,
          '-ml-2 w-full max-w-3xl overflow-hidden rounded border-dotted p-2 hover:border hover:!border-dotted focus:border focus:border-primary focus:outline-none ',
        )}
        contentEditable={true}
      >
        {value}
      </div>
    )
  }

  return (
    <div
      contentEditable={true}
      onInput={(e) => {
        setUnsavedValue((e.target as any).innerText)
        onChange && onChange((e.target as any).innerText)
      }}
      onBlur={() => onSave && onSave(unsavedValue)}
      className={utils.classNames(
        className,
        '-ml-2 rounded border-dotted p-2 hover:border hover:!border-dotted focus:border focus:border-primary focus:outline-none ',
      )}
    >
      {value}
    </div>
  )
}
