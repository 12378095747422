import { Button, Input } from 'semantic-ui-react'
import * as React from 'react'

import useField from '../../hooks/useField'

interface Props<V> {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  renderValue?(value: V): React.ReactNode
  addBtnLabel?: string
  onSave(value: string): Promise<any>
  onClear(): Promise<any>
  initialValue: V
  disabled?: boolean
  error?: string
}

export default function StringField(props: Props<string | undefined | null>) {
  const { initialValue, onClear, onSave, disabled, addBtnLabel } = props

  const field = useField<string | undefined | null>({
    initialValue,
    onClear,
    onSave,
  })

  const cancel = field.cancel

  React.useEffect(() => {
    if (disabled) cancel()
  }, [disabled, cancel])

  if (field.isEditing) {
    return (
      <form
        spellCheck="false"
        onSubmit={(e) => {
          e.preventDefault()
          field.save()
        }}
      >
        <Input
          fluid
          value={field.value || ''}
          onChange={(_, p) => field.setValue(p.value)}
          disabled={field.isSaving}
          /* disable 1Password */
          input={<input data-1p-ignore autoFocus {...props.inputProps} />}
        />
        <div className="flex justify-end gap-1 py-1">
          <Button
            content="Cancel"
            disabled={field.isSaving}
            onClick={field.cancel}
            size="mini"
            compact
            type="button"
            className="!mr-0"
          />
          <Button
            content="Save"
            disabled={field.isSaving}
            loading={field.isSaving}
            size="mini"
            compact
            primary
            type="submit"
            className="!mr-0"
          />
        </div>
        {props.error && (
          <div className="-mt-1 text-sm text-red-600">{props.error}</div>
        )}
      </form>
    )
  }

  return field.value ? (
    <div className="relative">
      {props.renderValue ? (
        props.renderValue(field.value)
      ) : (
        <div className="overflow-hidden text-ellipsis text-nowrap leading-[40px]">
          {field.value}
        </div>
      )}
      <div className="absolute right-0 top-1/2 flex -translate-y-1/2 gap-0.5 opacity-0 group-hover:opacity-100">
        <Button
          icon={field.isCopyingToClipboard ? 'check' : 'copy'}
          disabled={field.isCopyingToClipboard}
          size="tiny"
          compact
          basic
          className="!mr-0"
          onClick={() => field.copyToClipboard(field.value || '')}
        />
        {!disabled && (
          <Button
            icon="pencil"
            size="tiny"
            compact
            onClick={field.edit}
            className="!mr-0"
            basic
          />
        )}
      </div>
    </div>
  ) : (
    <div>
      <button
        className="leading-[40px] text-[var(--primary)] hover:text-blue-900 disabled:opacity-50"
        disabled={disabled}
        onClick={field.edit}
      >
        {addBtnLabel || '+ Add value'}
      </button>
      {props.error && (
        <div className="-mt-0.5 text-sm text-red-600">{props.error}</div>
      )}
    </div>
  )
}
