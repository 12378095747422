import React from 'react'
import { Recipe } from '../../../types'
import { Button, Dropdown, Input, TextArea } from 'semantic-ui-react'

const Step0ManualEditor = ({
  recipe,
  onChange,
}: {
  recipe: Partial<Recipe>
  onChange: (newRecipe: Partial<Recipe>) => void
}) => (
  <>
    <div className="mt-6 flex flex-col">
      <label className="mb-2 font-bold">Run on collection</label>
      {/* <p className="text-sm">
      Choose the collection over which you would like to run this
      recipe.
    </p> */}
      <Dropdown
        selection
        options={[
          {
            text: 'Candidates',
            value: 'candidates',
            key: 'candidates',
          },
          {
            text: 'Contacts',
            value: 'contacts',
            key: 'contacts',
          },
          {
            text: 'Companies',
            value: 'companies',
            key: 'companies',
          },
          {
            text: 'Positions',
            value: 'positions',
            key: 'positions',
          },
          {
            text: 'Invoices',
            value: 'invoices',
            key: 'invoices',
          },
        ]}
        placeholder="Choose a collection..."
        value={recipe.collection}
        onChange={(e, d) => {
          onChange({
            ...recipe,
            collection: (d as any).value,
          })
        }}
      />
    </div>

    <div className="mt-6 flex flex-col">
      <label className="mb-2 font-bold">Arguments</label>
      <p className="text-sm">Design input arguments for your recipe.</p>

      <table className="w-full">
        <tr className="border  border-dotted">
          <th className="p-3 text-left">Display name</th>
          <th className="p-3 text-left">Name</th>
          <th className="p-3 text-left">Description</th>
          <th className="p-3 text-left">Type</th>
          <th></th>
        </tr>
        {(recipe.args || []).map((arg, i) => (
          <>
            <tr key={i} className="border  border-dotted">
              <td className="p-3 align-text-top">
                <Input
                  type="text"
                  placeholder="Display Name..."
                  value={arg.displayName}
                  onChange={(e) => {
                    onChange({
                      ...recipe,

                      args: recipe.args?.map((argument, index) =>
                        i === index
                          ? {
                              ...argument,
                              displayName: e.target.value,
                            }
                          : argument,
                      ),
                    })
                  }}
                />
              </td>
              <td className="p-3 align-text-top">
                <Input
                  type="text"
                  placeholder="Name..."
                  value={arg.name}
                  onChange={(e) => {
                    onChange({
                      ...recipe,

                      args: recipe.args?.map((argument, index) =>
                        i === index
                          ? {
                              ...argument,
                              name: e.target.value,
                            }
                          : argument,
                      ),
                    })
                  }}
                />
              </td>
              <td className="p-3 ">
                <TextArea
                  className="h-24 text-sm"
                  type="textarea"
                  placeholder="Description..."
                  value={arg.description}
                  onChange={(e) => {
                    onChange({
                      ...recipe,

                      args: recipe.args?.map((argument, index) =>
                        i === index
                          ? {
                              ...argument,
                              description: e.target.value,
                            }
                          : argument,
                      ),
                    })
                  }}
                />
              </td>

              <td className="p-3 align-text-top">
                <Dropdown
                  selection
                  placeholder="Type..."
                  value={arg.type}
                  options={[
                    {
                      key: 'text',
                      text: 'Text',
                      value: 'text',
                    },
                    {
                      key: 'long-text',
                      text: 'Long text',
                      value: 'long-text',
                    },
                    {
                      key: 'number',
                      text: 'Number',
                      value: 'number',
                    },
                  ]}
                  onChange={(e, data) => {
                    onChange({
                      ...recipe,

                      args: recipe.args!.map((argument, index) =>
                        i === index
                          ? {
                              ...argument,
                              type: data.value as
                                | 'text'
                                | 'long-text'
                                | 'number',
                            }
                          : argument,
                      ),
                    })
                  }}
                />
              </td>
              <td className="p-3 align-text-top">
                <Button
                  size="mini"
                  icon="remove"
                  color="red"
                  onClick={() =>
                    onChange({
                      ...recipe,
                      args: recipe.args?.filter((x, index) => index !== i),
                    })
                  }
                />
              </td>
            </tr>
          </>
        ))}

        <tr className="border  border-dotted">
          <td colSpan={5} className="p-3 align-text-top">
            <Button
              size="tiny"
              basic
              onClick={() =>
                onChange({
                  ...recipe,
                  args: [
                    ...(recipe.args || []),
                    {
                      displayName: '',
                      name: '',
                      type: 'text',
                      description: '',
                    },
                  ],
                })
              }
            >
              + Add Argument
            </Button>
          </td>
        </tr>
      </table>
    </div>
  </>
)

export default Step0ManualEditor
