import React, { useState } from 'react'
import { Recipe } from '../../../types'
import { Button, ButtonGroup, Icon } from 'semantic-ui-react'

import { Card, IndexLabel } from '../ui/GUIElements'
import Step0ManualEditor from './Step0Manual'
import Step0Webhook from './Step0Webhook'
import Step0Event from './Step0Event'

export default function Step0({
  recipe,
  onSave,
}: {
  recipe: Recipe
  onSave: (recipe: Partial<Recipe>) => void
}) {
  const [editMode, setEditMode] = React.useState(false)

  const [unsavedRecipe, setUnsavedRecipe] = React.useState<Partial<Recipe>>({
    webhookId: recipe.webhookId,
    triggerEvent: recipe.triggerEvent,
    collection: recipe.collection,
    args: recipe.args,
    type: recipe.type,
  })

  const [isSaving, setIsSaving] = React.useState(false)

  const [showConfigDetails, setShowConfigDetails] = useState(false)

  const handleCancel = () => {
    setUnsavedRecipe({
      webhookId: recipe.webhookId,
      triggerEvent: recipe.triggerEvent,
      collection: recipe.collection,
      args: recipe.args,
      type: recipe.type,
    })
    setEditMode(false)
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await onSave(unsavedRecipe)

      setEditMode(false)
      setIsSaving(false)
    } catch (e) {
      alert((e as any).response.data.message)
    }
  }

  if (editMode) {
    return (
      <>
        <Card>
          <>
            <div className="absolute right-[10px] top-[-15px] flex flex-col justify-start gap-2">
              <IndexLabel>0</IndexLabel>
            </div>
            <div className="flex">
              <div className="mr-4 flex items-center font-bold">Launch on</div>
              <div>
                <ButtonGroup basic size="tiny" fluid>
                  <Button
                    active={unsavedRecipe.type === 'manual'}
                    onClick={() =>
                      setUnsavedRecipe({
                        ...unsavedRecipe,
                        type: 'manual',
                        triggerEvent: undefined,
                        triggerEnable: undefined,
                        webhookId: undefined,
                        args: recipe.args || [],
                        collection: recipe.collection || 'candidates',
                      })
                    }
                  >
                    Manual
                  </Button>
                  <Button
                    active={unsavedRecipe.type === 'event'}
                    onClick={() =>
                      setUnsavedRecipe({
                        ...unsavedRecipe,
                        type: 'event',
                        triggerEvent:
                          recipe.triggerEvent || 'candidate.created',
                        webhookId: undefined,
                        collection: undefined,
                        args: [],
                      })
                    }
                  >
                    Event
                  </Button>
                  <Button
                    active={unsavedRecipe.type === 'webhook'}
                    onClick={() =>
                      setUnsavedRecipe({
                        ...unsavedRecipe,
                        type: 'webhook',
                        triggerEvent: undefined,
                        collection: undefined,
                        args: [],
                        webhookId: recipe.webhookId || 'new-webhook',
                      })
                    }
                  >
                    Webhook
                  </Button>
                </ButtonGroup>
              </div>
            </div>

            {unsavedRecipe.type === 'manual' && (
              <Step0ManualEditor
                recipe={unsavedRecipe}
                onChange={(newValues) => setUnsavedRecipe(newValues)}
              />
            )}

            {unsavedRecipe.type === 'webhook' && (
              <Step0Webhook
                recipe={unsavedRecipe}
                onChange={(newValues) => setUnsavedRecipe(newValues)}
              />
            )}

            {unsavedRecipe.type === 'event' && (
              <Step0Event
                recipe={unsavedRecipe}
                onChange={(newValues) => setUnsavedRecipe(newValues)}
              />
            )}

            <div className="mt-8 flex justify-end">
              <Button size="tiny" basic onClick={handleCancel}>
                Nevermind
              </Button>
              <Button
                size="tiny"
                color="black"
                onClick={handleSave}
                loading={isSaving}
              >
                <Icon name="save" /> Save
              </Button>
            </div>
          </>
        </Card>
        {/* <Card>
          <pre style={{ whiteSpace: 'pre-wrap' }} className="w-{500px]">
            {JSON.stringify(unsavedRecipe, null, 2)}
          </pre>
        </Card> */}
      </>
    )
  }

  return (
    <React.Fragment>
      <Card>
        <div className="absolute right-[10px] top-[-15px] flex flex-col justify-start gap-2">
          <IndexLabel>0</IndexLabel>
        </div>
        <div className="absolute right-[-50px] top-0 flex flex-col justify-start gap-2">
          <Button
            icon="bars"
            circular
            basic
            onClick={() => setShowConfigDetails(!showConfigDetails)}
            className="!mr-0"
            size="tiny"
          />

          <Button
            icon="pencil"
            circular
            basic
            onClick={() => setEditMode(true)}
            className="!mr-0"
            size="tiny"
          />
        </div>

        <div>
          {recipe.type === 'webhook' && (
            <>
              <h3 className="mb-5  !font-bold">Run On Webhook call</h3>

              <table className="w-full">
                <tr className="border  border-dotted">
                  <th className="w-32 p-3 text-left align-text-top">url</th>

                  <td className="p-3">
                    https://hooks.southteams.com/v1/custom/
                    <b>{recipe.webhookId}</b>
                  </td>
                </tr>
              </table>
            </>
          )}

          {recipe.type === 'event' && (
            <>
              <h3 className="flex justify-between text-lg  !font-bold">
                Run on event
              </h3>
              <table className="w-full">
                <tr className="border  border-dotted">
                  <th className="w-32 p-3 text-left align-text-top">event</th>

                  <td className="p-3">{recipe.triggerEvent}</td>
                </tr>
              </table>
            </>
          )}

          {recipe.type === 'manual' && (
            <>
              <h3 className="mb-5 !font-bold ">Run manually</h3>

              <table className="w-full">
                <tr className="border  border-dotted">
                  <th className="w-32 p-3 text-left align-text-top">
                    collection
                  </th>

                  <td className="p-3">{recipe.collection}</td>
                </tr>
              </table>

              {showConfigDetails && recipe.args && (
                <div className="mt-6 flex flex-col">
                  <label className="mb-2 font-bold">Arguments</label>
                  <p className="text-sm">Input arguments for your recipe.</p>
                  <table className="w-full">
                    <tr className="border  border-dotted">
                      <th className="p-3 text-left">Display name</th>
                      <th className="p-3 text-left">Name</th>
                      <th className="p-3 text-left">Type</th>
                      <th></th>
                    </tr>
                    {recipe.args.map((arg, i) => (
                      <>
                        <tr key={i} className="border  border-dotted">
                          <td className="p-3 align-text-top">
                            {arg.displayName}
                          </td>
                          <td className="p-3 align-text-top">{arg.name}</td>

                          <td className="p-3 align-text-top">{arg.type}</td>
                        </tr>
                      </>
                    ))}
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    </React.Fragment>
  )
}
