import * as React from 'react'

import { BillingPeriodStatus } from '../../types'
import { classNames } from '../../utils'

interface Props {
  status: BillingPeriodStatus
}

export default function BillingPeriodStatusLabel({ status }: Props) {
  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase',
        status === 'Draft' && 'text-slate-400',
        status === 'Completed' && 'text-green-600',
      )}
    >
      {status}
    </span>
  )
}
