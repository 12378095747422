import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Button, Select } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { candidateSchema, funnelSchema } from '../../types'
import { validateResponse } from '../../utils'
import SearchCandidates from '../../components/search/searchCandidates'
import RequirePowers from '../../components/requirePowers'
import { useApi } from '../../store/mainContext'
import POWERS from '../../powers'

interface Props {
  collectionName: string
}

export default function FunnelHeader(props: Props) {
  const { collectionName } = props

  const { funnelName } = useParams<{ funnelName: string }>()

  if (!funnelName) {
    throw new Error('`funnelName` param must be present in the URL')
  }

  const history = useHistory()
  const api = useApi()

  const funnels = useSWR('funnels', () =>
    api
      .get('funnels', { params: { collection: collectionName } })
      .then(validateResponse(z.array(funnelSchema)))
      .then((f) => f.sort((a, b) => a.order - b.order)),
  )

  const handleAddCandidateClick = async () => {
    return api
      .post(collectionName, { name: 'New Candidate' })
      .then(validateResponse(candidateSchema))
      .then(({ _id }) => history.push(`/${collectionName}/${_id}`))
  }

  return (
    <div className="flex items-baseline gap-2 p-4">
      <div className="flex items-baseline">
        <label className="pr-2 font-semibold">View:</label>
        <Select
          placeholder="Pick one..."
          loading={funnels.isLoading}
          disabled={funnels.isLoading}
          value={funnelName}
          options={(funnels.data || [])?.map((funnel) => ({
            text: funnel.title,
            value: funnel.name,
            onClick: () =>
              history.push(`/${collectionName}/funnels/${funnel.name}`),
          }))}
        />
      </div>
      <RequirePowers powers={[POWERS.master]}>
        <Button
          to={`/${collectionName}/funnels/${funnelName}/edit`}
          as={NavLink}
          className="!mr-0"
          icon="pencil"
          basic
        />
      </RequirePowers>
      <Button
        className="!mr-0"
        basic
        icon="refresh"
        onClick={() => {
          Object.keys(window.localStorage)
            .filter((key) => key.startsWith('cache-'))
            .forEach((key) => window.localStorage.removeItem(key))
          document.location.reload()
        }}
      />

      <div className="grow" />

      <SearchCandidates />
      <Button
        content="Add Candidate"
        onClick={handleAddCandidateClick}
        className="!mr-0"
        icon="plus"
        basic
      />
    </div>
  )
}
