import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import { useTalentApi } from '../../store/mainContext'
import AddCompanyModal from '../modals/addCompanyModal'
import { Company } from '../../types'

interface Props {
  allowAdditions?: boolean
  onChange?: (
    e: React.SyntheticEvent<HTMLElement> | null,
    t: DropdownProps,
  ) => void
  name: string
  fluid?: boolean
  error?: boolean
  placeholder?: string
  value?: string
  disabled?: boolean
}

const CompanySelect = ({
  allowAdditions,
  onChange,
  name,
  fluid,
  error,
  disabled,
  placeholder = 'Choose a company',
  value,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState<any>()
  const [options, setOptions] = useState<DropdownItemProps[]>([])

  const api = useTalentApi()

  useEffect(() => {
    api
      .get(`companies`)
      .then(({ data }) =>
        setOptions(
          data.map((c: Company) => ({
            text: c.name,
            value: c._id,
            key: c._id,
          })),
        ),
      )
      .finally(() => setIsLoading(false))
  }, [api])

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleAddCompany = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    setSelectedValue(data.value)
    setShowAddModal(true)
  }

  const handleCancel = () => {
    setSelectedValue(null)
    setShowAddModal(false)
    onChange && onChange(null, { name, value: undefined })
  }

  const handleCompanyCreated = (company: any) => {
    const selectedOption = {
      text: company.name,
      value: company._id,
      key: company._id,
    }

    setOptions([...options, selectedOption])
    setSelectedValue(selectedOption.value)

    setShowAddModal(false)
    console.log('selected', selectedOption)
    onChange && onChange(null, { name, value: selectedOption.value })
  }

  const handleOnChange = (
    e: React.SyntheticEvent<HTMLElement>,
    t: DropdownProps,
  ) => {
    setSelectedValue(t.value)

    onChange && onChange(null, t)
  }

  return (
    <React.Fragment>
      <Dropdown
        key={selectedValue}
        name={name}
        onChange={handleOnChange}
        placeholder={placeholder || 'Company...'}
        search
        allowAdditions={allowAdditions}
        fluid={fluid || true}
        selection
        options={options}
        loading={isLoading}
        onAddItem={handleAddCompany}
        value={selectedValue}
        error={error}
        clearable
        disabled={disabled}
      />

      <AddCompanyModal
        open={showAddModal}
        defaults={{ name: selectedValue }}
        onCreated={handleCompanyCreated}
        onCancel={handleCancel}
      />
    </React.Fragment>
  )
}

export default CompanySelect
