import * as React from 'react'

import SmallUserEvent from './SmallUserEvent'
import StatusNames from '../../remoteValues/statusNames'
import RemoteValue from '../../remoteValues/remoteValue'
import { Event } from '../../../types'
import UserName from '../../remoteValues/userName'

interface Props {
  event: Event
}

export default function StatusChangeEvent(props: Props) {
  const { event } = props
  const { fromStatus, toStatus } = event

  if (!fromStatus && !toStatus) return null

  if (toStatus && !fromStatus) {
    return (
      <SmallUserEvent event={event}>
        <UserName id={event.createdBy} /> moved{' '}
        <RemoteValue
          collection={event.relatedCollection}
          id={event.relatedId}
          predicate={(c) => c.name}
        />{' '}
        to{' '}
        <b>
          <StatusNames status={toStatus} showLoading />
        </b>{' '}
        stage.
      </SmallUserEvent>
    )
  }

  if (!toStatus && fromStatus) {
    return (
      <SmallUserEvent event={event}>
        <UserName id={event.createdBy} /> removed{' '}
        <RemoteValue
          collection={event.relatedCollection}
          id={event.relatedId}
          predicate={(c) => c.name}
        />{' '}
        from{' '}
        <b>
          <StatusNames status={fromStatus} showLoading />
        </b>{' '}
        stage.
      </SmallUserEvent>
    )
  }

  return (
    <SmallUserEvent event={event}>
      <UserName id={event.createdBy} /> moved{' '}
      <RemoteValue
        collection={event.relatedCollection}
        id={event.relatedId}
        predicate={(c) => c.name}
      />{' '}
      from{' '}
      <b>
        <StatusNames status={fromStatus} showLoading />
      </b>{' '}
      to{' '}
      <b>
        <StatusNames status={toStatus} showLoading />
      </b>{' '}
      stage.
    </SmallUserEvent>
  )
}
