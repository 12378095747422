import type { SVGAttributes } from 'react'
import * as React from 'react'

interface Props extends SVGAttributes<SVGSVGElement> {}

export default function BofaLogo({ ...props }: Props) {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      viewBox="586 -25 136 130"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      {...props}
    >
      <path
        fill="#E31837"
        d="M653.3,72.1c21-18,50.5-35.5,66.7-41.8c-2.5-1.6-6.4-3.9-10.8-6.4c-20.9,6.9-45.3,22.2-67.1,39.5 C645.8,66.2,649.7,69.1,653.3,72.1L653.3,72.1z"
      />
      <path
        fill="#012169"
        d="M643.7,23.2c-3.1-1.5-6.5-2.9-9.1-4.1c-7.9,3.9-18.2,9.8-31.2,19.1c2.8,1.5,5.8,3.3,9,5.1 C622.4,35.9,632.5,28.9,643.7,23.2z"
      />
      <path
        fill="#E31837"
        d="M662.4,14.6c-3.8-2.1-17-7-26.1-9.1c-2.7,1-6.5,2.5-9.1,3.6c3.3,0.9,15.6,4.2,26.1,9.5 C656,17.3,659.8,15.7,662.4,14.6z"
      />
      <path
        fill="#012169"
        d="M617.7,13.3c-11.7,5.4-24,12.8-30.7,17.1c2.4,1.1,4.8,2,8.1,3.6c14.8-10,26.4-16.1,31-18.1 C622.8,14.7,619.7,13.9,617.7,13.3L617.7,13.3z"
      />
      <path
        fill="#E31837"
        d="M671.2,11.5c2.7-0.9,5.8-1.7,8.5-2.5c-7.8-3.3-17.6-6.8-26.4-9c-1.4,0.4-5.6,1.5-8.5,2.4 C647.8,3.3,657.7,5.6,671.2,11.5z M621.9,49.1c3.2,1.9,6.6,4.5,9.9,6.7c21.9-17,43.5-30.1,67.2-37.5c-3.3-1.7-6.2-3.2-9.9-5 C674.9,16.9,650.6,26.6,621.9,49.1z"
      />
    </svg>
  )
}
