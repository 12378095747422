import { Button, Confirm, ConfirmProps } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import * as React from 'react'

import { EnhancedPayment } from '../../hooks/useBillingPayments'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

interface Props extends ConfirmProps {
  payment: EnhancedPayment
}

export default function DeletePaymentDialog(props: Props) {
  const { payment, onSuccess, ...other } = props

  const { addToast } = useToasts()
  const api = useApi()

  const [isPending, setIsPending] = React.useState(false)

  const handleConfirm = () => {
    setIsPending(true)
    return api
      .delete('billing-payments/' + payment._id)
      .then(onSuccess)
      .then(() => addToast('Payment deleted', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsPending(false))
  }

  return (
    <Confirm
      header="Are you sure?"
      content={
        <div className="px-6 pb-4">
          <p className="text-base">This action will:</p>
          <ul className="list-disc space-y-2 pl-6 text-lg">
            <li>
              Restore the related invoice(s) &quot;total paid&quot; values and
              update their status.
            </li>
            <li>Delete the selected Payment.</li>
          </ul>
          <p className="mt-4 text-base font-semibold">
            This action cannot be undone.
          </p>
        </div>
      }
      closeOnDimmerClick={!isPending}
      closeOnEscape={!isPending}
      size="tiny"
      onConfirm={handleConfirm}
      cancelButton={<Button disabled={isPending} content="Cancel" />}
      confirmButton={
        <Button
          disabled={isPending}
          primary={false}
          color="red"
          loading={isPending}
        >
          Yes, delete the Payment
        </Button>
      }
      {...other}
    />
  )
}
