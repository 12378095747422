import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Placeholder } from 'semantic-ui-react'
import * as React from 'react'

interface Props {
  title: React.ReactNode
  isLoading?: boolean
  placeholderCount?: number
  children: React.ReactNode
  error?: string
}

export default function NewsSection(props: Props) {
  return (
    <section>
      <header className="mb-4 text-sm uppercase">{props.title}</header>

      {props.isLoading
        ? new Array(props.placeholderCount || 2).fill('').map((_, i) => (
            <Placeholder fluid key={'placeholder-' + i}>
              <Placeholder.Header image>
                <Placeholder.Line length="short" />
                <Placeholder.Line length="long" />
              </Placeholder.Header>
            </Placeholder>
          ))
        : props.children}

      {props.error && (
        <div className="my-6 rounded-md bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {props.error}
        </div>
      )}
    </section>
  )
}
