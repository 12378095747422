import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'
import RequirePowers from '../../components/requirePowers'
import { ENV_VARS } from '../../env'
import RemoteValue from '../../components/remoteValues/remoteValue'
import ContactName from '../../components/remoteValues/contactName'
import CompanyName from '../../components/remoteValues/companyName'
import PageLoader from '../../components/pageLoader'
import DateFormat from '../../components/dateFormat'
import UserName from '../../components/remoteValues/userName'
import NotFound from '../../components/notFound'
import Period from '../../components/period'
import POWERS from '../../powers'

const Container = styled.div`
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  min-height: 200vh;
  border: none;
  border-left: dotted 1px var(--border-grey);
  padding: 40px;
`

const Card = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--primary-light);
  border: dotted 1px var(--border-primary);

  &:hover {
    cursor: pointer !important;
    filter: brightness(0.98);
    border: solid 1px var(--border-primary);
  }
`

const Name = styled.h2`
  font-size: 17px;
  font-weight: bolder !important;
  margin-bottom: 5px !important;
`

const Line = styled.div`
  padding-bottom: 7px;
`

const SubmittedCandidates = ({ positionId }) => {
  const history = useHistory()
  const api = useTalentApi()

  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (!positionId) {
      setIsLoading(false)
      return
    }

    api
      .get(`submissions`, {
        params: { positionId },
      })
      .then(({ data: allSubmissions }) =>
        api
          .get(`candidates/in`, {
            params: { _id: allSubmissions.map((x) => x.candidateId) },
          })
          .then(({ data: allCandidates }) =>
            setRows(
              allSubmissions.map((x) => ({
                ...x,
                candidate: allCandidates.find((c) => c._id === x.candidateId),
              })),
            ),
          ),
      )
      .finally(() => setIsLoading(false))
  }, [positionId, api])

  if (isLoading) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  if (rows.length === 0) {
    return (
      <Container>
        <NotFound
          style={{ marginTop: 200 }}
          message="No submissions yet"
          sub="C'mon! what are you waiting for?"
        />
      </Container>
    )
  }

  return (
    <Container>
      <h2>Submissions</h2>

      <p>Here you fill find candidates we have submitted for this position.</p>

      {rows.map((c) => (
        <Card
          key={c.candidateId}
          onClick={() =>
            (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
              ? window.open
              : history.push)(`/candidates/${c.candidateId}`)
          }
        >
          <h3>
            <b>
              SENT <Period date={c.submittedOn} />
            </b>{' '}
            BY <UserName id={c.submittedBy} />
          </h3>
          <Name>{c.candidate?.name}</Name>

          <Line>
            <b>To:</b> <ContactName id={c.submittedTo} /> (
            <CompanyName id={c.customerId} />)
          </Line>
          <Line>
            <b>As:</b> <RemoteValue id={c.submittedAs} collection="seniority" />
          </Line>

          <Line>
            <b>YoE:</b> {c.candidate?.yearsInTheIndustry} |{' '}
            {c.candidate?.yearsInTheRole}
          </Line>

          <RequirePowers powers={[POWERS.customerSOWs]}>
            <Line>
              <b>Rate:</b> $ {c.rate}
            </Line>
          </RequirePowers>

          <Line>
            <b>Status:</b>{' '}
            <RemoteValue id={c.status} collection="submission-status" />
          </Line>

          {c.notes && <Line>{c.notes}</Line>}

          <Line>
            {c.submittedOn ? (
              <i>
                Sent on <DateFormat date={c.submittedOn} />
              </i>
            ) : (
              <i>Not sent yet.</i>
            )}
          </Line>
        </Card>
      ))}
    </Container>
  )
}

export default SubmittedCandidates
