import { Button, Dropdown, DropdownProps } from 'semantic-ui-react'
import React, { useState } from 'react'

interface Props extends DropdownProps {
  name: string
  value?: any
  onFilterChange(
    newValue: any,
    name: Props['name'],
    propsValue: DropdownProps['value'],
  ): void
}

const SimpleFilter = ({
  onFilterChange,
  options = [],
  value,
  name,
  ...other
}: Props) => {
  const [selected, setSelected] = useState(value || options[0])

  const handleChange = (_: any, p: DropdownProps) => {
    const selectedOption = options.find((x) => x.value === p.value)
    setSelected(selectedOption)

    const newFilter: Record<Props['name'], any> = {}

    if (!p.value || (Array.isArray(p.value) && p.value.length === 0)) {
      newFilter[name] = null
    } else {
      // TODO rethink how to support this type of use case as semantic-ui-react
      // Dropdown component does not support array values and triggers warnings
      newFilter[name] = Array.isArray(p.value) ? { $in: p.value } : p.value
    }

    onFilterChange && onFilterChange(newFilter, name, p.value)
  }

  return (
    <Dropdown
      position={100}
      icon={null}
      trigger={
        <Button
          color={selected.key !== 0 ? 'black' : undefined}
          basic={selected.key === 0}
          size="tiny"
        >
          <b className="whitespace-nowrap">{selected.text}</b>
        </Button>
      }
      options={options}
      onChange={handleChange}
      closeOnChange
      value={selected?.value}
      {...other}
    />
  )
}

export default SimpleFilter
