import React, { ChangeEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  Placeholder,
  Checkbox,
  Message,
  Button,
  Select,
  Icon,
} from 'semantic-ui-react'
import { EmailSyncSettings, GmailLabel } from '../../types'
import SettingsPage from './settingsPage'

import TextArea from '../../components/form/textArea'
import { useApi } from '../../store/mainContext'

const Setting = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  width: 600px;
`

const SettingLabel = styled.div`
  margin-right: 20px;
  width: 200px !important;
  min-width: 200px !important;
  text-align: right;
`

const options = [
  { text: 'Now on', value: 'now-on' },
  { text: 'One month ago', value: 'one-month-ago' },
  { text: 'Three month ago', value: 'three-months-ago' },
  { text: 'Six months ago', value: 'six-months-ago' },
  { text: 'One year ago', value: 'one-year-ago' },
  { text: 'The beginnig of time', value: 'the-beginnig-of-time' },
] as const

type Since = (typeof options)[number]['value']

const EmailSyncSettingsPage = () => {
  document.title = 'Settings > Email Sync'

  const api = useApi()

  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const [originalSettings, setOriginalSettings] = useState<EmailSyncSettings>()
  const [settings, setSettings] = useState<EmailSyncSettings>()
  const [labels, setLabels] = useState<GmailLabel[]>([])
  const [selectedSince, setSelectedSince] = useState<Since>('three-months-ago')

  const refresh = React.useCallback(() => {
    api
      .get<GmailLabel[]>(`v1/email/labels`)
      .then(({ data }) =>
        setLabels([
          { id: 'INBOX', name: 'Inbox', type: 'system' },
          { id: 'SENT', name: 'Sent', type: 'system' },
          ...data.filter((x) => x.type === 'user'),
        ]),
      )

    api.get<EmailSyncSettings>(`v1/email/settings`).then(({ data }) => {
      setSettings(data)
      setOriginalSettings(data)
    })
  }, [api])

  useEffect(() => {
    refresh()
  }, [refresh])

  const getTimeValue = (value: Since) => {
    const values = {
      'now-on': () => Date.now(),
      'one-month-ago': () => Date.now() - 30 * 24 * 60 * 60 * 1000,
      'three-months-ago': () => Date.now() - 3 * 30 * 24 * 60 * 60 * 1000,
      'six-months-ago': () => Date.now() - 6 * 30 * 24 * 60 * 60 * 1000,
      'one-year-ago': () => Date.now() - 12 * 30 * 24 * 60 * 60 * 1000,
      'the-beginnig-of-time': () => 0,
    }

    const syncSince = values[value]()

    return syncSince
  }

  const anychanges = () =>
    JSON.stringify(settings).toLowerCase() !==
    JSON.stringify(originalSettings).toLowerCase()

  const handleSaveAndReSync = async () => {
    setSaving(true)
    await api.patch(`v1/email/settings?resync=true`, settings)

    setSaving(false)
    refresh()
  }

  if (!settings) {
    return (
      <SettingsPage>
        <div>
          <h2>Email Sync Settings</h2>
          <p>Configure how would you like your email account to be synced.</p>

          <Placeholder fluid>
            {new Array(10).map((i) => (
              <Placeholder.Header key={i}>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            ))}
          </Placeholder>
        </div>
      </SettingsPage>
    )
  }

  return (
    <SettingsPage>
      <React.Fragment>
        <div>
          <h1>Email Sync Settings</h1>
          <p>Configure how would you like your email account to be synced.</p>

          <hr />
          <h3>SYNC SETTINGS</h3>

          <Setting>
            <SettingLabel>
              <h4>
                <b>Enable Sync</b>
              </h4>
            </SettingLabel>
            <div>
              <Checkbox
                toggle
                checked={settings.enableSync}
                onChange={() =>
                  setSettings({
                    ...settings,
                    enableSync: !settings.enableSync,
                  })
                }
              />
              <p style={{ marginTop: 5 }}>
                If enable, your gmail account will be synced.
              </p>
            </div>
          </Setting>

          {settings.enableSync && (
            <React.Fragment>
              <Setting>
                <SettingLabel>
                  <h4>Tags to Sync</h4>
                </SettingLabel>
                <div>
                  <p>Any message with these tags will be synced.</p>

                  {labels.map((label) => (
                    <p key={label.id}>
                      <Checkbox
                        checked={settings.labelsToSync.includes(label.name)}
                        onChange={(e, t) => {
                          const checked = t.checked

                          let labelsToSync: string[] = [
                            ...settings.labelsToSync,
                          ]

                          if (checked) {
                            labelsToSync.push(label.name)
                          } else {
                            labelsToSync = labelsToSync.filter(
                              (x) => x !== label.name,
                            )
                          }

                          setSettings({ ...settings, labelsToSync })
                        }}
                      />{' '}
                      {label.name}
                    </p>
                  ))}
                </div>
              </Setting>

              <Setting>
                <SettingLabel>
                  <h4>Addresses to Skip</h4>
                </SettingLabel>
                <div>
                  <p>
                    Avoid sync any message <b>sent or recieved</b> from this
                    email addresses: (Enter one per-line.)
                  </p>
                  <TextArea
                    value={settings.addressesToSkip.join('\n')}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      setSettings({
                        ...settings,
                        addressesToSkip: e.target.value.split('\n'),
                      })
                    }
                  />
                </div>
              </Setting>

              <Setting>
                <SettingLabel>
                  <h4>Sync since</h4>
                </SettingLabel>
                <div>
                  <Select
                    onChange={(_, d) => {
                      if (typeof d.value !== 'string') return
                      const value = getTimeValue(d.value as Since)
                      setSettings({ ...settings, syncSince: value })
                      setSelectedSince(d.value as Since)
                    }}
                    fluid
                    value={selectedSince}
                    options={[...options]}
                  />
                  <p style={{ marginTop: 10 }}>
                    Messages before this date wont be synced.
                  </p>
                </div>
              </Setting>

              <hr />

              <h3>SHARING SETTINGS</h3>

              <Setting>
                <SettingLabel>
                  <h4>
                    Share emails <br />
                    by default
                  </h4>
                </SettingLabel>
                <div>
                  <Checkbox
                    toggle
                    checked={settings.shareEmailsByDefault}
                    onChange={() =>
                      setSettings({
                        ...settings,
                        shareEmailsByDefault: !settings.shareEmailsByDefault,
                      })
                    }
                  />
                  <p style={{ marginTop: 5 }}>
                    If enable, your sync emails{' '}
                    <b>sent or recieved from or to Candidates and Contacts</b>{' '}
                    will be shared with other users depending on their access
                    level by default.
                  </p>
                </div>
              </Setting>
            </React.Fragment>
          )}
          <Setting>
            <SettingLabel>&nbsp;</SettingLabel>

            <div>
              {anychanges() && settings.enableSync && settings.lastSyncOn && (
                <Message color="violet">
                  Any changes applied to the Sync configuration will remove all
                  your syncronized emails and resync from the <b>Sync Since</b>{' '}
                  you choose using this new settings.
                </Message>
              )}

              {anychanges() && !settings.enableSync && settings.lastSyncOn && (
                <Message color="red">
                  This change will remove all your sync messages and stop the
                  syncronization.
                </Message>
              )}

              <Button basic onClick={() => history.goBack()}>
                Nevermind
              </Button>

              {!settings.enableSync && settings.lastSyncOn && (
                <Button
                  color="red"
                  disabled={!anychanges()}
                  onClick={handleSaveAndReSync}
                  loading={saving}
                >
                  <Icon name="sync alternate" /> Stop-Sync
                </Button>
              )}

              {settings.enableSync && (
                <Button
                  color="black"
                  disabled={!anychanges()}
                  loading={saving}
                  onClick={handleSaveAndReSync}
                >
                  <Icon name="sync alternate" />{' '}
                  {settings.lastSyncOn ? `Save & Re-Sync` : `Start Sync!`}
                </Button>
              )}
            </div>
          </Setting>
        </div>
      </React.Fragment>
    </SettingsPage>
  )
}

export default EmailSyncSettingsPage
