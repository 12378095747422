import { AxiosError } from 'axios'
import isMongoId from 'validator/es/lib/isMongoId'
import { z } from 'zod'

import { dateFromIsoString, toLocalTimestamp } from './utils'

export type ApiError = {
  message: string
  requestId?: string
  axiosError?: AxiosError
}

export type PageError = Error & {
  info?: ApiError
  status?: number
}

const timestampSchema = z.number().int()

const utc0TimestampSchema = z.number().int().transform(toLocalTimestamp)

export const apiDateSchema = z
  .string()
  .date()
  .or(z.string().datetime())
  .transform(dateFromIsoString)

export type ApiDate = z.infer<typeof apiDateSchema>

export const userSchema = z.object({
  _id: z.string().refine(isMongoId),
  image: z.string().url().optional(),
  email: z.string().email(),
  name: z.string(),
  disabled: z.boolean().optional(),
})

export type User = z.infer<typeof userSchema>

export const astorEntitySchema = z.object({
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

// Maybe we can define this in a more precise way
export const collectionSchema = z.enum([
  'payment-transactions',
  'billing-invoices',
  'billing-periods',
  'timeoff-requests',
  'payment-orders',
  'paymentOrders',
  'englishLevels',
  'candidates',
  'positions',
  'companies',
  'timezones',
  'seniority',
  'accounts',
  'profiles',
  'contacts',
  'invoices',
  'payrolls',
  'nothing',
  'recipes',
  `emails`,
  'techs',
  `feeds`,
  `cost-centers`,
])

export type Collection = z.infer<typeof collectionSchema>

export const badgeSchema = z.object({
  _id: z.string().refine(isMongoId),
  order: z.number(),
  name: z.string(),
  icon: z.string(),
  description: z.string(),
  color: z.string(),
})

export type Badge = z.infer<typeof badgeSchema>

export const senioritySchema = z.object({
  _id: z.string().refine(isMongoId),
  code: z.string(),
  text: z.string(),
  value: z.number(),
})

export type Seniority = z.infer<typeof senioritySchema>

export const englishLevelSchema = z.object({
  _id: z.string().refine(isMongoId),
  text: z.string(),
  value: z.number(),
})

export type EnglishLevel = z.infer<typeof englishLevelSchema>

export const candidateSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  email: z.string().email().optional(),
  southEmail: z.string().email().or(z.null()).optional(),
  address: z.string().optional(),
  birthdate: timestampSchema.optional(),
  whatsapp: z.string().nullable().optional(),
  status: z.string().or(z.null()).optional(), // TODO refine more
  lastStatus: z.string().nullable().optional(), // TODO add missing values
  lastStatusUpdate: z.number().optional(),
  yearsInTheRole: z.number().optional(),
  yearsInTheIndustry: z.number().optional(),
  lastEvent: z.number().optional(),
  linkedin: z.string().optional(), // should be url() but no validation on DB values
  resume: z.string().url().optional(),
  southResume: z.string().url().optional(),
  editableResume: z.string().optional(),
  location: z.string().optional(),
  emergencyContact: z.string().optional(),
  summary: z.string().optional(),
  codeChallenge: z.string().optional(),
  otherLinks: z.string().optional(),
  visa: z.string().optional(),
  visaDueDate: z.string().optional(),
  englishLevel: z.string().refine(isMongoId).optional(),
  englishSample: z.any().optional(), // TODO: refine
  seniority: senioritySchema.shape._id.optional(),
  profiles: z.array(z.string().refine(isMongoId)).optional(),
  mainTechs: z.array(z.string().refine(isMongoId)).optional(),
  secondaryTechs: z.array(z.string().refine(isMongoId)).optional(),
  badges: z.array(badgeSchema.shape._id).optional(),
  referal: z.string().optional(),
  availability: z.coerce.number().optional(),
  referalCandidateId: z.string().refine(isMongoId).optional(),
  positionId: z.string().refine(isMongoId).or(z.null()).optional(),
  salary: z.number().optional(), // A.K.A. intended fee
  sort: z.number().optional(),
})

export type Candidate = z.infer<typeof candidateSchema>

export const timezoneSchema = z.object({
  _id: z.string().refine(isMongoId),
})

export type Timezone = z.infer<typeof timezoneSchema>

export const contactSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  status: z.string().optional(),
  email: z.string().optional(),
  position: z.string().optional(),
  isCritical: z.boolean().optional(),
  companyId: z.string().refine(isMongoId),
  timezoneId: timezoneSchema.shape._id.optional(),
  lastStatusUpdate: z.number().optional(),
})

export type Contact = z.infer<typeof contactSchema>

export const companySchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  address: z.string().optional(),
  whatDoTheyDoExplained: z.string().optional(),
  website: z.string().optional(),
  linkedin: z.string().optional(),
  rateCard: z.string().optional(),
  timezoneId: timezoneSchema.shape._id.optional(),
  mainContactId: contactSchema.shape._id.optional(),
  accountManagerId: contactSchema.shape._id.optional(),
  adminManagerId: contactSchema.shape._id.optional(),
  idealDGM: z.coerce.number().optional(),
  about: z.string().optional(),
  location: z.string().optional(),
  sector: z.string().optional(),
  msa: z.string().optional(),
  status: z.string().or(z.null()).optional(), // TODO refine more
  ptoAccruedAutomaticallyAfterFirstYear: z.number().or(z.null()).optional(),
  ptoAccruedPerMonthDuringFirstYear: z.number().or(z.null()).optional(),
  ptoCarryOverLimit: z.number().or(z.null()).optional(),
  paymentTerms: z.number().positive().optional(),
  invoiceSendToIds: z.array(contactSchema.shape._id).optional(),
  invoiceSendToCopyIds: z.array(contactSchema.shape._id).optional(),
  costCenterId: z.string().optional(),
})

export type Company = z.infer<typeof companySchema>

export const contractSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  candidateId: candidateSchema.shape._id,
  customerId: companySchema.shape._id,
  active: z.boolean(),
  enableTaxAdvisorBenefit: z.boolean().optional(),
  enableOfficeAccess: z.boolean().optional(),
  startingDate: utc0TimestampSchema,
  endDate: utc0TimestampSchema.optional(),
  orgUnit: z.string().optional(),
  department: z.string().optional(),
  notes: z.string().optional(),
  ptoAccruedAutomaticallyAfterFirstYear: z.number().positive().optional(),
  ptoAccruedPerMonthDuringFirstYear: z.number().positive().optional(),
  ptoCarryOverLimit: z.number().positive().optional(),
  contact2: contactSchema.shape._id.optional(), // check if ok
  contact3: contactSchema.shape._id.optional(), // check if ok
  hiringManagerId: contactSchema.shape._id.optional(), // check if ok
  supervisorId: contactSchema.shape._id.optional(), // check if ok
  offerLetter: z.string().optional(), // check if ok
  agreement: z.string().optional(), // check if ok
  passport: z.string().optional(), // check if ok
  passport2: z.string().optional(), // check if ok
  w8ben: z.string().optional(), // check if ok
  w8ben1: z.string().optional(), // check if ok
  terminatedBy: z.string().or(z.null()).optional(), // seems backend does not enforce user
  terminationDetails: z.string().optional(),
  terminationFiledBy: userSchema.shape._id.optional(), // repeated on Deal
  terminationFiledOn: timestampSchema.optional(), // repeated on Deal
  terminationReason: z.string().optional(), // repeated on Deal
  costCenterId: z.string().optional(),
})

export type Contract = z.infer<typeof contractSchema>

export const customerSowSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  monthlyRate: z.number(),
  sow: z.string().optional(),
})

export type CustomerSow = z.infer<typeof customerSowSchema>

export const dealSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  contractId: contractSchema.shape._id,
  customerSOWId: customerSowSchema.shape._id,
  position: z.string(),
  sow: z.string().optional(), // same as customerSowId? deprecated?
  contractorSOW: z.string().optional(),
  active: z.boolean(),
  monthlySalary: z.number(),
  effectiveDate: utc0TimestampSchema,
  endDate: utc0TimestampSchema.optional(),
  terminationFiledBy: userSchema.shape._id.optional(), // repeated on Contract
  terminationFiledOn: timestampSchema.optional(), // repeated on Contract
})

export type Deal = z.infer<typeof dealSchema>

export const timeOffStatusSchema = z.enum([
  'Waiting for approval',
  'Approved',
  'Canceled',
  'Rejected',
])

export const timeoffRequestSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  contractId: contractSchema.shape._id,
  status: timeOffStatusSchema,
  dates: z.array(apiDateSchema),
  comments: z.string().optional(),
  underReview: z.boolean().optional(),
})

export type TimeoffRequest = z.infer<typeof timeoffRequestSchema>

export const timeoffCoverageSchema = z.object({
  date: apiDateSchema,
  coveredByPtoPolicy: z.boolean(),
  period: z.number(),
  dayValue: z.number().optional(),
})

export type TimeoffCoverage = z.infer<typeof timeoffCoverageSchema>

export const timeOffSummarySchema = z.object({
  year: z.number().positive(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  accrued: z.number().min(0),
  consumed: z.number().min(0),
  carriedOverFromLastYear: z.number().min(0),
  totalInPeriod: z.number().min(0),
  balance: z.number(),
  daysOff: z
    .array(
      z.object({
        ndate: z.number().positive(),
        status: timeOffStatusSchema,
      }),
    )
    .optional(),
})

export type TimeOffSummary = z.infer<typeof timeOffSummarySchema>

export const timeOffBalanceSchema = z.object({
  available: z.number(),
  renewalDate: z.coerce.date(),
  accrued: z.number(),
  consumed: z.number(),
  carriedOverFromLastYear: z.number(),
})

export type TimeOffBalance = z.infer<typeof timeOffBalanceSchema>

export const timeOffTransactionSchema = z.object({
  _id: z.string().refine(isMongoId),
  amount: z.number().positive(),
  contractId: contractSchema.shape._id,
  timeOffRequestId: timeoffRequestSchema.shape._id.optional(),
  detail: z.string().optional(),
  type: z.enum(['credit', 'debit']),
  date: utc0TimestampSchema,
  status: timeOffStatusSchema,
  uncoveredTransactionId: z.string().optional(),
  employeeBought: z.boolean().optional(),
  dayValue: z.number().optional(),
})

export type TimeOffTransaction = z.infer<typeof timeOffTransactionSchema>

export const eventSchema = astorEntitySchema
  .extend({
    _id: z.string().refine(isMongoId),
    relatedCollection: collectionSchema,
    relatedId: z.string(),
    type: z
      .enum([
        'position-status-change',
        'position-pause-resume',
        'position-candidate-changed',
        'status-candidate-changed',
        'user-event',
        'pipedrive-note',
        'note',
        'pto-requested',
        'account-created',
        'paymentSetup-changed',
        'event',
        'small-event',
        'action',
      ])
      .catch('event'),
    title: z.string().optional(),
    description: z.string().nullable().optional(),
    userName: z.string().optional(),
    userImage: z.string().url().optional(),
    isNowPaused: z.boolean().optional(),
    // context: z.object({ dates: z.array(apiDateSchema).optional() }).optional(),
    fromStatus: z.string().or(z.null()).optional(), // TODO refine better once Position type is defined
    toStatus: z.string().optional(), // TODO refine better once Position type is defined
    status: z
      .array(
        z.string(), // TODO refine better once Position type is defined
      )
      .optional(),
    positions: z
      .array(
        z.string(), // TODO refine better once Position type is defined
      )
      .optional(),
    fromPositionId: z.string().or(z.null()).optional(), // TODO refine better once Position type is defined
    toPositionId: z.string().optional(), // TODO refine better once Position type is defined
    info: z.any().optional(),
  })
  .passthrough() // TODO remove eventually.

export type Event = z.infer<typeof eventSchema>

export const taskSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  dueDate: utc0TimestampSchema,
  assignedToId: userSchema.shape._id,
  subject: z.string(),
  details: z.string().optional(),
  type: z.enum(['task']),
  isDone: z.boolean().optional(),
  doneBy: userSchema.shape._id.optional(),
  doneOn: timestampSchema.optional(),
  requireDoneNote: z.boolean().or(z.null()).optional(),
  doneNote: z.string().optional(),
  relatedCollection: collectionSchema.optional(),
  relatedId: z.string().refine(isMongoId).optional(),
})

export type Task = z.infer<typeof taskSchema>

export const techSchema = z.object({
  _id: z.string().refine(isMongoId),
  text: z.string(),
})

export type Tech = z.infer<typeof techSchema>

export const profileSchema = z.object({
  _id: z.string().refine(isMongoId),
  text: z.string(),
})

export type Profile = z.infer<typeof profileSchema>

export const positionSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  title: z.string(),
  description: z.string().optional(),
  number: z.number(),
  openings: z.number().optional(),
  salary: z.number().optional(),
  minYears: z.number().optional(),
  status: z.enum(['open', 'closed']),
  companyId: companySchema.shape._id.optional(),
  isPaused: z.boolean().optional(),
  isHighPriority: z.boolean().optional(),
  sharePublicly: z.boolean().optional(),
  shareInSlack: z.boolean().optional(),
  hiringManagerId: contactSchema.shape._id.optional(),
  secondContact: contactSchema.shape._id.optional(),
  keywords: z.string().optional(),
  lastEvent: timestampSchema.optional(),
  openOn: timestampSchema.optional(),
  openBy: userSchema.shape._id.optional(),
  closedOn: timestampSchema.optional(),
  closedBy: userSchema.shape._id.optional(),
  requesterId: userSchema.shape._id.optional(),
  techsRequired: z.array(techSchema.shape._id).optional(),
  profilesRequired: z.array(profileSchema.shape._id).optional(),
})

export type Position = z.infer<typeof positionSchema>

export const holidaySchema = z.object({
  _id: z.string().refine(isMongoId),
  customerId: companySchema.shape._id,
  date: apiDateSchema,
  ndate: utc0TimestampSchema.optional(),
  name: z.string(),
  year: z.number().positive().min(2010),
})

export type Holiday = z.infer<typeof holidaySchema>

export const funnelColumnSchema = z.object({
  id: z.string(),
  title: z.string(),
  rotting: z.number().optional(),
})

export type FunnelColumn = z.infer<typeof funnelColumnSchema>

export const funnelSchema = z.object({
  _id: z.string().refine(isMongoId),
  order: z.number(),
  name: z.string(),
  title: z.string(),
  sub: z.string(),
  collection: collectionSchema,
  cardType: collectionSchema.or(z.enum(['candidate'])), // it seems there are a few inconsistencies with data here
  columns: z.array(funnelColumnSchema),
})

export type Funnel = z.infer<typeof funnelSchema>

export const payrollTransactionStatusSchema = z.enum([
  'Draft',
  'Dismissed',
  'Approved',
  'Completed',
])

export type PayrollTransactionStatus = z.infer<
  typeof payrollTransactionStatusSchema
>

export const payrollTransactionSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  type: z.enum(['credit', 'discount']),
  beneficiaryId: candidateSchema.shape._id,
  beneficiaryType: z.literal('candidate'),
  dealId: dealSchema.shape._id.optional(),
  customerId: companySchema.shape._id.optional(),
  payrollId: z.string().refine(isMongoId),
  amount: z.number(),
  description: z.string(),
  status: payrollTransactionStatusSchema,
  generatedByPayroll: z.boolean().optional(),
  timeOffRequestId: timeoffRequestSchema.shape._id.optional(),
})

export type PayrollTransaction = z.infer<typeof payrollTransactionSchema>

export const payrollStatusSchema = z.enum(['Draft', 'Completed'])

export type PayrollStatus = z.infer<typeof payrollStatusSchema>

export const payrollSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  periodStart: utc0TimestampSchema,
  periodEnd: utc0TimestampSchema,
  dueDate: utc0TimestampSchema,
  status: payrollStatusSchema,
  total: z.number().optional(),
  completedBy: userSchema.shape._id.optional(),
  completedOn: timestampSchema.optional(),
})

export type Payroll = z.infer<typeof payrollSchema>

export const payrollWithTransactionsSchema = payrollSchema.extend({
  transactions: z.array(payrollTransactionSchema),
})

export type PayrollWithTransactions = z.infer<
  typeof payrollWithTransactionsSchema
>

export const paymentOrderTransactionStatusSchema = z.enum([
  'Draft',
  'Dismissed',
  'Approved',
  'Processing',
  'Completed',
  'Canceled',
])

export type PaymentOrderTransactionStatus = z.infer<
  typeof paymentOrderTransactionStatusSchema
>

export const paymentOrderTransactionSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  type: z.literal('debit'),
  beneficiaryId: z.string().refine(isMongoId),
  beneficiaryType: z.enum(['candidate', 'supplier']),
  bankAccountId: z.string().refine(isMongoId),
  paymentOrderId: z.string().refine(isMongoId),
  amount: z.number(),
  description: z.string(),
  status: paymentOrderTransactionStatusSchema,
  generatedByPaymentOrder: z.boolean().optional(),
  completedOn: timestampSchema.optional(),
  completedBy: userSchema.shape._id.optional(),
  canceledOn: timestampSchema.optional(),
  canceledBy: userSchema.shape._id.optional(),
})

export type PaymentOrderTransaction = z.infer<
  typeof paymentOrderTransactionSchema
>

export const paymentOrderStatusSchema = z.enum([
  'Draft',
  'Processing',
  'Completed',
])

export type PaymentOrderStatus = z.infer<typeof paymentOrderStatusSchema>

export const paymentOrderSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string().optional(),
  description: z.string().optional(),
  total: z.number().optional(),
  status: paymentOrderStatusSchema,
  completedBy: userSchema.shape._id.optional(),
  completedOn: timestampSchema.optional(),
})

export type PaymentOrder = z.infer<typeof paymentOrderSchema>

export const paymentOrderWithTransactionsSchema = paymentOrderSchema.extend({
  transactions: z.array(paymentOrderTransactionSchema),
})

export type PaymentOrderWithTransactions = z.infer<
  typeof paymentOrderWithTransactionsSchema
>

export const accountStatusSchema = z.enum([
  'Waiting for approval',
  'Approved',
  'Rejected',
])

export const commonAccountSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  beneficiaryId: z.string().refine(isMongoId),
  beneficiaryType: z.enum(['candidate', 'supplier']),
  alias: z.string(),
  status: accountStatusSchema,
  reason: z.string().optional(),
})

export const accountSchema = z.discriminatedUnion('type', [
  commonAccountSchema.extend({
    type: z.literal('payoneer'),
  }),
  commonAccountSchema.extend({
    type: z.literal('international'),
    accountCurrency: z.string().optional(),
    accountCountry: z.string().optional(),
    bankSwift: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z.string().optional(),
    beneficiary: z.string(),
    // address: z.string().optional(),
    beneficiaryStreetName: z.string(),
    beneficiaryHouseNumber: z.string(),
    beneficiaryApartment: z.string().optional(),
    beneficiaryCity: z.string(),
    beneficiaryStateOrProvince: z.string(),
    beneficiaryZipCode: z.string(),
    beneficiaryCountry: z.string(),
    accountNumber: z.union([z.string(), z.number()]),
  }),
  commonAccountSchema.extend({
    type: z.literal('usa'),
    routingNumber: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z.string().optional(),
    beneficiary: z.string(),
    // address: z.string().optional(),
    beneficiaryStreetName: z.string(),
    beneficiaryHouseNumber: z.string(),
    beneficiaryApartment: z.string().optional(),
    beneficiaryCity: z.string(),
    beneficiaryStateOrProvince: z.string(),
    beneficiaryZipCode: z.string(),
    beneficiaryCountry: z.string(),
    accountNumber: z.union([z.string(), z.number()]),
  }),
])

export type Account = z.infer<typeof accountSchema>

export const paymentSetupSchema = z.union([
  astorEntitySchema.extend({
    _id: z.string().refine(isMongoId),
    candidateId: z.string().refine(isMongoId),
    effectiveDate: timestampSchema.optional(),
    fixed: z.object({
      account: commonAccountSchema.shape._id,
      amount: z.literal('all'),
    }),
  }),
  astorEntitySchema.extend({
    _id: z.string().refine(isMongoId),
    candidateId: z.string().refine(isMongoId),
    effectiveDate: timestampSchema.optional(),
    fixed: z.object({
      account: commonAccountSchema.shape._id,
      amount: z.number(),
    }),
    rest: commonAccountSchema.shape._id,
  }),
])

export type PaymentSetup = z.infer<typeof paymentSetupSchema>

export const recipeStepSchema = z.object({
  name: z.string(),
  type: z.string(),
  config: z.any(),
  description: z.string().optional(),
})

// export interface StepDefinition {
//   func: StepFunction;
//   type: string;
//   name: string;
//   description: string;
//   config: RecipeArgsConfig[];
// }
export type RecipeStep = z.infer<typeof recipeStepSchema>

export const recipeArgsSchema = z.object({
  name: z.string(),
  displayName: z.string(),
  description: z.string().optional(),
  type: z.enum([
    'text',
    'long-text',
    'number',
    'enum',
    'boolean',
    'html',
    'object',
  ]),
  options: z.array(z.string()).optional(),
  template: z.string().optional(),
})

export type RecipeArgs = z.infer<typeof recipeArgsSchema>

export const recipeStepDefinitionSchema = z.object({
  category: z.enum(['tool', 'step']).optional(),
  name: z.string(),
  type: z.string(),
  description: z.string().optional(),
  config: z.array(recipeArgsSchema),
})

export type RecipeStepDefinition = z.infer<typeof recipeStepDefinitionSchema>

export const recipeStatusSchema = z.enum(['Draft', 'Published'])

export type RecipeStatus = z.infer<typeof recipeStatusSchema>

export const recipeSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  description: z.string().optional(),
  steps: z.array(recipeStepSchema).optional(),
  args: z.array(recipeArgsSchema).optional(),
  triggerEnable: z.boolean().optional(),
  triggerEvent: z.string().optional(),
  webhookId: z.string().optional(),
  collection: collectionSchema.optional(),
  type: z.enum(['manual', 'event', 'webhook']),
})

export type Recipe = z.infer<typeof recipeSchema>

export const recipeScheduleSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  recipeId: recipeSchema.shape._id,
  runOnCollection: collectionSchema,
  runOnId: z.string().refine(isMongoId),
  ranAs: z.string().refine(isMongoId),
  runType: z.enum(['instantly', 'delayed', 'triggered']),
  status: z.enum(['pending', 'completed', 'error']),
  whenToRun: timestampSchema.or(z.null()).optional(),
  addedToTheQueueOn: timestampSchema.optional(),
  startedOn: timestampSchema.optional(),
  completedOn: timestampSchema.optional(),
  jobId: z.number().optional(),
  eventTrigger: z.string().optional(),
  args: z.record(z.string(), z.any()),
  error: z.record(z.string(), z.any()).or(z.null()).optional(),
})

export type RecipeSchedule = z.infer<typeof recipeScheduleSchema>

// export const recipeConfigEntry = z.object({
//   type: z.enum(['string', 'number']),
//   value: z.string(),
// })

export const recipeResultStepSchema = z.object({
  name: z.string(),
  replacedConfig: z.record(z.string(), z.any()),
  config: z.record(z.string(), z.any()),
  result: z.any(),
  completed: z.boolean(),
  completedOn: z.number(),
})

export type RecipeResultStep = z.infer<typeof recipeResultStepSchema>

export const recipeExecutionSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  recipeId: recipeSchema.shape._id,
  recipe: recipeSchema,
  ranOn: timestampSchema.optional(),
  ranAs: z.string().refine(isMongoId),
  completed: z.boolean(),
  completedOn: timestampSchema.optional(),
  args: z.record(z.string(), z.any()),
  steps: z.array(recipeResultStepSchema),
  error: z.string().optional(),
})

export type RecipeExecution = z.infer<typeof recipeExecutionSchema>

export const quickSearchResultSchema = z.object({
  contacts: z.array(contactSchema),
  companies: z.array(companySchema),
  candidates: z.array(candidateSchema),
  events: z.array(eventSchema),
  tasks: z.array(taskSchema),
})

export type QuickSearchResult = z.infer<typeof quickSearchResultSchema>

export const emailMessageSchema = z.object({
  _id: z.string().refine(isMongoId).optional(),
  sentBy: z.string().refine(isMongoId).optional(),
  userId: z.string().refine(isMongoId),
  downloadedOn: timestampSchema,
  threadId: z.string().refine(isMongoId),
  messageId: z.string().refine(isMongoId),
  thumbprint: z.string(),
  snippet: z.string(),
  fullSnippet: z.string(),
  date: timestampSchema,
  subject: z.string().optional(),
  bodyPlain: z.string(),
  bodyHtml: z.string(),
  labels: z.array(z.string()),
  shared: z.boolean(),
  read: z.boolean(),
  from: z.string(),
  to: z.string(),
  cc: z.string().optional(),
  bcc: z.string().optional(),
})

export type EmailMessage = z.infer<typeof emailMessageSchema>

export type FeedItem = (
  | (RecipeSchedule & { type: 'recipe-schedule' })
  | (EmailMessage & { type: 'email' })
  | Event
  | Task
) & { sortBy: number }

export const gmailLabelSchema = z.object({
  id: z.string(),
  name: z.string(),
  messageListVisibility: z.enum(['show', 'hide']).optional(),
  labelListVisibility: z.enum(['labelShow', 'labelHide']).optional(),
  type: z.enum(['user', 'system']),
})

export type GmailLabel = z.infer<typeof gmailLabelSchema>

export const emailSyncSettingsSchema = z.object({
  _id: z.string().refine(isMongoId),
  userId: z.string().refine(isMongoId),
  enableSync: z.boolean(),
  syncSince: timestampSchema,
  shareEmailsByDefault: z.boolean(),
  labelsToSkip: z.array(z.string()),
  labelsToSync: z.array(z.string()),
  addressesToSkip: z.array(z.string()),
  lastSyncOn: timestampSchema.optional(),
})

export type EmailSyncSettings = z.infer<typeof emailSyncSettingsSchema>

export const threadSchema = z.object({
  _id: z.string().refine(isMongoId),
  messages: z.array(emailMessageSchema),
  selected: z.boolean(),
  highestDate: timestampSchema,
})

export type Thread = z.infer<typeof threadSchema>

export const supplierSchema = z.object({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  address: z.string().optional(),
  website: z.string().optional(),
  mainContactId: z.string().refine(isMongoId).optional(),
  location: z.string().optional(),
  sector: z.string().optional(),
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type Supplier = z.infer<typeof supplierSchema>

const invoiceScheduledEmailStatusSchema = z.enum([
  'Completed',
  'Unscheduled',
  'Scheduled',
  'Running',
  'Failed',
  'Sent',
])

export type InvoiceScheduledEmailStatus = z.infer<
  typeof invoiceScheduledEmailStatusSchema
>

export const invoiceScheduledEmailConfigSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  sendAt: timestampSchema,
  status: invoiceScheduledEmailStatusSchema,
  message: z.string().optional(),
  type: z.enum(['invoice_link', 'duedate_reminder', 'invoice_overdue']),
  payload: z.object({ subject: z.string() }).optional(),
  from: z.object({ name: z.string(), email: z.string() }).optional(),
  to: z.array(z.object({ name: z.string(), email: z.string() })).optional(),
  cc: z.array(z.object({ name: z.string(), email: z.string() })).optional(),
})

export type InvoiceScheduledEmailConfig = z.infer<
  typeof invoiceScheduledEmailConfigSchema
>

const billingPeriodStatusSchema = z.enum(['Draft', 'Completed'])

export type BillingPeriodStatus = z.infer<typeof billingPeriodStatusSchema>

export const billingPeriodSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  description: z.string(),
  status: billingPeriodStatusSchema,
  totalBilled: z.number(),
  totalPaid: z.number(),
  periodStart: utc0TimestampSchema,
  periodEnd: utc0TimestampSchema,
  completedBy: userSchema.shape._id.optional(),
  completedOn: timestampSchema.optional(),
})

export type BillingPeriod = z.infer<typeof billingPeriodSchema>

export const billingTransactionStatusSchema = z.enum([
  'Draft',
  'Approved',
  'Dismissed',
  'Sent',
  'Paid',
  'Canceled',
  'Completed',
  'Uncollectible',
])

export type BillingTransactionStatus = z.infer<
  typeof billingTransactionStatusSchema
>

export const billingTransactionSchema = z.object({
  _id: z.string().refine(isMongoId),
  billingInvoiceId: z.string().refine(isMongoId),
  contractId: contractSchema.shape._id.optional(), // not set on custom tx
  customerId: companySchema.shape._id.optional(), // TODO fix? make POST to create tx require this field
  dealId: dealSchema.shape._id.optional(), // not set on 'discount' type
  customerSOWId: customerSowSchema.shape._id.optional(), // not set on 'discount' type
  candidateId: candidateSchema.shape._id.optional(), // optional in custom txs
  amount: z.number(),
  description: z.string(),
  generatedByInvoice: z.boolean().optional(),
  type: z.enum(['charge', 'discount']),
  status: billingTransactionStatusSchema,
})

export type BillingTransaction = z.infer<typeof billingTransactionSchema>

const invoiceStatusSchema = z.enum([
  'Ready to be Sent',
  'Failed to Send',
  'Uncollectible',
  'Scheduled',
  'Canceled',
  'Draft',
  'Paid',
  'Sent',
])

export type InvoiceStatus = z.infer<typeof invoiceStatusSchema>

export const invoiceSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  billingPeriodId: billingPeriodSchema.shape._id,
  name: z.string().optional(),
  customerId: companySchema.shape._id.optional(),
  issueDate: utc0TimestampSchema.optional(),
  dueDate: utc0TimestampSchema.optional(),
  note: z.string().optional(),
  totalBilled: z.number().optional(),
  totalPaid: z.number().optional(),
  generatedByPeriod: z.boolean().optional(),
  manual: z.boolean().optional(),
  status: invoiceStatusSchema,
  invoicePdf: z.string().url().optional(),
  sendToIds: z.array(contactSchema.shape._id).optional(),
  sendToCopyIds: z.array(contactSchema.shape._id).optional(),
  sendMessage: z.string().optional(),
  sentOn: timestampSchema.optional(),
  sentBy: userSchema.shape._id.optional(),
  paidOn: timestampSchema.optional(),
  canceledOn: timestampSchema.optional(),
  sendAt: timestampSchema.optional(),
  scheduledEmailId: z.string().refine(isMongoId).optional(),
  scheduledOverdueEmailId: z.string().refine(isMongoId).optional(),
  scheduledOverdueReminderEmailId: z.string().refine(isMongoId).optional(),
})

export type Invoice = z.infer<typeof invoiceSchema>

export const paymentSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  date: utc0TimestampSchema,
  amount: z.number().positive(),
  customerId: companySchema.shape._id,
  transferDescription: z.string().optional(),
  transferFrom: z.string().optional(),
  attachment: z.string().optional(),
  status: z.enum(['Draft', 'Partially Allocated', 'Fully Allocated']),
})

export type Payment = z.infer<typeof paymentSchema>

export const paymentAllocationSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  paymentId: paymentSchema.shape._id,
  invoiceId: invoiceSchema.shape._id,
  amount: z.number().positive(),
})

export type PaymentAllocation = z.infer<typeof paymentAllocationSchema>

export const emailPresetSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  body: z.string(),
  status: z.enum(['Draft', 'Published']),
})

export type EmailPreset = z.infer<typeof emailPresetSchema>

export const relatedNoteSchema = astorEntitySchema.extend({
  _id: z.string().refine(isMongoId),
  relatedCollection: collectionSchema,
  relatedId: z.string(),
  body: z.string(),
})

export type RelatedNote = z.infer<typeof relatedNoteSchema>
