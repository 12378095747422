import React, { useEffect, useState } from 'react'
import { Button, Input, Modal } from 'semantic-ui-react'

import { useTalentApi } from '../../store/mainContext'
import CompanySelect from '../form/companySelect'
import { Contact } from '../../types'
import DataSelect from '../form/dataSelect'
import FormField from '../form/formField'

interface Props {
  onCancel(): void
  onCreated?(data: Contact): void
  defaults?: Partial<Contact>
  open?: boolean
}

const AddContactModal = ({ defaults, onCreated, onCancel, open }: Props) => {
  const [errors, setErrors] = useState<string[]>([])
  const [value, setValue] = useState<Partial<Contact>>()
  const [isLoading, setIsLoading] = useState(false)

  const api = useTalentApi()

  useEffect(() => {
    setErrors([])
  }, [open])

  useEffect(() => {
    if (!defaults) return
    setValue(defaults)
  }, [defaults])

  if (!open) {
    return null
  }

  const handleOnChange = async (_: any, target: any) => {
    const newValue: Record<string, any> = { ...value }
    newValue[target.name] = target.value

    if (target.value !== '') {
      setErrors(errors.filter((x) => x !== target.name))
    }

    setValue(newValue)
  }

  const handleAdd = async () => {
    setIsLoading(true)

    try {
      const { data } = await api.post<Contact>('contacts', value)

      setValue(data)
      if (onCreated) return onCreated(data)
    } catch (err: any) {
      if (err.response?.data?.fields) {
        setErrors(err.response.data.fields)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal open size="mini">
      <Modal.Header>New Contact</Modal.Header>
      <Modal.Content>
        <FormField label="Name *" error={errors.indexOf('name') !== -1}>
          <Input
            fluid
            error={errors.indexOf('name') !== -1}
            name="name"
            autoFocus
            onChange={handleOnChange}
            placeholder="Contact name..."
            value={(value || {}).name || ''}
            input={<input data-1p-ignore />}
            disabled={isLoading}
          />
        </FormField>

        <FormField label="Email *" error={errors.indexOf('email') !== -1}>
          <Input
            fluid
            type="email"
            error={errors.indexOf('email') !== -1}
            name="email"
            onChange={handleOnChange}
            placeholder="Contact Email..."
            input={<input data-1p-ignore />}
            disabled={isLoading}
          />
        </FormField>

        <FormField label="LinkedIn" error={errors.indexOf('linkedin') !== -1}>
          <Input
            fluid
            error={errors.indexOf('linkedin') !== -1}
            name="linkedin"
            onChange={handleOnChange}
            placeholder="Contact Linkedin..."
            disabled={isLoading}
          />
        </FormField>

        <FormField label="Company *" error={errors.indexOf('companyId') !== -1}>
          <CompanySelect
            fluid
            error={errors.indexOf('companyId') !== -1}
            name="companyId"
            onChange={handleOnChange}
            placeholder="Company..."
            disabled={isLoading}
          />
        </FormField>

        <FormField label="Timezone" error={errors.indexOf('timezoneId') !== -1}>
          <DataSelect
            disabled={isLoading}
            url="timezones"
            fluid={true}
            error={errors.indexOf('timezoneId') !== -1}
            name="timezoneId"
            onChange={handleOnChange}
            placeholder="Company Timezone..."
          />
        </FormField>
      </Modal.Content>

      <Modal.Actions>
        <Button
          basic
          onClick={onCancel}
          content="Cancel"
          disabled={isLoading}
        />
        <Button
          loading={isLoading}
          disabled={isLoading}
          content="Save"
          onClick={handleAdd}
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AddContactModal
