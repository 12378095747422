import { Helmet } from 'react-helmet'
import * as React from 'react'

import AccountsForReview from '../../components/billing-dashboard/AccountsForReview'
import TimeoffForReview from '../../components/billing-dashboard/TimeoffForReview'
import LastDaysEvents from '../../components/billing-dashboard/LastDaysEvents'
import PageHeader from '../../components/pageHeader/pageHeader'

export default function DashboardPage() {
  return (
    <>
      <Helmet>
        <title>Billing Dashboard</title>
      </Helmet>

      <PageHeader title="Billing Dashboard" />

      <div className="mx-14 grid grid-cols-3 gap-14 pt-4">
        <div className="self-start rounded-md border px-4 py-4 shadow">
          <LastDaysEvents title="Last Week Events" days={7} />
        </div>

        <div className="self-start rounded-md border px-4 py-4 shadow">
          <TimeoffForReview />
        </div>

        <div className="self-start rounded-md border px-4 py-4 shadow">
          <AccountsForReview />
        </div>
      </div>
    </>
  )
}
