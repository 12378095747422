import { format, isPast } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { Progress } from 'semantic-ui-react'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { classNames, currencyFormat, validateResponse } from '../../utils'
import InvoicePaymentsPopup from '../payments/InvoicePaymentsPopup'
import { EnhancedInvoice } from '../../hooks/useBillingPayments'
import { useApi, useList } from '../../store/mainContext'
import InvoiceStatusLabel from './InvoiceStatusLabel'
import InvoicesFilter from './InvoicesFilters'
import useSortable from '../../hooks/useSortable'
import ThSortable from '../tables/ThSortable'

interface Props {
  invoices: EnhancedInvoice[]
}

export default function InvoicesTable(props: Props) {
  const { invoices } = props

  const invoicesList = useList('invoices', {
    filters: { status: 'Sent', customerId: [] },
  })

  const invoicesSort = useSortable('invoices', {
    sortByDir: 'ascending',
    sortBy: 'customer',
  })

  const history = useHistory()

  const selectedCustomers = invoicesList.state.filters.customerId
  const selectedStatus = invoicesList.state.filters.status

  const filteredInvoices = invoices
    .filter((invoice) => {
      if (selectedStatus === 'All') return true
      if (
        selectedStatus === 'Overdue' &&
        invoice.status === 'Sent' &&
        invoice.dueDate &&
        isPast(invoice.dueDate)
      ) {
        return true
      }
      return invoice.status === invoicesList.state.filters.status
    })
    .filter((invoice) => {
      if (!Array.isArray(selectedCustomers)) return true
      if (selectedCustomers.length === 0) return true
      return (selectedCustomers || []).includes(invoice.customerId)
    })
    .sort((first, second) => {
      const { sortByDir, sortBy } = invoicesSort
      const a = sortByDir === 'ascending' ? first : second
      const b = sortByDir === 'ascending' ? second : first
      if (sortBy === 'customer') {
        return (a.customer?.name || '').localeCompare(b.customer?.name || '')
      }
      if (sortBy === 'invoice') {
        return (a.name || '').localeCompare(b.name || '')
      }
      if (sortBy === 'status') {
        const isOverdueA = Boolean(
          a.status === 'Sent' && a.dueDate && isPast(a.dueDate),
        )
        const isOverdueB = Boolean(
          b.status === 'Sent' && b.dueDate && isPast(b.dueDate),
        )
        const isPartiallyPaidA = a.status === 'Sent' && (a.totalPaid || 0) > 0
        const isPartiallyPaidB = b.status === 'Sent' && (b.totalPaid || 0) > 0
        const statusA = isOverdueA
          ? 'overdue'
          : isPartiallyPaidA
            ? 'Sent (partial)'
            : a.status
        const statusB = isOverdueB
          ? 'overdue'
          : isPartiallyPaidB
            ? 'Sent (partial)'
            : b.status
        return statusA.localeCompare(statusB)
      }
      if (sortBy === 'progress') {
        const progressA = a.totalBilled ? (a.totalPaid || 0) / a.totalBilled : 0
        const progressB = b.totalBilled ? (b.totalPaid || 0) / b.totalBilled : 0
        return progressA - progressB
      }
      if (sortBy === 'issueDate') return (a.issueDate || 0) - (b.issueDate || 0)
      if (sortBy === 'dueDate') return (a.dueDate || 0) - (b.dueDate || 0)
      return 0
    })

  const api = useApi()

  const pendingBilledTotal = useSWR(
    ['pending-billed', selectedCustomers, selectedStatus],
    () =>
      api
        .get('billing-invoices/total-billed', {
          params: { status: selectedStatus, customerId: selectedCustomers },
        })
        .then(
          validateResponse(
            z.object({ totalBilled: z.number(), totalOverdue: z.number() }),
          ),
        ),
    { keepPreviousData: true },
  )

  // const pendingBilledTotal = filteredInvoices.reduce((sum, invoice) => {
  //   if (invoice.status !== 'Sent') return sum
  //   return sum + ((invoice.totalBilled || 0) - (invoice.totalPaid || 0))
  // }, 0)

  // const overdueTotal = filteredInvoices.reduce((sum, invoice) => {
  //   const isOverdue = Boolean(
  //     invoice.status === 'Sent' && invoice.dueDate && isPast(invoice.dueDate),
  //   )
  //   return isOverdue
  //     ? sum + ((invoice.totalBilled || 0) - (invoice.totalPaid || 0))
  //     : sum
  // }, 0)

  return (
    <section className="mx-14">
      <div className="grid grid-cols-[repeat(auto-fill,_230px)] gap-6 py-4">
        <div className="rounded-md bg-slate-100 px-4 pb-7 pt-8">
          <div
            className={classNames(
              'text-center text-3xl font-semibold leading-none',
              pendingBilledTotal.isLoading || pendingBilledTotal.isValidating
                ? 'text-slate-400'
                : 'text-slate-700',
            )}
          >
            {currencyFormat(pendingBilledTotal.data?.totalBilled || 0)}
          </div>
          <div className="mt-3 text-center text-sm font-semibold uppercase leading-none text-slate-500">
            Pending billed
          </div>
        </div>
        <div className="rounded-md bg-slate-100 px-4 pb-7 pt-8">
          <div
            className={classNames(
              'text-center text-3xl font-semibold leading-none',
              pendingBilledTotal.isLoading || pendingBilledTotal.isValidating
                ? 'text-slate-400'
                : 'text-slate-700',
            )}
          >
            {currencyFormat(pendingBilledTotal.data?.totalOverdue || 0)}
          </div>
          <div className="mt-3 text-center text-sm font-semibold uppercase leading-none text-slate-500">
            Overdue
          </div>
        </div>
      </div>

      {invoices.length > 0 ? (
        <>
          <div className="px-2 py-6">
            <InvoicesFilter
              invoices={invoices}
              status={invoicesList.state.filters.status}
              customerId={invoicesList.state.filters.customerId}
              onChange={(filters) =>
                invoicesList.update({
                  ...invoicesList.state,
                  filters: { ...invoicesList.state.filters, ...filters },
                })
              }
            />
          </div>

          <table className="w-full">
            <thead>
              <tr className="border-b text-left text-sm uppercase text-primary">
                <ThSortable
                  className="px-2 py-2"
                  {...invoicesSort.register('invoice')}
                >
                  Invoice #
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...invoicesSort.register('customer')}
                >
                  Customer
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...invoicesSort.register('issueDate')}
                >
                  Issued on
                </ThSortable>
                <ThSortable
                  className="px-2 py-2"
                  {...invoicesSort.register('dueDate')}
                >
                  Due on
                </ThSortable>
                <ThSortable
                  className="w-[1%] px-6 py-2"
                  {...invoicesSort.register('status')}
                >
                  Status
                </ThSortable>
                <ThSortable
                  className="w-[200px] px-2 py-2 text-center"
                  {...invoicesSort.register('progress')}
                >
                  $ Paid / Billed
                </ThSortable>
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.length === 0 && (
                <tr>
                  <td
                    className="px-2 py-8 text-center text-base text-slate-500"
                    colSpan={7}
                  >
                    No invoices for the current filters
                  </td>
                </tr>
              )}
              {filteredInvoices.map((invoice) => {
                return (
                  <tr
                    className="cursor-pointer border-b align-baseline hover:bg-slate-50"
                    onClick={() => history.push(`/invoices/${invoice._id}`)}
                    key={invoice._id}
                  >
                    <td className="px-2 py-3">
                      {invoice.name ? (
                        invoice.name
                      ) : (
                        <span className="italic text-slate-400">No name</span>
                      )}
                    </td>
                    <td className="px-2 py-3">
                      {invoice.customer?.name ? (
                        invoice.customer.name
                      ) : (
                        <span className="text-slate-400">&ndash;</span>
                      )}
                    </td>
                    <td className="px-2 py-3">
                      {invoice.issueDate ? (
                        format(invoice.issueDate, 'yyyy-MM-dd')
                      ) : (
                        <span className="text-slate-400">&ndash;</span>
                      )}
                    </td>
                    <td className="px-2 py-3">
                      {invoice.dueDate ? (
                        format(invoice.dueDate, 'yyyy-MM-dd')
                      ) : (
                        <span className="text-slate-400">&ndash;</span>
                      )}
                    </td>
                    <td className="text-nowrap px-6 py-3">
                      <InvoiceStatusLabel
                        status={invoice.status}
                        dueDate={invoice.dueDate}
                      />{' '}
                      {invoice.status === 'Sent' &&
                        (invoice.totalPaid || 0) > 0 && (
                          <span className="text-nowrap text-sm text-slate-400">
                            (Partially Paid)
                          </span>
                        )}
                    </td>
                    <td className="px-2 py-0 text-center align-middle">
                      <InvoicePaymentsPopup
                        invoice={invoice}
                        trigger={
                          <div className="cursor-help py-2">
                            <div className="flex justify-center gap-1 text-sm">
                              <span
                                className={classNames(
                                  invoice.totalPaid === 0
                                    ? 'text-slate-400'
                                    : 'text-slate-600',
                                )}
                              >
                                {currencyFormat(invoice.totalPaid || 0)}
                              </span>
                              <span className="text-slate-400">/</span>
                              <span className="font-semibold text-slate-700">
                                {currencyFormat(invoice.totalBilled || 0)}
                              </span>
                            </div>
                            <Progress
                              className="!mb-0 [&>.bar]:!min-w-0"
                              total={invoice.totalBilled || 0}
                              value={invoice.totalPaid || 0}
                              size="tiny"
                              color={
                                (invoice.totalBilled || 0) -
                                  (invoice.totalPaid || 0) <
                                0.01
                                  ? 'green'
                                  : 'yellow'
                              }
                            />
                          </div>
                        }
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div className="mt-4 text-base text-slate-600">
          No pending invoices!
        </div>
      )}
    </section>
  )
}
