import { Button, Confirm, Modal } from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import useSWR from 'swr'
import { z } from 'zod'

import { classNames, validateResponse } from '../../utils'
import { Candidate, candidateSchema } from '../../types'
import { useToasts } from '../toasts/ToastsProvider'
import { ENV_VARS } from '../../env'
import { useApi } from '../../store/mainContext'

interface Props {
  candidate: Candidate
}

export default function CandidateDuplicate({ candidate }: Props) {
  const { linkedin, email, name } = candidate

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [candidateToMerge, setCandidateToMerge] = useState<Candidate | null>(
    null,
  )

  const [isMerging, setIsMerging] = useState(false)

  const dupes = useSWR(['duplicate-candidates', linkedin, email, name], () =>
    api
      .get(`candidates/any`, { params: { linkedin, email, name } })
      .then(validateResponse(z.array(candidateSchema)))
      .then((candidates) => candidates.filter((c) => c._id !== candidate._id)),
  )

  const handleMerge = () => {
    if (!candidateToMerge) return
    setIsMerging(true)
    return api
      .post(`candidates/merge`, {
        ids: [candidateToMerge._id, candidate._id],
      })
      .then(({ data }) => history.push(`/candidates/${data._id}`))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => {
        setCandidateToMerge(null)
        setIsMerging(false)
      })
  }

  if ((dupes.data || []).length === 0) return null

  return (
    <div className="mx-9 mb-9 rounded-md border border-dotted border-[#e9c776] bg-[#fff8db] p-4 text-[#b58105]">
      <div className="mb-4 text-[15.4px] font-bold">
        This candidate could be duplicated:
      </div>

      {(dupes.data || []).map((c, i) => (
        <div
          key={c._id}
          className={classNames(
            'flex items-center justify-between gap-2 border-b border-dotted border-[#e9c776] py-2',
            i === 0 && 'border-t',
          )}
        >
          <Link
            to={`/candidates/${c._id}`}
            target={
              ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false' ? '_blank' : undefined
            }
          >
            {c.name} ({format(c.createdOn, 'P')})
          </Link>

          <Button
            size="mini"
            color="red"
            onClick={() => setCandidateToMerge(c)}
            type="button"
            className="!mr-0"
          >
            Merge
          </Button>
        </div>
      ))}

      <Confirm
        header="Merge Candidate"
        key={candidateToMerge?._id}
        open={!!candidateToMerge}
        content={
          <Modal.Content>
            <div className="-mt-6 text-base">
              You are about to merge these two candidates:
            </div>

            <ul className="mt-6 space-y-2">
              <li className="rounded bg-slate-100 px-3 py-2">
                <div className="flex justify-between text-base">
                  <span className="font-semibold">{candidate.name}</span>
                  {candidate.email ? (
                    <span>{candidate.email}</span>
                  ) : (
                    <span className="italic text-slate-600">
                      E-mail not provided
                    </span>
                  )}
                </div>
                <div className="text-sm text-slate-500">
                  Created on <strong>{format(candidate.createdOn, 'P')}</strong>
                </div>
              </li>

              {candidateToMerge && (
                <li className="rounded bg-slate-100 px-3 py-2">
                  <div className="flex justify-between text-base">
                    <span className="font-semibold">
                      {candidateToMerge.name}
                    </span>
                    <span>{candidateToMerge.email}</span>
                  </div>
                  <div className="text-sm text-slate-500">
                    Created on{' '}
                    <strong>{format(candidateToMerge.createdOn, 'P')}</strong>
                  </div>
                </li>
              )}
            </ul>

            <div className="mt-6 text-base">This action will:</div>

            <ul className="mt-4 list-decimal space-y-2 pl-5">
              <li>
                Move all tasks and events from the <b>newest</b> candidate to
                the <strong>oldest</strong> candidate
              </li>
              <li>
                <strong className="text-red-600">Remove</strong> the{' '}
                <strong>newest</strong> candidate.
              </li>
            </ul>

            <div className="mt-6 text-base">
              <strong>This action cannot be undone.</strong> Do you want to
              continue?
            </div>
          </Modal.Content>
        }
        onCancel={() => setCandidateToMerge(null)}
        closeOnDimmerClick={!isMerging}
        closeOnEscape={!isMerging}
        size="tiny"
        onConfirm={handleMerge}
        confirmButton={
          <Button loading={isMerging} disabled={isMerging} negative>
            Yeap... let&apos;s merge them
          </Button>
        }
        cancelButton={
          <Button basic disabled={isMerging}>
            Ugh... no, no yet
          </Button>
        }
      />
    </div>
  )
}
