import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'
import { ENV_VARS } from '../../env'
import RemoteValue from '../../components/remoteValues/remoteValue'
import PageLoader from '../../components/pageLoader'
import DataTable from '../../components/dataTable/dataTable'
import NotFound from '../../components/notFound'
import Period from '../../components/period'

const SectionTitle = styled.h2`
  margin-top: 0 !important;
  margin-bottom: 5px !important;
`

const Sub = styled.p`
  color: #666;
`

const Section = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
`

const PositionCandidates = ({ positionId }) => {
  const [candidates, setCandidates] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()
  const api = useTalentApi()

  const getCandidatesInProcess = React.useCallback(async () => {
    const { data: cand } = await api.get(`/candidates`, {
      params: { positionId },
    })

    if (cand.length === 0) {
      return []
    }

    const [{ data: tasks }, { data: allFunnels }] = await Promise.all([
      api.get(`tasks/pro`, {
        params: {
          relatedId: { $in: cand.map((x) => x._id) },
          isDone: { $in: [null, false] },
        },
      }),
      api.get(`/funnels`, {
        params: { collection: 'candidates' },
      }),
    ])

    const grouped = cand.reduce((acc, obj) => {
      obj.nextTask = tasks
        .filter((t) => t.relatedId === obj._id)
        .sort((a, b) => a.dueDate - b.dueDate)[0]

      if (acc[obj.status]) {
        acc[obj.status].push(obj)
        return acc
      }

      acc[obj.status] = [obj]
      return acc
    }, {})

    const result = Object.keys(grouped)
      .map((status) => {
        const funnel = allFunnels.find((x) => x.name === status.split('.')[0])

        if (!funnel) {
          return {
            funnelOrder: -1,
            statusOrder: -1,
            funnelTitle: 'Unstaged Candidates',
            funnelSub: 'Candidates not assigned to any stage.',

            candidates: grouped[status],
          }
        }

        const index = funnel.columns.findIndex((x) => x.id === status)
        const stage = funnel.columns.find((x) => x.id === status)

        return {
          funnelOrder: funnel.order,
          statusOrder: index,
          funnelTitle: funnel.title,
          funnelSub: funnel.sub,
          statusTitle: stage.title,

          candidates: grouped[status],
        }
      })
      .sort((a, b) => {
        if (a.funnelOrder === b.funnelOrder) {
          return a.statusOrder - b.statusOrder
        }
        return a.funnelOrder - b.funnelOrder
      })
    return result
  }, [api, positionId])

  useEffect(() => {
    getCandidatesInProcess()
      .then((result) => setCandidates(result))
      .finally(() => setIsLoading(false))
  }, [getCandidatesInProcess])

  if (isLoading) {
    return <PageLoader />
  }

  if (candidates.length === 0) {
    return (
      <NotFound
        style={{ marginTop: 200 }}
        message="No candidates in this pipeline yet."
        sub="What are you waiting for?"
      />
    )
  }

  return (
    <React.Fragment>
      {candidates.map((x) => (
        <Section key={x.funnelTitle}>
          <SectionTitle>
            {x.funnelTitle} {x.statusTitle && ` - ${x.statusTitle}`}
          </SectionTitle>
          <Sub>{x.funnelSub}</Sub>

          <DataTable>
            <thead>
              <tr>
                <DataTable.Th width={200}>Name</DataTable.Th>
                <DataTable.Th align="center" width={150}>
                  Level
                </DataTable.Th>
                <DataTable.Th align="center" width={100}>
                  Years of Experience
                </DataTable.Th>
                <DataTable.Th align="center" width={100}>
                  Intended Fee
                </DataTable.Th>
                <DataTable.Th>Location</DataTable.Th>
                <DataTable.Th align="center" width={200}>
                  Next Task
                </DataTable.Th>{' '}
                <DataTable.Th width={200}>Referal</DataTable.Th>
              </tr>
            </thead>

            <tbody>
              {x.candidates.map((c) => (
                <tr
                  key={c._id}
                  onClick={() =>
                    (ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                      ? window.open
                      : history.push)(`/candidates/${c._id}`)
                  }
                >
                  <DataTable.Td>{c.name}</DataTable.Td>
                  <DataTable.Td align="center">
                    <RemoteValue collection="seniority" id={c.seniority} />
                  </DataTable.Td>
                  <DataTable.Td align="center">
                    {c.yearsInTheIndustry} | {c.yearsInTheRole}{' '}
                  </DataTable.Td>
                  <DataTable.Td align="center">$ {c.salary}</DataTable.Td>
                  <DataTable.Td>{c.location}</DataTable.Td>

                  <DataTable.Td align="center">
                    {c.nextTask ? (
                      <React.Fragment>
                        {c.nextTask && c.nextTask.subject}
                        <p style={{ fontSize: 14, marginTop: 10 }}>
                          <Period date={c.nextTask && c.nextTask.dueDate} />
                        </p>
                      </React.Fragment>
                    ) : (
                      <b>Nothing Scheduled</b>
                    )}
                  </DataTable.Td>

                  <DataTable.Td>{c.referal}</DataTable.Td>
                </tr>
              ))}
            </tbody>
          </DataTable>
        </Section>
      ))}
    </React.Fragment>
  )
}

export default PositionCandidates
