import { Select, SelectProps } from 'semantic-ui-react'
import useSWR from 'swr'
import React from 'react'

import { useApi } from '../../store/mainContext'

interface Props extends Omit<SelectProps, 'options'> {
  getValue?(o: any): any
  customSort?(a: any, b: any): number
  orderBy?(x: any): number
  render?(o: any): any
  filter?: Record<string, any>
  url: string
}

const DataSelect = ({
  clearable = true,
  customSort,
  getValue,
  orderBy,
  filter,
  render,
  url,
  ...other
}: Props) => {
  const api = useApi()

  const { data: options, isLoading } = useSWR([url, filter], () =>
    api
      .get<any[]>(url, { params: filter })
      .then(({ data }) =>
        customSort
          ? data.sort(customSort)
          : orderBy
            ? data.sort((a: any, b: any) => orderBy(a) - orderBy(b))
            : data,
      ),
  )

  return (
    <Select
      {...other}
      options={(options || []).map((x) => ({
        value: getValue ? getValue(x) : x._id,
        text: render ? render(x) : x.text,
      }))}
      clearable={clearable}
      loading={isLoading}
    />
  )
}

export default DataSelect
