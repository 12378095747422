import { groupBy, uniqBy } from 'ramda'
import { NavLink } from 'react-router-dom'
import * as React from 'react'
import { Icon } from 'semantic-ui-react'
import useSWR from 'swr'
import { z } from 'zod'

import { paymentSetupSchema, Candidate, Company } from '../types'
import { validateResponse } from '../utils'
import NewsSection from './NewsSection'
import { useApi } from '../store/mainContext'

interface Props {
  periodCandidates: { candidate?: Candidate; customer?: Company }[]
  periodStart: number
  periodEnd: number
}

export default function MissingPaymentSetupsNews(props: Props) {
  const { periodStart, periodEnd, periodCandidates } = props

  const api = useApi()

  const { data, isLoading, error } = useSWR(
    ['period-missing-payment-setups', periodStart, periodEnd],
    async () => {
      const candidateSetups = await api
        .get('payment-setups/in', {
          params: {
            candidateId: periodCandidates.map(
              ({ candidate }) => candidate?._id,
            ),
          },
        })
        .then(validateResponse(z.array(paymentSetupSchema)))

      return periodCandidates.filter(({ candidate }) => {
        const hastPaymentSetup = candidateSetups.some(
          (setup) => setup.candidateId === candidate?._id,
        )
        return !hastPaymentSetup
      })
    },
  )

  const missingPaymentSetups = data || []

  const groupedByCustomer = groupBy(
    ({ customer }) => customer?.name || 'none',
    missingPaymentSetups,
  )

  return (
    <NewsSection
      title="Missing Payment Setups"
      isLoading={isLoading}
      error={error?.message}
      placeholderCount={1}
    >
      {missingPaymentSetups.length > 0 ? (
        <ul className="space-y-2">
          {Object.entries(groupedByCustomer).map(([customerName, group]) => {
            return (
              <li
                className="rounded-md bg-slate-50 px-4 py-3"
                key={customerName}
              >
                <div className="m-0 text-base font-semibold leading-normal">
                  <Icon name="building outline" color="grey" /> {customerName}
                </div>
                <ul className="mt-2 space-y-1">
                  {uniqBy((s) => s.candidate?._id, group || []).map(
                    ({ candidate }) => {
                      return (
                        <li key={candidate?._id}>
                          {candidate?.name ? (
                            <NavLink
                              className="text-primary hover:!text-black"
                              to={'/candidates/' + candidate?._id}
                            >
                              {candidate.name}
                            </NavLink>
                          ) : (
                            <span className="text-slate-600">
                              Unknown candidate
                            </span>
                          )}
                        </li>
                      )
                    },
                  )}
                </ul>
              </li>
            )
          })}
        </ul>
      ) : (
        <div className="italic text-slate-500">
          No missing payment setups this period
        </div>
      )}
    </NewsSection>
  )
}
