import React, { useEffect, useState } from 'react'
import DataTable from '../../components/dataTable/dataTable'
import { Button, Checkbox, Icon } from 'semantic-ui-react'
import { startOfDay, format } from 'date-fns'
import { Thread } from '../../types'
import { useUser } from '../session/hooks'
import styled from 'styled-components'
import EmailsPage from './emails'
import { useHistory, useParams } from 'react-router-dom'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'

const Toolbar = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const EmailList = () => {
  const { label } = useParams<{ label: string }>()

  const api = useApi()
  const user = useUser()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [threads, setThreads] = useState<Thread[]>([])

  useEffect(() => {
    setLoading(true)
    api.get<Thread[]>(`v1/email/threads/${label}`).then(({ data }) => {
      setThreads(data.sort((a, b) => b.highestDate - a.highestDate))
      setLoading(false)
    })
  }, [label, api])

  const handleShareChange = async (thread: Thread) => {
    const newStatus = !thread.messages[0]?.shared || false

    const updatedEmails = thread.messages.map((m) => ({
      ...m,
      shared: newStatus,
    }))

    setThreads(
      threads.map((t) => {
        if (t._id === thread._id) {
          return { ...thread, messages: updatedEmails }
        }

        return t
      }),
    )

    await api.patch(`v1/email/threads/${thread._id}`, {
      shared: newStatus,
    })
  }

  const cleanFrom = (from: string) => {
    const regex = /^["]?([^<"]+)["]?\s*<[^>]+>$/
    const match = from.match(regex) || []

    return match[1]?.trim() || ''
  }

  const getFrom = (t: Thread) => {
    const sorted = t.messages.sort((a, b) => a.date - b.date)

    const froms = sorted.map((x) => {
      if (x.from.includes(user.email)) {
        return 'Me'
      }

      return cleanFrom(x.from)
    })

    return froms.join(', ')
  }

  const formatDate = (date: number) => {
    if (date > startOfDay(Date.now()).getTime()) {
      return new Date(date).toLocaleTimeString()
    }

    return format(new Date(date), 'MMM d')
  }

  if (loading) {
    return <PageLoader />
  }

  return (
    <EmailsPage>
      <React.Fragment>
        <Toolbar>
          <div>
            <Button icon="trash" color="red" disabled></Button>
            <Button icon="hide" color="black" disabled></Button>
          </div>
          <div>
            <Button color="black">
              <Icon name="pencil alternate"></Icon> Compose
            </Button>
          </div>
        </Toolbar>
        <DataTable>
          <thead>
            <DataTable.Th width={50}>
              <Checkbox
                checked={threads.every((x) => x.selected)}
                onClick={() => {
                  const areAllSelected = threads.every((x) => x.selected)
                  setThreads(
                    threads.map((t) => {
                      t.selected = !areAllSelected
                      return t
                    }),
                  )
                }}
              />
            </DataTable.Th>
            <DataTable.Th>&nbsp;</DataTable.Th>
            <DataTable.Th>&nbsp;</DataTable.Th>
            <DataTable.Th>Shared</DataTable.Th>
            <DataTable.Th>&nbsp;</DataTable.Th>
          </thead>
          <tbody>
            {threads.map((t) => (
              <tr
                key={t._id}
                onClick={() => history.push(`/emails/${label}/${t._id}`)}
              >
                <DataTable.Td>
                  <Checkbox
                    checked={t.selected}
                    onClick={(e) => {
                      e.stopPropagation()
                      setThreads(
                        threads.map((x) =>
                          x._id === t._id ? { ...x, selected: !x.selected } : x,
                        ),
                      )
                    }}
                  />
                </DataTable.Td>
                <DataTable.Td>
                  {getFrom(t)}
                  <span
                    style={{ color: '#888', fontSize: 12, marginLeft: 10 }}
                  >{`${t.messages.length}`}</span>
                </DataTable.Td>
                <DataTable.Td>
                  {/* <p>{t.messages.map((x) => x.labels).join(',')}</p> */}
                  {t.messages[0]?.subject}
                  <span
                    style={{ color: '#888' }}
                  >{` - ${t.messages[0]?.snippet}`}</span>
                </DataTable.Td>
                <DataTable.Td>
                  {
                    <Checkbox
                      toggle
                      checked={t.messages[0]?.shared}
                      onChange={(e) => {
                        e.stopPropagation()
                        handleShareChange(t)
                      }}
                    />
                  }
                </DataTable.Td>
                <DataTable.Td align="right">
                  {formatDate(t.highestDate || 0)}
                </DataTable.Td>
              </tr>
            ))}
          </tbody>
        </DataTable>
      </React.Fragment>
    </EmailsPage>
  )
}

export default EmailList
