import { Icon, Popup } from 'semantic-ui-react'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { EnhancedPayment } from '../../hooks/useBillingPayments'
import InvoiceStatusLabel from '../invoices/InvoiceStatusLabel'
import { currencyFormat } from '../../utils'

interface Props {
  payment: EnhancedPayment
}

export default function PaymentAmountPopup(props: Props) {
  const { payment } = props

  const unallocatedAmount = Number(
    payment.allocations
      .reduce((sum, allocation) => sum - allocation.amount, payment.amount)
      .toFixed(2),
  )

  const shouldDisplayPopover = unallocatedAmount !== payment.amount

  const trigger = (
    <div className="relative block text-nowrap py-3 pl-2 pr-6 text-slate-600 hover:text-primary">
      {currencyFormat(unallocatedAmount)}
      {shouldDisplayPopover && (
        <span className="opacity-0 group-hover:opacity-100">
          <Icon
            className="absolute right-0 top-[15.5px]"
            circular
            name="help"
            size="mini"
          />
        </span>
      )}
    </div>
  )

  return (
    <Popup
      disabled={!shouldDisplayPopover}
      position="right center"
      hoverable
      trigger={trigger}
    >
      <div className="grid grid-cols-[auto_1fr] items-baseline gap-x-6 pt-1">
        <div className="py-0.5 text-right font-semibold">Payment amount</div>
        <div className="py-0.5 text-right font-semibold text-slate-700">
          {currencyFormat(payment.amount)}
        </div>
        {payment.allocations.map((allocation) => (
          <React.Fragment key={allocation._id}>
            <div className="text-nowrap py-0.5 text-right">
              <Link
                className="hover:underline"
                to={'/invoices/' + allocation.invoiceId}
              >
                {allocation.invoice?.name || 'Unknown invoice'}
              </Link>
              {allocation.invoice?.status && (
                <>
                  <span className="mx-1 text-slate-400">-</span>
                  <InvoiceStatusLabel status={allocation.invoice.status} />
                </>
              )}
            </div>
            <div className="py-0.5 text-right text-slate-500">
              {currencyFormat(allocation.amount * -1)}
            </div>
          </React.Fragment>
        ))}
        {payment.allocations.length > 0 && (
          <>
            <div className="border-t border-transparent py-2 text-right italic text-slate-500">
              Unallocated
            </div>
            <div className="border-t border-slate-300 py-2 text-right italic text-slate-500">
              {currencyFormat(unallocatedAmount)}
            </div>
          </>
        )}
      </div>
    </Popup>
  )
}
