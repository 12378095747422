import { Button, Confirm, Popup } from 'semantic-ui-react'
import { groupBy } from 'ramda'
import * as React from 'react'

import {
  EnhancedBillingTransaction,
  EnhancedInvoice,
} from '../../hooks/useInvoice'
import { BillingTransactionStatus, BillingTransaction } from '../../types'
import { classNames, currencyFormat } from '../../utils'
import PreviousInvoicesMenu from './PreviousInvoicesMenu'
import CreateTxForm from './CreateTxForm'
import { ENV_VARS } from '../../env'
import CompanyForm from './CompanyForm'
import InvoiceRow from './InvoiceRow'
import NameForm from './NameForm'
import DateForm from './DateForm'
import NoteForm from './NoteForm'

const NO_CANDIDATE = 'none'

type Dialog = { type: 'delete-tx'; tx: EnhancedBillingTransaction }

interface Props {
  onFieldUpdate(data: Partial<EnhancedInvoice>): Promise<any>
  onFieldDelete(fieldName: keyof EnhancedInvoice): Promise<any>
  onTxStatusUpdate(
    txId: BillingTransaction['_id'],
    newStatus: BillingTransactionStatus,
  ): Promise<any>
  onTxCreate(data: Partial<BillingTransaction>): Promise<any>
  onTxDelete(txId: BillingTransaction['_id']): Promise<any>
  invoice: EnhancedInvoice
  nameSuggestion?: string
  sepia?: boolean
}

export default function InvoiceComponent(props: Props) {
  const { invoice, nameSuggestion, sepia } = props

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isCustomTxFormVisible, setIsCustomTxFormVisible] =
    React.useState(false)

  const txsByCandidate = groupBy(
    (tx) => tx.candidateId || NO_CANDIDATE,
    invoice.transactions || [],
  )

  const total = (invoice.transactions || []).reduce((sum, tx) => {
    if (
      tx.status === 'Completed' ||
      tx.status === 'Approved' ||
      tx.status === 'Paid' ||
      tx.status === 'Sent'
    ) {
      sum += tx.amount * (tx.type === 'discount' ? -1 : 1)
    }
    return sum
  }, 0)

  const [isDimissedVisible, setIsDismissedVisible] = React.useState(false)

  // reset back to hide dismissed upon status change
  const status = invoice.status
  React.useEffect(() => {
    if (status !== 'Draft') setIsDismissedVisible(false)
  }, [status])

  return (
    <div
      className={classNames(
        'aspect-[85/110] rounded-sm shadow-lg @container',
        sepia ? 'bg-orange-50' : 'bg-white',
      )}
    >
      <div className="px-6 py-8 @xl:px-12 @xl:py-14">
        <img
          className="float-right hidden w-1/6 max-w-24 @lg:block"
          alt="Southteams"
          src="/img/128.png"
        />
        <div className="float-left">
          <h1 className="m-0 text-base leading-none ![letter-spacing:2px] @xl:text-2xl @xl:leading-none">
            INVOICE
          </h1>
          <div className="mt-2 text-base text-slate-500">
            <div>Southteams</div>
            <div>66 W Flagler Street STE 900,</div>
            <div>Miami, FL 33130, United States</div>
            <a
              className="!text-slate-500 underline decoration-slate-300"
              target={
                ENV_VARS.REACT_APP_USE_NEW_TABS !== 'false'
                  ? '_blank'
                  : undefined
              }
              href="https://southteams.com"
              rel="noreferrer"
            >
              southteams.com
            </a>
          </div>
        </div>
        <div className="clear-both" />

        <div
          className={classNames(
            '-ml-2 mt-6 grid grid-cols-1 items-start gap-4 @2xl:mt-12',
            invoice.status === 'Draft'
              ? '@2xl:grid-cols-[1fr_325px]'
              : '@2xl:grid-cols-[1fr_auto]',
          )}
        >
          <CompanyForm
            isGeneratedByPeriod={invoice.generatedByPeriod}
            isEditable={invoice.status === 'Draft'}
            onChange={(customerId) => props.onFieldUpdate({ customerId })}
            onClear={() => props.onFieldDelete('customerId')}
            value={invoice.customerId}
          />

          <div
            className={classNames(
              '-order-1 rounded-md px-5 py-2 @2xl:order-1',
              sepia ? 'bg-slate-200' : 'bg-slate-100',
            )}
          >
            <NameForm
              suggestion={nameSuggestion}
              onChange={(name) => props.onFieldUpdate({ name })}
              onClear={() => props.onFieldDelete('name')}
              value={invoice.name}
              isEditable={invoice.status === 'Draft'}
            />
            <DateForm
              label="Issue date"
              value={invoice.issueDate}
              onChange={(issueDate) => props.onFieldUpdate({ issueDate })}
              onClear={() => props.onFieldDelete('issueDate')}
              isEditable={invoice.status === 'Draft'}
              datePickerProps={{
                maxDate: invoice.dueDate
                  ? new Date(invoice.dueDate)
                  : undefined,
              }}
            />
            <DateForm
              label="Due date"
              value={invoice.dueDate}
              onChange={(dueDate) => props.onFieldUpdate({ dueDate })}
              onClear={() => props.onFieldDelete('dueDate')}
              isEditable={invoice.status === 'Draft'}
              datePickerProps={{
                minDate: invoice.issueDate
                  ? new Date(invoice.issueDate)
                  : undefined,
              }}
            />
          </div>
        </div>

        {(invoice.status === 'Draft' || invoice.note) && (
          <section className="mt-4">
            <NoteForm
              isEditable={invoice.status === 'Draft'}
              onChange={(note) => props.onFieldUpdate({ note })}
              onClear={() => props.onFieldDelete('note')}
              value={invoice.note}
            />
          </section>
        )}

        <section className="mt-4 flex min-h-9 items-baseline">
          <div className="grow" />
          {(invoice.transactions || []).length > 0 &&
          invoice.status === 'Draft' ? (
            <Button
              onClick={() => setIsCustomTxFormVisible(true)}
              content="Add row"
              className="!mr-0"
              icon="plus"
              compact
              basic
            />
          ) : (
            (invoice.transactions || []).some(
              (tx) => tx.status === 'Dismissed',
            ) && (
              <Popup
                size="mini"
                content={
                  isDimissedVisible ? 'Hide dismissed' : 'Reveal dismissed'
                }
                trigger={
                  <Button
                    onClick={() => setIsDismissedVisible((v) => !v)}
                    className="!mr-0"
                    icon={isDimissedVisible ? 'unhide' : 'hide'}
                    compact
                    basic
                  />
                }
                position="top center"
              />
            )
          )}
        </section>

        {(invoice.transactions || []).length > 0 || isCustomTxFormVisible ? (
          <table className="mt-4 w-full @2xl:mt-6" cellPadding={0}>
            <thead>
              <tr className="border-b text-left text-sm font-semibold uppercase leading-none text-primary">
                <th className="w-full px-2 py-3">Detail</th>
                {invoice.status === 'Draft' && (
                  <th className="sr-only w-[1%] px-2 py-3 text-right leading-none ">
                    Is custom?
                  </th>
                )}
                {invoice.status === 'Draft' && (
                  <th className="min-w-[115px] py-3 pl-2 pr-8 text-right leading-none">
                    {!isCustomTxFormVisible && 'Status'}
                  </th>
                )}
                <th className="min-w-[100px] px-2 py-3 text-right">Amount</th>
              </tr>
            </thead>

            <tbody>
              {invoice.status === 'Draft' && isCustomTxFormVisible && (
                <CreateTxForm
                  invoiceId={invoice._id}
                  onSubmit={props.onTxCreate}
                  onCancel={() => setIsCustomTxFormVisible(false)}
                />
              )}

              {Object.entries(txsByCandidate).map(
                ([groupId, maybeGroupTxs]) => {
                  if (groupId === NO_CANDIDATE) return null
                  return (
                    <React.Fragment key={groupId}>
                      {(maybeGroupTxs || []).map((tx) => {
                        if (
                          invoice.status !== 'Draft' &&
                          tx.status === 'Dismissed' &&
                          !isDimissedVisible
                        ) {
                          return null
                        }
                        return (
                          <InvoiceRow
                            onStatusUpdate={(newStatus) =>
                              props.onTxStatusUpdate(tx._id, newStatus)
                            }
                            onDeleteClick={() => {
                              setCurrentDialog({ type: 'delete-tx', tx })
                            }}
                            invoice={invoice}
                            key={tx._id}
                            tx={tx}
                          />
                        )
                      })}
                    </React.Fragment>
                  )
                },
              )}

              {(txsByCandidate[NO_CANDIDATE] || []).map((tx) => (
                <InvoiceRow
                  onStatusUpdate={(newStatus) =>
                    props.onTxStatusUpdate(tx._id, newStatus)
                  }
                  onDeleteClick={() => {
                    setCurrentDialog({ type: 'delete-tx', tx })
                  }}
                  invoice={invoice}
                  key={tx._id}
                  tx={tx}
                />
              ))}
            </tbody>

            {(invoice.transactions || []).length > 0 && (
              <tfoot className="border-t">
                <tr className="text-right text-xl font-semibold uppercase">
                  <td className="px-2 py-8 leading-none" colSpan={4}>
                    <span className="mr-4">Total</span>{' '}
                    <span className="inline-block">
                      {currencyFormat(total)}
                    </span>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        ) : (
          <div className="py-16 text-center text-slate-500">
            <div className="mb-3">No transactions in this invoice yet</div>
            <Button
              content="Add a custom transaction"
              onClick={() => setIsCustomTxFormVisible(true)}
              className="!mr-0"
              primary
              basic
            />
          </div>
        )}
      </div>

      <Confirm
        header="Are you sure?"
        content={
          <div className="px-6 pb-4">
            <p className="text-base">This action will:</p>
            <ul className="list-disc space-y-2 pl-6 text-lg">
              <li>Delete the selected custom transaction.</li>
            </ul>
            <p className="mt-4 text-base font-semibold">
              This action cannot be undone.
            </p>
          </div>
        }
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => {
          if (!currentDialog?.tx._id) return
          setCurrentDialog(null)
          props.onTxDelete(currentDialog.tx._id)
        }}
        open={currentDialog?.type === 'delete-tx'}
        size="mini"
        cancelButton={<Button content="Cancel" />}
        confirmButton={
          <Button primary={false} color="red">
            Yes, delete
          </Button>
        }
      />
    </div>
  )
}

export function InvoiceWithPrevious(props: Props) {
  const [selectedItem, setSelectedItem] = React.useState(props.invoice)

  const isCurrent = selectedItem._id === props.invoice._id
  return (
    <>
      <PreviousInvoicesMenu
        currentInvoice={props.invoice}
        selectedItem={selectedItem}
        onClick={setSelectedItem}
      />
      <InvoiceComponent
        key={selectedItem._id}
        {...props}
        invoice={isCurrent ? props.invoice : selectedItem}
        sepia={!isCurrent}
      />
    </>
  )
}
