import {
  ButtonGroup,
  IconProps,
  Loader,
  Button,
  Popup,
  Icon,
} from 'semantic-ui-react'
import ExclamationCircleIcon from '@heroicons/react/24/outline/ExclamationCircleIcon'
import React, { useState } from 'react'

import {
  PositionPausedResumeEvent,
  PositionStatusChangeEvent,
  PositionChangeEvent,
  StatusChangeEvent,
  ActionEvent,
  SmallEvent,
  UserEvent,
  Event as EventComponent,
} from './eventTypes/event'

import {
  Collection,
  Candidate,
  FeedItem,
  Company,
  Payroll,
  Contact,
  Invoice,
} from '../../types'
import RecipeScheduleFeedItem from './eventTypes/RecipeScheduleFeedItem'
import featureToggles from '../../featureToggles'
import TaskComponent from './eventTypes/task'
import RequirePowers from '../requirePowers'
import PendingRecipe from './PendingRecipe'
import useEventFeed from '../../hooks/useEventFeed'
import AddTaskModal from '../modals/addTaskModal'
import AddLogModal from '../modals/addLogModal'
import PendingTask from './pendingTask'
import FeedEditor from './feedEditor/FeedEditor'
import FeedLoader from './FeedLoader'
import Email from './eventTypes/email'
import Note from './eventTypes/note'

type Filter =
  | 'recipe-schedules'
  | 'user-events'
  | 'rome-events'
  | 'emails'
  | 'tasks'

const filterEvents = (currentFilter: Filter[] | 'all') => (item: FeedItem) => {
  const isTask = item.type === 'task'

  const isRomeEvent =
    item.type !== 'user-event' && !isTask && item.type !== 'email'

  if (currentFilter === 'all') return true
  if (currentFilter.includes('tasks') && isTask) return true
  if (currentFilter.includes('user-events') && item.type === 'user-event')
    return true
  if (
    currentFilter.includes('recipe-schedules') &&
    item.type === 'recipe-schedule'
  ) {
    return true
  }
  if (currentFilter.includes('emails') && item.type === 'email') return true
  if (currentFilter.includes('rome-events') && isRomeEvent) return true

  return false
}

interface FilterBtnProps {
  setCurrentFilter: React.Dispatch<React.SetStateAction<Filter[] | 'all'>>
  currentFilter: Filter[] | 'all'
  tooltip: string
  filter: Filter | 'all'
  icon: IconProps['name']
}

function FilterBtn(props: FilterBtnProps) {
  const { setCurrentFilter, currentFilter, icon, tooltip, filter } = props
  return (
    <Popup
      trigger={
        <Button
          onClick={() => {
            setCurrentFilter((prev) => {
              if (filter === 'all') return 'all'
              if (prev === 'all') return [filter]
              if (!prev.includes(filter)) return [...prev, filter]
              return prev.length > 1
                ? prev.filter((item) => item !== filter)
                : 'all'
            })
          }}
          active={
            filter === 'all'
              ? currentFilter === 'all'
              : Array.isArray(currentFilter) && currentFilter.includes(filter)
          }
          icon
        >
          <Icon name={icon} />
        </Button>
      }
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      position="top center"
      content={tooltip}
      size="tiny"
    />
  )
}

interface Props {
  relatedCollection: Collection
  relatedId?: Candidate['_id']
  showEditor?: boolean
  showTasks?: boolean
  showFilter?: boolean
  enableAddLog?: boolean
  limitPerType?: number
  relatedObject?: Candidate | Contact | Company | Invoice | Payroll
}

const Feed = ({
  relatedId,
  relatedCollection,
  showEditor = true,
  showFilter = false,
  enableAddLog = true,
  showTasks = true,
  relatedObject,
  limitPerType,
}: Props) => {
  const { data, isLoading, mutate, error, isValidating } = useEventFeed({
    relatedCollection,
    relatedId,
    showTasks,
    limitPerType,
  })

  const [showAddLogModal, setShowAddLogModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<Filter[] | 'all'>('all')

  const handleTaskDone = (openModal = true) => {
    if (openModal) setShowAddModal(true)
    mutate()
  }

  const handleTaskCreated = () => mutate().then(() => setShowAddModal(false))

  const handleLogCreated = () => {
    setShowAddLogModal(false)
    mutate()
  }

  return (
    <React.Fragment>
      {showEditor && relatedId && (
        <FeedEditor
          relatedCollection={relatedCollection}
          onSuccess={() => mutate()}
          relatedId={relatedId}
        />
      )}

      {showTasks && (
        <section className="relative z-10 mb-12 mt-6">
          <header className="relative flex items-baseline">
            <h3 className="m-0">Scheduled</h3>
            <button
              className="ml-6 text-indigo-700 hover:text-indigo-600"
              onClick={() => setShowAddModal(true)}
              type="button"
            >
              [ + Add Task ]
            </button>

            <div className="absolute -top-1 right-0">
              <Loader
                active={isValidating && !isLoading}
                indeterminate
                size="mini"
                inline
              />
            </div>
          </header>

          {isLoading && <FeedLoader items={3} marginTop={20} />}

          {!isLoading && data?.pending?.length === 0 && (
            <div className="flex flex-col items-center px-3 py-6">
              <div className="mb-3 text-slate-500">Nothing scheduled yet</div>
              <Button basic onClick={() => setShowAddModal(true)}>
                <Icon name="add" /> Click here to add a task.
              </Button>
            </div>
          )}

          {!isLoading && data?.pending && data.pending.length > 0 && (
            <div className="mt-4">
              {data.pending.map((t) => (
                <PendingTask
                  key={t._id}
                  task={t}
                  onChangeSuccess={mutate}
                  onDone={handleTaskDone}
                />
              ))}
            </div>
          )}

          {!isLoading && data?.scheduled && data.scheduled.length > 0 && (
            <div className="mt-4">
              {data.scheduled.map((schedule) => (
                <PendingRecipe
                  onSuccess={() => mutate()}
                  schedule={schedule}
                  key={schedule._id}
                />
              ))}
            </div>
          )}
        </section>
      )}

      <section className="mb-12">
        <header className="relative flex items-baseline">
          <h3 className="m-0">Events Feed</h3>
          {enableAddLog && (
            <button
              className="ml-6 text-indigo-700 hover:text-indigo-600"
              onClick={() => setShowAddLogModal(true)}
              type="button"
            >
              [ + Add Log ]
            </button>
          )}
          <div className="grow" />
          {showFilter && (
            <div>
              <span className="mr-3 text-sm font-semibold text-slate-500">
                View
              </span>
              <ButtonGroup size="mini" basic>
                <FilterBtn
                  setCurrentFilter={setCurrentFilter}
                  currentFilter={currentFilter}
                  tooltip="All events"
                  filter="all"
                  icon="asterisk"
                />
                <FilterBtn
                  setCurrentFilter={setCurrentFilter}
                  currentFilter={currentFilter}
                  tooltip="Admin events"
                  filter="rome-events"
                  icon="user secret"
                />
                {featureToggles.emails && (
                  <FilterBtn
                    setCurrentFilter={setCurrentFilter}
                    currentFilter={currentFilter}
                    tooltip="Emails from Inbox"
                    filter="emails"
                    icon="mail"
                  />
                )}
                <FilterBtn
                  setCurrentFilter={setCurrentFilter}
                  currentFilter={currentFilter}
                  tooltip="Tasks"
                  filter="tasks"
                  icon="tasks"
                />
                <FilterBtn
                  setCurrentFilter={setCurrentFilter}
                  currentFilter={currentFilter}
                  tooltip="Candidate events"
                  filter="user-events"
                  icon="user"
                />
                <RequirePowers powers={['run-and-schedule-recipes']}>
                  <FilterBtn
                    setCurrentFilter={setCurrentFilter}
                    currentFilter={currentFilter}
                    tooltip="Recipe Runs"
                    filter="recipe-schedules"
                    icon="lab"
                  />
                </RequirePowers>
              </ButtonGroup>
            </div>
          )}
        </header>

        {isLoading && <FeedLoader marginTop={20} />}

        {!isLoading && data?.feed && data.feed?.length === 0 && (
          <div className="flex flex-col items-center px-3 py-6">
            <div className="mb-3 text-slate-500">Nothing logged yet</div>
            <Button basic onClick={() => setShowAddLogModal(true)}>
              <Icon name="add" /> Add a log
            </Button>
          </div>
        )}

        {error && !data && (
          <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
            <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
            {error.message}
          </div>
        )}

        {!isLoading && data?.feed && data.feed?.length > 0 && (
          <div className="mt-6">
            {data.feed
              .filter(filterEvents(currentFilter))
              .map((feedItem, index) => {
                const isLastOne = index === data.feed.length - 1

                if (feedItem.type === 'recipe-schedule') {
                  return (
                    <RecipeScheduleFeedItem
                      isFirstOne={isLastOne}
                      schedule={feedItem}
                      key={feedItem._id}
                    />
                  )
                }

                if (feedItem.type === 'email') {
                  return (
                    <Email
                      relatedObject={
                        relatedObject?.name
                          ? (relatedObject as Candidate | Contact)
                          : undefined
                      }
                      isFirstOne={isLastOne}
                      email={feedItem}
                      key={feedItem._id}
                      onUnshare={mutate}
                    />
                  )
                }

                if (feedItem.type === 'task') {
                  return (
                    <TaskComponent
                      onChangeSuccess={mutate}
                      isFirstOne={isLastOne}
                      task={feedItem}
                      key={feedItem._id}
                    />
                  )
                }

                if (
                  feedItem.type === 'pipedrive-note' ||
                  feedItem.type === 'note'
                ) {
                  return (
                    <Note
                      event={feedItem}
                      key={feedItem._id}
                      onRemoveSuccess={mutate}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'small-event') {
                  return (
                    <SmallEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                      userId={feedItem.createdBy}
                    />
                  )
                }

                if (
                  feedItem.type === 'paymentSetup-changed' ||
                  feedItem.type === 'account-created' ||
                  feedItem.type === 'pto-requested' ||
                  feedItem.type === 'user-event'
                ) {
                  return (
                    <UserEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'position-status-change') {
                  return (
                    <PositionStatusChangeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'position-pause-resume') {
                  return (
                    <PositionPausedResumeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'position-candidate-changed') {
                  return (
                    <PositionChangeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'status-candidate-changed') {
                  return (
                    <StatusChangeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'event') {
                  return (
                    <EventComponent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'action') {
                  return (
                    <ActionEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                // catch-all JSON render for debugging
                return (
                  <pre
                    className="text-wrap border bg-slate-50 p-2 text-xs leading-none"
                    key={feedItem._id}
                  >
                    {JSON.stringify(feedItem, null, 2)}
                  </pre>
                )
              })}
          </div>
        )}
      </section>

      <AddTaskModal
        show={showAddModal}
        relatedId={relatedId}
        relatedCollection={relatedCollection}
        onCancel={() => setShowAddModal(false)}
        onCreated={handleTaskCreated}
      />

      <AddLogModal
        show={showAddLogModal}
        relatedId={relatedId}
        relatedCollection={relatedCollection}
        onCancel={() => setShowAddLogModal(false)}
        onCreated={handleLogCreated}
      />
    </React.Fragment>
  )
}

export default Feed
