import * as React from 'react'

import TransactionStatusLabel from './TransactionStatusLabel'
import { Summary } from './PayrollProgressBar'
import { Payroll } from '../../types'

interface Props {
  payrollStatus: Payroll['status']
  summary: Summary
}

export default function TransactionsSummaryWidget(props: Props) {
  const { summary, payrollStatus } = props
  return (
    <table className="w-full">
      <tbody>
        <tr>
          <th className="pb-2 text-left text-sm font-semibold uppercase leading-6 text-slate-400">
            All
          </th>
          <td className="pb-2 text-right text-sm font-semibold text-slate-400">
            {summary.total}
          </td>
        </tr>
        {payrollStatus === 'Draft' ? (
          <tr>
            <th className="text-left">
              <TransactionStatusLabel status="Approved" />
            </th>
            <td className="text-right text-sm text-slate-600">
              {summary.statuses.Approved}
            </td>
          </tr>
        ) : (
          <tr>
            <th className="text-left">
              <TransactionStatusLabel status="Completed" />
            </th>
            <td className="text-right text-sm font-semibold text-slate-600">
              {summary.statuses.Completed}
            </td>
          </tr>
        )}
        <tr>
          <th className="text-left">
            <TransactionStatusLabel status="Dismissed" />
          </th>
          <td className="text-right text-sm text-slate-600">
            {summary.statuses.Dismissed}
          </td>
        </tr>
        <tr>
          <th className="text-left text-sm font-semibold uppercase leading-6 text-slate-400">
            Custom
          </th>
          <td className="text-right text-sm text-slate-600">
            {summary.custom}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
