import useSWR from 'swr'

import { recipeStepDefinitionSchema } from '../types'
import { validateResponse } from '../utils'
import { useApi } from '../store/mainContext'

export default function useRecipeSteps() {
  const api = useApi()

  return useSWR(['recipeSteps'], () =>
    api
      .get('recipes/steps')
      .then(validateResponse(recipeStepDefinitionSchema.array())),
  )
}
