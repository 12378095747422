import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { Breadcrumb, Button, Divider, Menu } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import * as React from 'react'

import {
  BillingTransactionStatus,
  BillingTransaction,
  invoiceSchema,
} from '../../types'
import useBillingPeriod, {
  EnhancedBillingPeriod,
} from '../../hooks/useBillingPeriod'
import { classNames, currencyFormat, validateResponse } from '../../utils'
import { InvoiceWithPrevious } from '../../components/invoices/Invoice'
import InvoiceDeliveryDetails from '../../components/invoices/InvoiceDeliveryDetails'
import { EnhancedInvoice } from '../../hooks/useInvoice'
import PeriodInvoicesList from '../../components/billing/PeriodInvoicesList'
import InvoiceDeliveries from '../../components/invoices/InvoiceDeliveries'
import InvoicePayments from '../../components/invoices/InvoicePayments'
import InvoiceActions from '../../components/invoices/InvoiceActions'
import PeriodActions from '../../components/billing/PeriodActions'
import { useToasts } from '../../components/toasts/ToastsProvider'
import PeriodNews from '../../components/billing/PeriodNews'
import PageLoader from '../../components/pageLoader'
import { useApi } from '../../store/mainContext'
import Feed from '../../components/feeds/feed'

type RouteParams = { billingPeriodId?: string; invoiceId?: string }

type Tab = 'feed' | 'payments' | 'scheduled-emails' | 'period-news'

export default function BillingPeriodPage() {
  const { params } = useRouteMatch<RouteParams>()

  const { billingPeriodId, invoiceId } = params

  if (!billingPeriodId) {
    throw new Error('`billingPeriodId` param must be present in the URL.')
  }

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const period = useBillingPeriod(billingPeriodId)

  const [isCreatingInvoice, setIsCreatingInvoice] = React.useState(false)

  const [selectedTab, setSelectedTab] = React.useState<Tab>('feed')

  const handleInvoiceCreate = () => {
    setIsCreatingInvoice(true)
    return api
      .post('billing-invoices', { billingPeriodId })
      .then(validateResponse(invoiceSchema))
      .then((invoice) =>
        period.mutate().then(() => {
          history.push(`/billing/${billingPeriodId}/invoice/${invoice._id}`)
        }),
      )
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreatingInvoice(false))
  }

  const handleFieldUpdate = async (data: Partial<EnhancedInvoice>) => {
    if (!period.data || !invoiceId) return
    return period.mutate(
      () =>
        api
          .patch('billing-invoices/' + invoiceId, data)
          .catch((err: AxiosError) =>
            addToast(err.response?.data.message || err.message, {
              variant: 'danger',
            }),
          ),
      {
        optimisticData: {
          ...period.data,
          invoices: period.data.invoices.map((invoice) =>
            invoice._id === invoiceId ? { ...invoice, ...data } : invoice,
          ),
        },
        populateCache: false,
      },
    )
  }

  const handleDeliveryDetailsUpdate = async (
    data: Partial<EnhancedInvoice>,
  ) => {
    if (!period.data || !invoiceId) return
    return api
      .patch('billing-invoices/' + invoiceId, data)
      .then(() => period.mutate())
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
  }

  const handleFieldDelete = async (fieldName: keyof EnhancedInvoice) => {
    if (!period.data || !invoiceId) return
    return period.mutate(
      () =>
        api
          .delete(`billing-invoices/${invoiceId}/${fieldName}`)
          .catch((err: AxiosError) =>
            addToast(err.response?.data.message || err.message, {
              variant: 'danger',
            }),
          ),
      {
        optimisticData: {
          ...period.data,
          invoices: period.data.invoices.map((invoice) => {
            if (invoice._id !== invoiceId) return invoice
            const optimisticData = { ...invoice }
            delete optimisticData[fieldName]
            return optimisticData
          }),
        },
        populateCache: false,
      },
    )
  }

  const handleTxStatusUpdate = async (
    txId: BillingTransaction['_id'],
    status: BillingTransactionStatus,
  ) => {
    if (!period.data) return
    return period.mutate(
      () =>
        api
          .patch('/billing-transactions/' + txId, { status })
          .catch((e: AxiosError) => {
            addToast(e.response?.data.message || e.message, {
              variant: 'danger',
            })
          }),
      {
        optimisticData: {
          ...period.data,
          invoices: period.data.invoices.map((invoice) => ({
            ...invoice,
            transactions: (invoice.transactions || []).map((tx) =>
              tx._id === txId ? { ...tx, status } : tx,
            ),
          })),
        },
        populateCache: false,
      },
    )
  }

  const handleTxCreate = async (data: BillingTransaction) => {
    return api
      .post('billing-transactions', data)
      .then(() => period.mutate())
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
  }

  const handleTxDelete = async (txId: BillingTransaction['_id']) => {
    if (!period.data) return
    return period.mutate(
      api.delete('billing-transactions/' + txId).catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      }),
      {
        optimisticData: {
          ...period.data,
          invoices: period.data.invoices.map((invoice) => ({
            ...invoice,
            transactions: (invoice.transactions || []).filter(
              (tx) => tx._id !== txId,
            ),
          })),
        },
        populateCache: false,
      },
    )
  }

  const [isFeedVisible, setIsFeedVisible] = React.useState(true)

  const selectedInvoice = period.data?.invoices.find(
    (invoice) => invoice._id === invoiceId,
  )

  React.useEffect(() => {
    if ((selectedInvoice?.totalPaid || 0) === 0 && selectedTab === 'payments') {
      setSelectedTab('feed')
    }
    if (!selectedInvoice?.sendAt && selectedTab === 'scheduled-emails') {
      setSelectedTab('feed')
    }
  }, [selectedInvoice, selectedTab])

  if (period.isLoading) return <PageLoader />

  if (!period.data) {
    addToast('Billing Period not found', { variant: 'danger' })
    return <Redirect to="/billing" />
  }

  // If in root "/billing" URL, automatically navigate to first invoice (if one exists)
  const maybeFirstInvoiceId = period.data.invoices[0]?._id
  if (!params.invoiceId && maybeFirstInvoiceId) {
    return (
      <Redirect
        to={`/billing/${billingPeriodId}/invoice/${maybeFirstInvoiceId}`}
      />
    )
  }

  return (
    <div className="flex h-screen justify-between bg-slate-200/80">
      <Helmet>
        <title>
          {period.data.periodStart
            ? format(period.data.periodStart, 'yyyy-MM-dd')
            : ''}{' '}
          &ndash;{' '}
          {period.data.periodEnd
            ? format(period.data.periodEnd, 'yyyy-MM-dd')
            : ''}{' '}
          - Billing
        </title>
      </Helmet>

      <div className="w-full min-w-[100px] max-w-[340px] shrink-0 overflow-y-auto border-r border-slate-300 bg-slate-50 [&::-webkit-scrollbar]:hidden">
        <div className="bg-white px-3 pt-5">
          <Breadcrumb>
            <Breadcrumb.Section>
              <Link to="/">Dashboard</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>
              <Link to="/billing">Billing</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>Billing Period</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className="bg-white px-3 pt-5 text-lg font-semibold text-slate-600">
          {period.data.description}
        </div>

        <div className="bg-white px-3 py-5">
          <PeriodActions period={period.data} mutate={period.mutate} />
        </div>

        <div className="bg-white px-3 pb-1 pt-3">
          <div className="rounded-md px-2 py-2 text-center">
            <div className="text-4xl font-semibold leading-10 text-slate-700">
              {currencyFormat(period.data.totalBilled)}
            </div>
            <div className="mt-1 text-center text-sm font-semibold uppercase text-slate-500">
              Total Billed
            </div>
          </div>
        </div>

        <PeriodInvoicesList
          onInvoiceCreateClick={handleInvoiceCreate}
          isCreatingInvoice={isCreatingInvoice}
          period={period.data}
        />
      </div>

      {invoiceId && isFeedVisible && (
        <div className="order-1 w-full min-w-[300px] max-w-[440px] overflow-y-auto border-l border-slate-300 bg-white [&::-webkit-scrollbar]:hidden">
          <div className="px-4 py-3">
            <Menu tabular size="tiny">
              <Menu.Item
                // icon="feed"
                name="Feed"
                active={selectedTab === 'feed'}
                onClick={() => setSelectedTab('feed')}
              />
              <Menu.Item
                icon="newspaper outline"
                name="Period News"
                active={selectedTab === 'period-news'}
                onClick={() => setSelectedTab('period-news')}
              />
              <Menu.Item
                icon="mail outline"
                name="Deliveries"
                active={selectedTab === 'scheduled-emails'}
                onClick={() => setSelectedTab('scheduled-emails')}
                disabled={!selectedInvoice?.sendAt}
              />
              <Menu.Item
                icon="dollar"
                name="Payments"
                active={selectedTab === 'payments'}
                onClick={() => setSelectedTab('payments')}
                disabled={(selectedInvoice?.totalPaid || 0) === 0}
              />
            </Menu>
            {selectedTab === 'feed' && (
              <Feed
                relatedCollection="billing-invoices"
                showEditor={false}
                relatedId={invoiceId}
              />
            )}
            {selectedTab === 'period-news' && (
              <PeriodNews
                periodStart={period.data.periodStart}
                periodEnd={period.data.periodEnd}
              />
            )}
            {selectedTab === 'payments' && (
              <InvoicePayments invoiceId={invoiceId} />
            )}
            {selectedTab === 'scheduled-emails' && (
              <InvoiceDeliveries
                isManuallySent={selectedInvoice?.manual}
                invoiceId={invoiceId}
              />
            )}
          </div>
        </div>
      )}

      <div
        className={classNames(
          'grow',
          selectedInvoice
            ? 'sticky top-0 overflow-y-auto px-4 [&::-webkit-scrollbar]:hidden '
            : 'flex items-center justify-center',
        )}
      >
        {selectedInvoice ? (
          <>
            <div className="relative z-20 mx-auto max-w-4xl py-6">
              <InvoiceActions
                onViewFeedClick={() => setIsFeedVisible((v) => !v)}
                isFeedVisible={isFeedVisible}
                onUpdateSuccess={() => period.mutate()}
                onDeleteSuccess={() =>
                  period
                    .mutate<EnhancedBillingPeriod>()
                    .then((mutatedPeriod) => {
                      const maybeInvoiceId = mutatedPeriod?.invoices?.[0]?._id
                      history.replace(
                        maybeInvoiceId
                          ? `/billing/${billingPeriodId}/invoice/${maybeInvoiceId}`
                          : `/billing/${billingPeriodId}`,
                      )
                    })
                }
                invoice={selectedInvoice}
                key={selectedInvoice._id}
              />
            </div>

            <div className="relative z-10 mx-auto max-w-4xl">
              <InvoiceDeliveryDetails
                onFieldUpdate={handleDeliveryDetailsUpdate}
                invoice={selectedInvoice}
                key={selectedInvoice._id}
              />
            </div>

            <div className="mx-auto max-w-4xl pb-8">
              <InvoiceWithPrevious
                onTxStatusUpdate={handleTxStatusUpdate}
                onTxCreate={handleTxCreate}
                onTxDelete={handleTxDelete}
                onFieldUpdate={handleFieldUpdate}
                onFieldDelete={handleFieldDelete}
                invoice={selectedInvoice}
                key={selectedInvoice._id}
                nameSuggestion={
                  selectedInvoice.customer?.name && period.data?.periodStart
                    ? `${selectedInvoice.customer?.name} - ${format(
                        period.data.periodStart,
                        'MMddyy',
                      )}`
                    : undefined
                }
              />
            </div>
          </>
        ) : (
          <div className="-mt-8 min-w-96 text-center">
            <div className="text-lg text-slate-600">Select an invoice</div>
            <Divider horizontal>Or</Divider>
            <Button
              content="Create a new invoice"
              onClick={handleInvoiceCreate}
              loading={isCreatingInvoice}
              disabled={isCreatingInvoice}
              primary
            />
          </div>
        )}
      </div>
    </div>
  )
}
