import { Button, Label, Menu, Popup } from 'semantic-ui-react'
import { NavLink, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as React from 'react'

import useBillingPayments, {
  EnhancedPayment,
} from '../../hooks/useBillingPayments'
import CreatePaymentDialog from '../../components/payments/CreatePaymentDialog'
import UpdatePaymentDialog from '../../components/payments/UpdatePaymentDialog'
import DeletePaymentDialog from '../../components/payments/DeletePaymentDialog'
import PaymentsTable from '../../components/payments/PaymentsTable'
import InvoicesTable from '../../components/invoices/InvoicesTable'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'
import Feed from '../../components/feeds/feed'

type Dialog =
  | { type: 'delete-payment'; payment: EnhancedPayment }
  | { type: 'update-payment'; payment: EnhancedPayment }
  | { type: 'create-payment' }

export default function InvoicesPage() {
  const { data, isLoading, mutate } = useBillingPayments()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const handleSuccess = async (shouldCloseDialog = true) => {
    return mutate().then(() => {
      if (shouldCloseDialog) setCurrentDialog(null)
    })
  }

  if (isLoading) return <PageLoader />

  const unnallocatedCount = (data?.payments || []).filter(
    (payment) =>
      payment.status === 'Draft' || payment.status === 'Partially Allocated',
  ).length

  return (
    <React.Fragment>
      <div className="flex h-screen">
        <div className=" min-w-[100px] shrink-0 flex-grow overflow-y-auto [&::-webkit-scrollbar]:hidden">
          <Helmet>
            <title>Invoices & Payments</title>
          </Helmet>

          <PageHeader
            title="Invoices & Payments"
            sub="A Lannister always pays his debts."
            breadcrumb={[
              { text: 'Dashboard', link: '/' },
              { text: 'Invoices & Payments' },
            ]}
            actions={
              <Button
                content="Add Payment"
                onClick={() => setCurrentDialog({ type: 'create-payment' })}
                className="!mr-0"
                icon="plus"
                basic
              />
            }
          />

          <div className="mx-14 mb-4">
            <Menu tabular className="h-[46px]">
              <Menu.Item
                to="/invoices"
                as={NavLink}
                icon="wordpress forms"
                name="Invoices"
              />
              <Menu.Item
                to="/payments"
                as={NavLink}
                icon="dollar"
                content={
                  <>
                    Payments{' '}
                    {unnallocatedCount > 0 && (
                      <Popup
                        trigger={
                          <Label
                            className="no-overrides cursor-help"
                            color="orange"
                            size="small"
                          >
                            {unnallocatedCount.toString()}
                          </Label>
                        }
                        content="Unallocated payments"
                        position="top center"
                      />
                    )}
                  </>
                }
              />
            </Menu>
          </div>

          <Route path="/invoices">
            <InvoicesTable invoices={data?.invoices || []} />
          </Route>

          <Route path="/payments">
            <PaymentsTable
              onUpdateClick={(payment) => {
                setCurrentDialog({ type: 'update-payment', payment })
              }}
              onDeleteClick={(payment) => {
                setCurrentDialog({ type: 'delete-payment', payment })
              }}
              payments={data?.payments || []}
            />
          </Route>
        </div>
        <div className="w-[480px] min-w-[300px] overflow-y-auto border-l border-dotted border-border-grey px-6 py-3 [&::-webkit-scrollbar]:hidden">
          <Feed
            relatedCollection="feeds"
            showEditor={false}
            relatedId={'673dd83c3da0628a5f6c1514'}
          />
        </div>
      </div>
      <CreatePaymentDialog
        open={currentDialog?.type === 'create-payment'}
        onSuccess={handleSuccess}
        onCancel={() => setCurrentDialog(null)}
        invoices={(data?.invoices || []).filter(
          (i) => i.status === 'Sent' || i.status === 'Uncollectible',
        )}
      />

      {currentDialog?.type === 'update-payment' && (
        <UpdatePaymentDialog
          open
          onSuccess={handleSuccess}
          onCancel={() => setCurrentDialog(null)}
          payment={
            data?.payments.find((p) => p._id === currentDialog.payment._id) ||
            currentDialog.payment
          }
          invoices={(data?.invoices || []).filter(
            (i) =>
              i.customerId === currentDialog.payment.customerId &&
              i.status === 'Sent',
          )}
        />
      )}

      {currentDialog?.type === 'delete-payment' && (
        <DeletePaymentDialog
          open
          onSuccess={handleSuccess}
          onCancel={() => setCurrentDialog(null)}
          payment={currentDialog.payment}
        />
      )}
    </React.Fragment>
  )
}
