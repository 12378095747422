import {
  DropdownItem,
  Dropdown,
  Confirm,
  Button,
  Icon,
} from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { NavLink } from 'react-router-dom'
import { format } from 'date-fns'
import * as React from 'react'

import { Recipe, RecipeSchedule } from '../../types'
import UpdateRecipeScheduleForm from '../recipes/UpdateRecipeScheduleForm'
import { useToasts } from '../toasts/ToastsProvider'
import RemoteValue from '../remoteValues/remoteValue'
import { useApi } from '../../store/mainContext'
import UserName from '../remoteValues/userName'

interface Props {
  onSuccess(): Promise<any>
  schedule: RecipeSchedule
}

export default function PendingRecipe(props: Props) {
  const { schedule, onSuccess } = props

  const { addToast } = useToasts()
  const api = useApi()

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isRemoving, setIsRemoving] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)

  const hasArgs = Object.keys(schedule.args || {}).length > 0

  const handleConfirmRemove = async () => {
    setIsRemoving(true)
    return api
      .delete('recipe-schedules/' + schedule._id)
      .then(onSuccess)
      .then(() => setShowConfirmDeleteModal(false))
      .then(() => addToast('Recipe Schedule deleted', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsRemoving(false))
  }

  return (
    <React.Fragment>
      <div className="mb-5 rounded border border-dotted bg-[#fcfcfc] p-6">
        <header className="flex items-baseline gap-2">
          <div className="grow">
            <span className="text-lg text-slate-600">
              Recipe{' '}
              <RemoteValue
                predicate={(r: Recipe) => (
                  <NavLink
                    className="font-semibold !text-slate-700"
                    to={'/recipes/' + r._id}
                  >
                    {r.name}
                  </NavLink>
                )}
                collection="recipes"
                id={schedule.recipeId}
              />{' '}
              will run on{' '}
              <span className="font-semibold !text-slate-700">
                {format(schedule.whenToRun || 0, 'PPpp')}
              </span>
            </span>
          </div>
          <div className="flex">
            <button
              className="px-2 py-0.5 disabled:cursor-not-allowed disabled:opacity-30"
              onClick={() => setIsExpanded((v) => !v)}
              type="button"
              disabled={isEditMode}
            >
              <Icon name={isExpanded ? 'chevron up' : 'chevron down'} fitted />
            </button>
            <Dropdown
              direction="left"
              className="-mr-2 px-2"
              pointing="top left"
              icon="ellipsis horizontal"
            >
              <Dropdown.Menu>
                <DropdownItem onClick={() => setIsEditMode(true)}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => setShowConfirmDeleteModal(true)}>
                  <span className="text-red-600">Remove</span>
                </DropdownItem>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </header>

        <div className="mt-1.5 text-base text-slate-500">
          <span className="relative -top-px">
            <Icon name="calendar alternate outline" fitted size="small" />
          </span>{' '}
          Scheduled by{' '}
          <span className="font-semibold text-slate-600">
            <UserName id={schedule.createdBy} />
          </span>{' '}
          on {format(schedule.addedToTheQueueOn || 0, 'PPpp')}
        </div>

        {isExpanded && !isEditMode && hasArgs && (
          <div className="-mx-6 -mb-6 mt-4 border-t bg-white px-4 pb-2">
            <div className="mb-2 mt-4 px-2 text-slate-500">
              Recipe will be invoked with these arguments:
            </div>
            <table className="w-full">
              <thead>
                <tr className="border-b text-left text-sm font-semibold uppercase text-primary">
                  <th className="px-2 py-2">Argument</th>
                  <th className="px-2 py-2">Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(schedule.args || {}).map(
                  ([argName, argValue]) => {
                    return (
                      <tr
                        className="border-b align-baseline text-sm last:border-b-0"
                        key={argName}
                      >
                        <td className="px-2 py-2 font-mono font-semibold text-slate-500">
                          {argName}
                        </td>
                        <td className="px-2 py-2 text-base text-slate-700">
                          {argValue || (
                            <span className="font-mono italic text-slate-400">
                              empty
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
          </div>
        )}

        {isExpanded && !isEditMode && !hasArgs && (
          <div className="-mx-6 -mb-6 mt-4 border-t bg-white px-6 py-4 italic text-slate-500">
            The recipe will be invoked with <strong>no arguments</strong>.
          </div>
        )}

        {isEditMode && (
          <RemoteValue
            collection="recipes"
            id={schedule.recipeId}
            predicate={(recipe: Recipe) => (
              <div className="-mx-6 -mb-6 mt-4 border-t bg-white px-6 pb-6 pt-4">
                <UpdateRecipeScheduleForm
                  onSuccess={() => onSuccess().then(() => setIsEditMode(false))}
                  onCancel={() => setIsEditMode(false)}
                  schedule={schedule}
                  recipe={recipe}
                />
              </div>
            )}
          />
        )}
      </div>

      <Confirm
        header="Hey... removing the Recipe Schedule?"
        size="tiny"
        open={showConfirmDeleteModal}
        closeOnDimmerClick={!isRemoving}
        closeOnEscape={!isRemoving}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleConfirmRemove}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this Recipe Schedule.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        confirmButton={
          <Button
            primary={false}
            color="red"
            loading={isRemoving}
            disabled={isRemoving}
            content="Sure, remove."
          />
        }
      />
    </React.Fragment>
  )
}
