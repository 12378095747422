import {
  SubmitHandler,
  useFieldArray,
  Controller,
  useForm,
} from 'react-hook-form'
import { format, startOfDay, startOfToday } from 'date-fns'
import { Button, Form, Input, Ref } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import TextareaAutosize from 'react-textarea-autosize'
import { AxiosError } from 'axios'
import * as React from 'react'

import { Recipe, RecipeArgs, RecipeSchedule } from '../../types'
import { classNames } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

type FormValues = {
  args: (RecipeArgs & { value: string | number | null })[]
  date: Date
  time: string
}

interface Props {
  onSuccess(): Promise<any>
  onCancel(): void
  schedule: RecipeSchedule
  recipe: Recipe
}

export default function UpdateRecipeScheduleForm(props: Props) {
  const { schedule, recipe, onCancel, onSuccess } = props

  const { addToast } = useToasts()
  const api = useApi()

  const form = useForm<FormValues>({
    defaultValues: {
      args: (recipe.args || []).map((arg) => ({
        ...arg,
        value: schedule.args[arg.name],
      })),
      date: startOfDay(schedule.whenToRun || 0),
      time: format(schedule.whenToRun || 0, 'HH:mm'),
    },
  })

  const argsField = useFieldArray({ control: form.control, name: 'args' })

  const [isPending, setIsPending] = React.useState(false)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const [hours, minutes] = data.time.split(':').map(Number)
    setIsPending(true)
    return api
      .patch('recipe-schedules/' + schedule._id, {
        whenToRun:
          data.date.getTime() +
          (hours || 0) * 60 * 60 * 1000 +
          (minutes || 0) * 60 * 1000,
        args: data.args.reduce((acc, arg) => {
          if (arg.type === 'number' && isNaN(Number(arg.value))) return acc
          if (arg.type === 'number' && typeof arg.value !== 'number') return acc
          return {
            ...acc,
            [arg.name]: arg.type === 'number' ? arg.value : arg.value,
          }
        }, {}),
      })
      .then(onSuccess)
      .then(() => addToast('Recipe updated', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsPending(false))
  }

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)} spellCheck="false">
      {argsField.fields.length > 0 ? (
        <fieldset className="p-0">
          <div className="pb-4 text-base text-slate-600">
            This recipe requires some parameters to run.{' '}
            <strong className="font-semibold">Please fill them carefuly</strong>
            .
          </div>

          {argsField.fields.map((field, index) => {
            return (
              <Form.Field key={field.id}>
                <label className="leading-none">{field.displayName}</label>

                {field.type === 'long-text' && (
                  <div>
                    <TextareaAutosize
                      {...form.register(`args.${index}.value`)}
                      className={classNames(
                        'field !mb-0 !resize-none',
                        form.formState.isSubmitting && 'disabled',
                        form.formState.errors.args?.[index] && 'error',
                      )}
                      disabled={form.formState.isSubmitting}
                      minRows={3}
                      rows={3}
                    />
                    <div className="text-sm text-red-600">
                      {form.formState.errors.args?.[index]?.value?.message}
                    </div>
                  </div>
                )}

                {field.type === 'text' && (
                  <Controller
                    name={`args.${index}.value`}
                    control={form.control}
                    render={({ field: f, fieldState }) => {
                      return (
                        <div>
                          <Input
                            {...f}
                            onChange={(e) => f.onChange(e.target.value)}
                            disabled={form.formState.isSubmitting}
                            value={f.value || ''}
                            type="text"
                            fluid
                            error={!!fieldState.error}
                          />
                          <div className="text-sm text-red-600">
                            {fieldState.error?.message}
                          </div>
                        </div>
                      )
                    }}
                  />
                )}

                {field.type === 'number' && (
                  <Controller
                    name={`args.${index}.value`}
                    control={form.control}
                    render={({ field: f, fieldState }) => {
                      return (
                        <div>
                          <Input
                            {...f}
                            onChange={(e) => {
                              f.onChange(
                                isNaN(e.target.valueAsNumber)
                                  ? null
                                  : e.target.valueAsNumber,
                              )
                            }}
                            disabled={form.formState.isSubmitting}
                            value={f.value === null ? '' : f.value}
                            type="number"
                            fluid
                            input={<input className="disabled:!bg-slate-100" />}
                            error={!!fieldState.error}
                          />
                          <div className="text-sm text-red-600">
                            {fieldState.error?.message}
                          </div>
                        </div>
                      )
                    }}
                  />
                )}

                {field.description && (
                  <div className="mt-1.5 pl-px text-sm leading-none text-slate-500">
                    {field.description}
                  </div>
                )}
              </Form.Field>
            )
          })}
        </fieldset>
      ) : (
        <div className="mt-2">This recipe does not have any parameters.</div>
      )}

      <Form.Field className="!mt-4">
        <label className="leading-none">Scheduled time</label>
        <div className="grid grid-cols-2 items-start gap-4">
          <Controller
            name="date"
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => {
              return (
                <div className="[&_.field]:!mb-0 [&_.field]:!block">
                  <Ref innerRef={field.ref}>
                    <SemanticDatepicker
                      {...field}
                      onChange={(_, { value }) => field.onChange(value)}
                      className="!mb-0"
                      clearable={false}
                      clearOnSameDateClick={false}
                      minDate={startOfToday()}
                      error={!!fieldState.error}
                      disabled={form.formState.isSubmitting}
                    />
                  </Ref>
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </div>
              )
            }}
          />
          <Controller
            name="time"
            control={form.control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => {
              return (
                <div>
                  <Input
                    {...field}
                    className="[&_::-webkit-calendar-picker-indicator]:hidden [&_::-webkit-datetime-edit-hour-field:focus]:bg-slate-300 [&_::-webkit-datetime-edit-minute-field:focus]:bg-slate-300"
                    icon="clock"
                    type="time"
                    onChange={(_, { value }) => field.onChange(value)}
                    fluid
                    error={!!fieldState.error}
                    disabled={form.formState.isSubmitting}
                  />
                  <div className="text-base leading-normal text-red-600">
                    {fieldState.error?.message}
                  </div>
                </div>
              )
            }}
          />
        </div>
      </Form.Field>

      <div className="mt-6 flex gap-2">
        <Button
          content="Save"
          disabled={isPending}
          loading={isPending}
          type="submit"
          primary
        />
        <Button
          content="Cancel"
          onClick={onCancel}
          disabled={isPending}
          type="button"
          basic
        />
      </div>
    </Form>
  )
}
