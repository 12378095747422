import * as React from 'react'

import { Candidate, Company, Payroll, PayrollTransaction } from '../../types'
import MissingPaymentSetupsNews from '../MissingPaymentSetupsNews'
import PaymentSetupUpdatesNews from '../PaymentSetupUpdatesNews'
import StartedDealsNews from '../StartedDealsNews'
import EndedDealsNews from '../EndedDealsNews'
import TimeoffNews from '../TimeoffNews'

type ExtraFields = { candidate?: Candidate; customer?: Company }

interface Props {
  payroll: Payroll & { transactions: (PayrollTransaction & ExtraFields)[] }
}

export default function PeriodNews(props: Props) {
  const { payroll } = props

  return (
    <div className="space-y-8 py-2">
      <EndedDealsNews
        periodStart={payroll.periodStart}
        periodEnd={payroll.periodEnd}
      />

      <StartedDealsNews
        periodStart={payroll.periodStart}
        periodEnd={payroll.periodEnd}
      />

      <TimeoffNews
        periodStart={payroll.periodStart}
        periodEnd={payroll.periodEnd}
      />

      <PaymentSetupUpdatesNews
        periodStart={payroll.periodStart}
        periodEnd={payroll.periodEnd}
      />

      <MissingPaymentSetupsNews
        periodCandidates={payroll.transactions}
        periodStart={payroll.periodStart}
        periodEnd={payroll.periodEnd}
      />
    </div>
  )
}
