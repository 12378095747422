import { Switch, Route } from 'react-router-dom'
import * as React from 'react'

import CandidatesSearchPage from './pages/candidates/CandidatesSearchPage'
import Candidates from './pages/candidates/candidates'
import Candidate from './pages/candidates/candidate'
import Positions from './pages/positions/positions'
import Position from './pages/positions/position'
import SideBar from './components/sidebar/Sidebar'

import Tasks from './pages/tasks/tasks'

import Contacts from './pages/contacts/contacts'
import Contact from './pages/contacts/contact'

import Companies from './pages/companies/companies'
import Company from './pages/companies/company'

import Contracts from './pages/contracts/contracts'
import FunnelEdit from './pages/funnels/funnelEdit'

import DashboardPage from './pages/billing/DashboardPage'
import BillingPeriodPage from './pages/billing/BillingPeriodPage'
import BillingPage from './pages/billing/BillingPage'

import InvoicesPage from './pages/invoices/InvoicesPage'
import InvoicePage from './pages/invoices/InvoicePage'

import Dashboard from './pages/dashboard/Dashboard'

import PayrollPage from './pages/payrolls/Payroll'
import Payrolls from './pages/payrolls/Payrolls'

import PaymentOrderPage from './pages/payment-orders/PaymentOrder'
import PaymentOrders from './pages/payment-orders/PaymentOrders'

import Suppliers from './pages/suppliers/Suppliers'

import RecipePage from './pages/recipes/RecipePage'
import RecipesPage from './pages/recipes/RecipesPage'

import EmailSyncSettingsPage from './pages/settings/emailSyncSettings'
import AccessControlSettings from './pages/settings/accessControlSettings'
import ExternalRecruitersSettings from './pages/settings/externalRecruitersSettings'
import EmailList from './pages/emails/emailList'
import ThreadPage from './pages/emails/email'

import FunnelPage from './pages/funnels/FunnelDetailPage'
import ContactsFunnelDetailPage from './pages/funnels/ContactsFunnelDetailPage'

const App = () => (
  <div className="flex flex-row items-stretch justify-start">
    <SideBar />

    <div id="content" className="w-full pl-[63px]">
      <Switch>
        <Route path="/emails/:label/:id" exact component={ThreadPage} />
        <Route path="/emails/:label" exact component={EmailList} />

        <Route
          path={[
            '/billing/:billingPeriodId/invoice/:invoiceId',
            '/billing/:billingPeriodId',
          ]}
          component={BillingPeriodPage}
        />
        <Route path="/billing" exact component={BillingPage} />
        <Route path="/billing-dashboard" exact component={DashboardPage} />

        <Route path="/invoices/:invoiceId/:tab" exact component={InvoicePage} />
        <Route path="/invoices/:invoiceId" exact component={InvoicePage} />
        <Route
          path={['/invoices', '/payments']}
          exact
          component={InvoicesPage}
        />

        <Route path="/payrolls/:payrollId" exact component={PayrollPage} />
        <Route path="/payrolls" exact component={Payrolls} />

        <Route
          path="/payment-orders/:paymentOrderId"
          exact
          component={PaymentOrderPage}
        />
        <Route path="/payment-orders" exact component={PaymentOrders} />

        <Route path="/contracts" exact component={Contracts} />

        <Route path="/positions/:positionId" exact component={Position} />
        <Route path="/positions" exact component={Positions} />

        <Route
          path="/candidates/search"
          exact
          component={CandidatesSearchPage}
        />

        <Route path="/candidates/:candidateId" exact component={Candidate} />
        <Route path="/candidates" exact component={Candidates} />

        <Route
          path="/candidates/funnels/:funnelName"
          exact
          component={FunnelPage}
        />
        <Route
          path="/candidates/funnels/:funnelName/edit"
          exact
          component={FunnelEdit}
        />

        <Route path="/contacts/:contactId" exact component={Contact} />
        <Route path="/contacts" exact component={Contacts} />

        <Route
          path="/contacts/funnels/:funnelName"
          exact
          component={ContactsFunnelDetailPage}
        />

        <Route
          path="/contacts/funnels/:funnelName/edit"
          exact
          component={FunnelEdit}
        />

        <Route path="/companies/:companyId" exact component={Company} />
        <Route path="/companies" exact component={Companies} />

        <Route
          path={[
            '/recipes/:recipeId/raw',
            '/recipes/:recipeId/schedules',
            '/recipes/:recipeId/history',
            '/recipes/:recipeId',
          ]}
          exact
          component={RecipePage}
        />
        <Route path="/recipes" exact component={RecipesPage} />

        <Route
          path="/settings/email-sync"
          exact
          component={EmailSyncSettingsPage}
        />

        <Route
          path="/settings/external-recruiters"
          exact
          component={ExternalRecruitersSettings}
        />

        <Route path="/settings" exact component={AccessControlSettings} />

        <Route path="/tasks" exact component={Tasks} />
        <Route path="/suppliers" exact component={Suppliers} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/" exact component={Candidates} />
      </Switch>
    </div>
  </div>
)

export default App
