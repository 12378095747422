import { io } from 'socket.io-client'

import { ENV_VARS } from './env'

export const socket = io(
  ENV_VARS.REACT_APP_TALENT_WS_DOMAIN || 'ws://localhost:8080/',
  {
    path: '/v1/talent/ws',
    transports: ['websocket'],
    reconnection: ENV_VARS.REACT_APP_TALENT_WS_RECONNECTION !== 'false',
  },
)
