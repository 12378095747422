import useSWR from 'swr'
import { z } from 'zod'

import { Task, User, taskSchema } from '../types'
import { validateResponse } from '../utils'
import { useTalentApi } from '../store/mainContext'

export default function useTasksAssignedToUser(userId: User['_id']) {
  const api = useTalentApi()

  return useSWR<Task[], Error>(['tasks-assignedToId', userId], () =>
    api
      .get('tasks/pro', {
        params: {
          assignedToId: userId,
        },
        headers: {
          'astor-sortby': 'dueDate',
          'astor-sortby-dir': '1',
        },
      })
      .then(validateResponse(z.array(taskSchema))),
  )
}
