import { Link, Redirect, useParams } from 'react-router-dom'
import { Breadcrumb, Menu } from 'semantic-ui-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import CompanySidePanel from '../../components/sidepanels/companySidePanel'
import { useTalentApi } from '../../store/mainContext'
import ContractsDetail from '../../components/contractsDetail'
import CompanyActions from '../../components/actions/companyActions'
import LinkedinViewer from '../../components/linkedinViewer'
import ContactsDetail from '../../components/contactsDetail'
import { classNames } from '../../utils'
import { useToasts } from '../../components/toasts/ToastsProvider'
import RequirePowers from '../../components/requirePowers'
import { Company } from '../../types'
import PageLoader from '../../components/pageLoader'
import useCompany from '../../hooks/useCompany'
import POWERS from '../../powers'
import Feed from '../../components/feeds/feed'

type Tab = 'feed' | 'contacts' | 'rate-card' | 'candidates'

const CompanyPage = () => {
  const { companyId } = useParams<{ companyId: string }>()

  const { addToast } = useToasts()
  const api = useTalentApi()

  const [selectedTab, setSelectedTab] = useState<Tab>('feed')

  const { data: company, isLoading, mutate } = useCompany(companyId)

  const handleCompanyChange = (property: keyof Company, value: any) => {
    if (!company) return
    const updatedProps = { [property]: value }

    mutate(() => api.patch(`companies/${companyId}`, updatedProps), {
      optimisticData: { ...company, ...updatedProps },
      populateCache: false,
      rollbackOnError: true,
    }).catch((e) => addToast(e.message, { variant: 'danger' }))
  }

  if (isLoading) return <PageLoader />

  if (!company) {
    addToast('Company not found', { variant: 'danger' })
    return <Redirect to="/companies" />
  }

  return (
    <div className="flex justify-between">
      <Helmet>
        <title>{company.name}</title>
      </Helmet>

      <div className="sticky top-0 z-50 ml-3 max-h-screen w-[400px] shrink-0 overflow-auto pb-28 pr-3 [&::-webkit-scrollbar]:hidden">
        <Breadcrumb className="py-4 pl-8 pr-2">
          <Breadcrumb.Section>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section>
            {!company.status && <Link to="/companies">Companies</Link>}

            {company.status && (
              <Link to={`/companies/funnels/${company.status.split('.')[0]}`}>
                Funnel
              </Link>
            )}
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section active>{company.name}</Breadcrumb.Section>
        </Breadcrumb>

        <h1 className="mb-0 ml-8 mr-6 mt-4">{company.name}</h1>

        {company.sector && (
          <div className="ml-8 mr-6 mt-1 text-base font-semibold text-slate-500">
            {company.sector}
          </div>
        )}

        {company.location && (
          <div className="ml-8 mr-6 mt-1 text-base font-semibold text-slate-500">
            {company.location}
          </div>
        )}

        <CompanyActions company={company} onSuccess={handleCompanyChange} />

        <CompanySidePanel
          onChangeSuccess={handleCompanyChange}
          company={company}
        />
      </div>

      <div
        className={classNames(
          'w-full min-w-[500px] p-6',
          selectedTab === 'rate-card' && 'flex flex-col',
        )}
      >
        <RequirePowers powers={[POWERS.contracts]}>
          <Menu tabular>
            <Menu.Item
              icon="feed"
              name="Feed"
              active={selectedTab === 'feed'}
              onClick={() => setSelectedTab('feed')}
            />

            <Menu.Item
              icon="users"
              name="Contacts"
              active={selectedTab === 'contacts'}
              onClick={() => setSelectedTab('contacts')}
            />

            <RequirePowers powers={[POWERS.customerSOWs]}>
              <Menu.Item
                icon="address card outline"
                name="Rate Card"
                active={selectedTab === 'rate-card'}
                onClick={() => setSelectedTab('rate-card')}
                disabled={!company.rateCard}
              />
            </RequirePowers>

            <Menu.Item
              icon="file alternate outline"
              name="Contracts"
              active={selectedTab === 'candidates'}
              onClick={() => setSelectedTab('candidates')}
            />
          </Menu>
        </RequirePowers>

        {selectedTab === 'feed' && (
          <Feed relatedId={company._id} relatedCollection="companies" />
        )}

        {selectedTab === 'contacts' && (
          <ContactsDetail companyId={company._id} />
        )}

        {selectedTab === 'candidates' && (
          <ContractsDetail
            contractsFilter={{ customerId: company._id }}
            submissionsFilter={{ customerId: company._id }}
          />
        )}

        {selectedTab === 'rate-card' && company.rateCard && (
          <iframe
            className="w-full grow"
            title="Company Rate Card"
            src={company.rateCard}
          />
        )}
      </div>

      {selectedTab === 'feed' && <LinkedinViewer url={company.linkedin} />}
    </div>
  )
}

export default CompanyPage
