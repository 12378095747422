import React, { useState } from 'react'
import { Select } from 'semantic-ui-react'
import useSWR from 'swr'
import { z } from 'zod'

import { Collection, Recipe, recipeSchema } from '../../../types'
import { validateResponse } from '../../../utils'
import RecipeSchedulerForm from '../../recipes/RecipeSchedulerForm'
import { useApi } from '../../../store/mainContext'

interface Props {
  inlineDateTime?: boolean
  relatedCollection: Collection
  onSuccess(): Promise<any>
  relatedId: string
  onCancel?(): void
}

export default function RecipeScheduler(props: Props) {
  const { inlineDateTime, relatedCollection, onSuccess, relatedId, onCancel } =
    props

  const api = useApi()

  const recipes = useSWR(['collection-recipes', relatedCollection], () =>
    api
      .get('recipes', {
        // get only published recipes
        params: {
          collection: relatedCollection,
          triggerEnable: true,
          type: 'manual',
        },
      })
      .then(validateResponse(z.array(recipeSchema))),
  )

  const [selectedRecipeId, setSelectedRecipeId] = useState<Recipe['_id']>()

  const selectedRecipe = recipes.data?.find(
    ({ _id }) => _id === selectedRecipeId,
  )

  return (
    <div>
      <label className="block pl-0.5 text-sm font-semibold">Recipe</label>
      <Select
        placeholder="Pick one..."
        options={(recipes.data || []).map((r) => ({
          value: r._id,
          text: r.name,
        }))}
        onChange={(_, { value }) => {
          if (typeof value === 'string') setSelectedRecipeId(value)
        }}
        selectOnBlur={false}
        loading={recipes.isLoading}
        value={selectedRecipeId}
        search
        fluid
      />

      {selectedRecipe && (
        <RecipeSchedulerForm
          inlineDateTime={inlineDateTime}
          relatedCollection={relatedCollection}
          relatedId={relatedId}
          onSuccess={onSuccess}
          recipe={selectedRecipe}
          key={selectedRecipeId}
          onCancel={onCancel}
        />
      )}
    </div>
  )
}
