import { Button, Popup } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import * as React from 'react'

import { classNames, currencyFormat } from '../../utils'
import { EnhancedBillingPeriod } from '../../hooks/useBillingPeriod'
import InvoiceStatusLabel from '../invoices/InvoiceStatusLabel'

interface Props {
  onInvoiceCreateClick(): Promise<any>
  isCreatingInvoice?: boolean
  period: EnhancedBillingPeriod
}

export default function PeriodInvoicesList(props: Props) {
  const { onInvoiceCreateClick, isCreatingInvoice, period } = props

  return (
    <React.Fragment>
      <header className="sticky top-0 flex items-baseline justify-between gap-4 border-b bg-white py-3 pl-4 pr-3">
        <span className="text-sm font-semibold uppercase leading-[29px] text-slate-500">
          Invoices{' '}
          <span className="ml-0.5 font-normal text-slate-400">
            ({period.invoices.length.toString()})
          </span>
        </span>
        {period.status === 'Draft' && (
          <Popup
            content="Add Invoice"
            position="left center"
            disabled={isCreatingInvoice}
            trigger={
              <Button
                onClick={onInvoiceCreateClick}
                disabled={isCreatingInvoice}
                loading={isCreatingInvoice}
                icon="plus"
                basic
                size="mini"
                className="!mr-0"
              />
            }
          />
        )}
      </header>

      {period.invoices.length > 0 ? (
        <ul className="divide-y border-b">
          {period.invoices.map((invoice) => {
            return (
              <li key={invoice._id}>
                <NavLink
                  to={`/billing/${period._id}/invoice/${invoice._id}`}
                  className={classNames(
                    'block w-full border-r-4 border-r-transparent bg-white py-4 pl-4 pr-3 text-left !text-slate-600 hover:bg-slate-50',
                  )}
                  activeClassName="!border-r-primary !bg-primary/5"
                >
                  <div className="flex items-baseline justify-between gap-4">
                    <div className="leading-snug">
                      {invoice.name || (
                        <span className="italic text-slate-400">No name</span>
                      )}
                      {invoice.name &&
                      invoice.customer?.name &&
                      invoice.name.includes(invoice.customer.name) ? null : (
                        <>
                          <span className="text-slate-400">&ndash;</span>{' '}
                          {invoice.customer?.name ? (
                            <span className="text-nowrap">
                              {invoice.customer.name}
                            </span>
                          ) : (
                            <span className="italic text-slate-400">
                              No Customer
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <InvoiceStatusLabel
                        status={invoice.status}
                        dueDate={invoice.dueDate}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <span className="text-xs font-semibold uppercase text-slate-500">
                      Billed:
                    </span>{' '}
                    <span className="text-sm">
                      {currencyFormat(invoice.totalBilled || 0)}
                    </span>
                    {(invoice.totalPaid || 0) > 0 && (
                      <>
                        <span className="mx-1 text-slate-400">&ndash;</span>
                        <span className="text-xs font-semibold uppercase text-slate-500">
                          Paid:
                        </span>{' '}
                        <span className="text-sm">
                          {currencyFormat(invoice.totalPaid || 0)}
                        </span>
                      </>
                    )}
                  </div>
                </NavLink>
              </li>
            )
          })}
        </ul>
      ) : (
        <div className="px-3 py-8 text-center text-slate-500">No invoices</div>
      )}
    </React.Fragment>
  )
}
