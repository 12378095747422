import {
  ButtonGroup,
  Dropdown,
  Confirm,
  Button,
  Popup,
} from 'semantic-ui-react'
import { KeyedMutator } from 'swr'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import * as React from 'react'

import { EnhancedBillingPeriod } from '../../hooks/useBillingPeriod'
import BillingPeriodStatusLabel from './BillingPeriodStatusLabel'
import InvoiceSchedulerForm from '../invoices/InvoiceSchedulerForm'
import InvoiceStatusLabel from '../invoices/InvoiceStatusLabel'
import { classNames } from '../../utils'
import { useToasts } from '../toasts/ToastsProvider'
import { useApi } from '../../store/mainContext'

type Dialog = 'delete-period' | 'mark-as-complete' | 'confirm-send'

interface Props {
  mutate: KeyedMutator<EnhancedBillingPeriod>
  period: EnhancedBillingPeriod
}

export default function PeriodActions(props: Props) {
  const { period, mutate } = props

  const { addToast } = useToasts()
  const history = useHistory()
  const api = useApi()

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isDeleting, setIsDeleting] = React.useState(false)

  const handleDelete = async () => {
    setIsDeleting(true)
    return api
      .delete('billing-periods/' + period._id)
      .then(() => history.push('/billing'))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsDeleting(false))
  }

  const [isMarkingAsComplete, setIsMarkingAsComplete] = React.useState(false)

  const handleMarkAsComplete = async () => {
    setIsMarkingAsComplete(true)
    return api
      .patch('billing-periods/' + period._id, { status: 'Completed' })
      .then(() => mutate())
      .then(() => setCurrentDialog(null))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsMarkingAsComplete(false))
  }

  const [isScheduling, setIsScheduling] = React.useState(false)

  const handleScheduleInvoices = async (sendAt: number) => {
    setIsScheduling(true)
    return api
      .post(`billing-periods/${period._id}/scheduled-invoices`, {
        sendAt,
      })
      .then(() => mutate())
      .then(() => setShouldSchedule(false))
      .then(() => setCurrentDialog(null))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsScheduling(false))
  }

  const canDelete =
    period.status === 'Draft' &&
    period.invoices.every((invoice) => invoice.status === 'Draft')

  const canSchedule =
    period.status === 'Draft' &&
    period.invoices.some((invoice) => invoice.status === 'Ready to be Sent')

  const [shouldSchedule, setShouldSchedule] = React.useState(false)

  return (
    <React.Fragment>
      <div className="flex items-baseline gap-2">
        <div className="grow">
          <span className="mr-2 text-sm font-semibold uppercase leading-[29px] text-slate-500">
            Status:
          </span>
          <BillingPeriodStatusLabel status={period.status} />
        </div>

        {period.status === 'Draft' && (
          <Popup
            content={
              <>
                Only <InvoiceStatusLabel status="Ready to be Sent" /> invoices
                can be scheduled for delivery.
              </>
            }
            position="top center"
            trigger={
              <ButtonGroup
                className={classNames(!canSchedule && 'cursor-not-allowed')}
                size="small"
                compact
                basic
              >
                <InvoiceSchedulerForm
                  title="Schedule Invoices Delivery"
                  description={
                    <>
                      Select a date and local time at which{' '}
                      <InvoiceStatusLabel status="Ready to be Sent" /> invoices
                      should be sent.{' '}
                      <em>Invoices with a different status will be ignored</em>.
                    </>
                  }
                  isPending={isScheduling}
                  onSubmit={handleScheduleInvoices}
                  onCancel={() => setShouldSchedule(false)}
                  isOpen={shouldSchedule}
                >
                  <Button
                    content="Schedule"
                    icon="calendar alternate outline"
                    className="!mr-0"
                    onClick={() => setShouldSchedule(true)}
                    disabled={shouldSchedule || !canSchedule}
                    type="button"
                  />
                </InvoiceSchedulerForm>
                <Dropdown
                  className="button icon"
                  disabled={shouldSchedule || !canSchedule}
                  compact
                  floating
                  pointing="top right"
                  options={[
                    {
                      icon: 'paper plane outline',
                      text: 'Send all invoices now',
                      onClick: () => setCurrentDialog('confirm-send'),
                      key: 'send-now',
                    },
                  ]}
                  trigger={<></>}
                />
              </ButtonGroup>
            }
          />
        )}

        {period.status === 'Draft' && (
          <Popup
            position="top center"
            content={
              <>
                All invoices must be in <InvoiceStatusLabel status="Draft" /> to
                be able to delete the Billing Period.
              </>
            }
            trigger={
              <div className={classNames(!canDelete && 'cursor-not-allowed')}>
                <Button
                  onClick={() => setCurrentDialog('delete-period')}
                  disabled={!canDelete}
                  compact
                  icon="trash alternate outline"
                  basic
                  color="red"
                  size="small"
                  className="!mr-0"
                />
              </div>
            }
          />
        )}
      </div>

      <Confirm
        header="Hey... removing the Billing Period?"
        content="This action cannot be undone."
        closeOnDimmerClick={!isDeleting}
        closeOnEscape={!isDeleting}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={handleDelete}
        open={currentDialog === 'delete-period'}
        cancelButton={<Button disabled={isDeleting} content="Cancel" />}
        confirmButton={
          <Button
            disabled={isDeleting}
            loading={isDeleting}
            content="Sure, remove"
            primary={false}
            color="red"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... marking as complete?"
        content="This action cannot be undone."
        closeOnDimmerClick={!isMarkingAsComplete}
        closeOnEscape={!isMarkingAsComplete}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={handleMarkAsComplete}
        open={currentDialog === 'mark-as-complete'}
        cancelButton={
          <Button disabled={isMarkingAsComplete} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isMarkingAsComplete}
            loading={isMarkingAsComplete}
            content="Sure, Mark as Complete"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... sending all ready invoices?"
        content={
          <div className="px-6 text-base">
            <div>Please note that the invoices</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>will be sent via e-mail to the customer,</li>
              <li>
                will change their status to{' '}
                <InvoiceStatusLabel status="Scheduled" />,
              </li>
              <li>
                will no longer be able to be converted back to{' '}
                <InvoiceStatusLabel status="Draft" /> or deleted.
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={!isScheduling}
        closeOnEscape={!isScheduling}
        onCancel={() => setCurrentDialog(null)}
        onConfirm={() => handleScheduleInvoices(Date.now() + 1000 * 60)}
        open={currentDialog === 'confirm-send'}
        cancelButton={<Button disabled={isScheduling} content="Cancel" />}
        confirmButton={
          <Button
            disabled={isScheduling}
            loading={isScheduling}
            content="Sure, send all ready invoices"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />
    </React.Fragment>
  )
}
