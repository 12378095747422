import { format, isPast, isSameDay } from 'date-fns'
import { Button, Popup } from 'semantic-ui-react'
import * as React from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import { z } from 'zod'

import { Candidate, TimeoffRequest, timeoffRequestSchema } from '../../types'
import { classNames, currencyFormat, validateResponse } from '../../utils'
import useTimeoffCoverage from '../../hooks/useTimeoffCoverage'
import { useApi } from '../../store/mainContext'

const Container = styled.div`
  margin: 0px 30px 30px 30px;
  border: dotted 1px #e9c776;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff8db;
  color: #b58105;
`

const Row = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: dotted 1px #e9c776;
  border-top: dotted 1px #e9c776;
  margin-bottom: -1px;
  padding: 5px;
`

interface ItemProps {
  timeoffRequest: TimeoffRequest
}

function Item(props: ItemProps) {
  const coverage = useTimeoffCoverage(props.timeoffRequest._id)

  return (
    <>
      <div className="inline-flex flex-wrap gap-x-2 pr-6">
        {props.timeoffRequest.dates.map((day, i) => {
          const tx = (coverage.data || []).find(
            (item) => isSameDay(item.date, day) && !item.coveredByPtoPolicy,
          )
          const isNotCovered = Boolean(tx)
          return (
            <span
              className={classNames(
                isNotCovered
                  ? 'cursor-help text-red-700'
                  : isPast(day)
                    ? 'text-slate-300'
                    : 'text-slate-600',
                'whitespace-nowrap text-base',
              )}
              key={day.toISOString()}
            >
              <Popup
                size="mini"
                content={
                  <div>
                    Not covered by PTO policy
                    {typeof tx?.dayValue === 'number' && (
                      <div>Cost: {currencyFormat(tx.dayValue)}</div>
                    )}
                  </div>
                }
                position="top center"
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                disabled={!isNotCovered}
                trigger={
                  <span
                    className={classNames(
                      isNotCovered &&
                        'underline decoration-red-600 decoration-dotted underline-offset-4',
                    )}
                  >
                    {format(day, 'MMM do')}
                  </span>
                }
              />

              {i < props.timeoffRequest.dates.length - 1 && (
                <span className="text-slate-800">,</span>
              )}
            </span>
          )
        })}
      </div>
      {props.timeoffRequest.status && (
        <div
          className={classNames(
            'inline-block rounded px-1.5 py-0.5 text-xs font-semibold uppercase',
            props.timeoffRequest.status === 'Approved' &&
              'bg-green-300 text-green-800',
            props.timeoffRequest.status === 'Rejected' &&
              'bg-red-200 text-red-800',
            props.timeoffRequest.status === 'Canceled' &&
              'bg-slate-200 text-slate-700',
            props.timeoffRequest.status === 'Waiting for approval' &&
              props.timeoffRequest.underReview
              ? 'bg-cyan-200 text-cyan-800'
              : 'bg-amber-200 text-amber-800',
          )}
        >
          {props.timeoffRequest.status === 'Waiting for approval' &&
          props.timeoffRequest.underReview
            ? 'Under review'
            : props.timeoffRequest.status}
        </div>
      )}
    </>
  )
}

interface Props {
  onReviewClick(request: TimeoffRequest): void
  candidateId: Candidate['_id']
}

export default function TimeoffForReview(props: Props) {
  const { candidateId, onReviewClick } = props
  const api = useApi()

  const requests = useSWR(['timeoff-for-review', candidateId], () =>
    api
      .get(candidateId + '/active-contract/timeoff-requests')
      .then(validateResponse(z.array(timeoffRequestSchema))),
  )

  // need this because API is not using request params
  const requestsForReview = (requests.data || [])?.filter(
    (r) => r.status === 'Waiting for approval',
  )

  if (requests.isLoading || requests.error || requestsForReview.length === 0) {
    return null
  }

  return (
    <Container>
      <p>
        {requestsForReview.length === 1 ? (
          <b>There is a timeoff request for review:</b>
        ) : (
          <b>
            There are {requestsForReview.length} timeoff requests for review:
          </b>
        )}
      </p>

      {requestsForReview.map((request) => (
        <Row key={request._id}>
          <div className="relative top-px">
            <Item timeoffRequest={request} />
          </div>
          <Button size="mini" primary onClick={() => onReviewClick(request)}>
            Review
          </Button>
        </Row>
      ))}
    </Container>
  )
}
